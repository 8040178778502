export enum USER_ACCESS_LEVEL {
  SUPER_ADMIN = 0,
  SUPER = 0,
  ADMIN = 1,
  LEARNER = 2
}

export interface IUser {
  _id: string;
  email: string;
  name: string;
  set_password_duedate?: Date;
  set_password_sent_at?: Date;
  has_created_password: boolean;
  set_password_uuid: string;
  accounts: string[];
  locale: string;
}
export interface IUserWithTeamAndAccess {
  team: any;
}
