import { message } from 'antd';
import apiClient from 'api/apiClient';
import {
  deleteCourseNote,
  getCourseInformation,
  getCourseProgress,
  postCreateCourseNote,
  unlockCourseProgress,
  updateCourseProgress
} from 'api/apiRoutes';
import { UserContext } from 'context/UserContext';
import { createContext, FC, useContext, useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { Course } from 'types/Course/course.types';
import { ICourseNote } from 'types/Course/CourseNoteTypes';
import { ICourseCompletionProgress } from 'types/Course/CourseTypes';

interface ICourseContext {
  course: Course | undefined;
  courseProgress: ICourseCompletionProgress | undefined;

  notes: ICourseNote[];

  videoStartTime: number;
  videoTime: number;

  // Functions

  startNewCourse: (subjectIdentifier: string) => void;
  completeCourse: () => void;
  advanceCourseProgress: Function;
  unlockSubject: Function;

  createNote: (noteContent: string) => void;
  deleteNote: (identifier: string) => void;

  setVideoTime: (time: number) => void;
  setVideoStartTime: (time: number) => void;
}

export const CourseContext = createContext<ICourseContext>({
  course: undefined,
  courseProgress: undefined,
  notes: [],
  videoTime: 0,
  videoStartTime: 0,

  advanceCourseProgress: () => {},
  startNewCourse: () => {},
  completeCourse: () => {},
  unlockSubject: () => {},

  createNote: () => {},
  deleteNote: () => {},

  setVideoTime: () => {},
  setVideoStartTime: () => {}
});

export const CourseContextProvider: FC = ({ children }) => {
  const { accountData } = useContext(UserContext);
  const { courseIdentifier, moduleIdentifier, subjectIdentifier } = useParams();
  const { data: course } = useSWR<Course>(getCourseInformation(courseIdentifier));
  const { data: courseProgress, mutate: refreshCourseProgress } = useSWR(
    getCourseProgress(courseIdentifier, accountData?._id)
  );
  const { data: notes = [], mutate: refreshNotes } = useSWR<ICourseNote[]>(course?._id ? `/notes/${course._id}` : null);

  const [videoTime, setVideoTime] = useState<number>(0);
  const [videoStartTime, setVideoStartTime] = useState<number>(0);

  const startNewCourse = async (subjectId: string) => {
    if (!course || !accountData) return;
    const startCourseUri = `/progresses/start/${accountData._id}/${course._id}/${subjectId}`;
    await apiClient.post(startCourseUri);
    refreshCourseProgress();
  };

  const completeCourse = async () => {
    if (!course || !accountData) return;
    const completeCourseUri = `/progresses/complete/${accountData._id}/${course._id}`;
    const completingCourse = await apiClient.patch(completeCourseUri).catch((err) => null);
    if (!completingCourse) {
      message.error("Couldn't complete course");
      return;
    }
    refreshCourseProgress();
  };

  const advanceCourseProgress = async (subjectId: string) => {
    if (!course) return;
    const updateUri = updateCourseProgress(course._id, 'module', subjectId, accountData?._id);
    if (!updateUri) return;
    await apiClient.patch(updateUri);
    refreshCourseProgress();
  };
  const unlockSubject = async (subjectId: string) => {
    if (!course || !accountData) return;
    if ((courseProgress?.completed_subjects ?? []).includes(subjectId)) return;
    const updateUri = unlockCourseProgress(course._id, subjectId, accountData?._id);
    if (!updateUri) return;
    await apiClient.patch(updateUri);
    refreshCourseProgress();
  };

  const createNote = async (noteContent: string) => {
    if (!courseIdentifier || !moduleIdentifier || !subjectIdentifier) return;
    await apiClient.post(postCreateCourseNote(courseIdentifier, moduleIdentifier, subjectIdentifier), {
      content: noteContent
    });
    refreshNotes();
  };

  const deleteNote = async (noteIdentifier: string) => {
    await apiClient.delete(deleteCourseNote(noteIdentifier));
    refreshNotes();
  };

  const contextValues = { course, courseProgress, notes, videoTime, videoStartTime };
  const contextFunctions = {
    startNewCourse,
    completeCourse,
    advanceCourseProgress,
    unlockSubject,

    refreshNotes,
    createNote,
    deleteNote,

    setVideoTime,
    setVideoStartTime
  };
  return <CourseContext.Provider value={{ ...contextValues, ...contextFunctions }}>{children}</CourseContext.Provider>;
};

export default CourseContextProvider;
