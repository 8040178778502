import { CourseQuestionnaire, CourseQuestionnaireQuestion } from './course.content.questionnaire.types';

export interface ICourseQuiz extends CourseQuestionnaire {
  pass_percentage?: number;
  questions: ICourseQuizQuestion[];
}

export interface ICourseQuizQuestion extends CourseQuestionnaireQuestion {}

export interface ICourseQuizQuestionAnswer {
  _id: string;
  answer: string;
  isCorrect: boolean;
}

export const isTypeofQuiz = (arg: ICourseQuiz | unknown): arg is ICourseQuiz =>
  (arg as ICourseQuiz).title !== undefined;
