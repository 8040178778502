import Header from 'components/header/Header';
import { Navigate, Outlet, Route } from 'react-router-dom';
import OrganizationManagementRouter from 'screens/Admin/Management/OrganizationManagementRouter';
import CourseDirectory from './CourseDirectory/CourseDirectory';
import CreateCourseView from './CreateCourseScreen/CreateCourseView';

const SuperAdminRoutes = [
  {
    name: 'Courses',
    path: '/courses'
  },
  {
    name: 'Teams & Users',
    path: '/management'
  },
  {
    name: 'Settings',
    path: '/settings'
  }
];

const SuperAdminRouter = (
  <Route
    element={
      <>
        <Header links={SuperAdminRoutes} />
        <Outlet />
      </>
    }
  >
    <Route path={'courses'}>
      <Route path={':courseId'} element={<CreateCourseView />} />
      <Route path="" element={<CourseDirectory />} />
    </Route>

    {OrganizationManagementRouter}

    <Route path="*" element={<Navigate to="/courses" />} />
  </Route>
);

export default SuperAdminRouter;
