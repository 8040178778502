import { FC } from 'react';
import ComplokIcon from 'components/icons/ComplokIcon';
import { PageHeader, Col, Row, Popover } from 'antd';

const AuthenticationHeader = () => (
  <PageHeader
    style={{ background: 'inherit' }}
    className="complok-auth-header"
    title={<ComplokIcon />}
    extra={
      <Row style={{ alignItems: 'center' }}>
        <Col>
          <div className="complok-auth-header-language">Language:</div>
        </Col>
        <Col>
          <Popover
            className="complok-header-popover"
            trigger={'click'}
            placement={'bottomRight'}
            content={() => <LanguagePopoverContent actions={[{ title: 'English' }]} />}
          >
            <div className="complok-auth-header-language-selected">English</div>
          </Popover>
        </Col>
      </Row>
    }
  />
);

const LanguagePopoverContent: FC<{ actions: { title: string; action?: Function }[] }> = ({ actions = [] }) => (
  <div className="complok-header-profile-popover">
    {actions.map((action, index) => (
      <div
        className={`complok-header-profile-popover-item ${
          index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
        }`}
        key={`complok-header-popover-action-${index}`}
      >
        {action.title}
      </div>
    ))}
  </div>
);

export default AuthenticationHeader;
