/* eslint-disable operator-linebreak */
import { SettingFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Tabs } from 'antd';
import apiClient from 'api/apiClient';
import BlueFlag from 'components/icons/BlueFlag';
import CheckBox from 'components/icons/CheckBox';
import CheckedCheckBox from 'components/icons/CheckedCheckBox';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import RoundAddIcon from 'components/icons/RoundAddIcon';
import SuccessCheck from 'components/icons/SuccessCheck';
import Button from 'components/interactables/Button';
import { isEqual, last } from 'lodash';
import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CourseEditContext } from 'screens/SuperAdmin/CourseEdit/CourseEdit';
import { mutate } from 'swr';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { ICourseExam } from 'types/Course/CourseContent/course.content.exam.types';
import { ICourseQuiz, ICourseQuizQuestion, isTypeofQuiz } from 'types/Course/CourseContent/course.content.quiz.types';
import { COURSE_CONTENT } from 'types/Course/CourseContent/course.content.types';
import ExamSettingsModal from './ExamSettingsModal';

const { TabPane: Tab } = Tabs;
const DEFAULT_EXPLANTAION = 'Correct answer explanation';

interface CourseQuizStateText {
  title?: string;
  text?: string;
}
interface CourseQuizCustomText {
  intro?: CourseQuizStateText;
  ending?: {
    success?: CourseQuizStateText;
    failure?: CourseQuizStateText;
  };
}

const TestContent: FC<{
  selectedSubject: ICourseSubject | undefined;
  setSelectedSubject: Dispatch<SetStateAction<ICourseSubject | undefined>>;
  isExam?: boolean;
}> = ({ selectedSubject, setSelectedSubject, isExam }) => {
  const { refreshCourse } = useContext(CourseEditContext);
  const { courseId } = useParams();

  const [questionTitle, setQuestionTitle] = useState<string>('');
  const [answerTitle, setAnswerTitle] = useState<string>('');
  const [explanation, setExplanation] = useState<string>(DEFAULT_EXPLANTAION); // question id, because we have 1 exlanation per question
  const [questionToEdit, setQuestionToEdit] = useState<undefined | string>();
  const [answerToEdit, setAnswerToEdit] = useState<undefined | string>();
  const [explanationToEdit, setExplanationToEdit] = useState<undefined | string>();
  const [examSettingsModalVisible, setExamSettingsModalVisible] = useState<boolean>(false);
  const [editExamTitle, setEditExamTitle] = useState(false);
  const [examTitle, setExamTitle] = useState('');

  const [customText, setCustomText] = useState<CourseQuizCustomText>(selectedSubject?.content?.custom_text ?? {});

  useEffect(() => {
    if (selectedSubject?.content_type === COURSE_CONTENT.EXAM) {
      setExamTitle(selectedSubject?.content?.title);
    }
    setCustomText(selectedSubject?.content?.custom_text ?? {});
  }, [selectedSubject]);

  const saveCustomText = async () => {
    if (!selectedSubject?.content?._id) return;
    // Push text
    if (isExam) {
      await apiClient.patch(`/exams/${selectedSubject?.content?._id}`, { custom_text: customText });
    } else {
      await apiClient.patch(`/quizzes/${selectedSubject?.content?._id}`, { custom_text: customText });
    }
    // Refresh model
    refreshCourse();
  };

  const addQuestionToSubject = useCallback(
    (data) => {
      setSelectedSubject((currentSubject) => {
        if (!currentSubject) {
          return undefined;
        }

        const { content } = currentSubject;

        if (isTypeofQuiz(content)) {
          const newContent = { ...content };
          newContent.questions = [...newContent.questions, data];
          return { ...currentSubject, content: newContent };
        }
        return currentSubject;
      });
    },
    [setSelectedSubject]
  );

  const overWriteQuestionObject = useCallback(
    (data: ICourseQuizQuestion) => {
      setSelectedSubject((currentSubject) => {
        if (!currentSubject) {
          return undefined;
        }

        const { content } = currentSubject;

        if (isTypeofQuiz(content)) {
          const newContent = { ...content };

          const newQuestions = newContent.questions.map((x) => (x._id === data._id ? data : x));
          newContent.questions = newQuestions;

          return { ...currentSubject, content: newContent };
        }
        return currentSubject;
      });
    },
    [setSelectedSubject]
  );

  const updateExamTitle = async () => {
    const response = await apiClient
      .patch('/exams', { _id: selectedSubject?.content._id, title: examTitle })
      .catch(() => null);

    if (response) {
      mutate(`/courses/content/${courseId}`);
      setEditExamTitle(false);
    }
  };

  /**
   * Question create/edit/delete functions
   */
  const createQuestion = async () => {
    if (selectedSubject) {
      const response = await apiClient
        .post('/questions', {
          course: selectedSubject.course,
          module: selectedSubject.module,
          ...(isExam ? { exam: selectedSubject.content._id } : { quiz: selectedSubject.content._id }),
          question: 'Question...'
        })
        .catch(() => null);

      if (response) {
        setQuestionToEdit(response.data._id); // created question is editable
        addQuestionToSubject(response.data);
        mutate(`/courses/content/${courseId}`); // re-fetch whole course
      }
      setQuestionTitle('');
    }
  };

  const editQuestion = async (question: any, props: any, callBack?: any) => {
    if (question && selectedSubject) {
      const response = await apiClient.patch(`/questions/${question._id}`, props).catch(() => null);

      if (response) {
        overWriteQuestionObject(response.data as ICourseQuizQuestion);
      }

      if (callBack) callBack();

      setQuestionToEdit(undefined);
      mutate(`/courses/content/${courseId}`);
    }
  };

  const deleteQuesion = async (question: any) => {
    if (question && selectedSubject) {
      const response = await apiClient.delete(`/questions/${question._id}`).catch(() => null);

      if (response) {
        const subjectResponse = await apiClient.get(`subjects/${selectedSubject._id}`);
        setSelectedSubject(subjectResponse.data);
        mutate(`/courses/content/${courseId}`);
      }
    }
  };

  /**
   * Answer create/edit/delete functions
   */
  const createAnswer = async (question: any) => {
    if (question && selectedSubject) {
      const response = await apiClient
        .patch(`/questions/${question._id}`, {
          answers: [...question.answers, { answer: 'New answer', isCorrect: false }]
        })
        .catch(() => null);

      if (response) {
        const lastAnswer = last(response.data.answers) as any;
        if (lastAnswer) setAnswerToEdit(lastAnswer._id); // added answer is going to become editable

        overWriteQuestionObject(response.data as ICourseQuizQuestion);
        mutate(`/courses/content/${courseId}`);
      }

      setAnswerTitle('');
    }
  };

  const editAnswer = async (question: any, targetAnswer: any, changeCorrect: boolean) => {
    if (question && targetAnswer && selectedSubject) {
      const newAnswers = question.answers.map((answer: any) => {
        if (answer._id === targetAnswer._id) {
          if (changeCorrect) {
            return {
              ...answer,
              isCorrect: !answer.isCorrect
            };
          }
          return {
            ...answer,
            answer: answerTitle
          };
        }
        return answer;
      });

      const response = await apiClient.patch(`/questions/${question._id}`, {
        answers: newAnswers
      });

      if (response) {
        overWriteQuestionObject(response.data as ICourseQuizQuestion);
        mutate(`/courses/content/${courseId}`);
      }
      setAnswerToEdit(undefined);
      setQuestionToEdit(undefined);
    }
  };

  const deleteAnswer = async (question: any, answerToDelete: any) => {
    if (question && answerToDelete && selectedSubject) {
      const newAnswers = question.answers.filter((answer: any) => answer._id !== answerToDelete._id);
      const response = await apiClient.patch(`/questions/${question._id}`, {
        answers: newAnswers
      });

      if (response) {
        const subjectResponse = await apiClient.get(`subjects/${selectedSubject._id}`).catch(() => null);

        if (subjectResponse) {
          setSelectedSubject(subjectResponse.data);
          mutate(`/courses/content/${courseId}`);
        }
      }
    }
  };

  return (
    <div>
      {!!isExam && (
        <Row className="super-admin-create-course-content-content-quiz-settings">
          <Col flex={12}>
            {editExamTitle ? (
              <Row>
                <Input
                  style={{
                    borderRadius: 10,
                    fontFamily: 'poppins',
                    fontWeight: 400,
                    fontSize: 16,
                    width: '50%'
                  }}
                  defaultValue={examTitle}
                  onChange={(e) => setExamTitle(e.target.value)}
                  onPressEnter={updateExamTitle}
                />
                <SuccessCheck onClick={updateExamTitle} style={{ marginLeft: 20, cursor: 'pointer' }} />
              </Row>
            ) : (
              <Row style={{ alignItems: 'center' }}>
                <div className="super-admin-create-course-content-content-exam-title">{examTitle}</div>
                <EditIcon style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => setEditExamTitle(true)} />
              </Row>
            )}
          </Col>
          <Col flex={0}>
            <Button
              icon={<SettingFilled />}
              iconRight={true}
              size="small"
              type="secondary"
              onClick={() => setExamSettingsModalVisible(true)}
            >
              Settings
            </Button>
          </Col>
        </Row>
      )}
      <div
        className="super-admin-create-course-content-content-quiz"
        style={
          (!!isExam && {
            marginTop: 0
          }) ||
          {}
        }
      >
        <div
          className="super-admin-create-course-content-content-quiz-questions"
          style={{ display: 'grid', placeItems: 'center', backgroundColor: 'white', padding: 20 }}
        >
          <Tabs defaultActiveKey="1">
            <Tab tab="Intro" key={0}>
              <Form
                layout="vertical"
                fields={[
                  { name: 'title', value: customText?.intro?.title ?? '' },
                  { name: 'text', value: customText?.intro?.text ?? '' }
                ]}
                onValuesChange={(items, allValues) => setCustomText({ ...customText, intro: { ...allValues } })}
              >
                <Form.Item label="Title" name="title">
                  <Input value={customText?.intro?.title ?? ''} />
                </Form.Item>
                <Form.Item label="Text" name="text">
                  <Input.TextArea showCount />
                </Form.Item>
              </Form>
            </Tab>
            <Tab tab="Quiz Failed" key={1}>
              <Form
                fields={[
                  { name: 'title', value: customText?.ending?.failure?.title ?? '' },
                  { name: 'text', value: customText?.ending?.failure?.text ?? '' }
                ]}
                layout="vertical"
                onValuesChange={(items, allValues) =>
                  setCustomText({ ...customText, ending: { ...customText.ending, failure: { ...allValues } } })
                }
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item label="Text" name="text">
                  <Input.TextArea showCount />
                </Form.Item>
              </Form>
            </Tab>
            <Tab tab="Quiz Completed" key={2}>
              <Form
                layout="vertical"
                fields={[
                  { name: 'title', value: customText?.ending?.success?.title ?? '' },
                  { name: 'text', value: customText?.ending?.success?.text ?? '' }
                ]}
                onValuesChange={(items, allValues) =>
                  setCustomText({ ...customText, ending: { ...customText.ending, success: { ...allValues } } })
                }
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item label="Text" name="text">
                  <Input.TextArea showCount />
                </Form.Item>
              </Form>{' '}
            </Tab>
          </Tabs>
          <Button onClick={saveCustomText}>Save</Button>
        </div>

        <div className="super-admin-create-course-content-content-quiz-questions">
          {[...((selectedSubject?.content as ICourseQuiz)?.questions || [])].map((question: any) => (
            <div
              key={question._id}
              className="super-admin-create-course-content-content-quiz-questions-question-container"
            >
              {isEqual(question._id, questionToEdit) ? (
                <div
                  style={{
                    paddingTop: 30,
                    paddingBottom: 30,
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Input
                    autoFocus={true}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setQuestionTitle(e.target.value)}
                    type="text"
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      borderWidth: 0,
                      border: 'none',
                      borderRadius: 15,
                      width: '100%',
                      flex: 1
                    }}
                    onPressEnter={() => editQuestion(question, { question: questionTitle })}
                    value={questionTitle}
                  />
                  <RoundAddIcon
                    style={{ marginLeft: 15 }}
                    onClick={() => editQuestion(question, { question: questionTitle })}
                  />
                </div>
              ) : (
                <div className="super-admin-create-course-content-content-quiz-questions-question-header">
                  <label className="super-admin-create-course-content-content-quiz-questions-question-title">
                    {question.question}
                  </label>
                  <EditIcon
                    onClick={() => {
                      setQuestionTitle(question.question);
                      setQuestionToEdit(question._id);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                  <DeleteIcon onClick={() => deleteQuesion(question)} style={{ cursor: 'pointer' }} />
                </div>
              )}

              {[...(question?.answers || [])].map((answer: any) => (
                <div key={answer._id} className="super-admin-create-course-content-content-quiz-questions-answer">
                  {isEqual(answer._id, answerToEdit) ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <Input
                        autoFocus={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAnswerTitle(e.target.value)}
                        type="text"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          borderWidth: 0,
                          border: 'none',
                          borderRadius: 15,
                          width: '100%',
                          flex: 1
                        }}
                        onPressEnter={() => editAnswer(question, answer, false)}
                        value={answerTitle}
                      />
                      <RoundAddIcon
                        onClick={() => editAnswer(question, answer, false)}
                        style={{ marginLeft: 15, cursor: 'pointer' }}
                      />
                    </div>
                  ) : (
                    <div className="super-admin-create-course-content-content-quiz-questions-answer-header">
                      {answer.isCorrect ? (
                        <CheckedCheckBox
                          onClick={() => editAnswer(question, answer, true)}
                          style={{ cursor: 'pointer', marginRight: 15 }}
                        />
                      ) : (
                        <CheckBox
                          onClick={() => editAnswer(question, answer, true)}
                          style={{ cursor: 'pointer', marginRight: 15 }}
                        />
                      )}
                      <label className="super-admin-create-course-content-content-quiz-questions-answer-title">
                        {answer.answer}
                      </label>
                      <EditIcon
                        onClick={() => {
                          setAnswerToEdit(answer._id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <DeleteIcon onClick={() => deleteAnswer(question, answer)} style={{ cursor: 'pointer' }} />
                    </div>
                  )}
                </div>
              ))}
              {(question?.explanation && (
                <div className="super-admin-create-course-content-content-quiz-questions-answer">
                  {isEqual(question._id, explanationToEdit) ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <Input
                        autoFocus={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setExplanation(e.target.value)}
                        type="text"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          borderWidth: 0,
                          border: 'none',
                          borderRadius: 15,
                          width: '100%',
                          flex: 1
                        }}
                        onPressEnter={() =>
                          editQuestion(question, { explanation }, () => {
                            setExplanationToEdit(undefined);
                          })
                        }
                        value={explanation}
                      />
                      <RoundAddIcon
                        onClick={() => editQuestion(question, { explanation }, () => setExplanationToEdit(undefined))}
                        style={{ marginLeft: 15, cursor: 'pointer' }}
                      />
                    </div>
                  ) : (
                    <div className="super-admin-create-course-content-content-quiz-questions-answer-header">
                      <BlueFlag style={{ marginRight: 15 }} />
                      <label className="super-admin-create-course-content-content-quiz-questions-answer-title">
                        {question.explanation}
                      </label>
                      <EditIcon
                        onClick={() => {
                          setExplanationToEdit(question._id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <DeleteIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          editQuestion(question, { explanation: null }, () => setExplanationToEdit(undefined))
                        }
                      />
                    </div>
                  )}
                </div>
              )) ||
                null}
              <div
                onClick={() => createAnswer(question)}
                className="super-admin-create-course-content-content-quiz-add-answer"
              >
                <RoundAddIcon style={{ marginRight: 15 }} />
                <label className="super-admin-create-course-content-content-quiz-add-answer-text">Add answer</label>
              </div>
              <div
                onClick={() =>
                  !question.explanation &&
                  editQuestion(question, { explanation }, () => {
                    setExplanationToEdit(question._id);
                  })
                }
                className="super-admin-create-course-content-content-quiz-add-explanation"
              >
                <RoundAddIcon style={{ marginRight: 15 }} />
                <label className="super-admin-create-course-content-content-quiz-add-answer-text">
                  Add explanation
                </label>
              </div>
            </div>
          )) || null}
        </div>
        <div onClick={createQuestion} className="super-admin-create-course-content-content-quiz-add-question">
          <RoundAddIcon style={{ marginRight: 15 }} />
          <label className="super-admin-create-course-content-content-quiz-add-question-text">Add question</label>
        </div>
      </div>
      <ExamSettingsModal
        exam={selectedSubject?.content as ICourseExam}
        visible={examSettingsModalVisible}
        onCancel={() => {
          mutate(`/courses/content/${courseId}`);
          setExamSettingsModalVisible(false);
        }}
      />
    </div>
  );
};

export default TestContent;
