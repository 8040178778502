import { SVGProps } from 'react';

const ComplokBook = (props: SVGProps<SVGSVGElement>) => (
  <svg width="203" height="182" viewBox="0 0 203 182" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M92.1152 179.972C50.8794 179.972 29.7107 162.896 17.3557 124.571C5.00075 86.2474 26.3269 33.6011 68.6643 26.5973C111.002 19.5935 112.497 6.60896 134.846 13.1406C157.195 19.6722 177.656 43.5165 187.807 71.5316C197.959 99.5468 178.049 133.543 155.228 152.429C132.407 171.316 116.274 179.972 92.1152 179.972Z"
        fill="#172845"
      />
      <path
        d="M102.267 2.75284C108.405 5.42844 95.499 13.0618 83.3801 16.603C71.2612 20.1442 61.4244 14.2422 67.8774 8.57621C74.3303 2.91023 91.0134 -2.20489 102.267 2.75284Z"
        fill="#172845"
      />
      <path
        d="M101.263 83.8586C104.811 83.8586 107.688 80.9793 107.688 77.4275C107.688 73.8756 104.811 70.9963 101.263 70.9963C97.7138 70.9963 94.8369 73.8756 94.8369 77.4275C94.8369 80.9793 97.7138 83.8586 101.263 83.8586Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M101.564 75.9203L102.016 76.7242L102.919 76.9252C103.17 76.9754 103.271 77.3272 103.12 77.5281L102.518 78.1813L102.618 79.1359C102.668 79.3871 102.367 79.5881 102.116 79.4876L101.263 79.0857L100.409 79.4876C100.158 79.5881 99.8572 79.3871 99.9074 79.1359L100.008 78.1813L99.4054 77.5281C99.2046 77.3272 99.305 77.0257 99.6062 76.9252L100.51 76.7242L100.962 75.9203C101.112 75.6691 101.464 75.6691 101.564 75.9203Z"
        fill="#E7AD80"
      />
      <path
        d="M101.564 75.9203L102.016 76.7242L102.919 76.9252C103.17 76.9754 103.271 77.3272 103.12 77.5281L102.518 78.1813L102.618 79.1359C102.668 79.3871 102.367 79.5881 102.116 79.4876L101.263 79.0857L100.409 79.4876C100.158 79.5881 99.8572 79.3871 99.9074 79.1359L100.008 78.1813L99.4054 77.5281C99.2046 77.3272 99.305 77.0257 99.6062 76.9252L100.51 76.7242L100.962 75.9203C101.112 75.6691 101.464 75.6691 101.564 75.9203Z"
        fill="url(#paint1_radial)"
      />
      <path
        d="M150.308 99.6853C152.887 99.6853 154.977 97.5933 154.977 95.0127C154.977 92.4321 152.887 90.3401 150.308 90.3401C147.73 90.3401 145.64 92.4321 145.64 95.0127C145.64 97.5933 147.73 99.6853 150.308 99.6853Z"
        fill="url(#paint2_radial)"
      />
      <path
        d="M150.509 93.9072L150.86 94.4599L151.513 94.6106C151.714 94.6609 151.764 94.9121 151.664 95.0126L151.212 95.515L151.262 96.2184C151.262 96.4194 151.061 96.5701 150.911 96.4696L150.308 96.1682L149.706 96.4696C149.505 96.5701 149.304 96.4194 149.354 96.2184L149.405 95.515L148.953 95.0126C148.802 94.8619 148.903 94.6106 149.103 94.6106L149.756 94.4599L150.107 93.9072C150.158 93.7565 150.409 93.7565 150.509 93.9072Z"
        fill="#E7AD80"
      />
      <path
        d="M150.509 93.9072L150.86 94.4599L151.513 94.6106C151.714 94.6609 151.764 94.9121 151.664 95.0126L151.212 95.515L151.262 96.2184C151.262 96.4194 151.061 96.5701 150.911 96.4696L150.308 96.1682L149.706 96.4696C149.505 96.5701 149.304 96.4194 149.354 96.2184L149.405 95.515L148.953 95.0126C148.802 94.8619 148.903 94.6106 149.103 94.6106L149.756 94.4599L150.107 93.9072C150.158 93.7565 150.409 93.7565 150.509 93.9072Z"
        fill="url(#paint3_radial)"
      />
      <path
        d="M120.841 62.5559C123.419 62.5559 125.51 60.4639 125.51 57.8833C125.51 55.3027 123.419 53.2107 120.841 53.2107C118.262 53.2107 116.172 55.3027 116.172 57.8833C116.172 60.4639 118.262 62.5559 120.841 62.5559Z"
        fill="url(#paint4_radial)"
      />
      <path
        d="M121.092 56.828L121.443 57.3806L122.096 57.5314C122.297 57.5816 122.347 57.8328 122.246 57.9333L121.795 58.4358L121.845 59.1392C121.845 59.3401 121.644 59.4909 121.493 59.3904L120.891 59.0889L120.289 59.3904C120.088 59.4909 119.887 59.3401 119.937 59.1392L119.987 58.4358L119.536 57.9333C119.385 57.7826 119.485 57.5314 119.686 57.5314L120.339 57.3806L120.69 56.828C120.74 56.627 120.991 56.627 121.092 56.828Z"
        fill="#E7AD80"
      />
      <path
        d="M121.092 56.828L121.443 57.3806L122.096 57.5314C122.297 57.5816 122.347 57.8328 122.246 57.9333L121.795 58.4358L121.845 59.1392C121.845 59.3401 121.644 59.4909 121.493 59.3904L120.891 59.0889L120.289 59.3904C120.088 59.4909 119.887 59.3401 119.937 59.1392L119.987 58.4358L119.536 57.9333C119.385 57.7826 119.485 57.5314 119.686 57.5314L120.339 57.3806L120.69 56.828C120.74 56.627 120.991 56.627 121.092 56.828Z"
        fill="url(#paint5_radial)"
      />
      <rect x="109.865" y="67.781" width="31.2246" height="28.4878" fill="url(#pattern0)" />
      <g filter="url(#filter0_f)">
        <path d="M140.77 95.8669L129.325 86.5217L121.845 68.7859L110.75 83.1052L129.325 86.6222" stroke="#E7AD80" strokeMiterlimit="10" />
        <path d="M140.77 95.8669L129.325 86.5217L121.845 68.7859L110.75 83.1052L129.325 86.6222" stroke="#E7AD80" strokeMiterlimit="10" />
        <path d="M140.77 95.8669L129.325 86.5217L121.845 68.7859L110.75 83.1052L129.325 86.6222" stroke="#E7AF83" strokeMiterlimit="10" />
      </g>
      <path d="M140.77 95.8669L129.325 86.5217L121.845 68.7859L110.75 83.1052L129.325 86.6222" stroke="#E7AD80" strokeMiterlimit="10" />
      <path
        d="M133.893 59.9427C135.029 59.9427 135.951 59.0204 135.951 57.8827C135.951 56.745 135.029 55.8228 133.893 55.8228C132.756 55.8228 131.834 56.745 131.834 57.8827C131.834 59.0204 132.756 59.9427 133.893 59.9427Z"
        fill="url(#paint6_radial)"
      />
      <path
        d="M133.893 58.4859C134.226 58.4859 134.495 58.2159 134.495 57.8829C134.495 57.55 134.226 57.28 133.893 57.28C133.56 57.28 133.29 57.55 133.29 57.8829C133.29 58.2159 133.56 58.4859 133.893 58.4859Z"
        fill="#E7AD80"
      />
      <path
        d="M133.893 58.4859C134.226 58.4859 134.495 58.2159 134.495 57.8829C134.495 57.55 134.226 57.28 133.893 57.28C133.56 57.28 133.29 57.55 133.29 57.8829C133.29 58.2159 133.56 58.4859 133.893 58.4859Z"
        fill="url(#paint7_radial)"
      />
      <path
        d="M84.9477 88.3804C86.9716 88.3804 88.6123 86.7383 88.6123 84.7127C88.6123 82.687 86.9716 81.0449 84.9477 81.0449C82.9238 81.0449 81.2831 82.687 81.2831 84.7127C81.2831 86.7383 82.9238 88.3804 84.9477 88.3804Z"
        fill="url(#paint8_radial)"
      />
      <path
        d="M84.9478 85.7674C85.53 85.7674 86.002 85.2951 86.002 84.7123C86.002 84.1296 85.53 83.6572 84.9478 83.6572C84.3655 83.6572 83.8936 84.1296 83.8936 84.7123C83.8936 85.2951 84.3655 85.7674 84.9478 85.7674Z"
        fill="#E7AD80"
      />
      <path
        d="M84.9478 85.7674C85.53 85.7674 86.002 85.2951 86.002 84.7123C86.002 84.1296 85.53 83.6572 84.9478 83.6572C84.3655 83.6572 83.8936 84.1296 83.8936 84.7123C83.8936 85.2951 84.3655 85.7674 84.9478 85.7674Z"
        fill="url(#paint9_radial)"
      />
      <path
        d="M62.2069 83.6578C63.3436 83.6578 64.2651 82.7355 64.2651 81.5978C64.2651 80.4601 63.3436 79.5378 62.2069 79.5378C61.0702 79.5378 60.1487 80.4601 60.1487 81.5978C60.1487 82.7355 61.0702 83.6578 62.2069 83.6578Z"
        fill="url(#paint10_radial)"
      />
      <path
        d="M62.2069 82.2007C62.5396 82.2007 62.8093 81.9308 62.8093 81.5978C62.8093 81.2648 62.5396 80.9949 62.2069 80.9949C61.8742 80.9949 61.6045 81.2648 61.6045 81.5978C61.6045 81.9308 61.8742 82.2007 62.2069 82.2007Z"
        fill="#E7AD80"
      />
      <path
        d="M62.2069 82.2007C62.5396 82.2007 62.8093 81.9308 62.8093 81.5978C62.8093 81.2648 62.5396 80.9949 62.2069 80.9949C61.8742 80.9949 61.6045 81.2648 61.6045 81.5978C61.6045 81.9308 61.8742 82.2007 62.2069 82.2007Z"
        fill="url(#paint11_radial)"
      />
      <rect x="74.1477" y="72.2886" width="15.6123" height="5.62723" fill="url(#pattern1)" />
      <g filter="url(#filter1_f)">
        <path d="M89.6163 72.7551L74.3052 77.478" stroke="#E7AD80" strokeMiterlimit="10" />
      </g>
      <g filter="url(#filter2_d)">
        <path d="M89.6163 72.7551L74.3052 77.478" stroke="#E7AD80" strokeMiterlimit="10" />
      </g>
      <path
        d="M89.616 77.4277C92.1944 77.4277 94.2846 75.3357 94.2846 72.7551C94.2846 70.1745 92.1944 68.0825 89.616 68.0825C87.0376 68.0825 84.9474 70.1745 84.9474 72.7551C84.9474 75.3357 87.0376 77.4277 89.616 77.4277Z"
        fill="url(#paint12_radial)"
      />
      <path
        d="M89.8167 71.7L90.1681 72.2527L90.8207 72.4034C91.0215 72.4537 91.0717 72.7049 90.9713 72.8054L90.5195 73.3078L90.5697 74.0112C90.5697 74.2122 90.3689 74.3629 90.2183 74.2624L89.6159 73.961L89.0135 74.2624C88.8127 74.3629 88.6119 74.2122 88.6621 74.0112L88.7123 73.3078L88.2605 72.8054C88.1099 72.6547 88.2103 72.4034 88.4111 72.4034L89.0637 72.2527L89.4151 71.7C89.4653 71.4991 89.7163 71.4991 89.8167 71.7Z"
        fill="#E7AD80"
      />
      <path
        d="M89.8167 71.7L90.1681 72.2527L90.8207 72.4034C91.0215 72.4537 91.0717 72.7049 90.9713 72.8054L90.5195 73.3078L90.5697 74.0112C90.5697 74.2122 90.3689 74.3629 90.2183 74.2624L89.6159 73.961L89.0135 74.2624C88.8127 74.3629 88.6119 74.2122 88.6621 74.0112L88.7123 73.3078L88.2605 72.8054C88.1099 72.6547 88.2103 72.4034 88.4111 72.4034L89.0637 72.2527L89.4151 71.7C89.4653 71.4991 89.7163 71.4991 89.8167 71.7Z"
        fill="url(#paint13_radial)"
      />
      <path
        d="M101.856 157.785C136.068 157.785 163.803 130.027 163.803 95.7854C163.803 61.5437 136.068 33.7854 101.856 33.7854C67.6434 33.7854 39.9087 61.5437 39.9087 95.7854C39.9087 130.027 67.6434 157.785 101.856 157.785Z"
        fill="url(#paint14_radial)"
      />
      <path
        d="M143.112 133.434L141.757 138.308L76.0446 134.339L50.4425 100.274L51.7477 95.0486H69.619C74.388 95.0486 79.0064 96.7568 82.6209 99.8217L85.7333 97.9627C88.8959 96.0534 92.4601 95.0486 96.1749 95.0486H118.062L143.112 133.434Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M116.154 99.8721H86.8371C86.7869 99.8721 86.7367 99.8721 86.6363 99.9223C83.5741 101.078 80.9637 101.229 78.8051 99.9726C78.7047 99.9223 78.6545 99.9223 78.5541 99.9223H49.94C49.5384 99.9223 49.2874 100.375 49.4882 100.726L74.3373 138.76C74.4879 139.011 74.7891 139.162 75.0903 139.162H141.254C141.656 139.162 141.907 138.71 141.706 138.358L116.857 100.324C116.756 100.023 116.505 99.8721 116.154 99.8721Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M112.389 139.112C109.377 140.619 106.717 140.619 104.357 139.112L78.755 99.8721C80.9136 101.229 83.6244 101.128 86.787 99.8721L112.389 139.112Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M102.098 136.147C102.098 136.147 97.9815 130.42 95.4715 126.601C92.9615 122.783 89.2969 116.754 89.2969 116.754C89.1965 116.553 89.6483 116.251 89.7989 116.402C89.7989 116.402 93.9153 122.13 96.4253 125.948C98.9353 129.766 102.6 135.796 102.6 135.796C102.7 136.047 102.249 136.348 102.098 136.147Z"
        fill="#172845"
      />
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1"></pattern>
      <filter id="filter0_f" x="105.855" y="63.8037" width="39.2313" height="36.4504" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
      </filter>
      <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1"></pattern>
      <filter id="filter1_f" x="70.1578" y="68.2773" width="23.6058" height="13.6785" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
      </filter>
      <filter id="filter2_d" x="70.1578" y="72.2773" width="23.6058" height="13.6785" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(101.415 77.5919) scale(5.22239 5.22684)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(101.241 77.3977) scale(3.35318 3.35603)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150.396 95.1396) scale(3.80121 3.80446)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150.27 94.9981) scale(2.44069 2.44276)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(120.958 58.0231) scale(3.80122 3.80446)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(120.831 57.8815) scale(2.4407 2.44277)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(133.929 57.95) scale(1.6807 1.68214)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(133.885 57.8928) scale(0.58754 0.588045)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.0059 84.8059) scale(2.99194 2.99449)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(84.9286 84.7033) scale(1.04597 1.04687)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.2704 81.6702) scale(1.68071 1.68214)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.2269 81.6127) scale(0.587546 0.588046)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(89.6968 72.899) scale(3.80122 3.80446)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(89.5702 72.7576) scale(2.4407 2.44277)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(101.873 95.768) scale(61.9664 62.0193)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.1918" stopColor="#E7AD80" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint15_linear" x1="92.8637" y1="130.538" x2="103.676" y2="92.1571" gradientUnits="userSpaceOnUse">
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient id="paint16_linear" x1="171.008" y1="164.016" x2="68.9736" y2="103.802" gradientUnits="userSpaceOnUse">
        <stop offset="0.0942909" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient id="paint17_linear" x1="176.718" y1="160.262" x2="76.8809" y2="110.187" gradientUnits="userSpaceOnUse">
        <stop offset="0.0942909" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="201.378" height="181.154" fill="white" transform="translate(0.908691 0.7854)" />
      </clipPath>
    </defs>
  </svg>
);

export default ComplokBook;
