/* eslint-disable operator-linebreak */
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import apiClient from 'api/apiClient';
import { CourseCard } from 'components/data/Card';
import { UserContext } from 'context/UserContext';
import { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import './CourseDirectory.scss';

type ICourseFilterName = 'All courses' | 'Published' | 'In Draft';
type CourseStatus = 'published' | 'in_draft';

interface SuperAdminDashboardCourse {
  title: string;
  status: CourseStatus;
  _id: string;
}

interface IFilteredCourses {
  publised: SuperAdminDashboardCourse[];
  inDraft: SuperAdminDashboardCourse[];
}

const CourseDirectory: FC = (props) => {
  const [courseFilter, setCourseFilter] = useState<ICourseFilterName>('All courses');
  const { accountData } = useContext(UserContext);

  const { data: courses } = useSWR<SuperAdminDashboardCourse[] | undefined>(
    accountData ? `/courses/by_account/${accountData._id}` : null
  );
  const navigate = useNavigate();

  const filteredCourses: IFilteredCourses = useMemo(() => {
    if (courseFilter === 'All courses') {
      return {
        publised: courses?.filter((course) => course.status === 'published') || [],
        inDraft: courses?.filter((course) => course.status === 'in_draft') || []
      };
    }
    if (courseFilter === 'In Draft') {
      return { publised: [], inDraft: courses?.filter((course) => course.status === 'in_draft') || [] };
    }

    if (courseFilter === 'Published') {
      return { publised: courses?.filter((course) => course.status === 'published') || [], inDraft: [] };
    }
    return {
      publised: courses?.filter((course) => course.status === 'published') || [],
      inDraft: courses?.filter((course) => course.status === 'in_draft') || []
    };
  }, [courseFilter, courses]);

  const createCourse = async () => {
    if (!accountData?.organization) return;
    const response = await apiClient.post('/courses', { organization: accountData.organization }).catch(() => null);
    if (response?.data) {
      navigate(`/courses/${response.data._id}`);
    }
  };

  const filterMenu = (
    <Menu onClick={(e: any) => e.key && setCourseFilter(e.key)}>
      <Menu.Item key="All courses">All courses</Menu.Item>
      <Menu.Item key="Published">Published</Menu.Item>
      <Menu.Item key="In Draft">In Draft</Menu.Item>
    </Menu>
  );

  return (
    <div className="super-admin-course-view">
      <div className="super-admin-course-view-header">
        <div className="super-admin-course-view-header-text">Courses</div>
        <div className="super-admin-course-view-header-filter">
          <Dropdown overlay={filterMenu} placement="bottomRight" arrow trigger={['click']}>
            <Button className="super-admin-course-view-filter-trigger">
              {courseFilter} <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <Button onClick={createCourse}>Create a new course</Button>
      </div>
      <div className="super-admin-course-view-courses">
        {(filteredCourses.publised.length && (
          <div className="super-admin-course-view-courses">
            <div className="super-admin-course-view-courses-header">Published courses</div>
            <div className="super-admin-course-view-courses-items">
              {filteredCourses.publised.map((course) => (
                <div key={course._id} className="super-admin-course-view-courses-items-item">
                  <CourseCard
                    id={course._id}
                    key={course._id}
                    title={course.title}
                    chevron
                    onClick={() => navigate(`/courses/${course._id}`)}
                  />
                </div>
              ))}
            </div>
          </div>
        )) ||
          null}
        {(filteredCourses.inDraft.length && (
          <div className="super-admin-course-view-courses">
            <div className="super-admin-course-view-courses-header">In draft</div>
            <div className="super-admin-course-view-courses-items">
              {filteredCourses.inDraft.map((course, index) => (
                <div className="super-admin-course-view-courses-items-item" key={index}>
                  <CourseCard
                    id={course._id}
                    title={course.title}
                    chevron
                    onClick={() => navigate(`/courses/${course._id}`)}
                  />
                </div>
              ))}
            </div>
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default CourseDirectory;
