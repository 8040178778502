import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ColumnsType } from "antd/lib/table";
import apiClient from "api/apiClient";
import AdminTag from "components/icons/AdminTag";
import EnglishFlag from "components/icons/EnglishFlag";
import EstonianFlag from "components/icons/EstonianFlag";
import StudentTag from "components/icons/StudentTag";
import SuperAdminTag from "components/icons/SuperAdminTag";
import { UserContext } from "context/UserContext";
import dayjs from "dayjs";
import i18n from "i18n";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { IAccount } from "types/AccountTypes";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import applicationInfo from "../../app.json";
import "./Settings.scss";

interface ISettingsView {}

const returnAccountOptionByAccessLevel = (
  access_level: USER_ACCESS_LEVEL | undefined
) => {
  switch (access_level) {
    case USER_ACCESS_LEVEL.SUPER_ADMIN:
      return {
        text: i18n.t("Super admin"),
        icon: <SuperAdminTag />,
      };
    case USER_ACCESS_LEVEL.ADMIN:
      return {
        text: i18n.t("Admin"),
        icon: <AdminTag />,
      };
    case USER_ACCESS_LEVEL.LEARNER:
      return {
        text: i18n.t("Student"),
        icon: <StudentTag />,
      };
    default:
      console.log("default");
      return { text: "Error", icon: null };
  }
};

const SettingsView: FC<ISettingsView> = () => {
  const { t } = useTranslation();
  const { userData, setUserData, accountData } = useContext(UserContext);
  const [editGeneral, setEditGeneral] = useState<boolean>(false);
  const [editPassword, setEditPassword] = useState<boolean>(false);

  const [generalForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [fallbackLang, setFallbackLang] = useState<string | undefined>();

  const isSmallScreen = useBreakpoint().md === false;

  const { data: accounts, error } = useSWR<IAccount[]>(
    userData?._id ? `accounts/by_user/${userData._id}/` : null
  );

  const accountColumns: ColumnsType<IAccount> = [
    {
      title: t("Role"),
      key: "access_level",
      render: (account) => {
        const { icon, text } = returnAccountOptionByAccessLevel(
          account.access_level
        );
        return (
          <Row
            align="middle"
            className="complok-header-account-selector-option"
          >
            <div className="complok-header-account-selector-option-icon">
              {icon}
            </div>
            <div
              style={{ marginLeft: 5, marginBottom: 4 }}
              className="complok-header-account-selector-option-text"
            >
              {text}
            </div>
          </Row>
        );
      },
    },
    {
      title: t("Created at"),
      responsive: ["md"],
      key: "created_at",
      render: (account) => (
        <div>{dayjs(account.created_at).format("DD/MM/YY hh:ss")}</div>
      ),
    },
    {
      title: t("Updated at"),
      key: "updated_at",
      responsive: ["md"],

      render: (account) => (
        <div>{dayjs(account?.updated_at).format("DD/MM/YY hh:ss")}</div>
      ),
    },
    {
      title: t("Groups"),
      key: "groups",
      render: (account) => (
        <Tag color="green" style={{ borderRadius: 5, padding: "5px 10px" }}>
          {account.groups.length}
        </Tag>
      ),
      responsive: ["md"],
    },
  ];

  const onEditFormFinish = async ({
    email,
    name,
  }: {
    email: string;
    name: string;
  }) => {
    if (userData) {
      const posting = message.loading(t("Updating user!"));
      apiClient
        .patch("/users/edit_details", { _id: userData?._id, name, email })
        .then((response) => {
          posting();
          if (response?.data) {
            setUserData(
              (currentUser) =>
                (currentUser && {
                  ...currentUser,
                  email: response.data.email,
                  name: response.data.name,
                }) ||
                undefined
            );
          }
        })
        .catch((err) => {
          posting();
        });
    }
  };

  const onPasswordFormFinish = async ({ password }: { password: string }) => {
    if (userData) {
      const posting = message.loading(t("Updating password"));

      apiClient
        .post("/users/create_password", { user_id: userData._id, password })
        .then((response) => {
          if (response?.data) {
            posting();
            message.success(t("Password has been updated!"));
          }
          setEditPassword(false);
        })
        .catch((err) => {
          posting();
          message.error(t("Failed to update password!"));
        });
    }
  };

  return (
    <div className="settings-view">
      <div
        className="settings-view-container"
        style={
          (isSmallScreen && { padding: "75px 10px" }) || {
            padding: "75px 30px",
          }
        }
      >
        <Row style={{ alignItems: "center" }}>
          <Col span={isSmallScreen ? 24 : 12}>
            <div className="settings-view-container-item">
              <Row>
                <Col className="settings-view-container-item-title" flex={1}>
                  {t("Basics")}
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      if (editGeneral) {
                        generalForm.submit();
                      } else {
                        setEditGeneral(true);
                      }
                    }}
                    type="primary"
                  >
                    {editGeneral ? t("Save") : t("Edit")}
                  </Button>
                </Col>
              </Row>
              <Form
                form={generalForm}
                hidden={!editGeneral}
                onFinish={onEditFormFinish}
                labelCol={{ span: 24 }}
                initialValues={{ email: userData?.email, name: userData?.name }}
              >
                <Form.Item label={t("Name")} name="name">
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label={t("Email")} name="email">
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Form>
              {!editGeneral && (
                <Col span={24}>
                  <Row className="settings-view-container-item-row">
                    <Col
                      className="settings-view-container-item-label-bold"
                      flex={1}
                    >
                      {t("Name")}
                    </Col>
                    <Col className="settings-view-container-item-label">
                      {userData?.name}
                    </Col>
                  </Row>
                  <Row className="settings-view-container-item-row">
                    <Col
                      className="settings-view-container-item-label-bold"
                      flex={1}
                    >
                      {t("Email")}
                    </Col>
                    <Col className="settings-view-container-item-label">
                      {userData?.email}
                    </Col>
                  </Row>
                </Col>
              )}
            </div>
          </Col>
          <Col span={isSmallScreen ? 24 : 12}>
            <Col className="settings-view-container-item">
              <div className="settings-view-container-item-title">
                {t("Preferences")}
              </div>

              <Row align="middle" className="settings-view-container-item-row">
                <Col flex={1} className="settings-view-container-item-label">
                  {t("Language")}
                </Col>
                <Col>
                  <Select
                    value={fallbackLang || userData?.locale || "en"}
                    style={{ width: "100%" }}
                    onSelect={async (val: string) => {
                      if (userData) {
                        // update user locale
                        const response = await apiClient
                          .patch("users/edit_details", {
                            _id: userData._id,
                            locale: val,
                          })
                          .then((res) => res?.data ?? null)
                          .catch(() => null);

                        if (response) {
                          setUserData(response);
                        } else {
                          setFallbackLang(val);
                          // fallback when updating user fails, then just change i18m value
                          i18n.changeLanguage(val);
                        }
                      }
                    }}
                  >
                    <Select.Option key="en" value="en">
                      <Row align="middle">
                        <EnglishFlag style={{ marginRight: 10 }} />
                        <div className="complok-header-selector-option-text">
                          English
                        </div>
                      </Row>
                    </Select.Option>
                    <Select.Option key="et" value="et">
                      <Row align="middle">
                        <EstonianFlag style={{ marginRight: 10 }} />
                        <div className="complok-header-selector-option-text">
                          Eesti
                        </div>
                      </Row>
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>

        <Row>
          <Col span={isSmallScreen ? 24 : 12}>
            <Col className="settings-view-container-item">
              <div className="settings-view-container-item-title">
                {t("Password and Two-Step Verification")}
              </div>
              {!editPassword ? (
                <Row className="settings-view-container-item-row">
                  <Col className="settings-view-container-item-label" flex={1}>
                    {t("Password")}
                  </Col>
                  <Col
                    onClick={() => setEditPassword(true)}
                    style={{ cursor: "pointer" }}
                    className="settings-view-container-item-label-bold"
                  >
                    {t("Change password")}
                  </Col>
                </Row>
              ) : (
                <Form
                  onFinish={onPasswordFormFinish}
                  labelCol={{ span: 12 }}
                  form={passwordForm}
                >
                  <Row style={{ alignItems: "center" }}>
                    <Col flex={1}>
                      <Form.Item name="password" label={t("New password")}>
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col
                      onClick={() => {
                        if (editPassword) {
                          passwordForm.submit();
                        } else {
                          setEditPassword(true);
                        }
                      }}
                      style={{ cursor: "pointer", marginLeft: 20 }}
                      className="settings-view-container-item-label-bold"
                    >
                      {t("Change password")}
                    </Col>
                  </Row>
                </Form>
              )}
              <Row className="settings-view-container-item-row">
                <Col className="settings-view-container-item-label" flex={1}>
                  {t("Two-Step Verification")}
                </Col>
                <Col
                  style={{ cursor: "pointer", color: "#f99820" }}
                  className="settings-view-container-item-label-bold"
                >
                  {t("ENABLED")}
                </Col>
              </Row>
            </Col>
          </Col>
          <Col span={isSmallScreen ? 24 : 12}>
            <Col className="settings-view-container-item">
              <div className="settings-view-container-item-title">
                {t("Privacy & Terms and Conditions")}
              </div>
              <Row className="settings-view-container-item-row">
                <Link to={`/privacy-policy?lang=${userData?.locale || "en"}`}>
                  {t("Privacy policy")}
                </Link>
              </Row>
              <Row className="settings-view-container-item-row">
                <Link
                  to={`/terms-and-conditions?lang=${userData?.locale || "en"}`}
                >
                  {t("Terms and conditions")}
                </Link>
              </Row>
            </Col>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Spin spinning={!accounts && !error}>
              <Col className="settings-view-container-item">
                <div className="settings-view-container-item-title">
                  {t("Account management")}
                </div>
                <Table
                  columns={accountColumns}
                  dataSource={accounts || []}
                  loading={!accounts && !error}
                  pagination={false}
                />
              </Col>
            </Spin>
          </Col>
        </Row>
      </div>
      {/* verions number */}
      <div
        style={{
          fontFamily: "monospace",
          fontSize: 10,
          marginRight: 55,
          marginBottom: 10,
          float: "right",
        }}
      >{`Current version: ${applicationInfo.version}${applicationInfo.release_candidate}`}</div>
    </div>
  );
};

export default SettingsView;
