/* eslint-disable react/prop-types */
import { Select } from 'antd';
import apiClient from 'api/apiClient';
import { getVideoUrl } from 'api/apiRoutes';
import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  ReactEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { ICourseVideo } from 'types/Course/CourseContent/course.content.video.types';

export type CourseVideoComponent = {
  /**
   * @description Request to change video time if possible
   * @note // TODO: Maybe will need to add additional argument to override and force the timechange (ignoring the furthest time)
   */
  setTime: (timeToSet: number) => void;
};
// TODO: Implement Video Configuration into props
interface CourseContentVideoConfiguration {
  /**
   * @description Disable users to scroll forward to skip certain video parts
   * @default false
   */
  disableForwardScroll?: boolean;
}
interface CourseContentVideo {
  subject?: ICourseSubject;
  content?: ICourseVideo;
  time?: number;

  // TODO: Consolidate options into configuration
  /**
   * @description Enable viewer to scroll forward to skip certain video parts
   */
  enableSkipping?: boolean;
  /** @description Set the furthest initial point the viewer can scroll */
  initialFurthestPoint?: number;
  /** @description Should the video send the furthest point reached update */
  syncFurthestPoint?: boolean;
  /** @description Interval length, which the video progress updates will be sent */
  syncInterval?: number;

  // TODO: Consolidate handlers into handle: { handleVideoCompletion?: Function, etc. }
  handleVideoCompletion?: Function;
  handleVideoTick?: Function;
  handleTimeUpdate?: Function;

  style?: CSSProperties;
}
const CourseVideo: ForwardRefRenderFunction<CourseVideoComponent, CourseContentVideo> = (
  {
    subject,
    content,

    enableSkipping,
    initialFurthestPoint,
    syncFurthestPoint,
    syncInterval,

    handleVideoCompletion,
    handleVideoTick,
    style = {}
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [furthestUnlockedTime, setFurthestTime] = useState<number>(initialFurthestPoint ?? 0);
  const [lastSyncTime, setLastSyncTime] = useState<number>(initialFurthestPoint ?? 0);
  const { data: videoSrc } = useSWR(getVideoUrl(content?.key));

  const { t } = useTranslation();

  const updateFurthestTime = () => {
    const currentVideoTime = videoRef?.current?.currentTime ?? 0;
    if (currentVideoTime > furthestUnlockedTime && !enableSkipping) {
      setFurthestTime(currentVideoTime);
      if (syncFurthestPoint) {
        syncFurthestTime(currentVideoTime);
      }
    }
  };

  const syncFurthestTime = async (currentTime: number) => {
    if (!syncFurthestPoint) return;
    const canSync = content?.course && subject?._id;
    if (lastSyncTime + (syncInterval ?? 10) < currentTime && canSync) {
      setLastSyncTime(currentTime);
      const syncVideoProgress = await apiClient
        .patch(`/progresses/video/${content?.course}/${subject?._id}`, { video_progress: currentTime })
        .catch((err) => console.error('Syncing video progress', err));
    }
  };

  const handleOnTimeUpdate: ReactEventHandler<HTMLVideoElement> = (event) => {
    if (handleVideoTick) handleVideoTick(event.currentTarget.currentTime);
    if (videoRef.current && !videoRef.current?.seeking) {
      updateFurthestTime();
    }
  };

  const handleOnSeeking: ReactEventHandler<HTMLVideoElement> = () => {
    if (videoRef.current && !enableSkipping) {
      const difference = (videoRef.current?.currentTime ?? 0) - furthestUnlockedTime;
      if (difference > 0) {
        videoRef.current.currentTime = furthestUnlockedTime;
      }
    }
  };

  const setVideoTime = (timeToSet: number) => {
    if (!videoRef?.current) return;
    if (enableSkipping) {
      videoRef.current.currentTime = timeToSet;
    } else {
      videoRef.current.currentTime = timeToSet <= furthestUnlockedTime ? timeToSet : furthestUnlockedTime;
    }
  };

  const handleOnVideoEnd: ReactEventHandler<HTMLVideoElement> = () => {
    if (handleVideoCompletion) handleVideoCompletion();
  };

  // TODO: Analyze if the updating rate can be reduced (currently updating too rapidly)
  useImperativeHandle(
    ref,
    () => ({
      setTime: setVideoTime
    }),
    [setVideoTime]
  );

  useEffect(() => {
    // Set time related states from props if possible
    setFurthestTime(initialFurthestPoint ?? 0);
    setVideoTime(initialFurthestPoint ?? 0);
  }, [videoSrc]);

  return (
    <div>
      <div id="complok-coursecontent-video" style={{ ...style }}>
        <video
          id="complok-coursecontent-video-player"
          playsInline
          src={videoSrc}
          ref={videoRef}
          onSeeking={handleOnSeeking}
          onEnded={handleOnVideoEnd}
          onTimeUpdate={handleOnTimeUpdate}
          style={{ height: '100%', width: '100%', borderRadius: 15, border: '1px solid rgb(226, 226, 234)' }}
          controlsList={'nodownload noplaybackrate'}
          onContextMenu={(event) => event.preventDefault()}
          controls
          autoPlay
        ></video>
      </div>
      <div id="complok-coursecontent-video-speed">
        <div id="complok-coursecontent-video-speed-text">{t('Video speed: ')}</div>
        <Select
          onSelect={(value: number) => {
            if (videoRef.current) {
              videoRef.current.playbackRate = value;
            }
          }}
          style={{ width: 90 }}
          defaultValue={1}
          options={[
            { label: '1x', value: 1 },
            { label: '1.25x', value: 1.25 },
            { label: '1.5x', value: 1.5 },
            { label: '1.75x', value: 1.75 },
            { label: '2x', value: 2 }
          ]}
        />
      </div>
    </div>
  );
};

CourseVideo.displayName = 'CourseVideo';

export default forwardRef(CourseVideo);
