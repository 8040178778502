import { UserContext } from 'context/UserContext';
import i18n from 'i18n';
import { FC, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRouter from 'screens/Admin/AdminRouter';
import ForgotPasswordView from 'screens/ForgotPassword/ForgotPasswordView';
import LearnerRouter from 'screens/Learner/LearnerRouter';
import LoginView from 'screens/login/LoginView';
import PrivacyPolicy from 'screens/privacy-policy/PrivacyPolicy';
import SetPasswordView from 'screens/setPassword/SetPasswordView';
import SuperAdminRouter from 'screens/SuperAdmin/SuperAdminRouter';
import TermsAndConditions from 'screens/terms-and-conditions/TermsAndConditions';
import { USER_ACCESS_LEVEL } from 'types/UserTypes';

const Router: FC = () => {
  const { accountData, userData } = useContext(UserContext);

  // initialize language based on user
  useEffect(() => {
    if (userData) {
      i18n.changeLanguage(userData.locale);
    }
  }, [userData]);

  const publicRoutes = (
    <Route>
      <Route path="/login" element={<LoginView />} />
      <Route path="/forgotpassword" element={<ForgotPasswordView />} />
      <Route path="/setpassword/:uuid" element={<SetPasswordView />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Route>
  );

  const accountIsSuperAdmin = accountData?.access_level === USER_ACCESS_LEVEL.SUPER_ADMIN;
  const accountIsAdmin = accountData?.access_level === USER_ACCESS_LEVEL.ADMIN;
  const accountIsLearner = accountData?.access_level === USER_ACCESS_LEVEL.LEARNER;

  const hasPredicamentsToAuthenticate = !!(
    localStorage.getItem('token')
    && localStorage.getItem('user')
    && localStorage.getItem('account')
  );

  return (
    <Routes>
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      {!accountData && !hasPredicamentsToAuthenticate && publicRoutes}
      {accountIsSuperAdmin && SuperAdminRouter}
      {accountIsAdmin && AdminRouter}
      {accountIsLearner && LearnerRouter}
    </Routes>
  );
};

export default Router;
