import { SVGProps } from 'react';
import './IconStyles.scss';

const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="complok-icon-close"
    {...props}
  >
    <path d="M6 6L17.5 17.5" stroke="#171725" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 17.5L17.5 6" stroke="#171725" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Close;
