import { Form, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import apiClient from 'api/apiClient';
import { UserContext } from 'context/UserContext';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ISuperAdminCourseHeader } from 'types/Course/CourseTypes';
import { OrganizationManagementContext } from '../OrganizationManagementContext';

type Props = {
  showModal?: unknown;
  organization?: string;
  closeModal: () => void;

  onSuccess: () => void;
};

const EditOrganizationModal: FC<Props> = ({ showModal, closeModal, onSuccess }) => {
  const { accountData } = useContext(UserContext);
  const { organization } = useContext(OrganizationManagementContext);

  const { data: courseHeaders } = useSWR<ISuperAdminCourseHeader[]>(
    accountData?._id ? `/courses/by_account/${accountData._id}` : null
  );
  const [form] = useForm();

  const { t } = useTranslation();

  const editOrganization = async ({ features, courses }: { features: string[]; courses: string[] }) => {
    // onSuccess();
    if (organization?._id) {
      const response = await apiClient.patch('/organizations', {
        organization_id: organization._id,
        features,
        courses
      });

      if (response.status === 200) {
        message.success('Organization has been updated');
        onSuccess();
      } else {
        message.error('Failed to update organization');
      }
    }
  };

  return (
    <Modal onOk={form.submit} title={t('Edit organization')} visible={!!showModal} destroyOnClose onCancel={closeModal}>
      <Form form={form} onFinish={editOrganization} layout="vertical">
        <Form.Item name="features" label={t('Features')}>
          <Select
            defaultValue={organization?.configuration ? Object.keys(organization.configuration) : []}
            options={[{ label: t('Two step authentication'), value: 'two_step_auth' }]}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item label={t('Courses')} name="courses">
          <Select
            mode={'multiple'}
            style={{ width: '100%' }}
            notFoundContent={t('No new courses available')}
            defaultValue={(organization?.courses && organization.courses.map((course) => course._id)) || []}
            options={courseHeaders?.map((courseHeader) => ({ label: courseHeader.title, value: courseHeader._id }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOrganizationModal;
