import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
// componenets
import useSWR from 'swr';
import { Course } from 'types/Course/course.types';
import { CourseEdit } from '../CourseEdit/CourseEdit';
import './createCourse.scss';

const CreateCourseView: FC = () => {
  const { courseId } = useParams();
  const [activeTab, setActiveTab] = useState<string>('description');
  const { data: course, error } = useSWR<Course>(`/courses/content/${courseId}`);

  return <CourseEdit courseId={courseId} />;
};

export default CreateCourseView;
