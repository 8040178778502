import { SVGProps } from 'react';

const SuperAdminTag = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M15.37 6.001a.448.448 0 01-.011.133l-.987 3.948a.449.449 0 01-.433.34l-5.922.03H2.093a.449.449 0 01-.435-.34L.671 6.148a.448.448 0 01-.012-.137.943.943 0 111.225-.899.94.94 0 01-.344.728l1.236 1.245c.312.314.745.495 1.189.495.524 0 1.024-.25 1.338-.666l2.031-2.691a.943.943 0 111.608-.666c0 .252-.1.48-.262.65l.002.002 2.016 2.698c.315.421.816.673 1.342.673.447 0 .868-.175 1.184-.491l1.244-1.243a.94.94 0 01.59-1.676.943.943 0 01.313 1.83zm-1.095 5.856c0-.247-.2-.448-.448-.448H2.223c-.248 0-.449.2-.449.448v1.077c0 .248.201.449.449.449h11.604c.248 0 .448-.201.448-.449v-1.077z"
        fill="#595959"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SuperAdminTag;
