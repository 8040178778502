/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={15} cy={15} r={14.5} fill="#fff" stroke="#D5D5DC" />
    <path
      d="M20.75 20.063H9.25a.5.5 0 00-.5.5v.562c0 .069.056.125.125.125h12.25a.125.125 0 00.125-.125v-.563a.5.5 0 00-.5-.5zm-9.723-1.313a.63.63 0 00.093-.008l2.628-.46a.153.153 0 00.083-.044l6.624-6.624a.155.155 0 000-.22l-2.597-2.599a.155.155 0 00-.111-.045.155.155 0 00-.111.045l-6.623 6.624a.159.159 0 00-.044.083l-.461 2.628a.523.523 0 00.147.465.53.53 0 00.372.155z"
      fill="#44444F"
    />
  </svg>
);

export default EditIcon;
