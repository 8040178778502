import { Outlet, Route } from 'react-router-dom';
import GroupManagement from './GroupManagement';
import ManagementBreadcrumbs from './ManagementBreadcrumbs';
import OrganizationManagement from './OrganizationManagement';
import OrganizationManagementContext from './OrganizationManagementContext';

const OrganizationManagementRouter = (
  <Route
    path="/management"
    element={
      <OrganizationManagementContext>
        <ManagementBreadcrumbs />
        <Outlet />
      </OrganizationManagementContext>
    }
  >
    <Route path={'g/:groupIdentifier'} element={<GroupManagement />} />
    <Route path={'c/:courseIdentifier'} element={<OrganizationManagement />} />
    <Route path="" element={<OrganizationManagement />} />
  </Route>
);

export default OrganizationManagementRouter;
