import Joi from "joi";
import JS_PDF from "jspdf";
import i18n from "i18n";
import dayjs from "dayjs";
import bg from "../../assets/certificates/certificateBackground.png";
import complokCertificateBadge from "../../assets/certificates/complokCertificateBadge.png";
import certificateSignature from "../../assets/certificates/certificateSignature.png";
import complokHeaderLogo from "../../assets/certificates/complokHeaderLogo.png";
import "./poppins-pdf-normal";

interface DownloadCourseCertificate {
  /**
   * @todo // TODO: make function into async and return when download process has reached its end
   *          completed, canceled or errored - so the download button can give feedback via loader spinner
   * @todo // TODO: Change arguments into course and courseProgress objects and fetch data from them independently
   *
   * @argument {string} fileName name for the pdf file (do not add affix to the name, it will be added automatically) <fileName>.pdf
   *
   * @description: Download a certificate for a completed course
   */
  (certificateData: CourseCertificateCreationArgs, fileName?: string): void;
}

export const downloadCourseCertificate: DownloadCourseCertificate = (
  certCreationArgs,
  fileName
) => {
  const courseCertificate = createCourseCertificate(certCreationArgs);
  if (courseCertificate && fileName) {
    courseCertificate.save(fileName);
  }
};

type CourseCertificateCreationArgs = {
  titles?: {
    course?: string;
    certificate?: string;
  };
  identifier?: string;
  participant?: string;
  hasDoneText?: string;
  date?: string | Date;
  durationText?: string;
  additionalText?: string;
  locale: string;
  lecturer: string;
  registryText: string;
  createdBy: string;
  signedBy: string;
  signerAdditionalInformation: string;
};
const createCourseCertificateValidator =
  Joi.object<CourseCertificateCreationArgs>({
    titles: Joi.object({
      course: Joi.string().required(),
      certificate: Joi.string().required(),
    }).required(),
    identifier: Joi.string(),
    participant: Joi.string().required(),
    hasDoneText: Joi.string(),
    date: Joi.string(),
    durationText: Joi.string(),
    additionalText: Joi.string().allow(""),
    locale: Joi.string().required(),
    lecturer: Joi.string().required(),
    registryText: Joi.string().required(),
    createdBy: Joi.string().required(),
    signedBy: Joi.string().required(),
    signerAdditionalInformation: Joi.string().required(),
  }).required();
interface CreateCourseCertificate {
  (args: CourseCertificateCreationArgs): JS_PDF | null;
}
export const createCourseCertificate: CreateCourseCertificate = ({
  titles,
  hasDoneText,
  participant,
  identifier,
  durationText,
  date,
  additionalText,
  locale,
  lecturer,
  registryText,
  signedBy,
  createdBy,
  signerAdditionalInformation,
}) => {
  const { error } = createCourseCertificateValidator.validate({
    titles,
    hasDoneText,
    participant,
    identifier,
    durationText,
    date,
    additionalText,
    locale,
    registryText,
    lecturer,
    createdBy,
    signerAdditionalInformation,
    signedBy,
  });
  if (error) {
    console.error(error);
    return null;
  }

  const MAX_HEIGHT = 842;
  const MAX_WIDTH = 596;
  const BORDER_WIDTH = 60;

  // 210 x 297 mm | 596 x 842 px
  const certificate = new JS_PDF("p", "px", [MAX_WIDTH, MAX_HEIGHT]);

  // Add poppinsFont Poppins-Regular
  certificate.setFont("Poppins-Regular", "normal");
  certificate.setTextColor("#192545");

  // background with gradient
  certificate.addImage(bg, 0, 0, BORDER_WIDTH, MAX_HEIGHT);
  certificate.addImage(
    bg,
    MAX_WIDTH - BORDER_WIDTH,
    0,
    BORDER_WIDTH,
    MAX_HEIGHT
  );

  // Logos
  certificate.addImage(complokHeaderLogo, 218, 40, 161, 42); // complok header logo

  // Text
  if (titles?.certificate) {
    certificate.setTextColor("#192545");
    certificate.setFontSize(48);
    certificate.text(titles.certificate, MAX_WIDTH / 2, 103, {
      align: "center",
      baseline: "top",
      // lineHeightFactor: 72
    });
    if (identifier) {
      certificate.setFontSize(12);
      certificate.text(i18n.t("Your unique identifier"), MAX_WIDTH / 2, 168, {
        align: "center",
        baseline: "middle",
      });
      certificate.text(identifier, MAX_WIDTH / 2, 186, {
        align: "center",
        baseline: "middle",
      });
    }
  }

  if (participant) {
    certificate.setTextColor("#E58B29");
    certificate.setFontSize(36);
    certificate.text(`${participant}`.toUpperCase(), MAX_WIDTH / 2, 278, {
      align: "center",
      baseline: "middle",
    });
    certificate.setTextColor("#192545");
    certificate.setFontSize(16);
    certificate.text(hasDoneText ?? "", MAX_WIDTH / 2, 311, {
      align: "center",
      baseline: "middle",
    });
  }

  if (titles?.course) {
    certificate.setTextColor("#192545");
    certificate.setFontSize(24);

    const courseTitlePreview = certificate.getTextDimensions(
      `${titles.course}`.toUpperCase(),
      {
        maxWidth: MAX_WIDTH - BORDER_WIDTH * 2,
      }
    );

    certificate.text(`${titles.course}`.toUpperCase(), MAX_WIDTH / 2, 344, {
      align: "center",
      baseline: "middle",
      maxWidth: MAX_WIDTH - BORDER_WIDTH * 2,
    });

    if (additionalText) {
      certificate.setTextColor("#192545");
      certificate.setFontSize(12);
      certificate.text(
        `${additionalText ?? ""}`,
        MAX_WIDTH / 2,
        350 + courseTitlePreview.h,
        {
          align: "center",
          baseline: "middle",
          maxWidth: 348,
        }
      );
    }
  }

  if (durationText) {
    certificate.setTextColor("#192545");
    certificate.setFontSize(12);
    certificate.text(durationText, MAX_WIDTH / 2, 435, {
      align: "center",
      baseline: "middle",
    });
  }

  if (lecturer) {
    certificate.setTextColor("#192545");
    certificate.setFontSize(12);
    certificate.text(`${lecturer}`, MAX_WIDTH / 2, 450, {
      align: "center",
      baseline: "middle",
    });
  }

  if (createdBy) {
    certificate.addImage(complokCertificateBadge, 147, 599, 41, 65);
    certificate.setTextColor("#192545");
    certificate.setFontSize(12);
    certificate.text(createdBy, 106, 685, { maxWidth: 130 });
  }

  if (signedBy && signerAdditionalInformation) {
    certificate.addImage(certificateSignature, 385, 564, 138, 103.56);
    certificate.setTextColor("#192545");
    certificate.setFontSize(12);
    certificate.text(signedBy, 413, 670);
    certificate.text(signerAdditionalInformation, 413, 685, {
      maxWidth: 130,
      align: "center",
    });
    if (date) {
      certificate.text(`${dayjs(date).format("DD/MM/YYYY")}`, 427, 700);
      certificate.text("Tallinn", 444, 715);
    }
  }

  certificate.setTextColor("#192545");
  certificate.setFontSize(12);
  // certificate.setFont('poppins', 'normal', 400);
  certificate.text(registryText, MAX_WIDTH / 2, 782, {
    align: "center",
    baseline: "middle",
    maxWidth: 389,
  });
  // 590 777
  return certificate;
};
