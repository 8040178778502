/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
import { CheckCircleTwoTone, CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import apiClient from 'api/apiClient';
import ComplokCertificateScroll from 'assets/illustrations/ComplokCertificateScroll';
import { keys } from 'lodash';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ICourseExam,
  IExamSubmission,
  IExamSubmissionResponse
} from 'types/Course/CourseContent/course.content.exam.types';
import { CourseContentCustomTextSection } from 'types/Course/CourseContent/course.content.types';
import CourseQuestionnaireBanner from './QuestionnaireComponents/CourseQuestionnaireBanner';
import QuestionnaireQuestion from './QuestionnaireComponents/CourseQuestionnaireQuestion';

enum EXAM_STATE {
  INTRODUCTION,
  QUESTIONS,
  RESULTS
}

interface ICourseContentExam {
  content: ICourseExam;

  handleCompletion?: (isFinalExam?: boolean) => void;
  handleContinue?: (isFinalExam?: boolean) => void;

  style?: CSSProperties;
}

const CourseExam: FC<ICourseContentExam> = ({ content, style, handleCompletion, handleContinue }) => {
  const { t } = useTranslation();
  const { title, questions = [] } = content;
  const [examState, setExamState] = useState<EXAM_STATE>(EXAM_STATE.INTRODUCTION);
  const [answeredQuestions, setAnsweredQuestions] = useState<{ [key: string]: boolean | undefined }>({});

  const [questionIndex, setQuestionIndex] = useState<number>(0);

  const [examSubmissionResult, setExamSubmissionResult] = useState<IExamSubmissionResponse>();

  const canStartExam = (content?.questions?.length ?? 0) > 0;

  const customText = content?.custom_text;

  const startQuiz = () => {
    setQuestionIndex(0);
    setExamState(EXAM_STATE.QUESTIONS);
  };

  const retryQuiz = () => {
    setExamSubmissionResult(undefined);
    setAnsweredQuestions({});
    setQuestionIndex(0);
    setExamState(EXAM_STATE.INTRODUCTION);
  };

  const handleQuestionAnswer = (answers: { [key: string]: boolean | undefined }) => {
    setAnsweredQuestions({ ...answeredQuestions, ...answers });
  };

  const handleNextQuestion = (targetQuestion: number) => {
    if (questions.length <= targetQuestion) {
      setExamState(EXAM_STATE.RESULTS);
    } else {
      setQuestionIndex(targetQuestion);
    }
  };

  useEffect(() => {
    const submitAnswers = async () => {
      const composedQuestionsAndAnswers = questions.reduce((questionsWithAnswers, question) => {
        const questionAnswers = question.answers
          .filter((answer) => answeredQuestions[answer._id] === true)
          .map((answer) => answer._id);
        questionsWithAnswers[question._id] = questionAnswers;
        return questionsWithAnswers;
      }, {} as { [key: string]: string[] });
      const { data: results } =
        (await apiClient
          .post(`/exams/${content._id}/submit`, {
            submission: composedQuestionsAndAnswers
          } as IExamSubmission)
          .catch(() => null)) ?? {};
      if (results) {
        setExamSubmissionResult(results);
      }
    };
    if (examState === EXAM_STATE.RESULTS) {
      submitAnswers();
    }
  }, [examState, content, questions, answeredQuestions]);

  const unansweredQuestions = questions.reduce((unansweredQuestionIds, question, index) => {
    const answerIds = question.answers.map((answer) => answer._id);
    const tickedAnswersIds = keys(answeredQuestions).filter((answerId) => answeredQuestions[answerId]);
    if (!tickedAnswersIds.find((selectedAnswerId) => answerIds.includes(selectedAnswerId))) {
      return [...unansweredQuestionIds, index + 1];
    }
    return unansweredQuestionIds;
  }, [] as number[]);

  return (
    <div id="complok-coursecontent-quiz" style={{ ...style }}>
      {examState === EXAM_STATE.INTRODUCTION && (
        <ExamIntroduction
          amountOfQuestions={questions.length}
          handleStart={startQuiz}
          canStartExam={canStartExam}
          customText={customText?.intro}
          passPercentage={content?.settings?.pass_percentage}
        />
      )}
      {/*
      {examState === EXAM_STATE.QUESTIONS && (
        <ExamQuestion
          question={questions[questionIndex]}
          handleAnswer={handleQuestionAnswer}
          handleNext={handleNextQuestion}
          currentQuestionIndex={questionIndex}
          totalQuestionCount={questions.length}
        />
      )}
      */}
      {examState === EXAM_STATE.QUESTIONS && (
        <QuestionnaireQuestion
          question={questions[questionIndex]}
          handleAnswer={handleQuestionAnswer}
          handleNext={handleNextQuestion}
          currentQuestionIndex={questionIndex}
          totalQuestionCount={questions.length}
          allowSubmit={unansweredQuestions.length === 0}
          submitDeniedMessage={`${t('Please answer question')} ${unansweredQuestions.join(', ')}`}
        />
      )}
      {examState === EXAM_STATE.RESULTS && (
        <ExamResults
          answers={answeredQuestions}
          content={content}
          handleRetry={retryQuiz}
          handleCompletion={() => handleCompletion && handleCompletion(true)}
          handleContinue={handleContinue}
          customText={customText?.ending}
          examResult={examSubmissionResult}
        />
      )}
    </div>
  );
};

const ExamIntroduction: FC<{
  title?: string;
  customText?: CourseContentCustomTextSection;
  amountOfQuestions?: number;
  passPercentage?: number;
  handleStart?: Function;
  canStartExam?: boolean;
}> = ({ amountOfQuestions, handleStart, title, customText, canStartExam, passPercentage = 0 }) => {
  const { t } = useTranslation();
  return (
    <div id="complok-coursecontent-quiz-introduction">
      <ComplokCertificateScroll />
      <Title level={3} id="complok-coursecontent-quiz-introduction-title">
        {canStartExam
          ? customText?.title ?? title ?? t("It's time to earn your certificate")
          : t('Unfortunately the exam is currently not available')}
      </Title>
      {canStartExam &&
        ((!customText?.text && (
          <Text id="complok-coursecontent-quiz-introduction-text">
            {`${t(`Almost there! You’ve reached the end of this course. Let’s take the final step and test what knowledge you
            have gained. This knowledge test consists of`)} ${amountOfQuestions} ${t(`questions and multiple answers are allowed.
            You can complete knowledge test anytime you want and retry it as many times you need. You’re allowed to use
            all of your notes, but keep in mind that`)}`}
            <b>
              {t('you need to get')} {passPercentage * 100}% {t('right to earn your certificate.')}
            </b>
          </Text>
        )) ||
          (customText?.text && <Text id="complok-coursecontent-quiz-introduction-text">{customText.text}</Text>))}
      {canStartExam && (
        <Button
          type={'primary'}
          onClick={() => handleStart && handleStart()}
          id="complok-coursecontent-quiz-introduction-start"
        >
          {t('Start knowledge test')}
        </Button>
      )}
    </div>
  );
};

interface IExamResults {
  content: ICourseExam;
  answers: { [key: string]: boolean | undefined };

  examResult?: IExamSubmissionResponse;
  customText?: {
    success?: CourseContentCustomTextSection;
    failure?: CourseContentCustomTextSection;
  };

  handleRetry?: () => void;
  handleContinue?: () => void;
  handleCompletion?: () => void;
}
const ExamResults: FC<IExamResults> = ({
  answers,
  content,
  handleRetry,
  handleContinue,
  handleCompletion,
  customText,
  examResult
}) => {
  const { t } = useTranslation();
  const [completionSent, setCompletionSent] = useState<boolean>();
  const quizPassed = examResult?.passed === true;
  const totalQuestions = content.questions.length;

  const correctlyAnsweredQuestions =
    (examResult?.exam_data &&
      examResult.exam_data
        .map((question) =>
          question.answers.reduce((questionCorrectlyAnswered, answer) => {
            const isAnswerCorrect = answer.isCorrect === (answers[answer._id] ?? false);
            if (isAnswerCorrect) return questionCorrectlyAnswered;
            return false;
          }, true)
        )
        .filter((questionAnswers) => questionAnswers === true).length) ||
    [];

  const resultTitle = quizPassed
    ? customText?.success?.title ?? t('Congratulations, you completed the exam.')
    : customText?.failure?.title ?? t('Sometimes we fail, it happens.');

  const resultMessage = quizPassed
    ? customText?.success?.text ||
      t(
        'You passed all the requirements to pass the exam. See all of the answers below and feel free to retry or finish course instead.'
      )
    : customText?.failure?.text ||
      t('You did not pass the requirements for the quiz. See all of the answers below and feel free to retry.');

  useEffect(() => {
    if (quizPassed && !completionSent) {
      if (handleCompletion) handleCompletion();
      setCompletionSent(true);
    }
  }, [quizPassed, handleCompletion, completionSent, setCompletionSent]);

  return (
    <div id="complok-coursecontent-quiz-result">
      {examResult && (
        <CourseQuestionnaireBanner
          percentage={examResult?.result_pct}
          handleContinue={handleContinue}
          handleRetry={handleRetry}
          customText={{
            title: {
              success: customText?.success?.title ?? t('Congratulations, you completed the exam.'),
              failure: customText?.failure?.title ?? t('Sometimes we fail, it happens.')
            },
            description: {
              success:
                customText?.success?.text ??
                t(
                  'You passed all the requirements to pass the exam. See all of the answers below and feel free to retry or finish course instead.'
                ),
              failure:
                customText?.failure?.text ??
                t(
                  'You did not pass the requirements for the quiz. See all of the answers below and feel free to retry.'
                )
            }
          }}
          thresholds={{
            success: examResult?.pass_pct ?? 100,
            failure: examResult?.pass_pct ?? 100
          }}
        />
      )}
      <div id="complok-coursecontent-quiz-result-threshold">
        <div id="complok-coursecontent-quiz-result-threshold-result">
          <Text>{t('My Result')}</Text>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleTwoTone twoToneColor={'#4caf50'} style={{ marginRight: 10 }} /> {examResult?.correctly_answered}{' '}
            / {totalQuestions}
            <CloseCircleOutlined style={{ marginLeft: 20, marginRight: 10 }} /> {examResult?.incorrectly_answered ?? 0}
          </div>
        </div>
        <div id="complok-coursecontent-quiz-result-threshold-requirements">
          <Text>{t('Required to pass')}</Text>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleTwoTone twoToneColor={'#4caf50'} style={{ marginRight: 10 }} /> {examResult?.pass_pct ?? '0'}%
          </div>
        </div>
      </div>

      <div id="complok-coursecontent-quiz-result-questions">
        {examResult?.exam_data &&
          examResult?.exam_data.map((question) => (
            <div id="complok-coursecontent-quiz-result-questions-question" key={question._id}>
              <h2 id="complok-coursecontent-quiz-result-questions-question-title">{question.question}</h2>
              <div id="complok-coursecontent-quiz-result-questions-question-answers">
                {question.answers.map((answer) => (
                  <div
                    key={answer._id}
                    id="complok-coursecontent-quiz-result-questions-question-answers-answer"
                    className={answers[answer._id] ? 'gray' : 'white'}
                  >
                    <Checkbox
                      checked={answers[answer._id] ?? false}
                      className={`answer-checkbox 
                    ${
                      ''
                      //  ? 'wrong-answer-checkbox'
                      //  : 'correct-answer-checkbox'
                    }`}
                    >
                      <Row>
                        <span
                          id="complok-quiz-result-questions-question-answers-answer-text"
                          className={answer.isCorrect ? 'correct' : 'incorrect'}
                        >
                          {answer.answer}
                          {answer.isCorrect && <CheckOutlined style={{ marginLeft: 5 }} />}
                          {!answer.isCorrect && <CloseOutlined style={{ marginLeft: 5 }} />}
                        </span>
                      </Row>
                    </Checkbox>
                  </div>
                ))}
                <h3>{question.explanation}</h3>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseExam;
