import { SVGProps } from 'react';

const StudentTag = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#prefix__clip0)" fill="#595959">
      <path d="M8.5 9.598L3.727 8.13V10.583c0 1.134 2.137 2.054 4.774 2.054s4.775-.92 4.775-2.054c0-.01-.003-.018-.003-.027V8.13L8.5 9.6z" />
      <path d="M.5 6.44l1.705.61.145-.31.627-.054.09.093-.538.128-.079.232s-1.214 2.538-1.036 3.78c0 0 .758.451 1.516 0l.201-3.395V7.24l1.128-.254-.08.196-.84.273.388.139L8.5 9.064l4.773-1.469L16.5 6.441l-8-3.078-8 3.078z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default StudentTag;
