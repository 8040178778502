/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={15} cy={15} r={15} fill="#F5222D" />
    <path
      d="M20.5 11h-2V9.75a1 1 0 00-1-1h-5a1 1 0 00-1 1V11h-2a.5.5 0 00-.5.5v.5c0 .069.056.125.125.125h.944l.386 8.172a1 1 0 00.998.953h7.094a.999.999 0 00.998-.953l.386-8.172h.944A.125.125 0 0021 12v-.5a.5.5 0 00-.5-.5zm-3.125 0h-4.75V9.875h4.75V11z"
      fill="#fff"
    />
  </svg>
);

export default DeleteIcon;
