import { RightOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { FC } from 'react';

const { Meta } = Card;

interface IEmptyContent {
  uploadingVideo: boolean;
  selectedFile: RcFile | undefined;
  setSelectedFile: React.Dispatch<React.SetStateAction<RcFile | undefined>>;
  uploadVideo: () => Promise<void>;
  createExam: () => Promise<void>;
  createQuiz: () => Promise<void>;
}

const EmptyContent: FC<IEmptyContent> = ({
  uploadVideo,
  createExam,
  createQuiz,
  uploadingVideo,
  selectedFile,
  setSelectedFile
}) => (
  <div style={{ padding: '5%' }}>
    <Card style={{ margin: 40 }}>
      <Meta
        title="Video"
        description={
          <div>
            <div>{selectedFile?.name || 'File not selected'}</div>
            <Row style={{ marginTop: 20, alignItems: 'center' }}>
              <Col flex="1">
                <Upload
                  accept="video/*"
                  beforeUpload={(videoFile) => {
                    setSelectedFile(videoFile || undefined);
                    return false;
                  }}
                  showUploadList={false}
                >
                  <Button>
                    <UploadOutlined /> Choose a video file to upload
                  </Button>
                </Upload>
              </Col>
              <Col>
                {uploadingVideo ? (
                  <Spin style={{ marginTop: 10, marginLeft: 100 }} />
                ) : (
                  <RightOutlined onClick={uploadVideo} style={{ marginTop: 10, marginLeft: 50 }} key="ellipsis" />
                )}
              </Col>
            </Row>
          </div>
        }
      />
    </Card>
    <Card style={{ margin: 40 }}>
      <Meta
        title="Exam"
        description={
          <div>
            <div>Create an exam</div>
            <RightOutlined style={{ justifyContent: 'flex-end', float: 'right' }} onClick={createExam} key="ellipsis" />
          </div>
        }
      />
    </Card>
    <Card style={{ margin: 40 }}>
      <Meta
        title="Quiz"
        description={
          <div>
            <div>Create a quiz</div>
            <RightOutlined style={{ justifyContent: 'flex-end', float: 'right' }} onClick={createQuiz} key="ellipsis" />
          </div>
        }
      />
    </Card>
  </div>
);

export default EmptyContent;
