/* eslint-disable arrow-body-style */
import { FC } from 'react';
import Chevron from 'components/icons/Chevron';
import { IComplokTag } from './Tag';
import './CardStyles.scss';

export interface ICard {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick?: Function;
}

const Card: FC<ICard> = ({ title, onClick, children }) => {
  return (
    <div className="complok-card" onClick={() => onClick && onClick()}>
      {title}
      {children}
    </div>
  );
};

export interface ICourseCard extends ICard {
  id: string;
  chevron?: boolean;
  tags?: Array<IComplokTag | undefined>;
}

export const CourseCard: FC<ICourseCard> = ({ title, tags, chevron, onClick, id }) => {
  return (
    <div className="complok-card" onClick={() => onClick && onClick()}>
      <div className="complok-card-content">
        <div className="complok-card-content-tags">{tags}</div>
        <div className="complok-card-content-title">{title}</div>
      </div>
      <div className="complok-card-actions">
        <div className="complok-card-actions-main">{chevron && <Chevron direction={'right'} />}</div>
        {/* <div className="complok-card-actions-secondary"></div> */}
      </div>
    </div>
  );
};

export default Card;
