import { Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

const defaultText = {
  title: {
    success: 'Congratulations, you completed the quiz.',
    neutral: 'Congratulations, you completed the quiz.',
    failure: 'Sometimes we fail, it happens.'
  },
  description: {
    success: 'You passed all the requirements to pass the quiz. See all of the answers below and feel free to retry or continue instead.',
    neutral: 'You passed all the requirements to pass the quiz. See all of the answers below and feel free to retry or continue instead.',
    failure: 'You did not pass the requirements for the quiz. See all of the answers below and feel free to retry.'
  }
};

interface CourseQuestionnaireBannerProps {
  /** @description Percentage shown on the banner */
  percentage: number;
  /**
   * @description
   *  Thresholds to indicate success, failure or neutral result
   *  Percentage between success and failure will be indicated as neutral [, unless indicated otherwise <not implemented>]
   */
  thresholds?: {
    /** @description Threshold above will be indicated as success */
    success?: number;
    /** @description Threshold below will be indicated as success */
    failure?: number;

    /** @description Allow continuing the course despite of failure */
    allowContinue?: boolean;
  };

  /** @description Custom text shown on the banner */
  customText?: {
    title?: {
      success?: string;
      neutral?: string;
      failure?: string;
    };
    description?: {
      success?: string;
      neutral?: string;
      failure?: string;
    };
  };

  style?: CSSProperties;

  /** Function that will be called, when Continue button is pressed */
  handleContinue?: () => void;
  handleRetry?: () => void;
}

const CourseQuestionnaireBanner: FC<CourseQuestionnaireBannerProps> = ({
  percentage,
  thresholds,
  customText,

  handleContinue,
  handleRetry,

  style = {}
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useBreakpoint().md === false;

  const successThreshold = thresholds?.success ?? 50;
  const failureThreshold = thresholds?.failure ?? 50;

  const resultClass = percentage >= successThreshold ? 'success' : (percentage < failureThreshold && 'failure') || 'neutral';

  const title = customText?.title?.[resultClass] ?? t(defaultText.title[resultClass]);
  const description = customText?.description?.[resultClass] ?? t(defaultText.description[resultClass]);

  return (
    <div id="complok-questionnaire-banner" className={resultClass} style={{ marginBottom: 48, ...style }}>
      {isSmallScreen && <Title id="complok-questionnaire-banner-percentage-watermark">{Math.round(percentage)}%</Title>}

      <Title id="complok-questionnaire-banner-percentage">
        {Math.round(percentage)}
        {isSmallScreen ? t('% Completed') : '%'}
      </Title>

      <div id="complok-questionnaire-banner-message">
        <Title className={'textwhite'} id="complok-questionnaire-banner-message-title">
          {title}
        </Title>
        <Text className={'textwhite'} id="complok-questionnaire-banner-message-description">
          {description}
        </Text>
      </div>
      <div id="complok-questionnaire-banner-actions">
        {(resultClass !== 'failure' || thresholds?.allowContinue) && (
          <Button
            id="complok-questionnaire-banner-actions-continue"
            className={resultClass}
            onClick={() => handleContinue && handleContinue()}
            size={'large'}
          >
            {t('Continue')}
          </Button>
        )}
        <Button
          id="complok-questionnaire-banner-actions-retry"
          className={resultClass}
          danger={resultClass === 'failure'}
          type={resultClass === 'failure' && !thresholds?.allowContinue ? 'default' : 'text'}
          onClick={() => handleRetry && handleRetry()}
          size={'large'}
        >
          {resultClass === 'failure' ? t('Try again') : t('Retry')}
        </Button>
      </div>
    </div>
  );
};

export default CourseQuestionnaireBanner;
