import { ICourseExam } from './course.content.exam.types';
import { ICourseQuiz } from './course.content.quiz.types';
import { ICourseVideo } from './course.content.video.types';

export enum COURSE_CONTENT {
  VIDEO = 'video',
  QUIZ = 'quiz',
  EXAM = 'exam',
  INFO = 'information',
}

export namespace CourseContentType {
  export type Video = ICourseVideo;
  export type Quiz = ICourseQuiz;
  export type Exam = ICourseExam;
  export type Info = ICourseInformation;
  export type Types = Video | Quiz | Exam | Info;
}

export type CourseContentCustomTextSection = {
  title?: string;
  text?: string;
};
export interface CourseContentCustomText {
  intro?: CourseContentCustomTextSection;
  ending?: {
    success: CourseContentCustomTextSection;
    failure?: CourseContentCustomTextSection;
  };
}
export interface ICourseContentBase {
  _id: string;
  title: string;

  course: string;
  module: string;

  custom_text?: CourseContentCustomText;
}

interface ICourseInformation extends ICourseContentBase {
  content?: string;
}
