import i18n from 'i18n';
import { createContext, useState, FC, useEffect, useCallback } from 'react';

interface ISettingsContext {
  language: string | undefined;
  updateLanguage: (newLang: string) => void;
}

export const SettingsContext = createContext<ISettingsContext>({
  language: undefined,
  updateLanguage: () => null
});

const SettingsContextProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState<string | undefined>();

  const updateLanguage = useCallback((newLang: string) => {
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  }, []);

  return <SettingsContext.Provider value={{ language, updateLanguage }}>{children}</SettingsContext.Provider>;
};

export default SettingsContextProvider;
