import { Tag } from 'antd';
import { CSSProperties, FC } from 'react';

export interface IComplokTag {
  title?: string;
  type?: 'default' | 'warning' | 'danger' | 'success';
  style?: CSSProperties | undefined;
}

const ComplokTag: FC<IComplokTag> = ({ title = '[]', type = 'default', style }) => {
  const tagClass = ` complok-tag complok-tag-${type}`;
  return (
    <Tag style={style} className={tagClass}>
      {title}
    </Tag>
  );
};

export default ComplokTag;
