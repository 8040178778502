import { Button, Col, Dropdown, Menu, message, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import apiClient from "api/apiClient";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAccount } from "types/AccountTypes";
import { DetailedUserGroup } from "types/GroupTypes";
import UserDetailsDrawer from "../drawers/UserDetailsDrawer";
import { OrganizationManagementContext } from "../OrganizationManagementContext";

interface GroupTableProps {
  group?: DetailedUserGroup;
  onAddUsers?: () => void;
  onAddUsersFromFile?: () => void;

  removeAccountFromGroup?: (account?: string) => void;
}

type GroupUsersActions = "remove_from_group";

const GroupUsersTable: FC<GroupTableProps> = ({
  group,
  onAddUsers = () => {},
  onAddUsersFromFile = () => {},
  removeAccountFromGroup = () => {},
}) => {
  const { refreshGroup } = useContext(OrganizationManagementContext);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedAction, setSelectedAction] = useState<
    GroupUsersActions | undefined
  >();
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();

  const { t } = useTranslation();

  const addUsersButtons = (
    <Dropdown.Button
      overlay={
        <Menu onClick={() => onAddUsersFromFile()}>
          <Menu.Item key="via-file">{t("Import users via file")}</Menu.Item>
        </Menu>
      }
      onClick={onAddUsers}
    >
      {t("Add users")}
    </Dropdown.Button>
  );

  const columns: ColumnsType<IAccount> = [
    {
      title: t("Name"),
      dataIndex: ["user", "name"],
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title: t("Email"),
      dataIndex: ["user", "email"],

      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title: () => addUsersButtons,

      align: "right",
      render: (account) => (
        <Button
          onClick={() => removeAccountFromGroup(account._id)}
          danger
          type={"text"}
        >
          {t("Remove")}
        </Button>
      ),
    },
  ];

  const onTableAction = async () => {
    if (selectedAction === "remove_from_group") {
      const loading = message.loading(t("Removing users from group!"));

      apiClient
        .put(`/groups/${group?._id}/remove_accounts`, {
          accounts: selectedUsers,
        })
        .then((response) => {
          if (response?.data) {
            loading();
            message.success(t("Users were removed"));
            if (refreshGroup) {
              refreshGroup();
            }
          }
        })
        .catch(() => {
          loading();
          message.error(t("Failed to remove users from group!"));
        });
    } else {
      message.error(t("Incorrect action!"));
    }
  };

  const Footer = (
    <Row gutter={16}>
      <Col flex={1}>
        <Select
          placeholder="Choose action"
          style={{ width: 200 }}
          onSelect={(val: GroupUsersActions) => setSelectedAction(val)}
          options={[
            {
              label: "Remove from group",
              value: "remove_from_group",
            },
          ]}
        />
      </Col>
      <Col>
        <Button
          onClick={onTableAction}
          style={{ padding: "2px 10px" }}
          type="default"
        >
          Apply
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <Table
        rowKey={"_id"}
        scroll={{ y: "70vh" }}
        pagination={{ pageSize: 100 }}
        columns={columns}
        dataSource={group?.accounts}
        rowSelection={{
          selectedRowKeys: selectedUsers,
          onChange: (x) => x && setSelectedUsers(x as string[]),
        }}
        id="complok-groupmanagement-details-group-users"
        footer={() => Footer}
      />
      <UserDetailsDrawer
        accountId={selectedAccount}
        onClose={() => setSelectedAccount(undefined)}
      />
    </>
  );
};

export default GroupUsersTable;
