import { SVGProps } from 'react';

const ComplokHopping = (props: SVGProps<SVGSVGElement>) => (
  <svg width="468" height="649" viewBox="0 0 468 649" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M303.325 569.424C324.168 576.396 355.011 575.129 368.18 562.312C380.784 549.988 383.319 527.876 355.716 527.806C328.112 527.735 296.846 532.876 285.65 540.834C274.453 548.862 282.481 562.382 303.325 569.424Z"
      fill="#172845"
    />
    <path
      d="M201.711 477.666C185.304 454.427 184.811 422.033 210.866 396.329C236.92 370.626 282.058 339.429 324.661 319.007C367.264 298.514 420.923 261.332 450.568 278.867C480.214 296.402 469.652 376.893 425.852 424.216C382.052 471.539 336.984 489.567 302.409 503.651C267.834 517.735 221.851 506.116 201.711 477.666Z"
      fill="#172845"
    />
    <path
      d="M125.802 324.993C86.9308 309.219 50.1022 251.122 95.1697 173.87C140.237 96.618 248.258 10.493 346.069 10.493C443.879 10.493 474.934 90.4914 449.161 155.349C423.388 220.207 385.362 256.826 329.521 292.177C273.679 327.529 192.91 352.176 125.802 324.993Z"
      fill="#172845"
    />
    <path
      d="M355.294 195.348C409.235 195.348 452.963 151.618 452.963 97.674C452.963 43.7301 409.235 0 355.294 0C301.352 0 257.624 43.7301 257.624 97.674C257.624 151.618 301.352 195.348 355.294 195.348Z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M381.771 84.9273C411.77 71.5473 417.614 65.4911 426.487 59.0123"
      stroke="url(#paint1_linear)"
      strokeMiterlimit="10"
    />
    <path
      d="M397.615 108.237C432.894 112.392 440.007 143.025 440.007 143.025C440.007 143.025 479.581 70.632 402.263 21.5486C406.136 46.2664 389.799 59.9985 382.475 63.8716C375.222 67.7448 397.615 108.237 397.615 108.237Z"
      fill="url(#paint2_radial)"
    />
    <path
      d="M369.73 167.602C369.659 162.18 308.818 152.462 310.297 160.137C311.776 167.813 325.648 184.433 343.746 188.728C361.913 193.024 369.589 179.503 369.73 167.602Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M303.96 116.758C305.72 125.772 313.818 159.997 316.424 170.137C318.677 171.546 322.621 172.179 325.085 173.024C331.423 175.067 336.07 170.208 342.69 170.841C344.169 170.982 345.647 171.053 347.126 171.053C343.183 166.616 332.55 133.941 332.479 133.237C331.775 128.025 331.705 111.265 330.578 106.97C329.381 102.744 300.791 100.632 303.96 116.758Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M401.206 81.0544C398.601 69.0124 391.982 60.2802 386.982 61.5478C386.56 61.6182 386.207 61.8295 385.785 62.0408C361.561 75.4208 301.706 105.138 277.693 101.969C278.398 106.547 283.327 122.11 283.327 122.11C283.327 122.11 321.634 107.11 392.897 107.674C394.587 107.814 395.08 107.885 396.207 107.603C401.277 106.336 403.812 93.1669 401.206 81.0544Z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M284.031 121.828C281.989 122.884 279.665 122.18 278.046 118.236C277.975 118.166 277.975 118.025 277.905 117.955C276.567 113.87 282.06 111.053 284.806 114.363L285.299 114.997C286.918 117.039 286.566 119.997 284.454 121.546L284.031 121.828Z"
      fill="url(#paint6_linear)"
    />
    <path
      d="M277.976 103.237C278.961 108.8 281.778 121.899 281.778 121.899C281.778 121.899 285.299 120.209 291.214 117.885C290.51 115.561 293.679 112.392 292.763 110.138C291.707 107.533 288.961 105.068 286.919 102.955C284.736 102.744 280.159 103.167 277.976 103.237Z"
      fill="url(#paint7_linear)"
    />
    <path
      d="M281.365 121.924C283.65 121.451 284.583 116.622 283.449 111.137C282.315 105.653 279.543 101.59 277.258 102.062C274.973 102.535 274.039 107.364 275.174 112.848C276.308 118.333 279.079 122.396 281.365 121.924Z"
      fill="#F0E6DA"
    />
    <path
      d="M281.496 121.899C279.243 122.462 276.426 118.448 275.3 112.955C274.173 107.463 275.018 102.533 277.271 101.97C279.525 101.406 282.341 105.42 283.468 110.913C284.595 116.476 283.75 121.336 281.496 121.899Z"
      fill="url(#paint8_linear)"
    />
    <path
      d="M281.496 121.901C283.186 121.479 284.101 118.591 283.961 114.789C282.904 115.141 281.566 115.704 280.017 116.338C278.116 115.493 278.046 113.098 277.764 110.845L280.721 110.352C280.228 108.31 279.313 106.62 278.398 106.408H278.186C277.693 106.338 272.975 110.634 279.031 121.056C279.876 121.76 280.721 122.112 281.496 121.901Z"
      fill="#F0E6DA"
    />
    <path
      d="M281.496 121.901C283.186 121.479 284.101 118.591 283.961 114.789C282.904 115.141 281.566 115.704 280.017 116.338C278.116 115.493 278.046 113.098 277.764 110.845L280.721 110.352C280.228 108.31 279.313 106.62 278.398 106.408H278.186C277.693 106.338 272.975 110.634 279.031 121.056C279.876 121.76 280.721 122.112 281.496 121.901Z"
      fill="url(#paint9_linear)"
    />
    <path
      d="M280.792 110.349L277.834 110.842C278.045 113.096 278.186 115.49 280.087 116.335C281.284 115.349 281.284 112.673 280.792 110.349Z"
      fill="#F0E6DA"
    />
    <path
      d="M280.792 110.349L277.834 110.842C278.045 113.096 278.186 115.49 280.087 116.335C281.284 115.349 281.284 112.673 280.792 110.349Z"
      fill="url(#paint10_linear)"
    />
    <path
      d="M280.018 116.406C281.567 115.702 282.905 115.209 283.961 114.857C282.905 115.209 281.496 115.702 280.018 116.406Z"
      fill="#F0E6DA"
    />
    <path
      d="M280.018 116.406C281.567 115.702 282.905 115.209 283.961 114.857C282.905 115.209 281.496 115.702 280.018 116.406Z"
      fill="url(#paint11_linear)"
    />
    <path
      d="M280.018 116.406C281.567 115.702 282.905 115.209 283.961 114.857C282.905 115.209 281.496 115.702 280.018 116.406Z"
      fill="url(#paint12_linear)"
    />
    <path
      d="M278.468 106.406C279.383 106.618 280.299 108.378 280.792 110.35C280.299 108.378 279.383 106.618 278.468 106.406Z"
      fill="#F0E6DA"
    />
    <path
      d="M278.468 106.406C279.383 106.618 280.299 108.378 280.792 110.35C280.299 108.378 279.383 106.618 278.468 106.406Z"
      fill="url(#paint13_linear)"
    />
    <path
      d="M278.468 106.406C279.383 106.618 280.299 108.378 280.792 110.35C280.299 108.378 279.383 106.618 278.468 106.406Z"
      fill="url(#paint14_linear)"
    />
    <path
      d="M280.792 110.349C281.355 112.673 281.285 115.349 280.018 116.406C281.285 115.349 281.285 112.673 280.792 110.349Z"
      fill="#F0E6DA"
    />
    <path
      d="M280.792 110.349C281.355 112.673 281.285 115.349 280.018 116.406C281.285 115.349 281.285 112.673 280.792 110.349Z"
      fill="url(#paint15_linear)"
    />
    <path
      d="M280.792 110.349C281.355 112.673 281.285 115.349 280.018 116.406C281.285 115.349 281.285 112.673 280.792 110.349Z"
      fill="url(#paint16_linear)"
    />
    <path
      d="M280.792 110.35C281.355 112.674 281.285 115.35 280.017 116.406C281.566 115.702 282.904 115.209 283.961 114.857C283.89 113.66 283.749 112.322 283.468 110.984C283.045 109.153 282.482 107.533 281.848 106.125L278.257 106.406C278.327 106.406 278.398 106.406 278.468 106.406C279.384 106.617 280.299 108.378 280.792 110.35Z"
      fill="#F0E6DA"
    />
    <path
      d="M280.792 110.35C281.355 112.674 281.285 115.35 280.017 116.406C281.566 115.702 282.904 115.209 283.961 114.857C283.89 113.66 283.749 112.322 283.468 110.984C283.045 109.153 282.482 107.533 281.848 106.125L278.257 106.406C278.327 106.406 278.398 106.406 278.468 106.406C279.384 106.617 280.299 108.378 280.792 110.35Z"
      fill="url(#paint17_linear)"
    />
    <path
      d="M336.282 92.7447C315.861 97.2516 286.919 104.575 282.482 104.153C286.003 108.519 287.482 118.096 284.102 121.899C301.214 113.167 363.886 107.181 397.686 108.308C409.023 99.7164 404.869 76.0549 398.32 66.1959C388.602 72.0409 361.984 87.111 336.282 92.7447Z"
      fill="url(#paint18_linear)"
    />
    <path
      d="M296.918 100.139C298.538 92.463 306.706 90.7729 310.016 99.7868C311.987 105.209 311.706 106.97 309.452 107.04C307.199 107.111 305.157 104.787 303.326 102.392C301.495 99.9981 300.932 99.0122 300.932 99.0122L296.918 100.139Z"
      fill="url(#paint19_linear)"
    />
    <path
      d="M313.748 92.6035C315.79 91.9697 319.1 89.012 321.917 92.8851C324.733 96.7583 325.93 101.406 323.888 103.026C321.846 104.645 318.114 99.0822 316.917 96.8287C315.72 94.5752 314.804 94.8569 314.804 94.8569L313.748 92.6035Z"
      fill="url(#paint20_linear)"
    />
    <path
      d="M307.059 94.716C313.326 87.8147 318.255 90.702 321.001 100.279C322.621 105.843 321.494 107.673 319.311 107.321C317.128 107.04 315.931 104.293 314.523 101.688C313.044 99.0821 311.917 97.6737 311.917 97.6737L307.059 94.716Z"
      fill="url(#paint21_linear)"
    />
    <path
      d="M301.143 95.2797C306.776 87.8855 311.987 90.4206 315.508 99.6458C317.55 105.068 316.635 106.97 314.381 106.829C312.128 106.688 310.79 104.082 309.1 101.618C307.41 99.1528 306.142 97.8148 306.142 97.8148L301.143 95.2797Z"
      fill="url(#paint22_linear)"
    />
    <path
      d="M309.452 138.448C304.241 128.025 299.946 121.476 299.171 117.462C298.397 113.378 301.636 106.265 304.241 104.012C305.65 102.815 309.804 102.885 312.199 102.955C316.776 103.167 317.621 107.322 312.128 109.505C308.185 111.054 309.664 115.209 314.382 117.251C318.114 118.871 321.846 120.842 324.663 127.603"
      fill="url(#paint23_linear)"
    />
    <path
      d="M272.06 150.982L313.818 180.207L310.226 159.996C311.283 166.897 369.659 175.841 369.73 167.602C369.73 189.643 368.04 218.586 360.153 230.347C352.336 242.107 339.239 245.698 326.422 236.966C313.606 228.234 283.82 202.812 272.341 187.108C260.863 171.404 272.06 150.982 272.06 150.982Z"
      fill="url(#paint24_linear)"
    />
    <path
      d="M283.115 172.39C287.34 174.996 291.636 177.46 296.143 179.362C298.044 180.207 300.016 180.911 301.987 181.475C303.607 181.897 305.227 182.179 306.846 182.038C308.043 181.967 309.311 181.545 310.156 180.629C310.719 180.066 311.071 179.291 311.283 178.446L271.989 150.982C271.989 150.982 269.454 155.63 268.046 162.25C269.243 163.095 270.37 163.94 271.567 164.785C275.369 167.461 279.242 169.996 283.115 172.39Z"
      fill="url(#paint25_linear)"
    />
    <path
      d="M105.17 278.867C94.7481 285.839 46.9343 239.925 57.0745 235.206C75.5944 226.545 106.578 237.812 106.578 237.812C106.578 237.812 119.535 269.079 105.17 278.867Z"
      fill="url(#paint26_linear)"
    />
    <path
      d="M22.7806 262.178C21.5131 266.685 19.7526 273.163 22.5694 274.079C26.4424 275.417 29.1182 265.558 30.0337 263.023C30.9491 260.417 23.7665 258.516 22.7806 262.178Z"
      fill="url(#paint27_linear)"
    />
    <path
      d="M32.8503 267.881C31.442 269.782 27.9915 279.36 29.9632 283.162C31.9349 286.965 35.8783 282.74 37.9204 276.754C39.9625 270.768 35.4558 264.501 32.8503 267.881Z"
      fill="url(#paint28_linear)"
    />
    <path
      d="M39.9625 273.937C38.6246 275.698 35.3853 284.712 37.2162 288.303C39.0471 291.895 42.7088 287.881 44.6101 282.318C46.5818 276.754 42.3567 270.839 39.9625 273.937Z"
      fill="url(#paint29_linear)"
    />
    <path
      d="M72.7773 227.178C72.7773 227.178 42.6384 244.009 30.5265 249.854C28.2027 250.558 21.5834 244.713 18.2033 245.136C14.8233 245.558 12.8516 246.474 10.0348 246.122C7.21812 245.77 3.27472 244.361 1.93678 244.361C0.598836 244.361 -1.65454 247.53 1.93678 251.192C5.52809 254.924 11.2319 255.91 16.5133 255.699C19.8934 263.445 26.5831 263.797 29.7519 267.178C32.9207 270.558 33.9065 276.051 38.4837 280.205C43.0609 284.36 46.9339 286.966 46.5818 289.853C46.2297 292.74 43.9059 295.205 43.9763 296.473C44.1171 297.74 45.5255 299.642 49.3281 297.6C53.1306 295.557 54.6094 294.571 55.1728 290.205C55.7361 285.839 54.9615 280.98 52.5673 273.938C51.511 270.84 51.511 270.84 51.511 270.84L114.746 245.91C107.564 234.009 95.5927 229.573 72.7773 227.178Z"
      fill="url(#paint30_linear)"
    />
    <path
      d="M64.679 238.446C65.8057 239.713 66.7211 241.122 67.4957 242.671C68.3408 244.291 69.0449 246.122 69.6083 247.882C70.6645 251.544 70.5941 255.347 69.7491 259.009C69.3266 260.84 68.7633 262.53 68.1295 264.29L114.887 245.84C107.564 234.009 95.6629 229.573 72.8475 227.178C72.8475 227.178 66.9324 230.488 59.2568 234.643C61.299 235.418 63.2002 236.756 64.679 238.446Z"
      fill="url(#paint31_linear)"
    />
    <path
      d="M238.259 281.262C218.19 285.839 187.488 294.289 180.587 277.036C173.686 259.713 168.405 201.615 167.489 180.207C166.574 158.799 186.572 146.616 212.979 143.588C239.386 140.49 242.203 141.828 243.893 153.236C245.583 164.644 269.666 273.515 238.259 281.262Z"
      fill="url(#paint32_linear)"
    />
    <path
      d="M176.081 198.94C162.772 200.418 159.603 204.784 159.321 213.376C159.039 221.967 163.969 256.192 167.419 264.009C170.87 271.825 191.925 272.177 194.248 259.22C196.572 246.262 192.066 219.15 190.446 211.686C188.826 204.292 183.475 198.165 176.081 198.94Z"
      fill="url(#paint33_linear)"
    />
    <path
      d="M140.378 514.496C138.195 509.496 131.787 510.693 126.506 513.721C120.591 517.172 118.056 521.186 118.056 521.186C118.056 521.186 111.577 520.2 101.719 527.312C91.8603 534.425 93.0574 539.354 93.0574 539.354C93.0574 539.354 92.0012 551.678 102.846 564.002C105.733 567.241 107.423 571.466 107.493 575.832C107.564 592.804 105.169 612.592 129.956 621.817C139.252 625.55 147.138 615.479 143.758 602.663C140.378 589.846 140.871 579.424 150.026 572.523C159.18 565.621 163.123 564.213 163.123 560.269C163.123 556.326 162.56 555.621 161.363 554.776C160.095 553.931 150.026 536.819 150.026 536.819L140.378 514.496Z"
      fill="url(#paint34_linear)"
    />
    <path
      d="M98.9022 529.496C96.5784 534.284 96.8601 547.171 106.578 559.495C111.366 565.622 112.07 568.439 112.352 578.861C112.634 589.354 112.986 598.579 115.943 604.635C118.901 610.762 125.591 619.494 138.688 621.043"
      stroke="#172845"
      strokeMiterlimit="10"
    />
    <path
      d="M161.715 505.2C157.561 501.467 150.308 500.834 145.942 505.622C141.576 510.411 136.647 514.918 133.548 519.002C130.379 523.157 130.802 532.241 133.548 539.283C136.294 546.396 141.857 550.832 147.561 551.607C158.406 551.607 161.363 554.706 161.363 554.706C161.363 554.706 173.757 548.368 177.7 544.847C181.644 541.326 178.897 532.241 177.278 528.579C174.672 522.594 161.715 505.2 161.715 505.2Z"
      fill="url(#paint35_linear)"
    />
    <path
      d="M178.404 486.187C178.404 486.187 157.983 502.383 151.646 508.721C145.308 515.059 157.983 542.523 172.771 539.002L233.33 508.017L178.404 486.187Z"
      fill="url(#paint36_linear)"
    />
    <path
      d="M227.344 238.375C227.344 238.375 214.81 344.57 218.683 354.006C222.556 363.442 233.753 398.301 237.696 415.554C241.146 430.835 244.386 428.934 244.386 428.934C244.386 428.934 239.386 431.188 246.076 435.624C207.698 450.624 200.586 460.06 168.827 488.44C190.516 528.509 233.4 529.073 233.4 529.073C233.4 529.073 267.764 508.017 281.144 499.637C294.523 491.257 309.17 483.862 311.353 471.046C319.733 436.54 302.199 359.076 287.2 317.317C287.763 298.937 282.482 254.783 273.116 234.29C273.116 234.22 252.695 229.009 227.344 238.375Z"
      fill="url(#paint37_linear)"
    />
    <path
      d="M241.076 512.665C241.076 512.665 239.808 512.454 237.696 512.102C236.639 511.891 235.372 511.609 233.964 511.327C233.259 511.187 232.555 510.975 231.781 510.764C231.006 510.553 230.231 510.342 229.386 510.13C228.541 509.919 227.767 509.567 226.851 509.356C226.006 509.074 225.091 508.792 224.246 508.44C222.485 507.736 220.725 507.102 219.035 506.328C215.514 504.849 212.204 502.947 209.388 501.257C206.571 499.426 203.473 497.314 200.515 494.919C199.036 493.722 197.628 492.455 196.22 491.187C195.515 490.553 194.882 489.919 194.248 489.286C193.614 488.652 192.98 488.018 192.417 487.384C191.854 486.75 191.29 486.117 190.797 485.553C190.234 484.99 189.741 484.427 189.319 483.863C188.403 482.736 187.629 481.751 186.924 480.906C185.657 479.145 184.882 478.159 184.882 478.159C184.46 477.596 185.164 477.173 185.586 477.666C185.586 477.666 186.361 478.652 187.699 480.342C188.333 481.187 189.178 482.103 190.164 483.159C190.586 483.722 191.149 484.215 191.713 484.779C192.276 485.342 192.839 485.905 193.403 486.539C198.121 491.328 204.74 496.469 210.303 499.919C215.866 503.37 223.401 507.032 229.809 509.074C230.583 509.285 231.358 509.567 232.133 509.778C232.907 509.989 233.611 510.271 234.316 510.412C235.724 510.764 236.921 511.116 237.977 511.327C240.019 511.75 241.287 511.961 241.287 511.961C241.85 512.032 241.78 512.736 241.076 512.665Z"
      fill="#172845"
    />
    <path
      d="M287.199 317.246C287.763 298.866 282.481 254.712 273.116 234.22C273.116 234.22 262.342 231.474 246.779 233.516C246.779 234.502 246.779 235.488 246.779 236.473C247.061 258.022 248.751 279.501 251.99 300.768C253.61 311.542 255.934 322.246 257.694 333.021C259.455 344.006 260.933 354.992 263.187 365.907C267.623 387.597 274.101 408.864 277.834 430.695C279.665 441.187 280.51 451.751 281.284 462.314C282.059 473.088 282.552 483.933 282.481 494.707C282.481 496.045 282.481 497.313 282.411 498.651C295.368 490.623 309.099 483.299 311.282 470.905C319.732 436.469 302.198 359.006 287.199 317.246Z"
      fill="url(#paint38_linear)"
    />
    <path
      d="M266.215 440.272C266.215 440.272 264.666 440.342 262.694 440.342C260.652 440.272 258.187 440.061 256.286 439.638C254.385 439.286 251.92 438.511 250.089 437.807C248.188 437.103 246.85 436.399 246.85 436.399C246.639 436.328 246.991 435.554 247.132 435.624C247.132 435.624 248.54 436.117 250.441 436.61C252.343 437.103 254.737 437.666 256.568 438.018C258.469 438.371 260.863 438.793 262.765 439.075C264.736 439.286 266.215 439.427 266.215 439.427C266.426 439.497 266.426 440.201 266.215 440.272Z"
      fill="#172845"
    />
    <path
      d="M254.878 428.229C254.878 428.229 254.103 428.511 252.977 428.793C251.92 429.074 250.582 429.356 249.456 429.497C248.399 429.638 246.991 429.638 245.935 429.638C244.808 429.638 243.963 429.567 243.963 429.567C243.822 429.567 243.822 428.722 243.963 428.722C243.963 428.722 244.808 428.582 245.864 428.441C246.921 428.3 248.259 428.089 249.315 427.948C250.371 427.807 251.709 427.736 252.765 427.666C253.822 427.596 254.667 427.525 254.667 427.525C254.807 427.455 254.948 428.159 254.878 428.229Z"
      fill="#172845"
    />
    <path
      d="M257.624 426.54C257.624 426.54 258.047 426.61 258.751 426.469C259.103 426.399 259.455 426.258 259.878 426.117C260.3 425.906 260.793 425.624 261.075 425.272C261.427 424.92 261.568 424.427 261.497 424.075C261.427 423.864 261.357 423.723 261.216 423.652C261.075 423.582 260.864 423.511 260.652 423.441C260.371 423.441 260.16 423.441 259.807 423.511C259.455 423.582 259.174 423.582 258.822 423.652C258.188 423.723 257.624 423.864 257.061 424.004C254.808 424.497 252.061 425.554 250.019 426.821C248.963 427.455 248.118 428.018 247.484 428.441C246.851 428.863 246.498 429.145 246.498 429.145C246.287 429.286 245.794 428.723 245.935 428.511C245.935 428.511 246.287 428.159 246.851 427.666C247.414 427.173 248.259 426.469 249.315 425.765C251.357 424.356 254.244 422.948 256.639 422.455C257.272 422.314 257.906 422.173 258.54 422.103C258.892 422.033 259.244 422.033 259.596 421.962C259.878 421.962 260.371 421.892 260.793 421.962C261.216 422.033 261.709 422.244 262.061 422.526C262.483 422.878 262.695 423.371 262.765 423.864C262.835 424.286 262.765 424.779 262.554 425.202C262.413 425.624 262.131 425.906 261.85 426.187C260.723 427.173 259.596 427.455 258.751 427.525C258.329 427.525 257.977 427.525 257.765 427.525C257.554 427.525 257.413 427.455 257.413 427.455C257.272 427.244 257.413 426.54 257.624 426.54Z"
      fill="#172845"
    />
    <path
      d="M217.557 514.285C221.289 507.525 234.598 512.665 245.161 518.651C255.653 524.637 259.455 534.425 257.977 541.186C256.498 547.946 240.654 564.495 240.654 564.495C240.654 564.495 226.641 584.706 210.867 568.861C195.093 553.087 205.304 536.679 209.811 527.665C214.317 518.651 215.726 517.595 217.557 514.285Z"
      fill="url(#paint39_linear)"
    />
    <path
      d="M241.147 483.863C227.838 506.257 221.782 516.75 221.782 516.75C216.359 525.411 238.612 548.087 251.639 539.707C252.625 539.073 288.96 501.398 288.96 501.398C288.96 501.398 271.919 454.849 241.147 483.863Z"
      fill="url(#paint40_linear)"
    />
    <path
      d="M247.062 561.115C245.231 558.298 242.485 559.214 238.33 560.622C234.176 562.03 225.092 557.946 220.374 562.735C218.332 565.129 218.332 565.129 218.332 565.129C218.332 565.129 220.374 556.96 215.585 551.397C210.797 545.834 206.783 540.059 207.206 532.735C210.515 526.679 211.642 524.214 211.642 524.214C211.642 524.214 206.149 523.581 201.995 527.102C197.84 530.623 194.53 545.552 194.53 545.552C194.53 545.552 180.869 548.58 178.616 568.087C178.052 572.312 178.686 577.664 180.095 581.678C181.714 586.255 185.798 592.523 191.291 597.03C196.15 600.973 199.741 606.184 201.361 612.17C204.318 623.438 206.431 636.325 218.261 643.085C234.528 652.381 252.273 649.845 256.85 641.536C261.427 633.296 253.541 620.269 248.541 606.959C242.203 590.058 243.752 573.157 245.583 568.791C247.485 564.425 248.259 563.016 247.062 561.115Z"
      fill="url(#paint41_linear)"
    />
    <path
      d="M246.498 642.873C247.414 642.592 248.259 642.24 249.104 641.817C253.047 639.775 255.934 636.324 257.906 632.31C256.709 624.916 251.92 615.972 248.54 606.888C243.611 593.579 243.47 580.339 244.526 573.156C244.315 573.297 244.104 573.509 243.893 573.649C241.076 575.973 238.541 578.649 236.569 581.677C234.668 584.565 233.26 587.663 232.344 590.973C231.429 594.283 230.936 597.663 230.865 601.114C230.795 604.635 231.147 608.226 231.781 611.677C232.485 615.268 233.471 618.789 234.809 622.24C236.147 625.761 237.766 629.212 239.597 632.451C241.428 635.761 243.47 638.859 245.794 641.887C246.005 642.24 246.287 642.521 246.498 642.873Z"
      fill="url(#paint42_linear)"
    />
    <path
      d="M247.695 647.873C232.626 648.366 216.429 642.521 210.796 625.62C205.163 608.719 205.163 602.874 195.727 594.706C185.164 585.621 181.08 577.03 180.094 561.115"
      stroke="#172845"
      strokeMiterlimit="10"
    />
    <path
      d="M244.315 588.015C244.315 588.015 243.963 588.156 243.329 588.438C242.696 588.72 241.851 589.072 240.865 589.353C238.893 590.058 236.288 590.551 234.245 590.691C232.203 590.832 229.527 590.691 227.485 590.269C226.429 590.058 225.584 589.846 224.95 589.706C224.317 589.494 223.964 589.424 223.964 589.424C223.753 589.353 223.964 588.579 224.176 588.579C224.176 588.579 224.528 588.649 225.162 588.72C225.795 588.79 226.711 588.931 227.697 589.001C229.739 589.213 232.203 589.142 234.175 589.001C236.147 588.861 238.611 588.579 240.583 588.086C241.569 587.875 242.414 587.593 243.048 587.452C243.681 587.241 244.034 587.17 244.034 587.17C244.174 587.241 244.456 587.875 244.315 588.015Z"
      fill="#172845"
    />
    <path
      d="M245.864 598.72C245.864 598.72 245.512 598.931 244.879 599.213C244.245 599.494 243.4 599.917 242.414 600.339C240.442 601.184 237.766 601.888 235.654 602.17C233.541 602.452 230.795 602.452 228.682 602.17C227.626 602.029 226.64 601.888 226.006 601.748C225.373 601.607 224.95 601.536 224.95 601.536C224.739 601.466 224.88 600.691 225.091 600.691C225.091 600.691 225.514 600.762 226.147 600.832C226.781 600.903 227.697 600.973 228.753 600.973C230.865 601.043 233.4 600.832 235.442 600.55C237.414 600.269 240.02 599.776 241.991 599.142C242.977 598.86 243.822 598.508 244.456 598.297C245.09 598.086 245.442 597.875 245.442 597.875C245.724 597.945 246.076 598.579 245.864 598.72Z"
      fill="#172845"
    />
    <path
      d="M249.526 608.367C249.526 608.367 249.244 608.578 248.822 608.93C248.329 609.282 247.695 609.705 246.92 610.127C245.371 611.043 243.329 611.958 241.569 612.451C239.879 612.944 237.625 613.226 235.794 613.226C234.879 613.226 234.104 613.156 233.541 613.156C232.978 613.085 232.626 613.085 232.626 613.085C232.414 613.085 232.485 612.24 232.696 612.24C232.696 612.24 233.048 612.24 233.541 612.24C234.104 612.24 234.809 612.17 235.724 612.029C237.414 611.818 239.527 611.395 241.146 610.902C242.766 610.48 244.808 609.775 246.357 609.071C247.132 608.719 247.836 608.367 248.329 608.156C248.822 607.874 249.103 607.733 249.103 607.733C249.244 607.663 249.667 608.226 249.526 608.367Z"
      fill="#172845"
    />
    <path
      d="M222.909 235.066C207.769 253.164 186.15 291.332 187.84 323.092C189.53 354.781 195.939 382.668 287.764 412.738C259.948 433.301 229.598 495.06 229.598 495.06C229.598 495.06 255.512 523.158 303.748 517.736C331.563 479.92 371.702 438.23 377.265 422.667C382.828 407.104 377.758 390.907 346.633 363.654C315.508 336.402 274.666 302.529 262.413 295.839C268.539 268.586 263.54 243.587 263.258 234.361C262.976 225.136 222.909 235.066 222.909 235.066Z"
      fill="url(#paint43_linear)"
    />
    <path
      d="M346.632 363.584C315.437 336.331 274.665 302.458 262.412 295.768C268.539 268.516 263.539 243.516 263.257 234.291C263.116 230.559 256.427 229.925 248.47 230.629L244.526 231.967C243.963 267.178 235.301 284.079 234.316 287.459C233.33 290.839 224.387 297.459 230.583 296.332C236.78 295.205 236.78 295.205 236.78 295.205C236.78 295.205 229.879 304.994 227.626 309.994C225.372 314.993 221.922 332.176 256.638 345.979C291.354 359.781 327.338 381.4 345.858 402.316C359.519 417.738 374.94 421.188 379.236 413.442C379.869 400.273 371.701 385.555 346.632 363.584Z"
      fill="url(#paint44_linear)"
    />
    <path
      d="M320.93 495.271C320.93 495.271 319.381 495.482 316.776 495.834C314.17 496.116 310.579 496.468 306.424 496.538C298.115 496.89 287.764 495.482 279.947 493.229C278.961 492.947 277.976 492.595 276.919 492.313C276.426 492.172 275.863 491.961 275.37 491.82C274.877 491.609 274.314 491.468 273.821 491.257C272.765 490.834 271.708 490.482 270.652 490.06C269.596 489.637 268.54 489.144 267.483 488.722C266.92 488.51 266.427 488.299 265.864 488.017C265.371 487.736 264.807 487.525 264.314 487.243C263.258 486.75 262.272 486.257 261.216 485.764C260.23 485.271 259.244 484.708 258.258 484.215C257.766 483.933 257.273 483.722 256.85 483.44C256.357 483.158 255.935 482.947 255.512 482.665C251.921 480.623 248.822 478.722 246.64 477.313C244.457 475.905 243.189 475.06 243.189 475.06C242.485 474.567 242.907 473.933 243.612 474.356C243.612 474.356 244.949 475.201 247.132 476.539C249.386 477.877 252.484 479.708 256.076 481.609C256.498 481.82 256.991 482.102 257.484 482.384C257.977 482.595 258.47 482.877 258.892 483.088C259.878 483.581 260.864 484.074 261.85 484.567C262.836 485.06 263.892 485.482 264.948 485.975C265.441 486.186 266.004 486.468 266.497 486.679C266.99 486.891 267.554 487.102 268.117 487.313C269.173 487.736 270.23 488.158 271.286 488.581C272.342 488.933 273.398 489.355 274.455 489.708C274.948 489.919 275.511 490.06 276.004 490.271C276.497 490.412 277.06 490.553 277.553 490.764C278.539 491.046 279.525 491.398 280.511 491.679C288.186 493.862 298.326 495.482 306.495 495.341C310.579 495.341 314.17 495.06 316.776 494.848C319.311 494.567 320.86 494.426 320.86 494.426C321.705 494.355 321.775 495.06 320.93 495.271Z"
      fill="#172845"
    />
    <path
      d="M168.545 196.685C167.7 184.08 170.517 172.813 178.756 168.587C186.995 164.362 215.444 160.419 215.444 160.419L230.513 150.982C230.513 150.982 187.629 156.475 176.362 161.052C165.095 165.559 167.207 180.911 168.545 196.685Z"
      fill="#172845"
    />
    <path
      d="M214.247 242.318C231.499 236.192 263.751 233.938 274.313 235.065C281.989 235.91 285.228 216.967 285.298 204.291C285.369 178.376 275.862 159.433 269.384 154.151C260.511 146.898 238.823 142.673 198.755 163.236C192.628 176.616 199.248 212.812 214.247 242.318Z"
      fill="url(#paint45_linear)"
    />
    <path
      d="M272.201 233.375C274.031 233.445 275.862 233.516 277.764 233.586C281.566 228.938 285.298 214.573 285.298 204.291C285.369 178.376 275.862 159.433 269.384 154.151C262.06 148.166 248.751 143.518 221.781 153.729C223.683 154.715 242.062 165.278 240.09 165.982C233.4 168.236 230.583 167.109 226.288 170.912C227.133 187.179 231.006 180.911 232.626 187.249C234.245 193.587 233.682 206.122 234.034 206.756C237.977 214.009 241.146 226.192 242.555 229.995C244.033 233.938 264.666 233.164 272.201 233.375Z"
      fill="url(#paint46_linear)"
    />
    <path
      d="M216.853 169.785C215.796 162.673 210.797 157.954 204.037 160.63C198.192 162.954 185.728 168.658 176.081 174.01C161.222 182.179 57.0039 235.206 57.0039 235.206C63.0599 232.108 110.662 265.91 105.099 278.867C134.675 258.516 148.406 249.502 161.222 237.248C174.109 224.995 203.121 198.798 208.825 193.376C212.487 189.996 218.05 178.165 216.853 169.785Z"
      fill="url(#paint47_linear)"
    />
    <path
      d="M269.385 154.152C260.864 147.25 240.795 143.096 204.177 160.56H204.107C198.262 162.884 185.798 168.588 176.151 173.94C161.222 182.179 57.0039 235.206 57.0039 235.206C63.0599 232.108 110.662 265.91 105.099 278.867C134.675 258.516 148.406 249.502 161.223 237.248C170.518 228.375 188.333 212.179 199.6 201.897C202.558 214.995 207.558 229.291 214.247 242.319C231.5 236.192 263.751 233.939 274.314 235.065C281.989 235.91 285.229 216.967 285.299 204.291C285.299 178.376 275.863 159.433 269.385 154.152Z"
      fill="url(#paint48_linear)"
    />
    <path
      d="M212.909 137.391C215.233 140.912 230.161 135.841 249.526 123.166C268.891 110.49 278.327 98.4479 276.074 94.8564C274.384 92.2508 260.582 97.6733 241.147 110.279C221.711 122.884 210.585 133.799 212.909 137.391Z"
      fill="url(#paint49_linear)"
    />
    <path
      d="M283.961 187.672C285.37 168.518 278.257 151.264 264.455 145.983C250.653 140.701 253.611 144.222 254.315 144.222C255.019 144.222 275.722 154.152 283.961 187.672Z"
      fill="url(#paint50_linear)"
    />
    <path
      d="M272.13 125.631C272.412 121.335 270.792 117.744 267.976 114.786C271.285 115.631 272.553 119.364 272.553 119.364C272.553 119.364 274.313 113.589 264.103 110.913C261.356 100.913 256.427 98.6598 253.118 97.674L245.724 115.913C245.724 115.913 246.005 125.49 250.723 128.73C242.484 150.701 253.61 148.588 258.61 154.504C258.61 154.504 265.089 153.307 270.44 150.138C281.285 143.729 283.045 132.532 272.13 125.631Z"
      fill="url(#paint51_linear)"
    />
    <path
      d="M225.302 153.87C230.091 151.616 235.654 154.222 246.921 159.222C254.948 162.813 259.103 161.687 253.962 155.067C253.399 143.025 252.554 132.532 252.554 132.532C252.554 132.532 256.99 128.73 258.892 126.899C260.793 125.068 262.624 104.716 254.667 92.1107C246.709 79.5053 219.669 121.969 219.669 121.969C219.669 121.969 234.316 135.49 225.302 153.87Z"
      fill="url(#paint52_linear)"
    />
    <path
      d="M254.596 92.1811C246.639 79.5758 219.669 121.969 219.669 121.969C219.669 121.969 223.472 125.49 226.077 131.406C227.345 131.546 228.542 131.758 229.809 131.899C238.471 132.955 247.414 133.589 256.005 131.969C259.807 130.701 263.046 129.786 264.173 127.533C265.37 125.138 262.553 104.787 254.596 92.1811Z"
      fill="url(#paint53_linear)"
    />
    <path
      d="M259.807 103.237C256.427 105.138 253.188 107.392 250.23 109.857C246.78 112.744 243.541 115.842 240.372 119.011C237.344 122.039 234.316 125.067 231.358 128.166C230.232 129.293 229.175 130.49 228.119 131.617C228.682 131.687 229.246 131.757 229.809 131.828C238.471 132.884 247.414 133.518 256.005 131.898C259.807 130.631 263.046 129.715 264.173 127.462C265.018 125.772 263.751 114.223 259.807 103.237Z"
      fill="url(#paint54_linear)"
    />
    <path
      d="M256.076 160.912C256.076 160.912 256.005 160.982 255.794 161.123C255.583 161.194 255.371 161.405 255.019 161.475C254.315 161.687 253.329 161.757 252.273 161.616C250.16 161.334 247.766 160.419 245.935 159.715C244.105 159.011 241.781 157.954 239.95 156.968C238.119 156.053 236.852 155.137 236.852 155.137C236.64 154.997 237.063 154.363 237.274 154.433C237.274 154.433 238.612 155.137 240.443 155.842C242.274 156.616 244.668 157.461 246.499 158.165C248.33 158.87 250.583 159.926 252.484 160.349C253.4 160.56 254.245 160.63 254.738 160.489C255.019 160.419 255.16 160.349 255.301 160.278C255.442 160.208 255.442 160.137 255.442 160.137C255.723 160.278 256.287 160.701 256.076 160.912Z"
      fill="#172845"
    />
    <path
      d="M242.978 139.364C246.78 132.11 243.47 116.97 234.316 108.237L221.148 121.829C221.148 121.829 217.064 130.561 219.81 135.561C211.853 138.518 210.585 141.124 209.529 144.152C208.402 147.18 209.036 148.73 209.036 148.73C209.036 148.73 211.43 143.448 215.303 140.772C212.839 145.208 212.487 150.56 215.726 154.363V149.786C215.726 149.786 216.712 153.307 217.557 154.222C226.148 154.152 226.288 164.504 235.865 166.124C248.259 168.236 253.118 148.096 242.978 139.364Z"
      fill="url(#paint55_linear)"
    />
    <path
      d="M220.796 154.715C220.726 154.715 220.655 154.645 220.585 154.645C220.655 154.645 220.726 154.645 220.796 154.715Z"
      fill="url(#paint56_linear)"
    />
    <path
      d="M222.346 155.489C222.275 155.489 222.275 155.419 222.205 155.419C222.275 155.419 222.346 155.489 222.346 155.489Z"
      fill="url(#paint57_linear)"
    />
    <path
      d="M225.162 157.954C226.57 159.503 227.838 161.334 229.528 162.884C227.838 161.334 226.5 159.574 225.162 157.954Z"
      fill="url(#paint58_linear)"
    />
    <path
      d="M221.571 155.067C221.5 155.067 221.43 154.997 221.359 154.997C221.43 154.997 221.5 154.997 221.571 155.067Z"
      fill="url(#paint59_linear)"
    />
    <path
      d="M225.091 157.955C225.021 157.814 224.88 157.744 224.81 157.603C224.88 157.744 225.021 157.814 225.091 157.955Z"
      fill="url(#paint60_linear)"
    />
    <path
      d="M223.119 156.053C223.049 155.983 222.979 155.912 222.908 155.912C222.979 155.912 223.049 155.983 223.119 156.053Z"
      fill="url(#paint61_linear)"
    />
    <path
      d="M242.978 139.363C245.584 134.434 244.88 125.772 241.218 118.026C240.443 117.321 240.162 117.181 239.176 117.462C237.908 117.814 236.148 119.082 234.951 121.054C237.838 131.828 231.007 137.18 231.007 137.18C231.007 137.18 232.064 140.419 234.317 147.673C235.232 143.377 233.965 139.997 233.965 139.997C233.965 139.997 237.275 144.222 236.852 149.011C236.5 153.306 235.021 158.87 229.388 162.743C231.007 164.222 233.049 165.489 235.866 165.982C248.26 168.236 253.119 148.095 242.978 139.363Z"
      fill="url(#paint62_linear)"
    />
    <path
      d="M224.246 157.039C224.316 157.109 224.387 157.249 224.527 157.32C224.457 157.249 224.387 157.179 224.246 157.039Z"
      fill="url(#paint63_linear)"
    />
    <path
      d="M223.754 156.546C223.684 156.476 223.613 156.476 223.613 156.405C223.613 156.476 223.684 156.476 223.754 156.546Z"
      fill="url(#paint64_linear)"
    />
    <path
      d="M241.147 110.35C258.822 98.801 271.568 93.3786 276.075 94.9278C271.075 90.9842 255.16 88.5195 249.527 88.8716C229.528 98.5897 220.515 111.336 216.571 119.153C212.628 126.969 212.698 136.124 212.698 136.124C213.332 131.265 223.684 121.758 241.147 110.35Z"
      fill="#172845"
    />
    <path
      d="M255.231 89.0119C247.344 78.3783 240.161 75.984 232.908 77.1812C225.655 78.3783 213.402 91.6879 212.769 102.04C212.346 109.011 217.134 120.631 217.134 120.631C217.134 120.631 249.386 99.3638 255.231 89.0119Z"
      fill="#172845"
    />
    <path
      d="M255.23 89.0119C247.343 78.3783 240.16 75.984 232.907 77.1812C225.725 78.3783 213.402 91.6879 212.768 102.04C212.416 107.392 215.233 115.631 216.5 119.011C212.557 126.828 212.627 135.983 212.627 135.983C213.331 131.194 223.612 121.617 241.076 110.209C258.751 98.6596 271.496 93.2372 276.003 94.7864C272.342 91.8992 262.342 89.7865 255.23 89.0119Z"
      fill="url(#paint65_linear)"
    />
    <path
      d="M197.347 235.629L200.586 255.77C227.134 223.094 236.429 193.729 231.077 174.504C226.218 156.898 213.825 154.082 203.262 154.504C197.488 154.363 187.629 157.039 182.841 159.082C182.841 159.082 218.191 154.152 219.529 179.574C220.796 201.968 212.768 219.714 197.347 235.629Z"
      fill="url(#paint66_linear)"
    />
    <path
      d="M326.845 214.714C326.845 214.714 326.422 214.151 325.648 213.165C324.944 212.179 323.958 210.841 322.972 209.221C320.93 205.982 318.747 201.757 317.338 198.306C315.93 194.856 314.663 190.208 313.958 186.475C313.606 184.574 313.395 182.954 313.254 181.757C313.113 180.56 313.043 179.856 313.043 179.856C313.043 179.433 313.818 179.363 313.888 179.785C313.888 179.785 314.029 180.49 314.17 181.687C314.381 182.884 314.733 184.504 315.156 186.335C316.001 189.997 317.479 194.433 318.817 197.813C320.155 201.193 322.127 205.489 323.958 208.729C324.873 210.348 325.789 211.757 326.422 212.743C327.056 213.728 327.479 214.292 327.479 214.292C327.69 214.644 327.127 214.996 326.845 214.714Z"
      fill="#172845"
    />
    <path
      d="M313.607 204.714C313.607 204.714 313.537 204.433 313.396 204.081C313.255 203.658 313.114 203.095 312.903 202.391C312.621 201.053 312.551 199.222 312.692 197.884C312.903 196.546 313.466 194.855 314.1 193.588C314.452 192.954 314.734 192.461 315.015 192.109C315.297 191.757 315.438 191.546 315.438 191.546C315.508 191.405 316.212 191.898 316.142 191.968C316.142 191.968 316.001 192.179 315.86 192.532C315.719 192.884 315.438 193.447 315.227 194.01C314.804 195.208 314.452 196.827 314.311 198.024C314.1 199.222 314.029 200.841 314.1 202.109C314.17 202.743 314.241 203.306 314.241 203.729C314.311 204.151 314.382 204.362 314.382 204.362C314.382 204.644 313.748 204.855 313.607 204.714Z"
      fill="#172845"
    />
    <path
      d="M137.351 194.151C137.632 194.362 137.844 194.503 138.125 194.714C140.66 196.404 143.547 197.742 146.505 198.517C149.181 199.221 151.927 199.503 154.673 199.573C160.518 199.644 166.292 198.517 171.785 196.827C177.7 194.996 183.474 192.461 188.967 189.714C194.53 186.968 199.882 183.869 205.233 180.771C208.261 179.01 217.697 171.405 210.937 165.842C207.628 163.095 203.191 161.546 198.755 163.306C192.91 165.63 185.798 168.729 176.151 174.01C171.01 176.827 155.518 184.785 137.351 194.151Z"
      fill="url(#paint67_linear)"
    />
    <path
      d="M144.463 307.951C172.912 276.684 200.727 253.375 212.768 269.642C229.809 292.67 213.754 328.444 187.981 346.965C162.208 365.485 136.224 370.063 125.661 356.471C115.169 342.81 132.632 320.979 144.463 307.951Z"
      fill="#172845"
    />
    <path
      d="M215.233 187.109C215.233 187.109 214.036 188.376 212.417 190.137C210.867 191.897 208.896 194.01 207.346 195.7C205.797 197.39 203.826 199.503 202.276 201.193C200.727 202.953 199.53 204.221 199.53 204.221C199.389 204.432 198.755 203.869 198.896 203.728C198.896 203.728 199.953 202.249 201.361 200.418C202.769 198.517 204.671 196.263 206.22 194.573C207.769 192.883 209.881 190.841 211.642 189.221C213.402 187.602 214.74 186.475 214.74 186.475C214.881 186.405 215.374 186.968 215.233 187.109Z"
      fill="#172845"
    />
    <path
      d="M266.85 295.698C266.85 295.698 266.99 294.571 267.202 292.599C267.413 290.698 267.695 287.952 267.976 284.924C268.469 278.797 268.821 271.121 268.892 265.135C269.033 259.149 268.962 251.474 268.61 245.347C268.469 242.248 268.258 239.572 268.117 237.601C267.976 235.699 267.835 234.502 267.835 234.502C267.765 233.868 268.61 233.798 268.681 234.432C268.681 234.432 268.821 235.629 269.033 237.53C269.244 239.432 269.526 242.178 269.807 245.277C270.3 251.474 270.582 259.149 270.511 265.206C270.441 271.262 269.878 278.938 269.173 285.064C268.821 288.163 268.469 290.839 268.188 292.74C267.906 294.642 267.695 295.839 267.695 295.839C267.483 296.473 266.779 296.332 266.85 295.698Z"
      fill="#172845"
    />
    <path
      d="M267.976 278.233C267.976 278.233 267.765 278.233 267.342 278.163C266.92 278.092 266.357 278.022 265.582 277.881C264.807 277.67 263.892 277.388 263.047 276.613C262.202 275.839 261.639 274.782 261.146 273.656C261.005 273.374 260.934 273.022 260.864 272.67C260.794 272.318 260.794 272.036 260.723 271.684C260.653 271.05 260.653 270.416 260.582 269.782C260.512 268.515 260.512 267.177 260.512 265.839C260.512 263.233 260.512 260.628 260.512 258.304C260.582 253.726 260.512 247.882 260.301 243.234C260.23 242.037 260.23 240.98 260.16 239.994C260.089 239.008 260.019 238.093 259.949 237.389C259.808 235.91 259.737 235.065 259.737 235.065C259.737 234.572 260.512 234.502 260.582 234.994C260.582 234.994 260.723 235.91 260.934 237.318C261.075 238.023 261.146 238.938 261.216 239.924C261.357 240.91 261.427 242.037 261.568 243.163C261.991 247.881 262.202 253.726 262.202 258.304C262.202 260.628 262.132 263.233 262.061 265.839C262.061 267.177 261.991 268.444 261.991 269.712C261.991 270.98 261.991 272.247 262.272 273.163C262.624 274.219 263.117 275.134 263.751 275.768C264.385 276.402 265.159 276.684 265.793 276.895C266.497 277.106 267.061 277.177 267.413 277.247C267.835 277.317 268.047 277.317 268.047 277.317C268.54 277.529 268.469 278.233 267.976 278.233Z"
      fill="#172845"
    />
    <path
      d="M251.709 293.022C251.709 293.022 251.921 292.952 252.273 292.881C252.484 292.811 252.695 292.811 252.977 292.811C253.258 292.811 253.54 292.74 253.892 292.74C255.301 292.67 257.202 292.881 259.314 293.586C261.427 294.219 263.61 295.276 265.722 296.473C267.835 297.67 269.807 298.938 271.497 300.135C274.947 302.459 279.102 305.768 282.341 308.585C283.961 309.994 285.299 311.261 286.285 312.177C287.27 313.092 287.834 313.656 287.834 313.656C288.115 313.937 287.622 314.571 287.27 314.289C287.27 314.289 286.637 313.796 285.651 312.881C284.665 312.036 283.186 310.839 281.566 309.501C278.257 306.825 274.032 303.726 270.581 301.473C267.201 299.149 262.835 296.121 258.962 294.712C256.991 294.008 255.23 293.726 253.963 293.656C253.611 293.656 253.329 293.656 253.118 293.656C252.836 293.656 252.625 293.656 252.484 293.726C252.132 293.797 251.921 293.797 251.921 293.797C251.428 293.867 251.216 293.163 251.709 293.022Z"
      fill="#172845"
    />
    <path
      d="M251.569 286.684C251.569 286.684 252.625 286.684 253.963 287.177C255.301 287.6 256.78 288.445 257.766 289.36C258.751 290.205 259.808 291.543 260.582 292.599C261.357 293.656 261.85 294.501 261.85 294.501C261.92 294.642 261.287 295.135 261.216 294.994C261.216 294.994 260.512 294.29 259.667 293.374C258.751 292.459 257.625 291.332 256.709 290.557C255.794 289.783 254.597 288.867 253.47 288.304C252.343 287.74 251.428 287.529 251.428 287.529C251.358 287.388 251.428 286.684 251.569 286.684Z"
      fill="#172845"
    />
    <path
      d="M241.499 294.994C241.499 294.994 241.076 294.994 240.372 295.065C239.668 295.135 238.682 295.276 237.555 295.487C235.372 295.91 232.696 296.755 230.654 297.6C228.612 298.445 226.148 299.712 224.317 300.98C223.401 301.614 222.627 302.248 222.063 302.67C221.5 303.163 221.218 303.374 221.218 303.374C221.007 303.515 220.444 302.952 220.655 302.741C220.655 302.741 220.937 302.388 221.5 301.895C221.993 301.403 222.768 300.698 223.683 299.924C225.514 298.445 228.049 296.896 230.161 296.051C232.274 295.205 235.161 294.431 237.485 294.22C238.682 294.079 239.668 294.079 240.442 294.079C241.147 294.079 241.64 294.079 241.64 294.079C241.71 294.22 241.71 294.924 241.499 294.994Z"
      fill="#172845"
    />
    <path
      d="M321.212 422.949C321.212 422.949 318.606 422.526 315.156 421.822C311.705 421.118 307.48 419.991 304.241 419.005C301.002 418.019 296.918 416.54 293.608 415.343C290.369 414.146 287.834 413.23 287.834 413.23C287.482 413.09 287.763 412.315 288.115 412.456C288.115 412.456 290.65 413.16 294.03 414.216C297.34 415.273 301.495 416.54 304.734 417.526C307.973 418.512 312.058 419.85 315.438 420.695C318.818 421.611 321.353 422.244 321.353 422.244C321.705 422.315 321.564 423.019 321.212 422.949Z"
      fill="#172845"
    />
    <path
      d="M141.646 589.284C141.646 589.284 140.237 589.636 139.322 589.636C138.406 589.636 136.998 589.143 136.998 589.143C136.928 589.143 136.998 588.298 136.998 588.298C136.998 588.298 138.406 587.946 139.322 587.946C140.237 587.946 141.646 588.439 141.646 588.439C141.716 588.509 141.646 589.213 141.646 589.284Z"
      fill="#172845"
    />
    <path
      d="M143.336 581.748C143.336 581.748 142.21 582.03 141.505 581.889C140.801 581.819 139.815 581.256 139.815 581.256C139.815 581.256 139.886 580.411 139.956 580.411C139.956 580.411 141.083 580.129 141.787 580.27C142.491 580.34 143.477 580.903 143.477 580.903C143.477 580.974 143.407 581.678 143.336 581.748Z"
      fill="#172845"
    />
    <path
      d="M147.491 575.199C147.491 575.199 146.434 575.199 145.871 574.988C145.308 574.777 144.533 574.002 144.533 574.002L144.815 573.227C144.815 573.227 145.871 573.227 146.434 573.439C146.998 573.65 147.772 574.424 147.772 574.424C147.772 574.565 147.491 575.199 147.491 575.199Z"
      fill="#172845"
    />
    <path
      d="M413.671 74.3645C418.649 74.3645 422.684 70.3288 422.684 65.3506C422.684 60.3723 418.649 56.3367 413.671 56.3367C408.693 56.3367 404.657 60.3723 404.657 65.3506C404.657 70.3288 408.693 74.3645 413.671 74.3645Z"
      fill="url(#paint68_radial)"
    />
    <path
      d="M414.093 63.2381L414.727 64.3649L415.995 64.6465C416.347 64.717 416.488 65.2099 416.276 65.4916L415.431 66.4071L415.572 67.7451C415.643 68.0972 415.22 68.3788 414.868 68.238L413.671 67.6746L412.474 68.238C412.122 68.3788 411.699 68.0972 411.77 67.7451L411.911 66.4071L411.065 65.4916C410.784 65.2099 410.925 64.7874 411.347 64.6465L412.615 64.3649L413.248 63.2381C413.389 62.9564 413.882 62.9564 414.093 63.2381Z"
      fill="#E7AD80"
    />
    <path
      d="M414.093 63.2381L414.727 64.3649L415.995 64.6465C416.347 64.717 416.488 65.2099 416.276 65.4916L415.431 66.4071L415.572 67.7451C415.643 68.0972 415.22 68.3788 414.868 68.238L413.671 67.6746L412.474 68.238C412.122 68.3788 411.699 68.0972 411.77 67.7451L411.911 66.4071L411.065 65.4916C410.784 65.2099 410.925 64.7874 411.347 64.6465L412.615 64.3649L413.248 63.2381C413.389 62.9564 413.882 62.9564 414.093 63.2381Z"
      fill="url(#paint69_radial)"
    />
    <path
      d="M426.487 113.025C430.881 113.025 434.444 109.462 434.444 105.068C434.444 100.673 430.881 97.11 426.487 97.11C422.092 97.11 418.529 100.673 418.529 105.068C418.529 109.462 422.092 113.025 426.487 113.025Z"
      fill="url(#paint70_radial)"
    />
    <path
      d="M426.768 103.66L427.19 104.364L428.035 104.575C428.247 104.646 428.387 104.928 428.176 105.139L427.613 105.773L427.683 106.618C427.683 106.899 427.472 107.04 427.19 106.97L426.416 106.618L425.641 106.97C425.43 107.111 425.148 106.899 425.148 106.618L425.219 105.773L424.655 105.139C424.514 104.928 424.585 104.646 424.796 104.575L425.641 104.364L426.064 103.66C426.345 103.449 426.627 103.449 426.768 103.66Z"
      fill="#E7AD80"
    />
    <path
      d="M426.768 103.66L427.19 104.364L428.035 104.575C428.247 104.646 428.387 104.928 428.176 105.139L427.613 105.773L427.683 106.618C427.683 106.899 427.472 107.04 427.19 106.97L426.416 106.618L425.641 106.97C425.43 107.111 425.148 106.899 425.148 106.618L425.219 105.773L424.655 105.139C424.514 104.928 424.585 104.646 424.796 104.575L425.641 104.364L426.064 103.66C426.345 103.449 426.627 103.449 426.768 103.66Z"
      fill="url(#paint71_radial)"
    />
    <path
      d="M408.459 92.8851C412.854 92.8851 416.416 89.3224 416.416 84.9275C416.416 80.5327 412.854 76.97 408.459 76.97C404.065 76.97 400.502 80.5327 400.502 84.9275C400.502 89.3224 404.065 92.8851 408.459 92.8851Z"
      fill="url(#paint72_radial)"
    />
    <path
      d="M408.741 83.52L409.164 84.2242L410.009 84.4355C410.22 84.5059 410.361 84.7876 410.15 84.9989L409.587 85.6326L409.657 86.4777C409.657 86.7594 409.446 86.9002 409.164 86.8298L408.389 86.4777L407.615 86.8298C407.404 86.9706 407.122 86.7594 407.122 86.4777L407.192 85.6326L406.629 84.9989C406.488 84.7876 406.559 84.5059 406.77 84.4355L407.615 84.2242L408.037 83.52C408.319 83.3088 408.671 83.3088 408.741 83.52Z"
      fill="#E7AD80"
    />
    <path
      d="M408.741 83.52L409.164 84.2242L410.009 84.4355C410.22 84.5059 410.361 84.7876 410.15 84.9989L409.587 85.6326L409.657 86.4777C409.657 86.7594 409.446 86.9002 409.164 86.8298L408.389 86.4777L407.615 86.8298C407.404 86.9706 407.122 86.7594 407.122 86.4777L407.192 85.6326L406.629 84.9989C406.488 84.7876 406.559 84.5059 406.77 84.4355L407.615 84.2242L408.037 83.52C408.319 83.3088 408.671 83.3088 408.741 83.52Z"
      fill="url(#paint73_radial)"
    />
    <path
      d="M408.459 46.8299C410.676 46.8299 412.473 45.0328 412.473 42.8159C412.473 40.599 410.676 38.8019 408.459 38.8019C406.242 38.8019 404.445 40.599 404.445 42.8159C404.445 45.0328 406.242 46.8299 408.459 46.8299Z"
      fill="url(#paint74_radial)"
    />
    <path
      d="M408.6 42.112L408.812 42.4641L409.234 42.5345C409.375 42.5345 409.375 42.7458 409.305 42.8162L409.023 43.0979L409.093 43.5204C409.093 43.6613 408.953 43.7317 408.882 43.6613L408.46 43.45L408.037 43.6613C407.896 43.7317 407.826 43.5909 407.826 43.5204L407.896 43.0979L407.615 42.8162C407.544 42.7458 407.544 42.605 407.685 42.5345L408.108 42.4641L408.319 42.112C408.389 42.0416 408.53 42.0416 408.6 42.112Z"
      fill="#E7AD80"
    />
    <path
      d="M408.6 42.112L408.812 42.4641L409.234 42.5345C409.375 42.5345 409.375 42.7458 409.305 42.8162L409.023 43.0979L409.093 43.5204C409.093 43.6613 408.953 43.7317 408.882 43.6613L408.46 43.45L408.037 43.6613C407.896 43.7317 407.826 43.5909 407.826 43.5204L407.896 43.0979L407.615 42.8162C407.544 42.7458 407.544 42.605 407.685 42.5345L408.108 42.4641L408.319 42.112C408.389 42.0416 408.53 42.0416 408.6 42.112Z"
      fill="url(#paint75_radial)"
    />
    <path
      d="M440.852 77.0408C443.069 77.0408 444.866 75.2437 444.866 73.0268C444.866 70.8099 443.069 69.0128 440.852 69.0128C438.635 69.0128 436.838 70.8099 436.838 73.0268C436.838 75.2437 438.635 77.0408 440.852 77.0408Z"
      fill="url(#paint76_radial)"
    />
    <path
      d="M440.993 72.3226L441.204 72.6747L441.627 72.7451C441.768 72.7451 441.768 72.9563 441.697 73.0268L441.416 73.3085L441.486 73.731C441.486 73.8718 441.345 73.9422 441.275 73.8718L440.852 73.6606L440.43 73.8718C440.289 73.9422 440.218 73.8014 440.218 73.731L440.289 73.3085L440.007 73.0268C439.937 72.9563 439.937 72.8155 440.078 72.7451L440.5 72.6747L440.711 72.3226C440.782 72.1817 440.923 72.1817 440.993 72.3226Z"
      fill="#E7AD80"
    />
    <path
      d="M440.993 72.3226L441.204 72.6747L441.627 72.7451C441.768 72.7451 441.768 72.9563 441.697 73.0268L441.416 73.3085L441.486 73.731C441.486 73.8718 441.345 73.9422 441.275 73.8718L440.852 73.6606L440.43 73.8718C440.289 73.9422 440.218 73.8014 440.218 73.731L440.289 73.3085L440.007 73.0268C439.937 72.9563 439.937 72.8155 440.078 72.7451L440.5 72.6747L440.711 72.3226C440.782 72.1817 440.923 72.1817 440.993 72.3226Z"
      fill="url(#paint77_radial)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(355.322 97.6907) scale(97.6864 97.6907)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.1918" stopColor="#E7AD80" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="381.655"
        y1="71.9723"
        x2="426.702"
        y2="71.9723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(371.877 84.3352) scale(67.0528 67.0558)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.1918" stopColor="#E7AD80" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear"
        x1="359.568"
        y1="139.292"
        x2="320.721"
        y2="195.434"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="345.09"
        y1="171.273"
        x2="291.81"
        y2="90.4494"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="345.708"
        y1="127.245"
        x2="337.128"
        y2="77.8429"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="285.657"
        y1="112.582"
        x2="279.099"
        y2="121.494"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" />
        <stop offset="0.5934" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="285.656"
        y1="106.417"
        x2="280.109"
        y2="128.251"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" stopOpacity="0" />
        <stop offset="0.2052" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="282.833"
        y1="127.976"
        x2="275.252"
        y2="93.2163"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" />
        <stop offset="0.2052" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="281.987"
        y1="124.86"
        x2="278.035"
        y2="103.084"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" />
        <stop offset="0.2052" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="279.458"
        y1="119.53"
        x2="279.416"
        y2="108.138"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" />
        <stop offset="0.2052" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="285.405"
        y1="114.518"
        x2="277.667"
        y2="116.998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="287.979"
        y1="114.266"
        x2="280.999"
        y2="115.839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="283.083"
        y1="107.275"
        x2="275.345"
        y2="109.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="286.314"
        y1="106.879"
        x2="279.333"
        y2="108.452"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="283.604"
        y1="112.732"
        x2="278.637"
        y2="113.733"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="287.422"
        y1="111.794"
        x2="280.442"
        y2="113.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="1" stopColor="#AF6E41" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="276.286"
        y1="107.255"
        x2="293.101"
        y2="121.841"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00951032" stopColor="#F0E6DA" />
        <stop offset="0.2052" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="353.023"
        y1="146.335"
        x2="341.486"
        y2="89.2414"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="332.454"
        y1="153.873"
        x2="298.63"
        y2="90.6632"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="322.943"
        y1="149.172"
        x2="317.999"
        y2="75.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="343.969"
        y1="152.792"
        x2="305.778"
        y2="81.4234"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="339.002"
        y1="154.428"
        x2="300.811"
        y2="83.0594"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="345.273"
        y1="171.17"
        x2="292.885"
        y2="91.699"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.9993" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="285.673"
        y1="96.1607"
        x2="350.706"
        y2="246.867"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="267.21"
        y1="166.539"
        x2="369.756"
        y2="166.539"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="36.1668"
        y1="305.581"
        x2="83.7459"
        y2="254.297"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="-37.703"
        y1="310.317"
        x2="66.7368"
        y2="237.616"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint28_linear"
        x1="-30.7403"
        y1="320.319"
        x2="73.6996"
        y2="247.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint29_linear"
        x1="-25.8126"
        y1="327.398"
        x2="78.627"
        y2="254.697"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint30_linear"
        x1="-77.1404"
        y1="297.681"
        x2="163.469"
        y2="235.367"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint31_linear"
        x1="17.4051"
        y1="286.538"
        x2="88.4702"
        y2="239.303"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint32_linear"
        x1="267.229"
        y1="89.8874"
        x2="215.266"
        y2="206.233"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint33_linear"
        x1="127.215"
        y1="248.642"
        x2="172.36"
        y2="235.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint34_linear"
        x1="271.153"
        y1="603.566"
        x2="108.543"
        y2="559.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint35_linear"
        x1="284.703"
        y1="551.157"
        x2="143.918"
        y2="525.911"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint36_linear"
        x1="262.574"
        y1="542.8"
        x2="139.333"
        y2="501.293"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint37_linear"
        x1="486.142"
        y1="98.7765"
        x2="141.169"
        y2="509.356"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint38_linear"
        x1="352.555"
        y1="269.064"
        x2="123.755"
        y2="535.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint39_linear"
        x1="336.842"
        y1="563.71"
        x2="212.287"
        y2="540.896"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint40_linear"
        x1="292.676"
        y1="516.304"
        x2="221.225"
        y2="501.182"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint41_linear"
        x1="320.578"
        y1="587.943"
        x2="181.46"
        y2="585.927"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint42_linear"
        x1="253.476"
        y1="610.52"
        x2="209.011"
        y2="596.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint43_linear"
        x1="472.948"
        y1="250.852"
        x2="167.891"
        y2="433.524"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint44_linear"
        x1="355.713"
        y1="271.781"
        x2="126.912"
        y2="537.719"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint45_linear"
        x1="258.508"
        y1="156.75"
        x2="223.399"
        y2="231.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint46_linear"
        x1="268.669"
        y1="173.216"
        x2="187.662"
        y2="260.825"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint47_linear"
        x1="127.966"
        y1="141.111"
        x2="146.514"
        y2="267.962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint48_linear"
        x1="179.996"
        y1="225.861"
        x2="141.524"
        y2="149.452"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2434" stopColor="#172845" stopOpacity="0" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint49_linear"
        x1="272.524"
        y1="141.226"
        x2="245.265"
        y2="116.837"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint50_linear"
        x1="305.853"
        y1="168.203"
        x2="266.811"
        y2="165.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint51_linear"
        x1="274.311"
        y1="127.051"
        x2="230.001"
        y2="124.614"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint52_linear"
        x1="267.709"
        y1="137.981"
        x2="220.074"
        y2="111.616"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1538" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint53_linear"
        x1="266.648"
        y1="122.168"
        x2="226.462"
        y2="105.487"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0942909" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint54_linear"
        x1="260.856"
        y1="119.25"
        x2="219.338"
        y2="114.925"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0942909" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint55_linear"
        x1="257.629"
        y1="146.709"
        x2="201.508"
        y2="123.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint56_linear"
        x1="220.675"
        y1="154.666"
        x2="220.48"
        y2="154.583"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint57_linear"
        x1="222.314"
        y1="155.467"
        x2="222.182"
        y2="155.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint58_linear"
        x1="227.34"
        y1="160.435"
        x2="221.824"
        y2="158.087"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint59_linear"
        x1="221.466"
        y1="154.998"
        x2="221.25"
        y2="154.906"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint60_linear"
        x1="224.963"
        y1="157.791"
        x2="224.592"
        y2="157.633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint61_linear"
        x1="223.017"
        y1="155.961"
        x2="222.769"
        y2="155.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint62_linear"
        x1="234.983"
        y1="140.607"
        x2="200.467"
        y2="125.912"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint63_linear"
        x1="224.404"
        y1="157.2"
        x2="224.085"
        y2="157.064"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint64_linear"
        x1="223.658"
        y1="156.488"
        x2="223.457"
        y2="156.402"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint65_linear"
        x1="278.697"
        y1="69.7601"
        x2="231.186"
        y2="100.708"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint66_linear"
        x1="249.51"
        y1="178.943"
        x2="222.795"
        y2="192.122"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <linearGradient
        id="paint67_linear"
        x1="226.575"
        y1="143.234"
        x2="88.4718"
        y2="253.94"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#172845" />
      </linearGradient>
      <radialGradient
        id="paint68_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(413.839 65.5941) scale(7.32566 7.32597)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint69_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(413.596 65.322) scale(4.70364 4.70383)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint70_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(426.66 105.274) scale(6.44957 6.44986)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint71_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(426.458 105.04) scale(3.06196 3.06213)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint72_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(408.652 85.1358) scale(6.44961 6.44986)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint73_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(408.45 84.902) scale(3.06202)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint74_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(408.569 42.9405) scale(3.24366 3.24381)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint75_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(408.467 42.8229) scale(1.53986 1.53997)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint76_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(440.925 73.1167) scale(3.24369 3.24381)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint77_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(440.824 72.9987) scale(1.53986 1.53998)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default ComplokHopping;
