import Header from 'components/header/Header';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import SettingsView from 'screens/SettingsScreen/SettingsView';
import CourseView from './Course/CourseView';
import CourseDirectory from './CourseDirectory';
import LearnerContext from './LearnerContext';

const LearnerRoutes = [
  {
    name: 'Courses',
    path: '/courses',
  },
  {
    name: 'Settings',
    path: '/settings',
  },
];

const LearnerRouter = (
  <Route
    element={
      <LearnerContext>
        <Header links={LearnerRoutes} />
        <Outlet />
      </LearnerContext>
    }
  >
    <Route path="/courses" element={<CourseDirectory />} />

    <Route path="/courses/:courseIdentifier/*" element={<CourseView />}></Route>
    <Route path="/settings" element={<SettingsView />} />
    <Route path="*" element={<Navigate to="/courses" />} />
  </Route>
);

export default LearnerRouter;
