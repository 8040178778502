import { Modal, Select } from 'antd';
import apiClient from 'api/apiClient';
import { isString } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailedUserGroup, UserGroup } from 'types/GroupTypes';
import { Organization } from 'types/OrganizationTypes';

const { Option } = Select;

interface CoursesToAssign {
  course?: string;
  count: number;
}
interface AssignCourseToGroup {
  group?: UserGroup | DetailedUserGroup;
  organization?: Organization;

  showModal?: unknown;
  onSuccess?: () => void;
  onCancel?: () => void;
}
const AssignCourseToGroupModal: FC<AssignCourseToGroup> = ({ group, organization, showModal, onSuccess, onCancel }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const [coursesToAdd, setCoursesToAdd] = useState<string[]>([]);

  const groupCourses = group?.assigned_courses?.map((course) => (isString(course) ? course : course._id)) ?? [];
  const courses = (organization?.courses ?? []).filter((course) => !groupCourses.includes(course?._id));

  const submitAddUsersToGroup = async () => {
    setLoading(true);
    if (coursesToAdd.length > 0) {
      if (group?._id && organization?._id) {
        const coursesAssigned = await apiClient
          .put(`/groups/${group._id}/add_courses/`, {
            courses: coursesToAdd,
            organization: organization._id,
            group_id: group._id
          })
          .catch((err) => null);
        if (coursesAssigned) {
          if (onSuccess) onSuccess();
        }
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      confirmLoading={loading}
      width={'700px'}
      title={t('Assign new courses to group')}
      visible={!!showModal}
      onOk={submitAddUsersToGroup}
      okText={t('Assign courses')}
      okButtonProps={{ htmlType: 'submit', disabled: coursesToAdd.length === 0 }}
      style={{ width: 700 }}
      onCancel={() => onCancel && onCancel()}
      destroyOnClose
    >
      <Select
        mode={'multiple'}
        style={{ width: '100%' }}
        onChange={(checkedCourses) => setCoursesToAdd(checkedCourses ?? [])}
        notFoundContent={t('No new courses available')}
        defaultValue={[]}
      >
        {courses.map((course) => (
          <Option value={course._id} key={course._id}>
            {course.title}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default AssignCourseToGroupModal;
