/* eslint-disable operator-linebreak */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
import { CheckCircleTwoTone, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Checkbox, Col, Collapse, Row, Skeleton, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Chevron from 'components/icons/Chevron';
import { isArray } from 'lodash';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ICourseModule } from 'types/Course/course.module.types';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { Course } from 'types/Course/course.types';
import { CourseContentType, COURSE_CONTENT } from 'types/Course/CourseContent/course.content.types';
import { ICourseCompletionProgress } from 'types/Course/CourseTypes';

interface ICourseNavigation {
  course?: Course;
  courseProgress?: ICourseCompletionProgress;
  adminAccess?: boolean;

  showNavigation?: boolean;
  handleToggleNavigation?: Function;

  hideClose?: boolean;

  styles?: CSSProperties;
  stylesClosed?: CSSProperties;
  stylesOpen?: CSSProperties;

  hasExamUnlocked?: boolean;

  hideTitle?: boolean;
}

const CourseNavigation: FC<ICourseNavigation> = ({
  course,
  courseProgress,
  styles = {},
  stylesClosed = {},
  stylesOpen = {},

  adminAccess = false,
  hideClose = false,

  showNavigation,
  handleToggleNavigation,

  hasExamUnlocked = false,

  hideTitle
}) => {
  const { t } = useTranslation();
  const { moduleIdentifier, subjectIdentifier } = useParams();

  const [showNavigationDefault, setShowNavigationDefault] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    if (moduleIdentifier) setActiveKey((val) => [...val, moduleIdentifier]);
  }, [moduleIdentifier]);

  const completedSubjects = courseProgress?.completed_subjects ?? [];
  const unlockedSubjects = courseProgress?.unlocked_subjects ?? [];

  const setShowNavigation = (show: boolean) => {
    if (handleToggleNavigation) handleToggleNavigation(show);
    setShowNavigationDefault(show);
  };

  const iconBySubjectId = (subjectId: string) => {
    if (adminAccess)
      return (
        <Tooltip placement={'left'} title={t('Unlocked')}>
          {completedIcon}
        </Tooltip>
      );
    if (!courseProgress?.completed_subjects)
      return (
        <Tooltip placement={'left'} title={t("Hasn't been unlocked yet")}>
          {lockedIcon}
        </Tooltip>
      );
    if (subjectId === 'certificate') {
      if (courseProgress?.has_completed_course) {
        return (
          <Tooltip placement={'left'} title={t('Completed')}>
            <Checkbox id="complok-completed-module-checkbox" checked={true} />
          </Tooltip>
        );
      }
      return lockedIcon;
    }
    if (courseProgress.completed_subjects.includes(subjectId))
      return (
        <Tooltip placement={'left'} title={t('Completed')}>
          <Checkbox id="complok-completed-module-checkbox" checked={true} />
        </Tooltip>
      );
    if (courseProgress.latest_unlocked_subject === subjectId)
      return (
        <Tooltip placement={'left'} title={t('Unlocked')}>
          <Checkbox id="complok-completed-module-checkbox" checked={false} />
        </Tooltip>
      );
    return lockedIcon;
  };

  const renderModuleTitle = (module: ICourseModule) => {
    const moduleHasBeenCompleted = module.ordered_subjects.every((x) => completedSubjects.includes(x._id));
    const moduleIsUnlocked = module.ordered_subjects.find((x) => unlockedSubjects.includes(x._id));

    return (
      <Row wrap={false}>
        <Col style={{ marginRight: 10 }}>
          {!moduleIsUnlocked && <LockOutlined />}
          {moduleIsUnlocked && <Checkbox id="complok-completed-module-checkbox" checked={moduleHasBeenCompleted} />}
        </Col>
        <Col style={{ color: '#595959', fontWeight: 600, fontSize: 14 }}>{module.title}</Col>
      </Row>
    );
  };

  if (!course) return <Skeleton paragraph={{ rows: 5 }} />;

  if (!showNavigation ?? showNavigationDefault)
    return (
      <div
        id="complok-coursecontent-navigation"
        className="menu-closed"
        style={{
          ...styles,
          ...stylesClosed
        }}
        onClick={() => setShowNavigation(true)}
      >
        <Chevron direction={'left'} />
      </div>
    );

  const orderedModules = [...course.ordered_modules];

  const createCertificateSubject: (ordered_module: ICourseModule) => ICourseSubject = (ordered_module) => ({
    _id: 'certificate',
    title: t('Certificate').toUpperCase(),
    course: ordered_module.course,
    module: ordered_module._id,
    content_type: COURSE_CONTENT.INFO,
    content: {
      _id: 'certificate',
      title: t('Certificate').toUpperCase(),
      course: ordered_module.course,
      module: ordered_module._id
    } as CourseContentType.Info
  });

  const allowSubjectLinkToBeClicked = (subject: ICourseSubject) => {
    if (adminAccess) return true;
    if (completedSubjects.includes(subject._id)) return true;
    if (courseProgress?.latest_unlocked_subject === subject._id) return true;
    if (subject._id === 'certificate' && courseProgress?.has_completed_course) return true;
    return false;
  };

  return (
    <div
      id="complok-coursecontent-navigation"
      className="menu-open"
      style={{
        ...styles,
        ...stylesOpen
      }}
    >
      <div
        id="complok-coursecontent-navigation-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {!hideTitle && <Title level={4}>{t('Course Content')}</Title>}
        {!hideClose && (
          <Chevron
            direction={'right'}
            onClick={() => setShowNavigation(false)}
            style={{ cursor: 'pointer', margin: '8px 11px' }}
          />
        )}
      </div>
      <div id="complok-coursecontent-navigation-modules">
        {course && (
          <Collapse
            ghost
            expandIconPosition={'right'}
            activeKey={activeKey && activeKey}
            onChange={(val) => isArray(val) && setActiveKey(val)}
            defaultActiveKey={moduleIdentifier}
          >
            {orderedModules.map((item, index, modules) => (
              <Collapse.Panel key={item._id} header={renderModuleTitle(item)}>
                {/* // TODO: Refactor this file - nav collapse iterator logic should be overlooked */}
                {item?.ordered_subjects?.length &&
                  (index === modules.length - 1
                    ? [...item.ordered_subjects, createCertificateSubject(item)]
                    : item.ordered_subjects
                  )?.map((subject: ICourseSubject) => (
                    <Link
                      to={`/courses/${course._id}/${item._id}/${subject._id}`}
                      onClick={() => subject._id === 'certificate' && setShowNavigation(false)}
                      style={{
                        display: 'block',
                        marginBottom: 10,
                        pointerEvents: allowSubjectLinkToBeClicked(subject) ? 'all' : 'none'
                      }}
                      key={subject._id}
                    >
                      <Row wrap={false}>
                        <Col style={{ marginRight: 10 }}>{iconBySubjectId(subject._id)}</Col>
                        <Col>
                          <Text strong={subject._id === subjectIdentifier}>{subject.title}</Text>
                        </Col>
                      </Row>
                    </Link>
                  ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </div>
    </div>
  );
};

const lockedIcon = <LockOutlined className="fillGray" />;
const unlockedIcon = <UnlockOutlined />;
const completedIcon = <CheckCircleTwoTone twoToneColor="#52c41a" />;

export default CourseNavigation;
