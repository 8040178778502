import { Col, Popover, Row, Typography } from 'antd';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router';

const { Title } = Typography;

const LANGUAGE_LABELS: { [key: string]: string } = {
  et: 'Eesti',
  en: 'English'
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PrivacyPolicy: FC = () => {
  const queryParams = useQuery();

  const lang = queryParams.get('lang') || 'en';
  const [language, setLanguage] = useState<string>(lang);

  const isEnglish = language === 'en';

  return (
    <Col style={{ width: '100%', height: '100%', overflow: 'auto', padding: 20 }}>
      <Row>
        <Col flex={1} />
        <Col style={{ marginRight: 20 }}>
          <Popover
            trigger={'hover'}
            placement={'bottomRight'}
            content={() => (
              <LanguagePopoverContent
                updateLanguage={(val) => {
                  setLanguage(val);
                }}
              />
            )}
          >
            <span className="login-view-header-langauge-container">
              <p className="login-view-header-langauge-prefix">{`${language === 'et' ? 'Keel' : 'Language'}`}</p>
              <p className="login-view-header-langauge-value">{language && LANGUAGE_LABELS[language]}</p>
            </span>
          </Popover>
        </Col>
      </Row>
      {isEnglish ? (
        <div>
          <Title level={4}>
            <span>Privacy Policy</span>
          </Title>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>1. Introduction</span>
            </b>
          </p>

          <p>
            <span>
              1.1. This Privacy Policy describes the data collected by Complok in connection with the Platform and how
              these data are used. The Privacy Policy also explains your rights regarding the administration of your
              data.{' '}
            </span>
          </p>

          <p>
            <span>
              1.2. The Privacy Policy does not set out a comprehensive list of all the legal bases which enable
              authorities or persons to request data from Complok and process these data, as these bases derive from
              various legislation and cannot be presented as an exhaustive list.
            </span>
          </p>

          <p>
            <span>1.3. Please take your time to read the Privacy Policy.</span>
          </p>

          <p>
            <b>
              <span> </span>
            </b>
          </p>

          <p>
            <b>
              <span>2. Definitions</span>
            </b>
          </p>

          <p>
            <span>
              2.1. The data subject means an identified or identifiable natural person whose personal data are being
              processed. You are a data subject only if you are identified or identifiable on the basis of the personal
              data which are being processed.
            </span>
          </p>

          <p>
            <span>
              2.2. Personal data are any data concerning an identified or identifiable natural person (data subject).
            </span>
          </p>

          <p>
            <span>
              2.3. Processing of personal data means any operation which is being performed on personal data (e.g.,
              collection, alteration, viewing, erasure).
            </span>
          </p>

          <p>
            <span>
              2.4. Complok means the company Complok Learning OÜ (registry code 16171413), with its address at Angerja
              tn 9-31, 10416 Tallinn, Estonia, being which is the owner and administrator of the Platform.
            </span>
          </p>

          <p>
            <span>2.5. The Platform is the website https://www.elearning.complok.eu/</span>
          </p>

          <p>
            <span>
              including all its subdomains and aliases, with all of its components, contents and software required for
              its functioning.
            </span>
          </p>

          <p>
            <span>2.6. The User is every person who uses the Platform, including only visits.</span>
          </p>

          <p>
            <span>
              2.7. The Employee is the User who has created a user account on the Platform to undergo the online
              trainings assigned to them by the Employer, or to administer the online trainings of any other Employees
              of the Employer.
            </span>
          </p>

          <p>
            <span>
              2.8. The Employer means the person that under a contract entered into with Complok specifies the online
              trainings to be passed on the Platform by any natural persons connected with the Employer in any way, and
              pays for them.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>3. Complok as a personal data processor </span>
            </b>
          </p>

          <p>
            <span>
              3.1. The Privacy Policy sets out information regarding the processing of personal data by Complok. Complok
              processes personal Data on the basis of Regulation (EU) 2016/679 of the European Parliament and of the
              Council on the protection of natural persons with regard to the processing of personal data and on the
              free movement of such data, (GDPR), Personal Data Protection Act and other legislation regulating data
              protection.
            </span>
          </p>

          <p>
            <span>
              3.2. Complok can be contacted with regard to any issues concerning data protection by e-mail
              mariliis.kurg@complok.eu or by posting a letter to the address Angerja tn 9-31, 10416 Tallinn, Estonia.
            </span>
          </p>

          <p>
            <span>
              3.3. With regard to the User, the processor means the providers of hosting, development and maintenance
              services, and with regard to the Employee, also the Employer.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>
                4. Legal bases for and purposes of processing of personal data, categories of data and retention of data{' '}
              </span>
            </b>
          </p>

          <p>
            <span>
              4.1. Complok processes all personal data which have become known to it or disclosed to it while
              communicating with the data subject. Personal data are processed and retained only during a specified
              term, and are thereafter erased or destroyed. The staff of Complok has the right to process personal data
              only to the extent needed for performing the duties assigned to them and for achieving the purpose of
              processing of personal data
            </span>
          </p>

          <p>
            <span>4.2. The processing of personal data takes place on the following legal bases:</span>
          </p>

          <p>
            <span>4.2.1. You have provided your consent for the processing of your personal data; </span>
          </p>

          <p>
            <span>4.2.2. Complok as an enterprise has a legitimate interest in the data processing.</span>
          </p>

          <p>
            <span>
              4.3. The processing of personal data may take place also on other legal bases, e.g., upon issue of data to
              law enforcement authorities to enable them to perform their duties (e.g., the police or court) or to
              enable to verify the correctness of the taxes paid by Complok (e.g., the obligations set forth in the
              Accounting Act and tax laws).{' '}
            </span>
          </p>

          <p>
            <span>4.4. Complok processes personal data for the following purposes:</span>
          </p>

          <p>
            <span>4.4.1. Providing online trainings, including:</span>
          </p>

          <p>
            <span>
              4.4.1.1. Collection of data necessary for identification of the User for the use of the Platform and
              performance and guarantee of the contracts for online trainings, and for issue of certificates;
            </span>
          </p>

          <p>
            <span>4.4.1.2. Recording the User’s progress in undergoing an online training; </span>
          </p>

          <p>
            <span>
              4.4.1.3. Making direct marketing offers and display of adapted contents or advertisements to the User on
              the Platform;
            </span>
          </p>

          <p>
            <span>
              4.4.2. Creation of a client base and administration thereof to improve the quality and availability of the
              services of Complok;
            </span>
          </p>

          <p>
            <span>
              4.4.3. Analysis and enhancement of the Platform and online trainings, including carrying out client
              satisfaction surveys;
            </span>
          </p>

          <p>
            <span>4.4.4. Development and administration of the Platform;</span>
          </p>

          <p>
            <span>4.4.5. Responding to the inquiries and requests of the Users;</span>
          </p>

          <p>
            <span>4.4.6. Compliance with the requirements deriving from legislation;</span>
          </p>

          <p>
            <span>4.4.7. Processing on the basis of other legitimate interests.</span>
          </p>

          <p>
            <span>4.5. Complok processes the following personal data and data related thereto: </span>
          </p>

          <p>
            <span>4.5.1. With regard to the User:</span>
          </p>

          <p>
            <span>4.5.1.1. The name, e-mail address and country of residence of the subscribers of newsletters;</span>
          </p>

          <p>
            <span>4.5.1.2. Feedback on the Platform and the passed online trainings;</span>
          </p>

          <p>
            <span>
              4.5.1.3. Automatically collected data from the User’s web browser, including the type of the browser, type
              of the device, operational system, selected language, IP-address, information on the visits to the
              websites of the Platform and other website traffic data;
            </span>
          </p>

          <p>
            <span>
              4.5.1.4. Operations carried out by the User on the Platform (recording the use of the Platform and
              progress of undergoing an online training) and operation logs (information on the operation, date and
              time);
            </span>
          </p>

          <p>
            <span>4.5.1.5. Other personal data made known to Complok by the User or Employer;</span>
          </p>

          <p>
            <span>4.5.2. With regard to the Employee, in addition to the User:</span>
          </p>

          <p>
            <span>4.5.2.1. Name of the Employer;</span>
          </p>

          <p>
            <span>4.5.2.2. Certificates of successfully passed online trainings;</span>
          </p>

          <p>
            <span>4.6. Personal data are retained in accordance with the purpose of processing as follows:</span>
          </p>

          <p>
            <span>
              4.6.1. Upon providing an online training to an Employee, the personal data of the Employee are retained
              for up to 3 months after the expiry of the contract between Complok and the Employer. If the Employer
              requests that the User’s user account on the Platform be deleted during the term of the aforesaid
              contract, then for 3 months after the deletion;
            </span>
          </p>

          <p>
            <span>
              4.6.2. Upon creation and administration of the client base, the personal data of the Employee are retained
              in accordance with clause 4.6.1, and the personal data of the remaining Users are retained for up to 1
              year after the moment of their collection;
            </span>
          </p>

          <p>
            <span>
              4.6.3. Upon analysis and enhancement of the Platform and online trainings, personal data are retained for
              up to 1 year after the moment of their collection;
            </span>
          </p>

          <p>
            <span>
              4.6.4. Upon development and administration of the Platform, personal data are retained for up to 1 year
              after the moment of their collection;
            </span>
          </p>

          <p>
            <span>
              4.6.5. Upon responding to the inquiries and requests from the Users, personal data are retained for up to
              1 year after responding to the inquiry or request;
            </span>
          </p>

          <p>
            <span>
              4.6.6. Upon processing on other legal bases or on the basis of legitimate interest, during the term
              provided for by law or should there not be such term, then for a minimum term until the need ceases to
              exist.
            </span>
          </p>

          <p>
            <span>
              4.7. Personal data are anonymised not later than at the end of the term of retention of personal data, and
              their processing will continue as statistical information regarding the services of Complok, functioning
              of the Platform, and the online trainings.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>5. Use of cookies</span>
            </b>
          </p>

          <p>
            <span>
              5.1. Complok uses the co-called cookies on the Platform. Cookies are text files which are sent to the
              User’s browser by the web server and are recorded on the data medium of the User’s device.{' '}
            </span>
          </p>

          <p>
            <span>
              5.2. The cookies are used for identification of the User, analysis of the use of the Platform and for
              marketing purposes, enabling to make the work of the Platform more efficient and provide better user
              experience to the User on the Platform.{' '}
            </span>
          </p>

          <p>
            <span>
              5.3. Complok uses cookies also for monitoring the web traffic and statistics on the use of the Platform
              and the operations carried out on the Platform in an aggregated form. The information obtained is used for
              improving the user convenience and contents of the Platform.{' '}
            </span>
          </p>

          <p>
            <span>5.4. The cookies collect the automatically collected data referred to in clause 4.5.1.3. </span>
          </p>

          <p>
            <span>5.5. While using the Platform, you provide your consent for recording cookies on your device. </span>
          </p>

          <p>
            <span>
              5.6. You will be entitled to prohibit the use of cookies any time, but in such case all the functions of
              the Platform may not work properly.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>6. Principles of personal data processing </span>
            </b>
          </p>

          <p>
            <span>
              6.1. Personal data are processed in accordance with legislation and the principles specified in the
              Privacy Policy:
            </span>
          </p>

          <p>
            <span>6.1.1. Transparency</span>
          </p>

          <p>
            <span>
              6.1.1.1. Complok processes your personal data in a fair and transparent way and only if Complok is
              entitled to process your personal data pursuant to law.
            </span>
          </p>

          <p>
            <span>6.1.2. Limited purpose</span>
          </p>

          <p>
            <span>
              6.1.2.1. Complok collects your personal data for accurately and clearly determined and legitimate
              purposes. Complok does not process your personal data in any manner which is inconsistent with the initial
              purposes. If your personal data are being processed for any other purpose than the initial purpose of
              personal data processing, Complok shall rely on the legal bases deriving from law (for example, while
              responding to inquiries from courts or law enforcement authorities, Complok does not process your personal
              data only for the purpose of providing its own services) or requests your prior permission to process your
              personal data for a purpose that differs from the initial purpose of personal data processing.
            </span>
          </p>

          <p>
            <span>6.1.3. Collection of minimum data </span>
          </p>

          <p>
            <span>
              6.1.3.1. Complok aims to ensure that the personal data being processed are sufficient and relevant for the
              purposes of processing and are limited to what is needed for processing. Complok does not collect any
              excessive or unnecessary information.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.3.2. Should you or a third person disclose more personal data than needed, Complok will take all
              reasonable measures to prevent further processing of excessive personal data, and return the quantity of
              the processed personal data to the minimum.
            </span>
          </p>

          <p>
            <span>6.1.4. Accuracy </span>
          </p>

          <p>
            <span>
              6.1.4.1. Complok aims to ensure that your personal data are accurate and up to date. Complok takes all
              reasonable measures to ensure that inaccurate personal data will be immediately erased or rectified.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.4.2. If your personal data are incorrect, you will be entitled to request the rectification and/or
              erasure of the data from Complok. Complok shall not rectify or delete such personal data which Complok is
              obliged to retain for the purpose of complying with a legal obligation (e.g., to meet any requirements
              deriving from tax laws).
            </span>
          </p>

          <p>
            <span>6.1.5. Limited retention </span>
          </p>

          <p>
            <span>
              6.1.5.1. Complok will retain your personal data in a form which enables identification of the data subject
              only for as long as it is necessary for the purpose of personal data processing and for compliance with
              the requirements deriving from law. Complok will process personal data only for as long as it is required
              pursuant to laws or binding agreements.
            </span>
          </p>

          <p>
            <span>6.1.6. Reliability and confidentiality </span>
          </p>

          <p>
            <span>
              6.1.6.1. Complok shall ensure the protection of personal data and process your personal data in a manner
              ensuring the required security, including protection against unauthorised or unlawful processing. Complok
              will make reasonable efforts to prevent loss or destruction of or damage to data.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.6.2. Technical and organisational measures are implemented for enhanced security upon the processing
              of personal data. In order to increase awareness and knowledge of personal data protection, relevant
              trainings are organised for the staff processing your personal data. In addition, the protection of
              personal data is ensured through the confidentiality obligation applicable to the staff and processors of
              Complok.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.6.3. Complok does not process any special categories of your personal data (i.e., sensitive data like
              data concerning health, or data revealing racial or ethnical origin, political views, religious or
              philosophical beliefs).
            </span>
          </p>

          <p>
            <span>6.1.7. Data protection by design and by default</span>
          </p>

          <p>
            <span>
              6.1.7.1. In the development and administration of the Platform, Complok will take into account the data
              subject’s right to the protection of personal data and privacy. The processes of personal data processing
              and development thereof will be based on the development of science and technology, and measures will be
              taken to mitigate risks related to the processing of personal data in accordance with the development of
              science and technology as far as possible. It will be ensured that the processes of personal data
              processing are protected according to the type, extent, context and purposes of personal data processing.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>7. Rights of data subject</span>
            </b>
          </p>

          <p>
            <span>
              7.1. Complok shall provide information regarding a specific data subject at the request of the data
              subject electronically, in writing or orally, considering that the identity of the data subject is
              previously clear and verified. It means that if there any doubts arise while processing your request,
              Complok may ask you to provide additional information. Complok does it to be sure about the identity of
              the data subject and to ensure that correct information is submitted to the correct person.
            </span>
          </p>

          <p>
            <span>7.2. The right to receive information on the extent and use of processed personal data </span>
          </p>

          <p>
            <span>
              7.2.1. You are entitled to access your personal data and receive information on which data regarding you
              are processed by Complok and how these data are being processed. This enables you to be informed, as
              necessary, and verify which personal data are being processed and how these data are being processed by
              Complok in connection to you. You may also contact Complok and ask for which purpose we are processing
              your personal data, if this remains unclear to you or if you have additional questions.
            </span>
          </p>

          <p>
            <span>
              7.2.2. Complok will respond to you within a reasonable time but not later than within one month. In case
              of more complicated inquiries and requests, Complok will be entitled to extend the response time by two
              months. In such case Complok will contact you for extending the response time and explain to you the
              reasons for such extension.
            </span>
          </p>

          <p>
            <span>7.3. Right to rectification of personal data </span>
          </p>

          <p>
            <span>
              7.3.1. Should you notice that your personal data are no longer up to date, are incorrect, need
              rectification or supplementing, you may contact Complok to change the data. Complok will change your
              personal data in case of a reasoned and legitimate request as soon as possible but not later than within
              30 days.
            </span>
          </p>

          <p>
            <span>7.4. Right to withdraw your consent </span>
          </p>

          <p>
            <span>
              7.4.1. In case of personal data processing on the basis of consent, you will be entitled to contact
              Complok at any time and withdraw your consent for the processing of personal data. The withdrawal of
              consent does not have retroactive effect and it shall not retroactively affect the lawfulness of personal
              data processing during the time when the consent was valid.
            </span>
          </p>

          <p>
            <span>
              7.4.2. If consent is withdrawn, the collected personal data shall be retained during the terms set out in
              clause 4.6 of the Privacy Policy, whereas the term will be calculated not later than from the day when the
              consent was withdrawn. Upon a request of erasure of personal data upon withdrawal of consent, Complok
              shall act in accordance with clause 7.5 of this Privacy Policy.{' '}
            </span>
          </p>

          <p>
            <span>
              7.4.3. The withdrawal of consent may result in limitation of the use of the Platform and undergoing online
              trainings in accordance with relevant contracts.
            </span>
          </p>

          <p>
            <span>7.5. Right to erasure of personal data </span>
          </p>

          <p>
            <span>
              7.5.1. You are entitled to request erasure of your personal data if the personal data are no longer
              necessary or suitable in relation to the purposes of their collection or processing. The right to erasure
              is not an absolute right and therefore your request for erasure of personal data may not mean that all
              your personal data will be erased after receipt of the request. The law prescribes obligations to retain
              data in certain cases, and therefore it may not be possible to grant your request in such cases.
              Furthermore, respective data may be retained for the exercise or defence of legal claims.
            </span>
          </p>

          <p>
            <span>7.6. Right to lodge a complaint with a data protection supervisory authority </span>
          </p>

          <p>
            <span>
              7.6.1. All data subjects have the right to lodge a complaint with a supervisory authority exercising state
              supervision of data protection or with a court if they find that the processing of their personal data is
              not in compliance with legislation regarding data protection. In Estonia, the supervisory authority is the
              Data Protection Inspectorate, whose contact details can be found here:
              https://www.aki.ee/et/inspektsioon-kontaktid/tootajate-kontaktid.
            </span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>8. Safeguards and notification</span>
            </b>
          </p>

          <p>
            <span>
              8.1. Complok shall maintain confidentiality of personal data and protect them against unlawful disclosure
              to third persons by implementing relevant IT security measures and organisational and technical measures.
            </span>
          </p>

          <p>
            <span>
              8.2. In the case of a personal data breach, if it involves a probable risk to the rights and freedoms of
              the data subject, Complok shall notify the breach to the Data Protection Inspectorate. Additional measures
              shall be taken to end such infringement as soon as possible.
            </span>
          </p>

          <p>
            <span>
              8.3. If the breach results in a probable major risk to the rights and freedoms of the data subject,
              Complok shall notify the data subject about the breach. The purpose of such notification is to enable the
              data subjects themselves to take precautionary measures to mitigate the potential risks deriving from the
              situation.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>9. Amendments to Privacy Policy</span>
            </b>
          </p>

          <p>
            <span>
              9.1. Complok will be entitled to amend the Privacy Policy based on the amendments to laws and to the state
              of the art which ensures high level of protection of personal data. You are required to become acquainted
              with the amendments.
            </span>
          </p>

          <p>
            <span>
              9.2. Complok will give 14 days’ advance notice of any amendments to the Privacy Policy via the Platform,
              unless the amendments have to take effect earlier due to regulations or instructions of state authorities.{' '}
            </span>
          </p>

          <p>
            <span>
              9.3. Should you not agree with any amendments to the Privacy Policy, you will have to delete your user
              account, and refrain from further use of the Platform.
            </span>
          </p>

          <p>
            <span>
              9.4. Should you continue to use the Platform, you thereby confirm that the amendments are suitable, and
              you will be deemed to have agreed with the amendments.
            </span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>
        </div>
      ) : (
        <div>
          <Title level={4}>
            <span>Privaatsuspoliitika</span>
          </Title>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>1. Sissejuhatus</span>
            </b>
          </p>

          <p>
            <span>
              1.1. Käesolev privaatsuspoliitika selgitab, milliseid andmeid Complok seoses Platvormiga kogub ja kuidas
              neid kasutatakse. Privaatsuspoliitika selgitab ka teie õigusi oma andmete haldamise osas.{' '}
            </span>
          </p>

          <p>
            <span>
              1.2. Privaatsuspoliitikas ei ole esitatud ammendavat loetelu kõigist õiguslikest alustest, mis võimaldavad
              asutustel või isikutel andmeid Complokilt nõuda ja töödelda, kuivõrd need tulenevad erinevatest
              õigusaktidest ja ei ole lõpliku nimekirjana esitatavad.
            </span>
          </p>

          <p>
            <span>1.3. Palume teil võtta aega, et Privaatsuspoliitikaga tutvuda.</span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>2. Mõisted</span>
            </b>
          </p>

          <p>
            <span>
              2.1. Andmesubjekt on tuvastatav või tuvastatud füüsiline isik, kelle isikuandmeid töödeldakse. Teie olete
              andmesubjekt ainult siis, kui teie olete töödeldavate isikuandmete alusel tuvastatav või tuvastatud.
            </span>
          </p>

          <p>
            <span>
              2.2. Isikuandmed on mistahes andmed tuvastatava või tuvastatud füüsilise isiku (andmesubjekt) kohta.
            </span>
          </p>

          <p>
            <span>
              2.3. Isikuandmete töötlemine on mistahes isikuandmetega tehtav toiming (näiteks kogumine, muutmine,
              vaatamine, kustutamine).
            </span>
          </p>

          <p>
            <span>
              2.4. Complok on äriühing Complok Learning OÜ (registrikood 16171413), mis asub aadressil Angerja tn 9-31,
              10416 Tallinn, Eesti, ning on Platvormi omanik ja haldaja.
            </span>
          </p>

          <p>
            <span>2.5. Platvorm on https://www.elearning.complok.eu/</span>
          </p>

          <p>
            <span>
              veebileht, sh kõik selle alamdomeenid ja aliased, koos kõigi selle komponentide, sisu ja toimimiseks
              vajaliku tarkvaraga.
            </span>
          </p>

          <p>
            <span>2.6. Kasutaja on iga isik, kes Platvormi kasutab, sh ainult külastab.</span>
          </p>

          <p>
            <span>
              2.7. Töötaja on Kasutaja, kes on loonud Platvormil kasutajakonto Tööandja poolt talle määratud
              online-koolituste läbimiseks või Tööandja teiste Töötajate online-koolituste haldamiseks.
            </span>
          </p>

          <p>
            <span>
              2.8. Tööandja on isik, kes Complokiga sõlmitud lepingu alusel määrab endaga mingil moel seotud
              füüsilistele isikutele Platvormil läbitavaid online-koolitusi ning tasub nende eest.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>3. Complok kui isikuandmete töötleja</span>
            </b>
          </p>

          <p>
            <span>
              3.1. Privaatsuspoliitikas on välja toodud informatsioon Comploki poolt isikuandmete töötlemise kohta.
              Complok lähtub isikuandmete töötlemisel Euroopa Parlamendi ja nõukogu määrusest (EL) 2016/679
              (isikuandmete kaitse üldmäärus ehk GDPR), isikuandmete kaitse seadusest ning muudest andmekaitset
              reguleerivatest õigusaktidest.
            </span>
          </p>

          <p>
            <span>
              3.2. Andmekaitsealastes küsimustes on võimalik Complokiga ühendust võtta e-posti teel
              mariliis.kurg@complok.eu või saates kirja aadressile Angerja tn 9-31, 10416 Tallinn, Eesti.
            </span>
          </p>

          <p>
            <span>
              3.3. Volitatud töötlejaks on Kasutaja puhul Platvormi majutus-, arendus- ja hooldusteenust pakkuvad
              teenuseosutajad ning Töötaja puhul lisaks Tööandja.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>
                4.Isikuandmete töötlemise õiguslikud alused ja eesmärgid ning andmete kategooriad ja andmete säilitamine
              </span>
            </b>
          </p>

          <p>
            <span>
              4.1. Complok töötleb kõiki isikuandmeid, mis suhtluses andmesubjektiga on talle teatavaks saanud või mis
              on talle avaldatud. Isikuandmeid töödeldakse ja säilitatakse ainult kindlaksmääratud aja jooksul ning
              seejärel need kustutatakse või hävitatakse. Comploki personalil on õigus töödelda isikuandmeid üksnes
              ulatuses, mis on vajalik määratud tööülesannete täitmiseks ning mis on vajalikud isikuandmete töötlemise
              eesmärgi saavutamiseks.{' '}
            </span>
          </p>

          <p>
            <span>4.2. Isikuandmete töötlemine põhineb järgmistel õiguslikel alustel:</span>
          </p>

          <p>
            <span>4.2.1. olete andnud oma isikuandmete töötlemiseks nõusoleku; </span>
          </p>

          <p>
            <span>4.2.2. Complokil on ettevõttena on õigustatud huvi andmete töötlemiseks.</span>
          </p>

          <p>
            <span>
              4.3. Isikuandmete töötlemine võib toimuda ka teistel õiguslikel alustel, näiteks õiguskaitseorganitele
              andmete väljastamisel nende ülesannete täitmise võimaldamiseks (näiteks politsei ja kohus) või Comploki
              poolt tasutud maksude õigsuse kontrollimise võimaldamiseks (näiteks raamatupidamisseaduses ja
              maksuseadustes sisalduvad kohustused).{' '}
            </span>
          </p>

          <p>
            <span>4.4. Complok töötleb isikuandmeid järgmistel eesmärkidel:</span>
          </p>

          <p>
            <span>4.4.1. Online-koolituste pakkumine, seal hulgas:</span>
          </p>

          <p>
            <span>
              4.4.1.1. Platvormi kasutamise ja online-koolituste lepingute täitmise ning tagamise eesmärgil Kasutaja
              tuvastamine ja tunnistuse väljastamiseks vajalike andmete kogumine;
            </span>
          </p>

          <p>
            <span>4.4.1.2. Kasutaja online-koolituse läbimise progressi salvestamine; </span>
          </p>

          <p>
            <span>
              4.4.1.3. otseturunduslike reklaampakkumiste tegemine ja Platvormil kohandatud sisu või reklaamide kuvamine
              Kasutajale;
            </span>
          </p>

          <p>
            <span>
              4.4.2. kliendibaasi loomine ja selle haldamine, et parandada Comploki teenuste kvaliteeti ja
              kättesaadavust;
            </span>
          </p>

          <p>
            <span>
              4.4.3. Platvormi ja online-koolituste analüüsimine ja täiustamine, sh kliendirahuolu uuringute
              läbiviimine;
            </span>
          </p>

          <p>
            <span>4.4.4. Platvormi arendamine ja haldamine;</span>
          </p>

          <p>
            <span>4.4.5. Kasutajate päringutele ja taotlustele vastamine;</span>
          </p>

          <p>
            <span>4.4.6. õigusaktidest tulenevate nõuete täitmine;</span>
          </p>

          <p>
            <span>4.4.7. muu õigustatud huvi alusel töötlemine.</span>
          </p>

          <p>
            <span>4.5. Complok töötleb järgmisi isikuandmeid ja nendega seotud andmeid: </span>
          </p>

          <p>
            <span>4.5.1. Kasutaja puhul:</span>
          </p>

          <p>
            <span>4.5.1.1. uudiskirja tellija nimi, e-posti aadress ja elukoha riik;</span>
          </p>

          <p>
            <span>4.5.1.2. tagasiside Platvormi ja läbitud online-koolituste kohta;</span>
          </p>

          <p>
            <span>
              4.5.1.3. automaatselt kogutavad andmed Kasutaja veebibrauserilt, sealhulgas veebibrauseri liik, seadme
              tüüp, operatsioonisüsteem, valitud keel, IP-aadress, info Platvormi veebilehtede külastamise kohta ja muud
              veebiliiklusandmed;
            </span>
          </p>

          <p>
            <span>
              4.5.1.4. kasutaja poolt Platvormil tehtavad toimingud (Platvormi kasutamise ja online-koolituse läbimise
              progressi salvestamine) ning toimingute logid (info toimingu, kuupäeva ja kellaaja kohta);
            </span>
          </p>

          <p>
            <span>4.5.1.5. muud isikuandmed, mille Kasutaja või Tööandja on teinud Complokile teatavaks;</span>
          </p>

          <p>
            <span>4.5.2. Töötaja puhul lisaks Kasutajale:</span>
          </p>

          <p>
            <span>4.5.2.1. Tööandja nimi;</span>
          </p>

          <p>
            <span>4.5.2.2. edukalt läbitud online-koolituste tunnistused;</span>
          </p>

          <p>
            <span>4.6. Isikuandmeid säilitatakse vastavalt töötlemise eesmärgile järgnevalt:</span>
          </p>

          <p>
            <span>
              4.6.1. Online-koolituse pakkumisel Töötajale säilitatakse Töötaja isikuandmeid kuni 3 kuud peale Comploki
              ja Tööandja vahelise lepingu lõppemist. Kui viidatud lepingu kehtivuse ajal taotleb Tööandja Kasutaja
              Platvormi kasutajakonto kustutamist, siis 3 kuud alates kustutamisest;
            </span>
          </p>

          <p>
            <span>
              4.6.2. kliendibaasi loomisel ja haldamisel säilitatakse Töötaja isikuandmeid vastavalt punktile 4.6.1,
              ülejäänud Kasutajate isikuandmeid säilitatakse kuni 1 aasta kogumise hetkest arvates;
            </span>
          </p>

          <p>
            <span>
              4.6.3. Platvormi ja online-koolituste analüüsimisel ja täiustamisel säilitatakse isikuandmeid kuni 1 aasta
              kogumise hetkest arvates.
            </span>
          </p>

          <p>
            <span>
              4.6.4. Platvormi arendamisel ja haldamisel säilitatakse isikuandmeid kuni 1 aasta kogumise hetkest
              arvates.
            </span>
          </p>

          <p>
            <span>
              4.6.5. Kasutajate päringutele ja taotlustele vastamisel säilitatakse isikuandmeid kuni 1 aasta päringule
              või taotlusele vastamisest arvates.
            </span>
          </p>

          <p>
            <span>
              4.6.6. muul õiguslikul alusel või õigustatud huvi korral töötlemisel õigusaktis sätestatud tähtaja jooksul
              või tähtaja puudumisel minimaalse aja kuni vajaduse äralangemiseni.
            </span>
          </p>

          <p>
            <span>
              4.7. Hiljemalt isikuandmete säilitamise tähtaja lõpus isikuandmed anonüümistatakse ning töödeldakse edasi
              statistilise infona Comploki teenuste, Platvormi toimimise ja online-koolituste kohta.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>5. Küpsiste kasutamine</span>
            </b>
          </p>

          <p>
            <span>
              5.1. Complok kasutab Platvormil nn küpsiseid. Küpsised on tekstifailid, mille veebiserver saadab Kasutaja
              veebibrauserile ja mis salvestatakse Kasutaja seadme andmekandjale.{' '}
            </span>
          </p>

          <p>
            <span>
              5.2. Küpsiseid kasutatakse Kasutaja identifitseerimise, Platvormi kasutamise analüüsimise ja turunduse
              eesmärgil, võimaldades muuta Platvormi töö tõhusamaks ja pakkuda Kasutajale paremat Platvormi kasutamise
              kogemust.{' '}
            </span>
          </p>

          <p>
            <span>
              5.3. Complok kasutab küpsiseid ka selleks, et jälgida Platvormi veebiliiklust ja kasutusstatistikat ning
              Platvormil tehtavaid toiminguid agregeeritud kujul. Saadud infot kasutatakse Platvormi kasutusmugavuse ja
              sisu parendamiseks.{' '}
            </span>
          </p>

          <p>
            <span>5.4. Küpsised koguvad punktis 4.5.1.3 nimetatud automaatselt kogutavaid andmeid. </span>
          </p>

          <p>
            <span>5.5. Platvormi kasutamisega annate nõusoleku küpsiste talletamiseks oma seadmesse. </span>
          </p>

          <p>
            <span>
              5.6. Teil on õigus igal ajal küpsiste kasutamine keelata, kuid sellisel juhul ei pruugi kõik Platvormi
              funktsioonid korralikult toimida.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>6. Isikuandmete töötlemise põhimõtted</span>
            </b>
          </p>

          <p>
            <span>
              6.1. Isikuandmeid töödeldakse kooskõlas õigusaktidega ja vastavalt Privaatsuspoliitikas sätestatud
              täpsustatud põhimõtetele:
            </span>
          </p>

          <p>
            <span>6.1.1. Läbipaistvus</span>
          </p>

          <p>
            <span>
              6.1.1.1. Complok töötleb teie isikuandmeid õiglasel ja läbipaistval viisil ning ainult siis, kui Complokil
              on õigusaktide kohaselt lubatud teie isikuandmeid töödelda.
            </span>
          </p>

          <p>
            <span>6.1.2. Eesmärgi piirang</span>
          </p>

          <p>
            <span>
              6.1.2.1. Complok kogub teie isikuandmeid täpselt ja selgelt kindlaksmääratud ning õiguspärastel
              eesmärkidel. Complok ei töötle teie isikuandmeid viisil, mis ei ühti esialgsete eesmärkidega. Kui teie
              isikuandmeid töödeldakse muul eesmärgil kui esialgne isikuandmete töötlemise eesmärk, toetub Complok
              õigusaktidest tulenevatele õiguslikele alustele (näiteks kohtute või õiguskaitseorganite päringutele
              vastamisel ei töötle Complok teie isikuandmeid enam ainult enda teenuste osutamise eesmärgil) või Complok
              küsib teie eelnevat luba, et töödelda teie isikuandmeid esialgsest isikuandmete töötlemiseesmärgist
              erineval eesmärgil.
            </span>
          </p>

          <p>
            <span>6.1.3. Võimalikult väheste andmete kogumine</span>
          </p>

          <p>
            <span>
              6.1.3.1. Comploki eesmärgiks on tagada, et töödeldavad isikuandmed on andmete töötlemise eesmärkideks
              piisavad, asjakohased ja piiratud töötlemiseks vajalikuga. Complok ei kogu ülearust ega ebavajalikku
              teavet.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.3.2. Kui teie või kolmas isik edastab Complokile vajalikust määrast rohkem isikuandmeid, võtab Complok
              kasutusele kõik mõistlikud meetmed selleks, et vältida hilisemalt üleliigsete isikuandmete töötlemist ning
              viia töödeldavate isikuandmete maht uuesti miinimumini.
            </span>
          </p>

          <p>
            <span>6.1.4. Õigsus</span>
          </p>

          <p>
            <span>
              6.1.4.1. Comploki eesmärk on tagada, et teie isikuandmed on õiged ja ajakohased. Complok võtab kasutusele
              kõik mõistlikud meetmed tagamaks, et ebaõiged isikuandmed kustutatakse või parandatakse viivitamata.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.4.2. Juhul, kui teie isikuandmed on valed, on teil võimalik Complokilt taotleda andmete parandamist
              ja/või kustutamist. Complok ei paranda ega kustuta selliseid isikuandmeid, mida Complok on kohustatud
              hoidma juriidilise kohustuse täitmise eesmärgil (näiteks maksuseadustest tulenevate nõuete täitmiseks).
            </span>
          </p>

          <p>
            <span>6.1.5. Säilitamise piirang</span>
          </p>

          <p>
            <span>
              6.1.5.1. Complok säilitab teie isikuandmeid kujul, mis võimaldab andmesubjekti tuvastamist, ainult seni,
              kuni see on vajalik isikuandmete töötlemise eesmärkide ja õigusaktidest tulenevate nõuete täitmiseks.
              Complok töötleb isikuandmeid ainult seni, kuni see on õigusaktide või siduvate lepingute kohaselt nõutav.
            </span>
          </p>

          <p>
            <span>6.1.6. Usaldusväärsus ja konfidentsiaalsus</span>
          </p>

          <p>
            <span>
              6.1.6.1. Complok tagab isikuandmete kaitse ja töötleb teie isikuandmeid viisil, mis tagab nõuetekohase
              turvalisuse, sealhulgas kaitse lubamatu või ebaseadusliku töötlemise eest. Complok teeb mõistlikke
              jõupingutusi vältimaks andmete kaotamist, hävitamist või kahjustumist.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.6.2. Isikuandmete töötlemisel kasutatakse turvalisuse suurendamiseks tehnilisi ja organisatsioonilisi
              meetmeid. Isikuandmete kaitse kohta teadlikkuse ja teadmiste suurendamiseks korraldatakse teie
              isikuandmeid töötlevale personalile asjakohaseid koolitusi. Lisaks sellele on tagatud isikuandmete kaitse
              läbi Comploki personalile ja volitatud töötlejatele kohaldatava konfidentsiaalsuskohustuse.{' '}
            </span>
          </p>

          <p>
            <span>
              6.1.6.3. Complok ei töötle teie eriliiki isikuandmeid (see tähendab delikaatseid andmeid nagu tervisega
              seotud andmed, rassilist või etnilist päritolu, poliitilisi vaateid, religioosseid või filosoofilisi
              uskumusi paljastavad andmed).
            </span>
          </p>

          <p>
            <span>6.1.7. Lõimitud ja vaikimisi andmekaitse</span>
          </p>

          <p>
            <span>
              6.1.7.1. Platvormi arendades ja hallates arvestab Complok andmesubjekti õigust isikuandmete kaitsele ja
              privaatsusele. Isikuandmete töötlemist puudutavates protsessides ja nende arendamisel lähtutakse teaduse
              ja tehnoloogia arengust ning võetakse kasutusele meetmeid, et isikuandmete töötlemisega seonduvaid ohte
              vastavalt teaduse ja tehnoloogia arengule võimaluste piires maandada. Isikuandmete töötlemisega seotud
              protsessides tagatakse nende kaitse lähtudes isikuandmete töötlemise laadist, ulatusest, kontekstist ja
              eesmärkidest.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>7. Andmesubjekti õigused</span>
            </b>
          </p>

          <p>
            <span>
              7.1. Andmesubjekti taotlusel esitab Complok konkreetse andmesubjekti kohta teavet elektrooniliselt,
              kirjalikult või suuliselt, võttes arvesse, et andmesubjekti identiteet on eelnevalt selge ja tõestatud.
              See tähendab, et kui teie päringuga tegeledes esineb kahtlusi, võib Complok paluda teil esitada
              lisateavet. Complok teeb seda selleks, et olla andmesubjekti identiteedis kindel ja tagada, et esitatakse
              õigele isikule õige teabe.
            </span>
          </p>

          <p>
            <span>7.2. Õigus saada teavet töödeldavate isikuandmete ulatuse ja kasutamise kohta</span>
          </p>

          <p>
            <span>
              7.2.1. Teil on õigus tutvuda oma isikuandmetega ja saada teavet selle kohta, milliseid andmeid ja kuidas
              Complok teie osas töötleb. See võimaldab teil olla vajadusel kursis ja kontrollida, milliseid isikuandmeid
              ja kuidas Complok neid teiega seonduvalt töötleb. Samuti võite pöörduda Comploki poole ja küsida, mis
              eesmärgil me teie isikuandmeid töötleme, kui see jääb teile ebaselgeks või kui teil on lisaküsimusi.
            </span>
          </p>

          <p>
            <span>
              7.2.2. Complok vastab teile mõistliku aja jooksul, kuid hiljemalt ühe kuu jooksul. Keerulisemate päringute
              ja taotluste korral on Complokil õigus pikendada vastamiseks kuluvat aega kahe kuu võrra. Sellisel juhul
              võtab Complok teiega vastamisperioodi pikendamise osas ühendust ja selgitab teile pikendamise põhjuseid.
            </span>
          </p>

          <p>
            <span>7.3. Õigus isikuandmete parandamisele</span>
          </p>

          <p>
            <span>
              7.3.1. Kui märkate, et teie isikuandmed ei ole ajakohased, on valed, vajavad parandamist või täiendamist,
              võite pöörduda andmete muutmiseks Comploki poole. Complok muudab teie isikuandmeid põhjendatud ja
              õiguspärase taotluse korral esimesel võimalusel, kuid hiljemalt 30 päeva jooksul.
            </span>
          </p>

          <p>
            <span>7.4. Õigus nõusolek tagasi võtta</span>
          </p>

          <p>
            <span>
              7.4.1. Nõusolekul põhineva isikuandmete töötlemisega on teil igal ajal õigus pöörduda Comploki poole ning
              oma nõusolek isikuandmete töötlemiseks tagasi võtta. Nõusoleku tagasivõtmisel ei ole tagasiulatuvat jõudu
              ning see ei mõjuta tagasiulatuvalt juba toimunud isikuandmete töötlemise õiguspärasust kehtinud nõusoleku
              ajal.
            </span>
          </p>

          <p>
            <span>
              7.4.2. Nõusoleku tagasivõtmisel säilitatakse kogutud isikuandmeid vastavalt privaatsuspoliitika punktis
              4.6 toodud tähtaegadele, kusjuures tähtaeg hakkab kulgema hiljemalt nõusoleku tagasivõtmise päevast.
              Nõusoleku tagasivõtmisel isikuandmete kustutamise nõudmisel lähtub Complok käesoleva privaatsuspoliitika
              punktist 7.5.{' '}
            </span>
          </p>

          <p>
            <span>
              7.4.3. Nõusoleku tagasivõtmine võib kaasa tuua Platvormi kasutamise ja online-koolituste läbimise
              piiramise vastavalt asjakohastele lepingutele.
            </span>
          </p>

          <p>
            <span>7.5. Õigus isikuandmete kustutamisele</span>
          </p>

          <p>
            <span>
              7.5.1. Teil on õigus nõuda oma isikuandmete kustutamist, kui isikuandmed ei ole seoses nende kogumise või
              töötlemise eesmärkidega enam vajalikud või sobivad. Õigus kustutamisele ei ole absoluutne õigus ja
              seetõttu ei pruugi teie taotlus isikuandmete kustutamiseks tähendada, et taotluse saamise järel kõik teie
              isikuandmed kustutatakse. Õigusaktid sisaldavad kohustusi andmete säilitamiseks teatud juhtudel, mistõttu
              ei pruugi sellisel juhul olla võimalik teie taotlust rahuldada. Samuti võidakse vastavaid andmeid
              säilitada õiguslike nõuete teostamiseks või kaitsmiseks.
            </span>
          </p>

          <p>
            <span>7.6. Õigus esitada kaebus andmekaitse järelevalveasutusele</span>
          </p>

          <p>
            <span>
              7.6.1. Kõikidel andmesubjektidel on õigus pöörduda kaebusega riikliku andmekaitse järelevalve teostaja või
              kohtu poole, kui andmesubjekt leiab, et temaga seotud isikuandmete töötlemine ei vasta andmekaitsega
              seonduvatele õigusaktidele. Eestis on riiklikuks järelevalve teostajaks Andmekaitse Inspektsioon, mille
              kontaktinformatsioon on leitav siin: https://www.aki.ee/et/inspektsioon-kontaktid/tootajate-kontaktid .
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>8. Kaitsemeetmed ja teavitamine</span>
            </b>
          </p>

          <p>
            <span>
              8.1.Complok hoiab isikuandmeid konfidentsiaalsena ja kaitseb isikuandmeid ebaseaduslikult kolmandate
              isikute kätte sattumise eest asjakohaste infotehnoloogiliste turvameetmete ja organisatoorsete ning
              tehniliste meetmete rakendamisega.
            </span>
          </p>

          <p>
            <span>
              8.2. Isikuandmetega seotud rikkumise toimumisel, kui see kujutab endast tõenäolist ohtu andmesubjekti
              õigustele ja vabadustele, teavitab Complok sellisest rikkumisest Andmekaitse Inspektsiooni. Täiendavalt
              võetakse kasutusele meetmeid, et rikkumine esimesel võimalusel lõpetada
            </span>
          </p>

          <p>
            <span>
              8.3. Kui rikkumise tulemusena tekib andmesubjekti õigustele ja vabadustele tõenäoliselt suur oht, siis
              teavitab Complok juhtumist andmesubjekti. Teavituse eesmärk on võimaldada andmesubjektil endal võtta
              vajalikke ettevaatusabinõusid olukorrast tulenevate potentsiaalsete ohtude leevendamiseks.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>9. Privaatsuspoliitika muutmine</span>
            </b>
          </p>

          <p>
            <span>
              9.1. Complokil on õigus teha Privaatsuspoliitikas muudatusi, võttes arvesse õigusaktides ja isikuandmete
              kaitse kõrget taset tagava tehnoloogia arengutes aset leidvaid muutusi. Olete kohustatud muudatustega
              tutvuma.
            </span>
          </p>

          <p>
            <span>
              9.2. Complok teavitab Privaatsuspoliitika muutmisest Platvormi kaudu 14 päeva ette, välja arvatud juhul,
              kui regulatsioonist või riigiasutuse juhistest tulenevalt peavad muudatused jõustuma varem.{' '}
            </span>
          </p>

          <p>
            <span>
              9.3. Kui teie ei nõustu Privaatsuspoliitika muudatustega, peate oma kasutajakonto kustutama ning hoiduma
              edaspidi Platvormi kasutamisest.
            </span>
          </p>

          <p>
            <span>
              9.4. Kui teie jätkate Platvormi kasutamist, kinnitate sellega muudatuste sobimist ja teid loetakse
              muudatustega nõustunuks.
            </span>
          </p>
        </div>
      )}
    </Col>
  );
};

export default PrivacyPolicy;

const LanguagePopoverContent: FC<{ updateLanguage: (newLang: string) => void }> = ({ updateLanguage }) => {
  const languages = [
    { key: 'en', title: 'English' },
    { key: 'et', title: 'Eesti' }
  ];

  return (
    <div className="complok-header-profile-popover">
      {languages.map((action, index) => (
        <div
          onClick={() => updateLanguage(action.key)}
          className={`complok-header-profile-popover-item ${
            index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
          }`}
          key={`complok-header-popover-action-${index}`}
        >
          {action.title}
        </div>
      ))}
    </div>
  );
};
