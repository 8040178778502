import Title from 'antd/lib/typography/Title';
import Typography from 'antd/lib/typography/Typography';
import apiClient from 'api/apiClient';
import PublishCourseImage from 'components/icons/PublishCourseImage';
import ComplokButton from 'components/interactables/Button';
import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router';
import { mutate } from 'swr';
import { CourseEditContext } from './CourseEdit';

const CourseEditPublish: FC = () => {
  const { course } = useContext(CourseEditContext);
  const navigate = useNavigate();

  const publishCourse = async () => {
    if (!course?._id) return;
    const response = await apiClient.patch(`/courses/publish/${course._id}`).catch(() => null);

    if (response?.data) {
      mutate('/courses');
      navigate('/courses');
    }
  };

  return (
    <div id="complok-course-edit-content-publish">
      <PublishCourseImage />
      <div id="complok-course-edit-content-publish-text">
        <Title level={2}>You did it!</Title>
        <Typography>
          Congratulations on finishing up the course. Feel free to publish the course or do it later.
        </Typography>
      </div>

      <div className="super-admin-create-course-publish-container-actions">
        <ComplokButton onClick={publishCourse}>Publish course</ComplokButton>
        <label
          onClick={() => navigate('/courses')}
          className="super-admin-create-course-publish-container-actions-dashboard"
        >
          Go to dashboard
        </label>
      </div>
    </div>
  );
};

export default CourseEditPublish;
