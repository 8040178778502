/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const CheckBox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={29} height={29} rx={4.5} fill="#E2E2EA" stroke="#B5B5BE" />
  </svg>
);

export default CheckBox;
