import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  message,
  Popover,
  Row,
  Spin,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import apiClient from "api/apiClient";
import AdminTag from "components/icons/AdminTag";
import StudentTag from "components/icons/StudentTag";
import SuperAdminTag from "components/icons/SuperAdminTag";
import dayjs from "dayjs";
import { getStatus } from "helpers";
import i18n from "i18n";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { IAccount } from "types/AccountTypes";
import { ICourseHeaderType } from "types/Course/CourseTypes";
import { USER_ACCESS_LEVEL } from "types/UserTypes";

interface IUserDetailsDrawer {
  accountId?: string;
  onClose: () => void;
}

const returnAccountOptionByAccessLevel = (
  access_level: USER_ACCESS_LEVEL | undefined
) => {
  switch (access_level) {
    case USER_ACCESS_LEVEL.SUPER_ADMIN:
      return {
        text: i18n.t("Super admin"),
        icon: <SuperAdminTag />,
      };
    case USER_ACCESS_LEVEL.ADMIN:
      return {
        text: i18n.t("Admin"),
        icon: <AdminTag />,
      };
    case USER_ACCESS_LEVEL.LEARNER:
      return {
        text: i18n.t("Student"),
        icon: <StudentTag />,
      };
    default:
      console.log("default");
      return { text: "Error", icon: null };
  }
};

const UserDetailsDrawer: FC<IUserDetailsDrawer> = ({ accountId, onClose }) => {
  const { t } = useTranslation();

  const { data: selectedAccount, error: selectedAccountError } =
    useSWR<IAccount>(accountId ? `/accounts/by_account/${accountId}/` : null);
  const { data: userAccounts, error: userAccountsError } = useSWR<IAccount[]>(
    selectedAccount && selectedAccount?.user?._id
      ? `/accounts/by_user/${selectedAccount.user._id}/`
      : null
  );

  const { data: userCourses, error: userCoursesError } = useSWR<
    ICourseHeaderType<USER_ACCESS_LEVEL.LEARNER>[]
  >(
    accountId && selectedAccount?.access_level === USER_ACCESS_LEVEL.LEARNER
      ? `/courses/by_account/${accountId}/`
      : null
  );

  const loading =
    !userCourses &&
    !userCoursesError &&
    !selectedAccount &&
    !selectedAccountError &&
    !userAccounts &&
    !userAccountsError;

  const userData = selectedAccount?.user || null;

  const accountColumns: ColumnsType<IAccount> = [
    {
      title: t("Role"),
      key: "access_level",
      render: (x) => {
        const { icon, text } = returnAccountOptionByAccessLevel(x.access_level);
        return (
          <Row
            align="middle"
            className="complok-header-account-selector-option"
          >
            <div className="complok-header-account-selector-option-icon">
              {icon}
            </div>
            <div
              style={{ marginLeft: 5, marginBottom: 4 }}
              className="complok-header-account-selector-option-text"
            >
              {text}
            </div>
          </Row>
        );
      },
    },
    {
      title: t("Created at"),
      key: "created_at",
      render: (x) => <div>{dayjs(x.created_at).format("DD/MM/YY hh:mm")}</div>,
    },
    {
      title: t("Updated at"),
      key: "updated_at",
      render: (x) => <div>{dayjs(x?.updated_at).format("DD/MM/YY hh:mm")}</div>,
    },
    {
      title: t("Groups"),
      key: "groups",
      render: (x) => (
        <Tag color="green" style={{ borderRadius: 5, padding: "5px 10px" }}>
          {x.groups.length}
        </Tag>
      ),
    },
  ];

  const groupColumns: ColumnsType<any> = [
    {
      title: t("Name"),
      dataIndex: "title",
    },
    {
      title: t("Users"),
      dataIndex: "accounts",
      render: (accounts: string[]) => accounts?.length || 0,
    },
    {
      title: t("Courses"),
      dataIndex: "assigned_courses",
      render: (courses: string[]) => courses?.length || 0,
    },
  ];

  const courseColumns: ColumnsType<
    ICourseHeaderType<USER_ACCESS_LEVEL.LEARNER>
  > = [
    { title: t("Title"), dataIndex: "title" },
    {
      title: t("Status"),
      dataIndex: "status",
      filters: [
        {
          text: t("completed").toUpperCase(),
          value: "completed",
        },
        {
          text: t("in progress").toUpperCase(),
          value: "in_progress",
        },
        {
          text: t("not started").toUpperCase(),
          value: "not_started",
        },
      ],
      onFilter: (value, record: any) =>
        record.status.indexOf(value.toString()) === 0,
      render: (x) => {
        const { color, text } = getStatus(x);
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: t("Progress"),
      dataIndex: "completed_pct",
      sorter: (a: any, b: any) => a.completed_pct - b.completed_pct,
      render: (percentage) => (
        <Tag
          color={percentage === 100 ? "green" : "default"}
        >{`${percentage}%`}</Tag>
      ),
    },
    {
      title: t("Completed at"),
      render: (x) =>
        x.course_completed_at
          ? dayjs(x.course_completed_at).format("DD.MM.YYYY")
          : null,
    },
    {
      dataIndex: ["due_date"],
      title: t("Due date"),
      render: (duedate) => (
        <div>
          {duedate ? dayjs(duedate).format("DD.MM.YYYY") : t("No duedate set")}
        </div>
      ),
    },
  ];

  const sendResetPasswordMail = async () => {
    if (!userData) {
      return null;
    }

    apiClient
      .patch("users/reset_password/", { email: userData?.email })
      .then((response) => {
        if (response?.data) {
          message.success(t("Password has been sent"));
        }
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.msg || t("Failed to send password link!")
        );
      });
  };

  const UserActions = (
    <Col>
      <Col>
        <Button
          onClick={sendResetPasswordMail}
          disabled={!!userData?.has_created_password}
        >
          {t("Resend password creation email")}
        </Button>
      </Col>
    </Col>
  );

  return (
    <Drawer
      title={userData?.name || t("Loading")}
      width={"65%"}
      visible={!!accountId}
      onClose={onClose}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Row>
          <Col flex={1}>
            <Title level={5}>{t("Details")}</Title>
          </Col>
          <Col>
            <Popover
              content={UserActions}
              trigger="hover"
              placement="bottomLeft"
            >
              <MoreOutlined style={{ fontSize: 21, fontWeight: "bold" }} />
            </Popover>
          </Col>
        </Row>
        <Col style={{ marginTop: 20 }} span={24}>
          <Row style={{ marginBottom: 5 }}>
            <Col style={{ fontWeight: 500 }} span={10}>
              {t("Name")}
            </Col>
            <Col span={14}>{userData?.name}</Col>
          </Row>
          <Row style={{ marginBottom: 5 }}>
            <Col style={{ fontWeight: 500 }} span={10}>
              {t("Email")}
            </Col>
            <Col span={14}>{userData?.email}</Col>
          </Row>
          <Row style={{ marginBottom: 5 }}>
            <Col style={{ fontWeight: 500 }} span={10}>
              {t("Has created password")}
            </Col>
            <Col span={14}>
              <Tag color={userData?.has_created_password ? "green" : "orange"}>
                {userData?.has_created_password ? "Yes" : "No"}
              </Tag>
            </Col>
          </Row>
          {userData?.set_password_uuid && (
            <Row>
              <Col style={{ fontWeight: 500 }} span={10}>
                {t("Password creation link")}
              </Col>
              <Col span={14}>
                {userData?.set_password_uuid
                  ? `https://learning.complok.eu/setpassword/${userData.set_password_uuid}`
                  : t("User already has set password")}
              </Col>
            </Row>
          )}
        </Col>
        <Divider />
        <Title level={5}>{t("Groups")}</Title>
        <Col style={{ marginTop: 20 }} span={24}>
          <Table
            rowKey={"_id"}
            columns={groupColumns}
            dataSource={selectedAccount?.groups || []}
            pagination={false}
          />
        </Col>
        <Divider />
        <Title level={5}>{t("Course completion")}</Title>
        <Col style={{ marginTop: 20 }}>
          <Table
            columns={courseColumns}
            dataSource={userCourses}
            rowKey={"_id"}
            pagination={false}
          />
        </Col>
        <Divider />
        <Title level={5}>{t("Accounts")}</Title>
        <Col style={{ marginTop: 20 }} span={24}>
          <Table
            rowKey={"_id"}
            columns={accountColumns}
            dataSource={userAccounts || []}
            pagination={false}
          />
        </Col>
        <Divider />
      </Spin>
    </Drawer>
  );
};

export default UserDetailsDrawer;
