/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { UserContext } from "context/UserContext";
import dayjs from "dayjs";
import { downloadCourseCertificate } from "features/certificate";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import {
  COURSE_COMPLETION_STATUS,
  ICourseHeader,
} from "types/Course/CourseTypes";
import { LearnerContext } from "./LearnerContext";

const CourseDirectory: FC = () => {
  const { courseHeaders, loadingCourseHeaders } = useContext(LearnerContext);

  if (courseHeaders && courseHeaders.length === 1) {
    const onlyCourseId = courseHeaders[0]._id;
    return <Navigate to={`/courses/${onlyCourseId}`} />;
  }

  return (
    <div className="coursedirectory">
      {/* Courses by status */}
      {loadingCourseHeaders ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "70%",
          }}
        />
      ) : (
        <div
          className="coursedirectory-lists"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CourseDirectoryList
            index={0}
            courseHeaders={courseHeaders || []}
            filterBy={COURSE_COMPLETION_STATUS.NOT_STARTED}
          />
          <CourseDirectoryList
            index={1}
            courseHeaders={courseHeaders || []}
            filterBy={COURSE_COMPLETION_STATUS.IN_PROGRESS}
          />
          <CourseDirectoryList
            index={2}
            courseHeaders={courseHeaders || []}
            filterBy={COURSE_COMPLETION_STATUS.COMPLETED}
          />
        </div>
      )}
    </div>
  );
};

type CourseDirectoryListProps = {
  index: number;
  courseHeaders: ICourseHeader[];
  filterBy: COURSE_COMPLETION_STATUS;
};
const CourseDirectoryList: FC<CourseDirectoryListProps> = ({
  courseHeaders,
  filterBy,
  index,
}) => {
  const { t } = useTranslation();
  const dataToRender = courseHeaders
    .filter(
      (courseHeader) =>
        (courseHeader.status === filterBy &&
          (courseHeader?.completed_pct ?? 0) < 100) ||
        (filterBy === COURSE_COMPLETION_STATUS.COMPLETED &&
          courseHeader.completed_pct === 100)
    )
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((courseHeader) => (
      <CourseDirectoryListItem key={courseHeader._id} header={courseHeader} />
    ));

  if (dataToRender.length) {
    return (
      <div key={index} className="complok-coursedirectory-list">
        <Title level={4}>
          {filterBy === COURSE_COMPLETION_STATUS.NOT_STARTED &&
            t("Not Started")}
          {filterBy === COURSE_COMPLETION_STATUS.IN_PROGRESS &&
            t("In Progress")}
          {filterBy === COURSE_COMPLETION_STATUS.COMPLETED && t("Completed")}
        </Title>
        <div className="complok-coursedirectory-list-items">
          {/* TODO: FIX: Remove filtering by percentage - backend creating completed courses with percentages over 100% */}
          {dataToRender}
        </div>
      </div>
    );
  }

  return null;
};

const CourseDirectoryListItem: FC<{ header: ICourseHeader }> = ({ header }) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const itemColor =
    (header?.title?.length ?? 0) > 30 ? "dark-blue" : "light-blue";

  const downloadCertificate = () => {
    downloadCourseCertificate(
      {
        participant: userData?.name,
        titles: {
          course: header?.title,
          certificate: t("Certificate").toUpperCase(),
        },

        identifier: header.progress_id,
        hasDoneText: t("has completed"),
        durationText: header.duration
          ? `${t("Course duration:")} ${header.duration} ${t("hours")}`
          : undefined,
        date: header?.course_completed_at,
        additionalText: header?.additional_certificate_text
          ? t(header.additional_certificate_text)
          : " ",
        locale: userData?.locale ?? "en",
        lecturer: `${t("Lecturer")} Mari-Liis Kurg`,
        registryText:
          t(`Complok Ltd (reg. number 16034780) is operating under the Estonian continuing
        education license number 229603`),
        signerAdditionalInformation: t("Founder & CEO of Complok"),
        signedBy: "Mari-Liis Kurg",
        createdBy: t("This course is created by Complok team of AML experts"),
      },
      header.title
    );
  };

  const difference = header?.due_date
    ? dayjs(header.due_date).diff(dayjs(), "d")
    : undefined;
  const daysLeft = difference
    ? dayjs.duration(difference, "days").humanize(true)
    : ""; // 'No duedate set';
  const duedateShort = header?.due_date
    ? dayjs(header.due_date).format("DD. MMM")
    : "No duedate set";

  return (
    <Link
      to={`/courses/${header._id}`}
      className={`complok-coursedirectory-list-item ${itemColor}`}
      title={header.title}
      key={header._id}
    >
      <Title
        level={1}
        className="complok-coursedirectory-list-item-percentage"
        style={{ color: "#fff", fontWeight: 500, fontSize: 72 }}
      >
        {(header?.completed_pct && header?.completed_pct) || 0}%
      </Title>
      <div
        className="complok-coursedirectory-list-item-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text
          type={"secondary"}
          className="complok-coursedirectory-list-item-duedate"
          title={duedateShort}
        >
          {daysLeft}
          {/* {daysLeft} */}
          {/* {'31. Dec'} */}
        </Text>
        <Title level={5} className="complok-coursedirectory-list-item-title">
          {header.title}
        </Title>
        {header.completed_pct === 100 && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              downloadCertificate();
            }}
            style={{
              width: "85%",
              fontWeight: 600,
              color: "#EA9857",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "-0.03em",
              borderWidth: 0,
            }}
            icon={<DownloadOutlined style={{ marginTop: 4 }} />}
            size="large"
          >
            {t("Download certificate")}
          </Button>
        )}
      </div>
    </Link>
  );
};

export default CourseDirectory;
