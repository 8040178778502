import { Button, Col, Input, message, Popconfirm, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import apiClient from "api/apiClient";
import AdminTag from "components/icons/AdminTag";
import StudentTag from "components/icons/StudentTag";
import SuperAdminTag from "components/icons/SuperAdminTag";
import { UserContext } from "context/UserContext";
import { objectToQueryParams } from "helpers";
import { flatten } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWRInfinite from "swr/infinite";
import { IAccount } from "types/AccountTypes";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import UserDetailsDrawer from "../drawers/UserDetailsDrawer";
import { MANAGEMENT_MODALS } from "../modals/management.modals";
import { OrganizationManagementContext } from "../OrganizationManagementContext";

const returnAccountOptionByAccessLevel = (
  access_level: USER_ACCESS_LEVEL | undefined
) => {
  switch (access_level) {
    case USER_ACCESS_LEVEL.SUPER_ADMIN:
      return {
        text: "Super admin",
        icon: <SuperAdminTag />,
      };
    case USER_ACCESS_LEVEL.ADMIN:
      return {
        text: "Admin",
        icon: <AdminTag />,
      };
    case USER_ACCESS_LEVEL.LEARNER:
      return {
        text: "Student",
        icon: <StudentTag />,
      };
    default:
      console.error("Account access level undefined");
      return { text: "Error", icon: null };
  }
};

interface IUsersQuery {
  organization_id: string;
  page_size?: number;
  search?: string;
}

type OrganizationTableProps = {
  organization?: string;
};
const OrganizationUsersTable: FC<OrganizationTableProps> = () => {
  const { t } = useTranslation();
  const { accountData } = useContext(UserContext);
  const { modalAction, organization } = useContext(
    OrganizationManagementContext
  );

  const [paginationQuery, setPaginationQuery] = useState<IUsersQuery>({
    organization_id: organization?._id || "",
    page_size: 120,
  });
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();

  useEffect(() => {
    if (organization) {
      setPaginationQuery((currQuery) => ({
        ...currQuery,
        organization_id: organization._id,
      }));
    }
  }, [organization]);

  const {
    data: paginatedAccounts = [],
    size,
    isValidating: isPaginationLoading,
    mutate: refreshTable,
    setSize,
  } = useSWRInfinite<IAccount[]>(
    (pageNumber) =>
      `organizations/accounts/?page=${pageNumber}&${objectToQueryParams(
        paginationQuery
      )}`,
    {
      initialSize: 2,
    }
  );

  const handlePaginationChange = (page: number) => {
    if (page > size) {
      setSize(page);
    }
  };

  const onDeleteUser = (accountId: string) => {
    apiClient
      .delete(`/accounts/${accountId}`)
      .then(() => {
        message.success(t("User was deleted successfully!"));
        refreshTable();
      })
      .catch((err) => {
        message.error(t("Failed to delete user"));
      });
  };

  const tableColumns: ColumnsType<IAccount> = [
    {
      title: t("Name"),
      dataIndex: ["user", "name"],
      render: (name?: string) => name ?? "-",
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title: t("Email"),
      dataIndex: ["user", "email"],
      render: (email?: string) => email ?? "-",
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title:
        accountData?.access_level === USER_ACCESS_LEVEL.SUPER_ADMIN ? (
          <Button
            onClick={() =>
              modalAction({ type: MANAGEMENT_MODALS.ADD_ADMINISTRATORS })
            }
          >
            {t("Add administrators")}
          </Button>
        ) : (
          t("Role")
        ),
      dataIndex: ["access_level"],
      render: (access_level?: USER_ACCESS_LEVEL) => {
        const { icon, text } = returnAccountOptionByAccessLevel(access_level);
        return (
          <Row
            align="middle"
            className="complok-header-account-selector-option"
          >
            <div
              style={{ marginRight: 5, marginTop: 2 }}
              className="complok-header-account-selector-option-icon"
            >
              {icon}
            </div>
            <div className="complok-header-account-selector-option-text">
              {t(text)}
            </div>
          </Row>
        );
      },
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title: t("Has created password"),
      dataIndex: ["user", "has_created_password"],
      render: (has_created_password: boolean) => (
        <Tag color={has_created_password ? "green" : "orange"}>
          {has_created_password ? t("Yes") : t("No")}
        </Tag>
      ),
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      title: t("Groups"),
      dataIndex: ["groups"],
      render: (groups?: string[]) => (
        <Tag color="green">{groups?.length ?? 0}</Tag>
      ),
      onCell: (account) => ({ onClick: () => setSelectedAccount(account._id) }),
    },
    {
      render: (account) => (
        <Popconfirm
          title={t("Are you sure that you want to delete this user?")}
          okText={t("Delete")}
          onConfirm={() => onDeleteUser(account._id)}
          cancelText={t("No")}
        >
          <Button type="text" danger>
            {t("Delete user")}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Col>
      <Input.Search
        onSearch={(val) => {
          setSize(1);
          setPaginationQuery((x) => ({ ...x, search: val }));
        }}
        placeholder={t("Search users by email or name")}
        style={{ width: 350, marginBottom: 15, marginLeft: 5 }}
      />
      <Table
        pagination={{
          pageSize: 100,
          onChange: handlePaginationChange,
          showSizeChanger: false,
          showQuickJumper: false,
        }}
        scroll={{ y: "57vh" }} // dont change this otherwise smaller screens are not going to see table
        rowKey={"_id"}
        sticky
        columns={tableColumns}
        dataSource={flatten(paginatedAccounts)}
        loading={isPaginationLoading}
      />
      {selectedAccount && (
        <UserDetailsDrawer
          accountId={selectedAccount}
          onClose={() => setSelectedAccount(undefined)}
        />
      )}
    </Col>
  );
};

export default OrganizationUsersTable;
