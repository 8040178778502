import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Select, Spin, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { UserContext } from "context/UserContext";
import dayjs from "dayjs";
import { downloadCourseCertificate } from "features/certificate";
import { getStatus, objectToQueryParams } from "helpers";
import { FC, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Course } from "types/Course/course.types";
import { UserGroup } from "types/GroupTypes";
import { IUser, USER_ACCESS_LEVEL } from "types/UserTypes";
import { OrganizationManagementContext } from "../OrganizationManagementContext";
import UserDetailsDrawer from "./UserDetailsDrawer";

interface ICourseDetailsDrawer {
  selectedCourse?: string;
  groups?: UserGroup[];
  defaultGroup?: string;
  onClose: () => void;
}

interface ICourseStats {
  _id: string;
  access_level: USER_ACCESS_LEVEL;
  completed_pct: number;
  created_at: Date;
  groups: string[];
  organization: string;
  status: string;
  updated_at: Date;
  user: IUser;

  course_completed_at?: Date | string;
}

const CourseDetailsDrawer: FC<ICourseDetailsDrawer> = ({
  defaultGroup,
  selectedCourse,
  groups,
  onClose,
}) => {
  const { userData } = useContext(UserContext);
  const { organization } = useContext(OrganizationManagementContext);
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    defaultGroup || groups?.[0]?._id
  );
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();

  const { data: courseData, error: courseError } = useSWR<Course>(
    selectedCourse ? `/courses/content/${selectedCourse}` : null
  );
  const { data: courseStats, error: courseStatsError } = useSWR<
    ICourseStats[] | undefined
  >(
    (organization?._id &&
      selectedCourse &&
      `courses/progress_stats?${objectToQueryParams({
        organization_id: organization._id,
        course_id: selectedCourse,
        group_id: selectedGroup,
      })}`) ||
      null
  );

  const atleastOneUserHasCompletedCourse = useMemo(() => {
    if (courseStats && courseStats.length > 0) {
      return !!courseStats.find(
        (courseStat) => courseStat.completed_pct === 100
      );
    }
    return false;
  }, [courseStats]);

  const downloadAllCompletedCourseCertificates = () => {
    if (courseStats && courseStats.length > 0) {
      courseStats.forEach((progressWithUser) => {
        if (
          progressWithUser.completed_pct === 100 &&
          progressWithUser?.user?.name &&
          courseData
        ) {
          downloadCourseCertificate(
            {
              participant: progressWithUser.user.name,
              durationText: courseData?.configuration?.suggested_duration
                ? `${t("Course duration:")} ${
                    courseData.configuration.suggested_duration
                  } ${t("hours")}`
                : undefined,
              identifier: progressWithUser._id,
              titles: {
                certificate: t("Certificate").toUpperCase(),
                course: courseData.title,
              },
              date:
                progressWithUser?.course_completed_at ||
                progressWithUser.updated_at,
              hasDoneText: t("has completed"),
              additionalText: courseData?.additional_certificate_text
                ? t(courseData.additional_certificate_text)
                : "",
              locale: userData?.locale || "en",
              lecturer: `${t("Lecturer")} Mari-Liis Kurg`,
              registryText:
                t(`Complok Ltd (reg. number 16034780) is operating under the Estonian continuing
              education license number 229603`),
              signerAdditionalInformation: t("Founder & CEO of Complok"),
              signedBy: "Mari-Liis Kurg",
              createdBy: t(
                "This course is created by Complok team of AML experts"
              ),
            },
            `${progressWithUser.user.name}_${courseData.title}`
          );
        }
      });
    }
  };

  const selectAccount = (stats: ICourseStats) => ({
    onClick: () => {
      setSelectedAccount(stats._id);
    },
  });

  const columns: ColumnsType<ICourseStats> = [
    {
      title: t("Name"),
      key: "name",
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      render: (account: ICourseStats) => account?.user?.name || "-",
      onCell: selectAccount,
    },
    {
      title: t("Email"),
      dataIndex: ["user", "email"],

      render: (email?: string) => email ?? "-",
      onCell: selectAccount,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      filters: [
        {
          text: t("completed").toUpperCase(),
          value: "completed",
        },
        {
          text: t("in progress").toUpperCase(),
          value: "in_progress",
        },
        {
          text: t("not started").toUpperCase(),
          value: "not_started",
        },
      ],
      onFilter: (value, record) =>
        record.status.indexOf(value.toString()) === 0,
      render: (x) => {
        const { color, text } = getStatus(x);
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: t("Completed at"),
      render: (x) =>
        x.course_completed_at
          ? dayjs(x.course_completed_at).format("DD.MM.YYYY")
          : null,
    },
    {
      title: t("Progress"),
      dataIndex: "completed_pct",
      sorter: (a, b) => a.completed_pct - b.completed_pct,
      render: (percentage) => (
        <Tag
          color={percentage === 100 ? "green" : "default"}
        >{`${percentage}%`}</Tag>
      ),
    },
    {
      title: () => (
        <Row align="middle">
          <Col flex={1}>{t("Certificate")}</Col>
          <Col>
            <Button
              onClick={downloadAllCompletedCourseCertificates}
              disabled={!atleastOneUserHasCompletedCourse}
            >
              <DownloadOutlined />
            </Button>
          </Col>
        </Row>
      ),
      dataIndex: "completed_pct",
      render: (percentage) => (
        <Button
          disabled={percentage !== 100}
          title={
            percentage === 100
              ? t("Download certificate")
              : t("User has not completed the course")
          }
        >
          <DownloadOutlined />
        </Button>
      ),
      onCell: (x: ICourseStats) => ({
        onClick: () => {
          if (x.completed_pct === 100 && x?.user?.name && courseData) {
            downloadCourseCertificate(
              {
                participant: x.user.name,
                durationText: courseData?.configuration?.suggested_duration
                  ? `${t("Course duration:")} ${
                      courseData.configuration.suggested_duration
                    } ${t("hours")}`
                  : undefined,
                identifier: x._id,
                titles: {
                  certificate: t("Certificate").toUpperCase(),
                  course: courseData.title,
                },
                date: x?.course_completed_at || x?.updated_at,
                hasDoneText: t("has completed"),
                additionalText: courseData?.additional_certificate_text
                  ? t(courseData.additional_certificate_text)
                  : "",
                locale: userData?.locale || "en",
                lecturer: `${t("Lecturer")} Mari-Liis Kurg`,
                registryText:
                  t(`Complok Ltd (reg. number 16034780) is operating under the Estonian continuing
                education license number 229603`),
                signerAdditionalInformation: t("Founder & CEO of Complok"),
                signedBy: "Mari-Liis Kurg",
                createdBy: t(
                  "This course is created by Complok team of AML experts"
                ),
              },
              `${x.user.name}_${courseData.title}`
            );
          }
        },
      }),
    },
  ];

  return (
    <Drawer
      onClose={onClose}
      width={selectedAccount ? "80%" : "65%"}
      visible={!!selectedCourse}
      title={courseData?.title || t("Loading...")}
    >
      <Spin spinning={!courseData && !courseStats}>
        {/* TODO: Download all group certificates currently not working */}

        <Row
          style={{ alignItems: "center", height: 50 }}
          hidden={!groups?.length}
        >
          <Col flex={1}>
            <Title level={5}>{t("Progress by group")}</Title>
          </Col>
          <Col>
            <Select
              style={{ minWidth: 200 }}
              onSelect={(val: string) => setSelectedGroup(val)}
              defaultValue={selectedGroup}
              options={
                (groups &&
                  groups?.map((group) => ({
                    label: group.title,
                    value: group._id,
                  }))) ||
                []
              }
            />
          </Col>
        </Row>
        <Table
          pagination={{ defaultPageSize: 30 }}
          dataSource={courseStats || []}
          columns={columns}
          loading={!courseStats && !courseStatsError}
        />
      </Spin>
      <UserDetailsDrawer
        onClose={() => setSelectedAccount(undefined)}
        accountId={selectedAccount}
      />
    </Drawer>
  );
};

export default CourseDetailsDrawer;
