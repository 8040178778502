import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#prefix__clip0_2156:35859)">
      <rect width={30} height={30} rx={15} fill="#fff" />
      <path d="M40.596-4H-16v38h56.596V-4z" fill="#F5F7FA" />
      <path d="M40.596-4H-16V8.667h56.596V-4z" fill="#3BAFDA" />
      <path d="M40.596 8.667H-16v12.666h56.596V8.667z" fill="#434A54" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_2156:35859">
        <rect width={30} height={30} rx={15} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
