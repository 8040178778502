/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const RoundAddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={15} cy={15} r={15} fill="#B5B5BE" />
    <path d="M9.667 15.088h10.842m-5.421 5.42V9.668" stroke="#fff" strokeWidth={1.333} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default RoundAddIcon;
