/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const CheckedCheckBox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={30} height={30} rx={5} fill="#52C41A" />
    <path d="M11 14.5l3 3.5 5-6" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CheckedCheckBox;
