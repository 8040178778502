import { Spin, Steps } from 'antd';
import apiClient from 'api/apiClient';
import { createContext, FC, useState } from 'react';
import useSWR from 'swr';
import { Course } from 'types/Course/course.types';
import CourseEditAccess from './CourseEditAccess';
import CourseEditContent from './CourseEditContent';
import CourseEditDescription from './CourseEditDescription';
import CourseEditPublish from './CourseEditPublish';
import CourseEditSettings from './CourseEditSettings';

type UpdateCourseConfigurationProps = {
  duedate?: number;
  skipping?: boolean;
  locale?: string;
  suggested_duration?: number;
};
type UpdateCourseConfiguration = (props?: UpdateCourseConfigurationProps) => void;
interface CourseEditContextProps {
  course?: Course;
  currentStep: COURSE_EDIT_STEPS;

  refreshCourse: () => void;
  updateCourseConfiguration: UpdateCourseConfiguration;
}

enum COURSE_EDIT_STEPS {
  DESCRIPTION,
  CONTENTS,
  SETTINGS,
  MANAGE_ACCESS,
  PUBLISH
}

export const CourseEditContext = createContext<CourseEditContextProps>({
  currentStep: COURSE_EDIT_STEPS.DESCRIPTION,

  refreshCourse: () => {},
  updateCourseConfiguration: () => {}
});

export const CourseEdit: FC<{ courseId?: string }> = ({ courseId }) => {
  const { data: course, mutate: refreshCourse } = useSWR<Course>(courseId ? `/courses/content/${courseId}` : null);
  const [currentStep, setCurrentStep] = useState<COURSE_EDIT_STEPS>(COURSE_EDIT_STEPS.DESCRIPTION);

  const updateCourseConfiguration: UpdateCourseConfiguration = async (configUpdates) => {
    if (!configUpdates || !course) return;

    const coursePreviousConfiguration = course?.configuration ?? {};
    if ((coursePreviousConfiguration as any)?.module_skipping !== undefined) {
      delete (coursePreviousConfiguration as any).module_skipping;
    }

    const newConfiguration = {
      ...(course?.configuration ?? {}),
      ...(configUpdates?.skipping !== undefined && { skipping: configUpdates.skipping }),
      ...(configUpdates?.locale !== undefined && { locale: configUpdates.locale }),
      ...(configUpdates?.duedate !== undefined && { duedate: configUpdates.duedate }),
      ...(configUpdates?.suggested_duration !== undefined && { suggested_duration: configUpdates.suggested_duration })
    };
    await apiClient.patch('/courses', { _id: course._id, configuration: newConfiguration }).catch(() => null);
    refreshCourse();
  };

  const contextValues = { course, currentStep };
  const contextFunctions = { updateCourseConfiguration, refreshCourse };

  return (
    <CourseEditContext.Provider value={{ ...contextValues, ...contextFunctions }}>
      <div id="complok-course-edit">
        {/* Content */}
        <div id="complok-course-edit-content">{renderCourseEditContent(currentStep, course)}</div>
        {/* Steps */}
        <div id="complok-course-edit-steps">
          <Steps current={currentStep} onChange={(key) => setCurrentStep(key)} size={'small'} progressDot>
            <Steps.Step title="Description" key={COURSE_EDIT_STEPS.DESCRIPTION} />
            <Steps.Step title="Contents" key={COURSE_EDIT_STEPS.CONTENTS} />
            <Steps.Step title="Settings" key={COURSE_EDIT_STEPS.SETTINGS} />
            <Steps.Step title="Users & Teams" key={COURSE_EDIT_STEPS.MANAGE_ACCESS} />
            <Steps.Step title="Publish" key={COURSE_EDIT_STEPS.PUBLISH} />
          </Steps>
        </div>
      </div>
    </CourseEditContext.Provider>
  );
};

const renderCourseEditContent = (currentStep: COURSE_EDIT_STEPS, course?: Course) => {
  if (!course) return <Spin spinning={true} />;
  switch (currentStep) {
    case COURSE_EDIT_STEPS.DESCRIPTION:
      return <CourseEditDescription />;
    case COURSE_EDIT_STEPS.CONTENTS:
      return <CourseEditContent />;
    case COURSE_EDIT_STEPS.SETTINGS:
      return <CourseEditSettings />;
    case COURSE_EDIT_STEPS.MANAGE_ACCESS:
      return <CourseEditAccess />;
    case COURSE_EDIT_STEPS.PUBLISH:
      return <CourseEditPublish />;
    default:
      return <Spin spinning={true} />;
  }
};
