import { Button, Col, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserGroup } from "types/GroupTypes";

interface GroupTableProps {
  groups: UserGroup[];
  selectGroup?: (groupId: string) => void;
  onCreateGroup?: () => void;
  onAddUsers?: (groupId: string) => void;
  loading: boolean;
  onDeleteGroup?: (groupId: string) => void;
}
const GroupTable: FC<GroupTableProps> = ({
  groups,
  selectGroup = (group) => console.log("Selected group", group),
  onAddUsers,
  onCreateGroup,
  onDeleteGroup,
  loading,
}) => {
  const { t } = useTranslation();

  const columns: ColumnsType<UserGroup> = [
    {
      title: t("Name"),
      dataIndex: "title",
      onCell: (group) => ({ onClick: () => selectGroup(group._id) }),
    },
    {
      title: t("Users"),
      dataIndex: "accounts",
      render: (accounts: string[]) => accounts?.length || 0,
      onCell: (group) => ({ onClick: () => selectGroup(group._id) }),
    },
    {
      title: () => (
        <Button onClick={() => onCreateGroup && onCreateGroup()}>
          {t("Create group")}
        </Button>
      ),
      align: "right",
      fixed: "right",
      render: (group) => (
        <>
          <Button
            type={"link"}
            onClick={() => onAddUsers && onAddUsers(group._id)}
          >
            {t("Add users")}
          </Button>
          <Button
            danger
            onClick={() => onDeleteGroup && onDeleteGroup(group._id)}
            type={"text"}
          >
            {t("Delete Group")}
          </Button>
        </>
      ),
    },
  ];

  return (
    <Col>
      <Table
        rowKey={"_id"}
        loading={loading && !groups.length}
        columns={columns}
        dataSource={groups}
        scroll={{ y: "70vh" }}
        pagination={{ pageSize: 100 }}
        id="complok-groupmanagement-groups"
      />
    </Col>
  );
};

export default GroupTable;
