import { getCourseHeaders } from 'api/apiRoutes';
import { UserContext } from 'context/UserContext';
import { createContext, FC, useContext, useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { ICourseHeader } from 'types/Course/CourseTypes';

export interface ILearnerContext {
  currentCourseProgress: any;
  headerNote?: string;

  setHeaderNote: (note?: string | undefined) => void;

  courseHeaders: ICourseHeader[] | undefined;
  loadingCourseHeaders: boolean;
}

export const LearnerContext = createContext<ILearnerContext>({
  currentCourseProgress: {},
  courseHeaders: [],
  loadingCourseHeaders: true,

  setHeaderNote: () => {},
});
export const LearnerContextProvider: FC = ({ children }) => {
  const { accountData } = useContext(UserContext);

  const { courseIdentifier } = useParams();
  const { data: courseHeaders, error: dataCourseError } = useSWR(
    accountData ? getCourseHeaders(accountData._id) : null,
    {
      refreshInterval: 60 * 1000,
    }
  );
  // Needs correct type - as currently, on error, data is displayed as an error object
  const { data: currentCourseProgress } = useSWR(
    courseIdentifier && accountData?._id ? `/progresses/${accountData._id}/${courseIdentifier}/continue` : null
  );

  const [headerNote, setHeaderNote] = useState<string | undefined>();

  const contextValues = {
    headerNote,

    currentCourseProgress,
    courseHeaders,
    loadingCourseHeaders: !courseHeaders && !dataCourseError,
  };
  const contextFunctions = { setHeaderNote };
  return (
    <LearnerContext.Provider value={{ ...contextValues, ...contextFunctions }}>{children}</LearnerContext.Provider>
  );
};

export default LearnerContextProvider;
