/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SVGProps } from 'react';

const ComplokIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 0C23.6463 0 30 6.29717 30 15C30 23.7028 23.6463 30 15 30C14.8035 30 14.607 29.9646 14.4105 29.9646V22.1108C14.607 22.1462 14.8035 22.1816 15 22.1816C18.3079 22.1816 20.9607 19.4575 20.9607 15C20.9607 10.5425 18.3079 7.8184 15 7.8184C11.6921 7.8184 9.0393 10.5425 9.0393 15C9.0393 15.8137 9.13756 16.5212 9.30131 17.1934H0.163756C0.0655022 16.4858 0 15.7429 0 15C0 6.33255 6.35371 0 15 0Z"
      fill="#F99820"
    />
  </svg>
);

export default ComplokIcon;
