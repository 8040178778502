import React, { FC } from 'react';
import { Course } from 'types/Course/course.types';
import ModuleCard from '../cards/ModuleCard';
import './courseContent.scss';

const SuperAdminCourseContents: FC<{ course: Course }> = ({ course }) => (
  <div className="course-content-list">
    <label className="course-content-header">Course content</label>
    <div className="course-content-list-content">
      {course.ordered_modules.map((module) => (
        <ModuleCard key={module._id} module={module} />
      ))}
      {/* TODO: REFACTO */}
      {/*
      {(course?.final_quiz && (
        <div className="module-list-item" style={{ padding: 15 }}>
          <div className="module-list-item-header-title">
            {course?.final_quiz?.title}
          </div>
        </div>
      )) ||
        null}
        */}
    </div>
  </div>
);

export default SuperAdminCourseContents;
