/* eslint-disable operator-linebreak */
import { Button, Form, Input, message, Modal, Select, Table, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import apiClient from "api/apiClient";
import { UserContext } from "context/UserContext";
import { useDebounce } from "helpers/hooks";
import { isObject, keys } from "lodash";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { AccountBase } from "types/AccountTypes";
import { DetailedUserGroup, IGroup } from "types/GroupTypes";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import { OrganizationManagementContext } from "../OrganizationManagementContext";

const { TabPane: Tab } = Tabs;

interface AddUserDataList {
  name?: string;
  email?: string;
  count: number;
}
interface AddUsersToGroupModalProps {
  group: DetailedUserGroup | undefined;

  showModal?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
}
const AddUsersToGroupModal: FC<AddUsersToGroupModalProps> = ({
  group,
  showModal,
  onSuccess,
  onCancel,
}) => {
  const { accountData } = useContext(UserContext);
  const { organization } = useContext(OrganizationManagementContext);

  const { t } = useTranslation();
  const title =
    t("Add ") +
    (group?.title ? `${t("Learners to")} ${group.title}` : t("new users"));
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [cellCounter, setCellCounter] = useState<number>(1);
  const [usersToAdd, setUsersToAdd] = useState<AddUserDataList[]>([
    { count: 0 },
  ]);
  const [selectedAccounts, setSelectedAccounts] = useState<
    string[] | undefined
  >();
  const [selectedTab, setSelectedTab] = useState<string>("add_new");

  const [form] = Form.useForm<AddUserDataList>();

  const debouncedSearch = useDebounce(search, 500);

  const { data: matchingAccounts } = useSWR<AccountBase[]>(
    organization?._id && debouncedSearch.length > 2
      ? `organizations/accounts/?organization_id=${organization._id}&search=${debouncedSearch}`
      : null
  );

  const addNewUserRow = () => {
    setUsersToAdd([...usersToAdd, { count: cellCounter }]);
    setCellCounter(cellCounter + 1);
  };
  const removeUserFromUsersToAdd = (count: number) => {
    setUsersToAdd(usersToAdd.filter((user) => user.count !== count));
  };

  const submitAddUsersToGroup = async () => {
    setLoading(true);

    if (selectedTab === "add_new") {
      const allFieldsValid = await form
        .validateFields()
        .catch((reason) => null);
      if (allFieldsValid) {
        const users = Object.values(allFieldsValid ?? {})
          .filter((user) => isObject(user))
          .map(
            (
              user: Omit<
                AddUserDataList & { access_level?: USER_ACCESS_LEVEL },
                "count"
              >
            ) => {
              user.access_level = USER_ACCESS_LEVEL.LEARNER;
              return user;
            }
          );
        if (users) {
          const createdUsers = await apiClient
            .post("/users/create", {
              users,
              organization: group?.organization,
              group_id: group?._id,
            })
            .catch((err) => {
              if (
                err?.response?.data?.failedFieldsWithMapping &&
                err?.response?.data?.column_name
              ) {
                const { failedFieldsWithMapping, column_name } =
                  err?.response?.data ?? {};
                keys(failedFieldsWithMapping).forEach((index) => {
                  form.setFields([
                    {
                      name: [parseInt(index), column_name],
                      errors: [failedFieldsWithMapping[index]],
                    },
                  ]);
                });
              }
              return null;
            });
          if (createdUsers) {
            if (onSuccess) {
              onSuccess();
              form.resetFields();
            }
          }
        }
      }
    } else if (
      group &&
      selectedAccounts &&
      selectedAccounts.length &&
      accountData
    ) {
      const didAdd = await apiClient
        .put(`groups/${group._id}/add_accounts/`, {
          accounts: selectedAccounts,
          organization: organization?._id,
        })
        .then(() => true)
        .catch((err) => {
          message.error(t("Failed to add existing users to group", err));
          return false;
        });

      if (didAdd) {
        if (onSuccess) onSuccess();
      }
    }
    setLoading(false);
  };

  const columns: ColumnsType<{ email?: string; name?: string }> = [
    {
      title: t("Name"),
      render: (user) => (
        <Form.Item
          rules={[{ required: true, message: t("Please enter name!") }]}
          name={[user.count, "name"]}
          required
        >
          <Input placeholder="John Smith" />
        </Form.Item>
      ),
    },
    {
      title: t("Email"),
      render: (user) => (
        <Form.Item
          rules={[
            { required: true, message: t("Please enter email!") },
            { type: "email", message: t("Email incorrect") },
          ]}
          name={[user.count, "email"]}
          required
        >
          <Input placeholder="name@complok.com" />
        </Form.Item>
      ),
    },
    {
      title: () => <Button onClick={addNewUserRow}>{t("Add user")}</Button>,
      align: "right",
      render: (user) => (
        <Form.Item>
          <Button
            type={"text"}
            danger
            onClick={() => removeUserFromUsersToAdd(user.count)}
          >
            {t("Remove")}
          </Button>
        </Form.Item>
      ),
    },
  ];

  const accountIsLearner = (account: AccountBase) => {
    if (account.access_level === USER_ACCESS_LEVEL.LEARNER) {
      return true;
    }
    return false;
  };

  const accountIsInGroup = (account: AccountBase, compareGroup: IGroup) => {
    const accountGroups = account?.groups ?? [];
    if (
      accountGroups.filter(
        (accountGroupId) => accountGroupId === compareGroup._id
      ).length
    ) {
      return true;
    }
    return false;
  };

  const foundLearnerAccounts =
    (group &&
      matchingAccounts?.filter(
        (foundAccount) =>
          accountIsLearner(foundAccount) &&
          !accountIsInGroup(foundAccount, group)
      )) ??
    [];
  const foundLearnerAccountSelection = foundLearnerAccounts.map(
    (account: AccountBase) => ({
      label: (
        <div>
          <div>{account.user.email}</div>
          <div>{account.user.name}</div>
        </div>
      ),
      value: account._id,
    })
  );

  return (
    <Modal
      confirmLoading={loading}
      width={"700px"}
      title={title}
      visible={showModal}
      onOk={submitAddUsersToGroup}
      okText={"Save"}
      okButtonProps={{ htmlType: "submit", disabled: usersToAdd.length === 0 }}
      style={{ width: 700 }}
      onCancel={() => {
        onCancel && onCancel();
        form.resetFields();
      }}
      destroyOnClose
    >
      <Form component={false} form={form}>
        <Tabs
          defaultActiveKey="add_new"
          onChange={(val) => setSelectedTab(val)}
        >
          <Tab key="add_new" tab={t("Create new users")}>
            <Table
              columns={columns}
              dataSource={usersToAdd}
              pagination={false}
              rowKey={(key, index) => `${key.email}-${key.name}-${index}`}
            />
          </Tab>
          <Tab key="add_existing" tab={t("Add existing users")}>
            <Select
              onChange={(vals: string[]) => setSelectedAccounts(vals)}
              filterOption={false}
              allowClear
              mode="multiple"
              options={foundLearnerAccountSelection}
              onSearch={(val) => setSearch(val)}
              showSearch
              style={{ width: "100%" }}
            />
          </Tab>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default AddUsersToGroupModal;
