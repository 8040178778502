import { Form, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import apiClient from 'api/apiClient';
import { UserContext } from 'context/UserContext';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ISuperAdminCourseHeader } from 'types/Course/CourseTypes';

interface CreateNewOrganizationModalProps {
  showModal?: unknown;
  onCancel?: () => void;

  onSuccess?: () => void;
}
const CreateOrganizationModal: FC<CreateNewOrganizationModalProps> = ({
  showModal,
  onCancel = () => {},
  onSuccess
}) => {
  const { accountData } = useContext(UserContext);
  const { data: courseHeaders } = useSWR<ISuperAdminCourseHeader[]>(
    accountData?._id ? `/courses/by_account/${accountData._id}` : null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();

  const { t } = useTranslation();

  const submitCreateOrganization = async ({ title, courses }: { title?: string; courses?: string[] }) => {
    if (!title) return;
    setIsLoading(true);

    const organizationCreation = await apiClient
      .post('/organizations', { title, courses: courses ?? [] })
      .catch((reason) => null);
    setIsLoading(false);
    if (!organizationCreation) {
      message.error(t("Couldn't create organization"));
      return;
    }
    message.success(t('Organization created'));
    form.resetFields();
    if (onSuccess) onSuccess();
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={t('Create a new organization')}
      visible={!!showModal}
      onOk={form.submit}
      confirmLoading={isLoading}
      okText={t('Create')}
      okButtonProps={{ disabled: false }}
      onCancel={onClose}
      destroyOnClose
    >
      <Form form={form} onFinish={submitCreateOrganization} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: t('Please enter organization name') }]}
          label={t('Organization name')}
          name={'title'}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Courses')} name="courses">
          <Select
            mode={'multiple'}
            style={{ width: '100%' }}
            // onChange={(checkedCourses) => setCoursesToAdd(checkedCourses ?? [])}
            notFoundContent={t('No new courses available')}
            defaultValue={[]}
            options={courseHeaders?.map((courseHeader) => ({ label: courseHeader.title, value: courseHeader._id }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrganizationModal;
