import { t } from 'i18next';
import { concat, entries } from 'lodash';

/* eslint-disable import/prefer-default-export */
export const formatSecondsToMinutesWithSeconds = (seconds: number): string => {
  try {
    if (seconds > 3600) {
      return new Date(seconds * 1000).toISOString().substr(12, 7);
    }
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  } catch (error) {
    return '00:00';
  }
};

export const getInitials = (string: string) => {
  const names = string.split(' ');

  const firstnameInitial = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    const lastnameInitial = names[names.length - 1].substring(0, 1).toUpperCase();
    return concat([firstnameInitial, lastnameInitial]);
  }
  return firstnameInitial;
};

export const objectToQueryParams = (object: { [key: string]: any }): string => {
  const searchParams = new URLSearchParams();

  entries(object).map(
    (keyValuePair) =>
      keyValuePair.length > 1 && keyValuePair[1] && searchParams.append(keyValuePair[0], keyValuePair[1])
  );

  return `${searchParams}`;
};

export const getStatus = (status: string) => {
  switch (status) {
    case "completed":
      return { text: t("completed").toUpperCase(), color: "green" };

    case "in_progress":
      return { text: t("in progress").toUpperCase(), color: "yellow" };
    default:
      return { text: t("not started").toUpperCase(), color: "orange" };
  }
};