import { UserOutlined } from '@ant-design/icons';
import { Col, Form, Input, message, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import apiClient from 'api/apiClient';
import AuthenticationHeader from 'components/header/AuthenticationHeader';
import Button from 'components/interactables/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.scss';

const { Title } = Typography;

const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [sendingPasswordLink, setSendingPasswordLink] = useState<boolean>(false);

  const { t } = useTranslation();

  const onFinish = (values: { email: string }) => {
    const { email } = values;
    if (email) {
      setSendingPasswordLink(true);
      apiClient
        .patch('users/reset_password/', { email })
        .then((response) => {
          if (response?.data) {
            message.success('Password has been sent');
            setSendingPasswordLink(false);
          }
        })
        .catch((err) => {
          message.error(err?.response?.data?.msg || 'Failed to send password link!');
          setSendingPasswordLink(false);
        });
    }
  };

  return (
    <div className="forgot-password-view">
      <AuthenticationHeader />
      <div className="forgot-password-view-container">
        <Title style={{ textAlign: 'center', margin: '40px 0px' }}>{t('Forgot password')}</Title>
        <Form size="large" onFinish={onFinish} form={form} className="forgot-password-view-container-form">
          <Form.Item name="email" rules={[{ required: true, message: t('Please enter email!') }]}>
            <Input className="login-view-container-form-input" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="e-mail" />
          </Form.Item>
          <Row style={{ alignItems: 'center', marginTop: 10 }}>
            <Col flex={12}>
              <Button
                style={{ borderRadius: 50, width: 150, height: 44 }}
                loading={sendingPasswordLink}
                onClick={() => form.submit()}
                type="primary"
                className="login-form-button"
              >
                {t('Send reset link')}
              </Button>
            </Col>
            <Col flex="auto">
              <div className="login-view-container-form-forgot-password" onClick={() => navigate('/')}>
                {t('Back to log in')}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
