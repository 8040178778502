import { isNumber, values } from 'lodash';

export enum MANAGEMENT_MODALS {
  NONE,
  ADD_USERS,
  CREATE_GROUP,
  ASSIGN_COURSES,
  ADD_USERS_FROM_FILE,
  EDIT_ORGANIZATION,
  CREATE_ORGANIZATION,
  ADD_ADMINISTRATORS
}
export type ManagementModal =
  | {
      type?: MANAGEMENT_MODALS;
      data?: any;
    }
  | undefined;

export type ManagementModalReducer = {
  (state: ManagementModal | undefined, action: ManagementModal | undefined): ManagementModal;
};

export const managementModalReducer: ManagementModalReducer = (state, { type, data } = {}) => {
  if (type === undefined || !isNumber(type)) return undefined;

  if (values(MANAGEMENT_MODALS).includes(type)) {
    return {
      type,
      data
    };
  }
  return undefined;
};
