import { Select, Row } from 'antd';
import apiClient from 'api/apiClient';
import EnglishFlag from 'components/icons/EnglishFlag';
import EstonianFlag from 'components/icons/EstonianFlag';
import { UserContext } from 'context/UserContext';
import i18n from 'i18n';
import { FC, useContext, useState } from 'react';

interface ILanguagePicker {}

const LanguagePicker: FC<ILanguagePicker> = () => {
  const [fallbackLang, setFallbackLang] = useState<string | undefined>();
  const { userData, setUserData } = useContext(UserContext);
  return (
    <span id="complok-header-language-selector">
      <Select
        onSelect={async (val: string) => {
          if (userData) {
            // update user locale
            const response = await apiClient
              .patch('users/edit_details', { _id: userData._id, locale: val })
              .then((res) => res?.data ?? null)
              .catch(() => null);

            if (response) {
              setUserData(response);
            } else {
              setFallbackLang(val);
              // fallback when updating user fails, then just change i18m value
              i18n.changeLanguage(val);
            }
          }
        }}
        className="complok-header-selector"
        value={fallbackLang || userData?.locale || 'en'}
        defaultValue={userData?.locale || 'en'}
        style={{ width: 100, marginRight: 11 }}
      >
        <Select.Option key="en" value="en">
          <Row align="middle">
            <EnglishFlag style={{ marginRight: 10 }} />
            <div className="complok-header-selector-option-text">ENG</div>
          </Row>
        </Select.Option>
        <Select.Option key="et" value="et">
          <Row align="middle">
            <EstonianFlag style={{ marginRight: 10 }} />
            <div className="complok-header-selector-option-text">EST</div>
          </Row>
        </Select.Option>
      </Select>
    </span>
  );
};

export default LanguagePicker;
