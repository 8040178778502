import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import apiClient from 'api/apiClient';
import axios from 'axios';
import EditableCourseContents from 'components/data/CourseContent/EditableCourseContents';
import { FC, useContext, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { ICourseVideo } from 'types/Course/CourseContent/course.content.video.types';
import { isCourseSubjectVideo } from 'types/Course/CourseValidators';
import EmptyContent from '../CreateCourseScreen/steps/content/components/EmptyContent';
import TestContent from '../CreateCourseScreen/steps/content/components/TestContent';
import VideoContent from '../CreateCourseScreen/steps/content/components/VideoContext';
import { CourseEditContext } from './CourseEdit';

const CourseEditContent: FC = () => {
  const { course } = useContext(CourseEditContext);

  const [selectedSubject, setSelectedSubject] = useState<ICourseSubject | undefined>(
    course?.ordered_modules?.[0]?.ordered_subjects?.[0] ?? undefined
  );
  const [selectedFile, setSelectedFile] = useState<undefined | RcFile>(undefined);
  const [filePath, setFilePath] = useState<undefined | string>();
  const [uploadingVideo, setUploadingVideo] = useState<boolean>(false);

  useEffect(() => {
    if (isCourseSubjectVideo(selectedSubject?.content_type)) {
      setFilePath((selectedSubject?.content as ICourseVideo)?.key || undefined);
    }
  }, [selectedSubject]);

  const { data: videoUrl } = useSWR<string | undefined>(filePath ? `/fileuploads/video/${filePath}` : null);

  const uploadVideo = async () => {
    if (!course) return;
    if (selectedFile && selectedSubject) {
      if (selectedFile.type !== 'video/mp4') {
        message.error('Invalid file type ');
        return;
      }

      setUploadingVideo(true);

      // get presigned s3 upload url
      const response = await apiClient.get(`fileuploads/signed_video_upload/${course._id}`).catch(() => null);

      if (response) {
        const { url, filePath: responseFilePath } = response.data;

        const s3Response = await axios
          .request({
            method: 'PUT',
            url,
            data: selectedFile,
            headers: {
              'Content-Type': selectedFile.type
            }
          })
          .catch((err) => {
            console.log('error: ', err);
            return null;
          });

        if (s3Response) {
          const addVideoToSubject = await apiClient
            .patch('/subjects', {
              _id: selectedSubject._id,
              module: selectedSubject.module,
              course: course._id,
              content_type: 'video',
              content_data: { title: 'VIDEO', key: responseFilePath }
            })
            .catch((err) => {
              console.log('error while adding subject', err);
              return null;
            });

          if (addVideoToSubject) {
            const newSubjectResponse = await apiClient.get(`subjects/${addVideoToSubject.data._id}`).catch(() => null);

            if (newSubjectResponse) {
              setSelectedSubject(newSubjectResponse.data);
              mutate(`/courses/content/${course._id}`);

              message.success('Video was uploaded successfully');
            }
          }
        }
      }
    }
    setUploadingVideo(false);
  };

  const createQuiz = async () => {
    if (!course) return;
    if (selectedSubject) {
      const response = await apiClient
        .patch('/subjects', {
          _id: selectedSubject._id,
          module: selectedSubject.module,
          course: selectedSubject.course,
          content_type: 'quiz',
          content_data: { title: selectedSubject.title }
        })
        .catch(() => null);

      if (response) {
        const newSubjectResponse = await apiClient.get(`subjects/${response.data._id}`).catch(() => null);
        if (newSubjectResponse) {
          setSelectedSubject(newSubjectResponse.data);
          mutate(`/courses/content/${course._id}`);
        }
      }
    }
  };

  const createExam = async () => {
    if (!course) return;
    if (selectedSubject) {
      const response = await apiClient
        .patch('/subjects', {
          _id: selectedSubject._id,
          module: selectedSubject.module,
          course: selectedSubject.course,
          content_type: 'exam',
          content_data: { title: selectedSubject.title }
        })
        .catch(() => null);

      if (response) {
        const newSubjectResponse = await apiClient.get(`subjects/${response.data._id}`).catch(() => null);
        if (newSubjectResponse) {
          setSelectedSubject(newSubjectResponse.data);
          mutate(`/courses/content/${course._id}`);
        }
      }
    }
  };

  const renderContent = () => {
    if (!selectedSubject) {
      return null;
    }

    // render video
    if (selectedSubject.content_type === 'video' && course) {
      return <VideoContent course={course} selectedSubject={selectedSubject} videoUrl={videoUrl} />;
    }

    // render quiz
    if (selectedSubject.content_type === 'quiz') {
      return <TestContent setSelectedSubject={setSelectedSubject} selectedSubject={selectedSubject} />;
    }

    if (selectedSubject.content_type === 'exam') {
      return <TestContent setSelectedSubject={setSelectedSubject} selectedSubject={selectedSubject} isExam />;
    }

    // render option to create video or quiz
    if (!selectedSubject.content_type) {
      return (
        <EmptyContent
          uploadVideo={uploadVideo}
          createExam={createExam}
          createQuiz={createQuiz}
          uploadingVideo={uploadingVideo}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      );
    }
    return null;
  };

  if (!course) return null;

  return (
    <div className="super-admin-create-course-content-content">
      <div className="super-admin-create-course-content-content-container">{renderContent()}</div>
      <EditableCourseContents
        course={course}
        updateSelectedSubject={(sub) => {
          console.info('click', sub);
          setSelectedSubject(sub);
        }}
        selectedSubject={selectedSubject}
      />
    </div>
  );
};

export default CourseEditContent;
