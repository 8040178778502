import { Button, Col, Form, Input, message, Modal, Popover, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import apiClient from 'api/apiClient';
import ComplokIconFull from 'assets/illustrations/ComplokIconFull';
import ComplokLock from 'assets/illustrations/ComplokLock';
import ComplokWalkingMobile from 'assets/illustrations/ComplokWalkingMobile';
import ComplokIcon from 'components/icons/ComplokIcon';
import WalkingComplokIcon from 'components/icons/WalkingComplokIcon';
import { SettingsContext } from 'context/SettingsContext';
import { UserContext } from 'context/UserContext';
import i18n from 'i18n';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './login.scss';

const { Title } = Typography;

const LANGUAGE_LABELS: { [key: string]: string } = {
  et: 'Eesti',
  en: 'English'
};

const LoginView = () => {
  const { setUserData, setAccountData } = useContext(UserContext);

  const language = i18n.language || 'en';

  const [form] = useForm();
  const [loggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showFactorAuth, setShowFactorAuth] = useState<boolean>(false);
  const isSmallScreen = useBreakpoint().md === false;

  const onFinish = async (values: { email: string; password: string; code?: string }) => {
    const { email, password, code } = values;
    if (email && password) {
      setLoggingIn(true);

      console.info('Sending login check to', process.env.REACT_APP_API)

      const userAuthenticationFactorIdentifier = localStorage.getItem(btoa(email));

      const loginResponse = await apiClient
        .post('/users/login', {
          email,
          password,
          code,
          ...(userAuthenticationFactorIdentifier && { factor_identifier: userAuthenticationFactorIdentifier })
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setShowFactorAuth(true);
          }
          if (typeof err?.response?.data === 'string') {
            message.error(t(err?.response?.data ?? 'Failed to log in'));
          } else {
            message.error(t('Failed to log in'));
          }
          setLoggingIn(false);
        });

      if (loginResponse?.data && loginResponse?.status === 202) {
        setShowFactorAuth(true);
        setLoggingIn(false);
      }
      if (loginResponse?.data?.user) {
        const { token, user, account, factor_identifier } = loginResponse.data;

        apiClient.setAccount(account);
        apiClient.setAccessToken(token);
        localStorage.setItem('user', user);
        localStorage.setItem('token', token);
        localStorage.setItem('account', account);
        if (factor_identifier) localStorage.setItem(btoa(email), factor_identifier);

        apiClient
          .get(`/accounts/by_account/${account}`)
          .then((accountResponse) => {
            setAccountData(accountResponse.data);
            setUserData(accountResponse.data.user);
            setLoggingIn(false);
          })
          .catch((err) => {
            console.log('error: ', err);

            setLoggingIn(false);
          });
      }
    } else {
      message.error(t('Please enter email and password!'));
    }
  };

  return (
    <div className={isSmallScreen ? 'login-view-mobile' : 'login-view'}>
      <Row className="login-view-header">
        <Col flex={1}>
          <a href="https://elearning.complok.eu">{isSmallScreen ? <ComplokIcon /> : <ComplokIconFull />}</a>
        </Col>
        <Col>
          <Popover trigger={'hover'} placement={'bottomRight'} content={() => <LanguagePopoverContent />}>
            <span className="login-view-header-langauge-container">
              <p className="login-view-header-langauge-prefix">{`${t('Language')}`}</p>
              <p className="login-view-header-langauge-value">{language && LANGUAGE_LABELS[language]}</p>
            </span>
          </Popover>
        </Col>
      </Row>
      <Row style={{ height: '100%', width: '100%' }}>
        <Col className={isSmallScreen ? 'login-view-mobile-icon-container' : 'login-view-icon-container'}>
          {isSmallScreen ? <ComplokWalkingMobile style={{ width: 205, height: 200 }} /> : <WalkingComplokIcon />}
        </Col>
        <Col className={isSmallScreen ? 'login-view-mobile-form' : 'login-view-form'}>
          <Form
            size="large"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            style={{ padding: 20 }}
            requiredMark={false}
          >
            <Title
              style={{ width: '100%', margin: '20px 0px', textAlign: isSmallScreen ? 'center' : 'start' }}
              level={2}
            >
              {t('Welcome to All-In-One Online AML Training Centre!')}
            </Title>

            <Form.Item
              name="email"
              label={<div style={{ fontWeight: 600 }}>{t('Email')}</div>}
              rules={[{ required: true, message: t('Please enter email!') }]}
            >
              <Input className="login-view-form-input" placeholder="john@complok.eu" />
            </Form.Item>

            <Form.Item
              name="password"
              label={<div style={{ fontWeight: 600 }}>{t('Password')}</div>}
              rules={[{ required: true, message: t('Please enter password!') }]}
            >
              <Input.Password
                className="login-view-form-input"
                placeholder="password"
                onPressEnter={() => form.submit()}
              />
            </Form.Item>
            <Modal
              visible={showFactorAuth}
              okText={t('Verify')}
              onCancel={() => setShowFactorAuth(false)}
              onOk={() => form.submit()}
              width={isSmallScreen ? '90%' : '500px'}
              bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              maskStyle={{ backdropFilter: 'blur(8px)' }}
              footer={false}
              destroyOnClose
            >
              <Col
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <ComplokLock style={{ margin: '32px 0', alignSelf: 'center' }} />
                <Title level={3}>{t('Two Factor Authentication')}</Title>
                <Typography>{t('A verification code has been sent to your email.')}</Typography>
                <Form.Item
                  name={'code'}
                  label={<div style={{ fontWeight: 600, fontSize: 12 }}>{t('Authentication code')}</div>}
                  rules={[{ pattern: /[0-9]/, message: 'Numbers only' }]}
                  style={{ margin: '32px 0', width: '100%' }}
                >
                  <Input type="tel" placeholder="123456" maxLength={6} onPressEnter={() => form.submit()} />
                </Form.Item>
              </Col>
              <Col span={24} style={{ width: '100%' }}>
                <Row style={{ alignItems: 'flex-start' }}>
                  <Col flex={1}>
                    <Button
                      type={'primary'}
                      size={'large'}
                      style={{ display: 'flex', alignItems: 'center', borderRadius: 14, padding: '8px 24px' }}
                      onClick={() => form.submit()}
                    >
                      {t('Verify')}
                    </Button>
                  </Col>
                  <Col>
                    <Button type={'text'} size={'large'} onClick={() => setShowFactorAuth(false)}>
                      {t('Cancel')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Modal>

            <Row style={{ alignItems: 'center', marginTop: 20 }}>
              <Col flex={12}>
                <Button
                  style={{ width: 120, height: 44, borderRadius: 20 }}
                  size={'large'}
                  onClick={() => form.submit()}
                  type="primary"
                  className="login-form-button"
                  loading={loggingIn}
                >
                  {t('Sign in')}
                </Button>
              </Col>
              <Col flex="auto">
                <div className="login-view-form-forgot-password" onClick={() => navigate('/forgotpassword')}>
                  {t('Forgot password')}
                </div>
              </Col>
            </Row>
          </Form>
          <Typography
            style={{
              textAlign: 'center',
              ...((isSmallScreen && { paddingTop: 40, paddingLeft: 5, paddingRight: 5, paddingBottom: 40 }) || {
                marginTop: 90,
                padding: 8,
                borderRadius: 14,
                background: 'white'
              })
            }}
          >
            {t('Visit our')}
            <a href="https://elearning.complok.eu">
              <b> {t('website')} </b>
            </a>
            {t('to learn more about platform and AML courses.')}
            {/* {t('Visit our website to learn more about platform and AML courses.')}{' '} */}
          </Typography>
        </Col>
      </Row>
    </div>
  );
};

export default LoginView;

const LanguagePopoverContent: FC = () => {
  const { updateLanguage } = useContext(SettingsContext);

  const languages = [
    { key: 'en', title: 'English' },
    { key: 'et', title: 'Eesti' }
  ];

  return (
    <div className="complok-header-profile-popover">
      {languages.map((action, index) => (
        <div
          onClick={() => updateLanguage(action.key)}
          className={`complok-header-profile-popover-item ${
            index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
          }`}
          key={`complok-header-popover-action-${index}`}
        >
          {action.title}
        </div>
      ))}
    </div>
  );
};
