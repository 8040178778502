import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import { Breadcrumb } from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { FC, useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { OrganizationManagementContext } from './OrganizationManagementContext';

const ManagementBreadcrumbs: FC = () => {
  const { organization, groups } = useContext(OrganizationManagementContext);
  const { groupIdentifier, courseIdentifier } = useParams();

  const breadcrumbsPath = createConditionalBreadcrumbs([
    {
      link: '/management',
      title: organization?.title,
      show: true
    },
    // Course Icon
    {
      link: `/management/c/${courseIdentifier}`,
      show: courseIdentifier,
      title: <SchoolIcon style={{ marginBottom: -4 }} /> // 'Groups'
    },
    // Group Icon
    {
      link: '/management/g',
      title: <GroupsIcon style={{ marginBottom: -4 }} />,
      show: groupIdentifier
    },
    // Group Name
    {
      link: `/management/g/${groupIdentifier}`,
      title: groups.find((group) => group._id === groupIdentifier)?.title ?? '',
      show: groups.find((group) => group._id === groupIdentifier)?.title
    }
  ]);

  return (
    <div id="complok-management-breadcrumbs" style={{ marginTop: 60 }}>
      <Breadcrumb separator={'>'}>{breadcrumbsPath}</Breadcrumb>
    </div>
  );
};

const createConditionalBreadcrumbs = (breadcrumbsData: { title?: any; show?: unknown; link: string }[]) => {
  const allowedBreadcrumbs = breadcrumbsData.filter(
    (breadcrumbData) => breadcrumbData?.show && breadcrumbData?.title && breadcrumbData?.link
  );
  return allowedBreadcrumbs.map((breadcrumbData, crumbIndex) => (
    <BreadcrumbItem key={crumbIndex}>
      <Link to={breadcrumbData?.link ?? ''}>{breadcrumbData.title}</Link>
    </BreadcrumbItem>
  ));
};

export default ManagementBreadcrumbs;
