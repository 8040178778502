/* eslint-disable operator-linebreak */
/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
import { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router';
import CourseContent from './CourseContent';
import CourseContext from './CourseContext';
import CourseInformation from './CourseInformation';

export const CourseView: FC = () => {
  const { courseIdentifier, moduleIdentifier } = useParams();

  return (
    <Routes>
      <Route
        element={
          <CourseContext>
            <Outlet />
          </CourseContext>
        }
      >
        <Route path=":moduleIdentifier/:subjectIdentifier" element={<CourseContent />} />
        <Route path="*" element={<CourseInformation />} />
      </Route>
    </Routes>
  );
};

export default CourseView;
