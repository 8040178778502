import { Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import apiClient from 'api/apiClient';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import PlayIcon from 'components/icons/PlayIcon';
import Button from 'components/interactables/Button';
import { formatSecondsToMinutesWithSeconds } from 'helpers';
import { find } from 'lodash';
import { Dispatch, FC, MutableRefObject, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import { mutate } from 'swr';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { Course } from 'types/Course/course.types';
import { ICourseVideo, ICourseVideoChapter } from 'types/Course/CourseContent/course.content.video.types';

const VideoContent: FC<{
  course: Course;
  selectedSubject: ICourseSubject | undefined;
  videoUrl: string | undefined;
}> = ({ videoUrl, selectedSubject, course }) => {
  const tabs = ['Additional text', 'Video chapters'];
  const videoData = selectedSubject?.content as ICourseVideo;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [additionalText, setAdditionalText] = useState<string | undefined | 'new_chapter'>();
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<string>('00:00');

  const videoRef = useRef<null | HTMLVideoElement>(null);

  useEffect(() => {
    setAdditionalText(videoData.additional_text);
    setCurrentPosition('00:00');
  }, [videoData]);

  const changeAdditionalText = async () => {
    if (!selectedSubject || !additionalText) return;

    const response = await apiClient.patch('/videos/additional_text', {
      _id: selectedSubject.content._id,
      text: additionalText
    });

    if (response) {
      message.success('Text was changed successfully!');
      mutate(`/courses/content/${course._id}`);
    }
  };

  const renderVideoAdditionalData = (): ReactElement | null => {
    switch (activeTabIndex) {
      case 0:
        // render additional text
        return (
          <div className="super-admin-create-course-content-content-container-video-additional-text">
            <TextArea
              value={additionalText}
              onChange={(e) => setAdditionalText(e.target.value)}
              rows={4}
              style={{
                borderRadius: 10,
                boxSizing: 'border-box',
                borderWidth: 1,
                fontFamily: 'poppins',
                fontWeight: 400,
                fontSize: 16
              }}
            />
            <Button onClick={changeAdditionalText} style={{ float: 'right', marginTop: 20 }}>
              Save text
            </Button>
          </div>
        );
      case 1:
        // render video chapters
        return (
          <AdditionalChapters
            videoChapters={videoData.chapters}
            videoRef={videoRef}
            isPlaying={isPlaying}
            currentPosition={currentPosition}
            setCurrentPosition={setCurrentPosition}
            videoData={videoData}
            course={course}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="super-admin-create-course-content-content-container-video">
      <video
        id="complok-coursecontent-video-player"
        playsInline
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        src={videoUrl}
        ref={(ref) => {
          if (ref) {
            videoRef.current = ref;
          }
        }}
        style={{ height: '100%', width: '100%', borderRadius: 15, border: '1px solid rgb(226, 226, 234)' }}
        controlsList={'nodownload noplaybackrate'}
        onContextMenu={(event) => event.preventDefault()}
        controls
        autoPlay
      ></video>

      <div className="super-admin-create-course-content-content-container-video-tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => setActiveTabIndex(index)}
            className={
              index !== activeTabIndex
                ? 'super-admin-create-course-content-content-container-video-tabs-text'
                : 'super-admin-create-course-content-content-container-video-tabs-text-selected'
            }
          >
            {tab}
          </div>
        ))}
      </div>
      {renderVideoAdditionalData()}
    </div>
  );
};

interface IAdditionalChapters {
  isPlaying: boolean;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  setCurrentPosition: Dispatch<SetStateAction<string>>;
  currentPosition: string;
  videoChapters: ICourseVideoChapter[];
  videoData: ICourseVideo;
  course: Course;
}

const AdditionalChapters: FC<IAdditionalChapters> = ({
  isPlaying,
  videoRef,
  setCurrentPosition,
  currentPosition,
  videoChapters,
  videoData,
  course
}) => {
  const [renderableChapters, setRenderableChapters] = useState<ICourseVideoChapter[]>(videoChapters);
  const [chapterToEdit, setChapterToEdit] = useState<string | undefined>();
  const [chapterTitle, setChapterTitle] = useState<string | undefined>();
  const [chapterTimestamp, setChapterTimestamp] = useState<number | undefined>();

  useEffect(() => {
    setRenderableChapters(videoChapters);
  }, [videoChapters]);

  useEffect(() => {
    const interval = setInterval(() => {
      // logic
      if (isPlaying) {
        const currentPlayerSeconds = videoRef.current?.currentTime;
        if (currentPlayerSeconds) {
          setCurrentPosition(formatSecondsToMinutesWithSeconds(currentPlayerSeconds));
        }
      }
    }, 500);

    const currentPlayerSeconds = videoRef.current?.currentTime;
    if (currentPlayerSeconds) {
      setCurrentPosition(formatSecondsToMinutesWithSeconds(currentPlayerSeconds));
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying, setCurrentPosition, videoRef]);

  if (!videoRef.current) return null;

  const createNewEditableChapter = (newChapter: ICourseVideoChapter) => {
    const alreadyExistsEditableUnSavedChapter = find(renderableChapters, (x) => x._id === newChapter._id);
    if (!alreadyExistsEditableUnSavedChapter) {
      setRenderableChapters((currentChapters: ICourseVideoChapter[]) => [...currentChapters, newChapter]);
      setChapterToEdit(newChapter._id);
    }
  };

  const editSelectedChapter = (chapter: ICourseVideoChapter) => {
    if (!chapterToEdit) {
      setChapterToEdit(chapter._id);
      setChapterTitle(chapter.title);
    }
  };

  const saveChapterChanges = async () => {
    const newChapterProps = find(renderableChapters, (x) => x._id === chapterToEdit);
    const currentPlayerSeconds = videoRef.current?.currentTime;

    console.log('currentPlayerSeconds:', currentPlayerSeconds);

    if (newChapterProps && currentPlayerSeconds !== undefined) {
      if (chapterToEdit === 'new_chapter') {
        const response = await apiClient
          .patch('/videos/video_chapter/attach', {
            _id: videoData._id,
            timestamp: currentPlayerSeconds,
            title: chapterTitle
          })
          .catch(() => null);
        if (response) {
          setRenderableChapters(response.data.chapters);
          message.success('Chapter has been added');
          mutate(`/courses/content/${course._id}`);
          setChapterToEdit(undefined);
          setChapterTitle(undefined);
        }
      } else {
        // handle when no changes were done
        if (chapterTitle === newChapterProps.title) {
          setChapterToEdit(undefined);
          setChapterTitle(undefined);
          return;
        }

        const response = await apiClient.patch('/videos/video_chapter/chapter', {
          chapterId: chapterToEdit,
          videoId: videoData._id,
          title: chapterTitle,
          timestamp: chapterTimestamp
        });
        if (response) {
          setRenderableChapters(response.data.chapters);
          message.success('Chapter has been edited');
          mutate(`/courses/content/${course._id}`);
          setChapterToEdit(undefined);
          setChapterTitle(undefined);
        }
      }
    }
  };

  const removeChapterFromVideo = async (chapterId: string) => {
    const response = await apiClient
      .patch('/videos/video_chapter/detach', {
        videoId: videoData._id,
        chapterId
      })
      .catch(() => null);

    if (response) {
      message.success('Chapter was removed');
      setRenderableChapters((currChapters) => currChapters.filter((x) => x._id !== chapterId));
      mutate(`/courses/content/${course._id}`);
    }
  };

  return (
    <div className="super-admin-create-course-content-content-container-video-chapters">
      {renderableChapters
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((chapter) => {
          if (chapter._id === chapterToEdit) {
            return (
              <div
                className="super-admin-create-course-content-content-container-video-chapters-item-editable"
                key={chapter._id}
              >
                <div className="super-admin-create-course-content-content-container-video-chapters-item-timestamp">
                  <PlayIcon />
                  <div className="super-admin-create-course-content-content-container-video-chapters-item-timestamp-text">
                    {formatSecondsToMinutesWithSeconds(chapter.timestamp)}
                  </div>
                </div>
                <Input
                  onPressEnter={saveChapterChanges}
                  value={chapterTitle}
                  style={{
                    borderRadius: 10,
                    fontFamily: 'poppins',
                    fontWeight: 400,
                    fontSize: 16,
                    width: '50%'
                  }}
                  onChange={(e) => setChapterTitle(e.target.value)}
                />
                <div style={{ flex: 1 }} />
                <Button
                  size="small"
                  type="secondary"
                  onClick={() =>
                    setRenderableChapters((currChapters) => currChapters.filter((x) => x._id !== 'new_chapter'))
                  }
                >
                  Remove
                </Button>
                <Button size="small" style={{ marginLeft: 5 }} onClick={() => saveChapterChanges()}>
                  Save
                </Button>
              </div>
            );
          }
          return (
            <div className="super-admin-create-course-content-content-container-video-chapters-item" key={chapter._id}>
              <div
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime = chapter.timestamp;
                  }
                }}
                className="super-admin-create-course-content-content-container-video-chapters-item-timestamp"
              >
                <PlayIcon />
                <div className="super-admin-create-course-content-content-container-video-chapters-item-timestamp-text">
                  {formatSecondsToMinutesWithSeconds(chapter.timestamp)}
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  fontFamily: 'poppins',
                  fontWeight: 400,
                  fontSize: 16,
                  width: '100%'
                }}
              >
                {chapter.title}
              </div>
              <div style={{ flex: 1 }} />
              <DeleteIcon
                style={{ marginLeft: 10, cursor: 'pointer' }}
                onClick={() => removeChapterFromVideo(chapter._id)}
              />
              <EditIcon onClick={() => editSelectedChapter(chapter)} style={{ marginLeft: 5, cursor: 'pointer' }} />
            </div>
          );
        })}
      <div
        className="super-admin-create-course-content-content-container-video-chapters-add"
        onClick={() =>
          createNewEditableChapter({
            _id: 'new_chapter',
            title: 'Video chapter title',
            timestamp: videoRef.current?.currentTime || 0
          })
        }
      >
        <label className="super-admin-create-course-content-content-container-video-chapters-add-text">
          {`+ Add chapter (${currentPosition})`}
        </label>
      </div>
    </div>
  );
};

export default VideoContent;
