import { ICourseContentBase } from './course.content.types';

export interface ICourseVideo extends ICourseContentBase {
  key: string;
  srcUrl?: string;
  length: number;

  additional_text: string;
  chapters: ICourseVideoChapter[];
}

export interface ICourseVideoChapter {
  _id: string;
  title: string;
  timestamp: number;
}

export const isTypeofVideo = (arg: ICourseVideo | unknown): arg is ICourseVideo =>
  (arg as ICourseVideo).key !== undefined;
