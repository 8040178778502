import apiClient from 'api/apiClient';
import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { IAccount } from 'types/AccountTypes';
import { IUser } from 'types/UserTypes';

interface IUserContext {
  userData?: IUser;
  accountData?: IAccount;

  accounts?: IAccount[];
  accountsLoading?: boolean;
  contextInitialized: boolean;
  setUserData: Dispatch<SetStateAction<IUser | undefined>>;

  setAccountData: Dispatch<SetStateAction<IAccount | undefined>>;
  refreshAccounts: () => void;
  selectAccount: (accountId: string) => void;

  logout: () => void;
  clearUserCache: () => void;
}

export const UserContext = createContext<IUserContext>({
  contextInitialized: false,
  setAccountData: () => null,
  setUserData: () => null,

  selectAccount: () => null,
  refreshAccounts: () => null,

  logout: () => null,
  clearUserCache: () => null
});

// TODO: Account & accounts fetching and switching logic
const UserContextProvider: FC = ({ children }) => {
  const [userData, setUserData] = useState<IUser>();
  const [accountData, setAccountData] = useState<IAccount>();
  const [contextInitialized, setContextInitialized] = useState<boolean>(false)

  const {
    data: accounts,
    mutate: refreshAccounts,
    isValidating: accountsLoading
  } = useSWR<IAccount[]>(userData?._id ? `/accounts/by_user/${userData._id}` : null);

  const selectAccount = async (accountId: string) => {
    const { data: fetchedAccount } = await apiClient
      .get(`/accounts/by_account/${accountId}`)
      .catch(() => ({ data: null }));

    if (!fetchedAccount || !fetchedAccount.user) return;

    apiClient.setAccount(fetchedAccount._id);
    setAccountData(fetchedAccount);
    setUserData(fetchedAccount.user);

    window.localStorage.setItem('account', fetchedAccount._id);
  };

  const clearUserCache = () => {
    apiClient.setAccessToken('');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('account');
    window.localStorage.removeItem('token');
    setUserData(undefined);
    setAccountData(undefined);
  };

  const logout = useCallback(() => {
    clearUserCache();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user');
    const accountId = localStorage.getItem('account');

    if (token && userId && accountId) {
      apiClient.setAccessToken(token);
      apiClient.setAccount(accountId);

      apiClient
        .get(`/accounts/by_account/${accountId}`)
        .then((response) => {
          const { data } = response;
          if (data && data.user) {
            setAccountData(response.data);
            setUserData(response.data.user);
          }
          setContextInitialized(true);
        })
        .catch((err) => {
          console.error('Error on initial auth', err)
          if (err?.response?.status === 403 || err?.response?.status === 401) {
            logout();
          }
          setContextInitialized(true);
        })
    } else {
      setContextInitialized(true)
    }
  }, [logout, setAccountData, setUserData]);

  const values = { userData, accountData, accounts, accountsLoading, contextInitialized };
  const functions = { setUserData, setAccountData, selectAccount, refreshAccounts, logout, clearUserCache };

  return <UserContext.Provider value={{ ...values, ...functions }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
