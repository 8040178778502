import { Alert, Button, Checkbox, Form, Pagination } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Title from 'antd/lib/typography/Title';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CourseQuestionnaireQuestion,
  ICourseExamQuestion
} from 'types/Course/CourseContent/course.content.questionnaire.types';
import { ICourseQuizQuestion } from 'types/Course/CourseContent/course.content.quiz.types';

interface QuestionnaireQuestionProps {
  question: CourseQuestionnaireQuestion | ICourseQuizQuestion | ICourseExamQuestion;
  currentQuestionIndex?: number;
  totalQuestionCount?: number;

  allowSubmit?: boolean;
  submitDeniedMessage?: string;

  handleAnswer?: (answers: any) => void;
  handleNext?: (targetQuestion: number) => void;
}

const QuestionnaireQuestion: FC<QuestionnaireQuestionProps> = ({
  question,
  currentQuestionIndex,
  totalQuestionCount,

  allowSubmit,
  submitDeniedMessage,

  handleAnswer,
  handleNext
}) => {
  const { t } = useTranslation();

  const isSmallScreen = useBreakpoint().md === false;

  const isNotLastQuestion = (currentQuestionIndex ?? 0) + 1 < (totalQuestionCount ?? 1);

  return (
    <div id="complok-coursecontent-quiz-question">
      <Title level={3} id="complok-coursecontent-quiz-question-title">
        {(currentQuestionIndex ?? 0) + 1}. {question.question || 'Question'}
      </Title>
      <Form
        onValuesChange={(changedValues, allValues) => handleAnswer && handleAnswer(allValues)}
        id="complok-coursecontent-quiz-question-answers"
      >
        {question.answers.map((answer) => (
          <Form.Item
            name={answer._id}
            valuePropName="checked"
            key={answer._id}
            id="complok-coursecontent-quiz-question-answer"
          >
            <Checkbox>{answer.answer}</Checkbox>
          </Form.Item>
        ))}
      </Form>
      <div id="complok-coursecontent-quiz-question-footer">
        {currentQuestionIndex !== 0 && isSmallScreen && (
          <Button
            type="primary"
            size="large"
            onClick={() =>
              typeof currentQuestionIndex === 'number' && handleNext && handleNext(currentQuestionIndex - 1)
            }
          >
            {t('Previous Question')}
          </Button>
        )}
        <Button
          type="primary"
          size="large"
          onClick={() => typeof currentQuestionIndex === 'number' && handleNext && handleNext(currentQuestionIndex + 1)}
          disabled={!isNotLastQuestion && !allowSubmit}
        >
          {isNotLastQuestion ? t('Next Question') : t('Finish Quiz')}
        </Button>

        {!isSmallScreen && !(!currentQuestionIndex && !totalQuestionCount) && (
          <Pagination
            style={{ display: 'flex', alignItems: 'center' }}
            current={(currentQuestionIndex ?? 0) + 1}
            total={totalQuestionCount}
            pageSize={1}
            onChange={(questionNumber) => handleNext && handleNext(questionNumber - 1)}
          />
        )}
      </div>
      {isSmallScreen && !(!currentQuestionIndex && !totalQuestionCount) && (
        <Pagination
          style={{ marginTop: 20 }}
          current={(currentQuestionIndex ?? 0) + 1}
          total={totalQuestionCount}
          pageSize={1}
          onChange={(questionNumber) => handleNext && handleNext(questionNumber - 1)}
        />
      )}
      {!isNotLastQuestion && !allowSubmit && submitDeniedMessage && (
        <Alert message={submitDeniedMessage} type="warning" style={{ marginTop: 16 }} showIcon />
      )}
    </div>
  );
};

export default QuestionnaireQuestion;
