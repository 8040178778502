import { FC, useState } from 'react';
import Chevron from 'components/icons/Chevron';
import './cards.scss';
import { ICourseModule } from 'types/Course/course.module.types';
import { ICourseSubject } from 'types/Course/course.subject.types';

const ModuleCard: FC<{ module: ICourseModule }> = ({ module }) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const toggleContent = () => setShowContent(!showContent);
  // const { courseIdentifier }: { courseIdentifier: string } = useParams();

  const { title, ordered_subjects } = module;

  return (
    <div className="module-list-item">
      <div className={`module-list-item-header ${showContent ? 'module-list-item-closed' : ''}`} onClick={toggleContent}>
        <div className="module-list-item-header-title">{title}</div>
        <div className="module-list-item-header-arrow">
          <Chevron direction={showContent ? 'up' : 'down'} />
        </div>
      </div>
      <div className="module-list-item-contents">
        {ordered_subjects
          .filter((subject) => subject.content)
          .map((subject: ICourseSubject) => (
            <div key={subject._id} style={{ fontSize: 16, lineHeight: '28px', marginBottom: '10px' }} className="module-list-item-contents-item">
              <span style={{ marginLeft: 10 }}>{subject.title}</span>
              {/* {subject.duration && <ComplokTag title={subject.duration} />} */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ModuleCard;
