import { Modal } from 'antd';
import React, { FC, ReactNode } from 'react';

interface IGenericModalProps {
  visible: boolean;
  onOk?: any;
  onCancel?: any;
  title?: string | undefined | ReactNode;
  children?: any;
  okText?: ReactNode;
  okType?: any;
  closeIcon?: ReactNode | undefined;
  bodyStyle?: React.CSSProperties;
  footer?: React.ReactNode;
  destroyOnClose?: boolean;
}

const GenericModal: FC<IGenericModalProps> = ({
  visible,
  title = undefined,
  onOk,
  onCancel,
  children,
  okText,
  okType,
  bodyStyle,
  closeIcon,
  footer,
  destroyOnClose
}) => (
  <Modal
    centered
    destroyOnClose={destroyOnClose}
    width={'55%'}
    bodyStyle={bodyStyle && bodyStyle}
    visible={visible}
    title={title && title}
    onCancel={onCancel}
    onOk={onOk}
    okText={okText && okText}
    okType={okType && okType}
    closeIcon={closeIcon || <></>}
    footer={footer}
  >
    {children}
  </Modal>
);

export default GenericModal;
