import { Col, Popover, Row, Typography } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const { Title, Paragraph } = Typography;

const LANGUAGE_LABELS: { [key: string]: string } = {
  et: 'Eesti',
  en: 'English'
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TermsAndConditions: FC = () => {
  const queryParams = useQuery();
  const { t } = useTranslation();

  const lang = queryParams.get('lang') || 'en';
  const [language, setLanguage] = useState<string>(lang);

  const isEnglish = language === 'en';

  return (
    <Col style={{ width: '100%', height: '100%', overflow: 'auto', padding: 20 }}>
      <Row>
        <Col flex={1} />
        <Col style={{ marginRight: 20 }}>
          <Popover
            trigger={'hover'}
            placement={'bottomRight'}
            content={() => (
              <LanguagePopoverContent
                updateLanguage={(val) => {
                  setLanguage(val);
                }}
              />
            )}
          >
            <span className="login-view-header-langauge-container">
              <p className="login-view-header-langauge-prefix">{`${language === 'et' ? 'Keel' : 'Language'}`}</p>
              <p className="login-view-header-langauge-value">{language && LANGUAGE_LABELS[language]}</p>
            </span>
          </Popover>
        </Col>
      </Row>
      {isEnglish ? (
        <div>
          <Title level={4}>
            <span>Terms of Use</span>
          </Title>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>1. Scope of application</span>
            </b>
          </p>

          <p>
            <span>1.1. These Terms of Use regulate the use of the Platform.</span>
          </p>

          <p>
            <span>
              1.2. The Terms of Use do not regulate the service provided on the Platform, i.e. online trainings. The
              provision of online trainings on the Platform is regulated by:{' '}
            </span>
          </p>

          <p>
            <span>
              1.2.1. In the case of the Employee, the contract entered into between the Employee and the Employer, and
              the contract entered into between the Employer and Complok.
            </span>
          </p>

          <p>
            <span>
              1.3. The Terms of Use constitute the entire agreement between Complok and the User. They shall take
              precedence over other sources of information (e.g. the marketing materials of the Platform) and
              interpretations (e.g. explanations provided by the Platform user support). The contract entered into
              between the Employer and Complok shall take precedence over these Terms of Use.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>2. Definitions</span>
            </b>
          </p>

          <p>
            <span>
              2.1. Complok means the company Complok Learning OÜ (registry code 16171413), with its address at Angerja
              tn 9-31, 10416 Tallinn, Estonia, which is the owner and administrator of the Platform.
            </span>
          </p>

          <p>
            <span>2.2. The Platform is the website https://www.elearning.complok.eu/</span>
          </p>

          <p>
            <span>
              including all its subdomains and aliases, with all of its components, contents and software required for
              its functioning.
            </span>
          </p>

          <p>
            <span>2.3. The User is every person who uses the Platform, including only visits.</span>
          </p>

          <p>
            <span>
              2.4. The Employee is the User who has created a user account on the Platform to undergo the online
              trainings assigned to them by the Employer, or to administer the online trainings of any other Employees
              of the Employer.
            </span>
          </p>

          <p>
            <span>
              2.5. The Employer means the person that under a contract entered into with Complok specifies the online
              trainings to be passed on the Platform by any natural persons connected with the Employer in any way, and
              pays for them.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>3. General terms and conditions</span>
            </b>
          </p>

          <p>
            <span>
              3.1. The User, while using the Platform, including only visiting the Platform, confirms that they have
              examined and agreed with the Terms of Use and the data processing principles connected therewith,
              including the conditions of cookie use which are available at: https://www.elearning.complok.eu/
            </span>
          </p>

          <p>
            <span>
              3.2. The only purpose of the Platform is to enable the Users to undergo online trainings and obtain
              certificates that certify the successful passing of these trainings. It is prohibited to use the Platform
              for any other purpose.
            </span>
          </p>

          <p>
            <span>
              3.3. Every person must make sure that they have the right to use the Platform. A person who is not
              entitled to use the Platform (e.g., if the law applicable to the person prohibits them to participate in
              online trainings via the Platform, or if Estonian law prohibits Complok to provide the service to them)
              shall avoid using the Platform in any way, including avoid visiting the Platform.
            </span>
          </p>

          <p>
            <span>
              3.4. Complok will be entitled to promptly limit the access of any person to the Platform and delete their
              user account if it turns out that such person does not have the right to use the Platform for any reason
              whatsoever.
            </span>
          </p>

          <p>
            <span>
              3.5. Complok will be entitled to prohibit any person to use the Platform if allowing it would be in
              conflict with Complok’s business ethics, moral values or public order. Complok will be entitled to
              promptly limit the access of such person to the Platform and delete their user account.
            </span>
          </p>

          <p>
            <span>
              3.6. The User is prohibited from using the Platform in any unlawful manner, including copying any works
              protected by copyright from the Platform, or in any other manner infringing the Terms of Use. Complok will
              be entitled to promptly limit the access of such person to the Platform and delete their user account.
            </span>
          </p>

          <p>
            <span>
              3.7. The User undertakes to enter in the Platform and deliver to Complok in any other manner only correct
              and up-to-date information, and ensure necessary updates when the entered information becomes out of date.
              The User shall be liable for the damage caused to Complok or a third person by provision of incorrect
              information or by failure to update information.
            </span>
          </p>

          <p>
            <span>
              3.8. Complok will be entitled to request the Users to provide additional information necessary for the
              provision of services by Complok, functioning of the Platform or enabling the purposeful use thereof at
              any time. Should the User fail to provide or refuse to provide additional information within a reasonable
              period of time, Complok will be entitled to promptly limit the User’s access to the Platform and delete
              their user account.
            </span>
          </p>

          <p>
            <span>
              3.9. The Employer and the User shall avoid assuming any obligations related to the Platform if they know
              or should have known that they are probably unable to perform them.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>4. Use of the Platform</span>
            </b>
          </p>

          <p>
            <span>
              4.1. The Platform will be accessible to all persons on the Internet after they have examined the Terms of
              Use and agreed with them.
            </span>
          </p>

          <p>
            <span>
              4.2. The Users without a user account can access only the free parts of the Platform (e.g., see the online
              trainings provided on the Platform, subscribe to the newsletter and contact the user support).
            </span>
          </p>

          <p>
            <span>
              4.3. The Users with the user account can undergo online trainings and see the certificates issued to them.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>5. User accounts</span>
            </b>
          </p>

          <p>
            <span>5.1. A User shall create a user account on the Platform in order to undergo online trainings.</span>
          </p>

          <p>
            <span>5.2. Complok shall delete user accounts in accordance with the following principles:</span>
          </p>

          <p>
            <span>5.2.1. A User’s user account in the cases prescribed in the Terms of Use;</span>
          </p>

          <p>
            <span>
              5.2.2. An Employee’s user account based on an order of the Employer, but not later than three months after
              the expiry of the contract between Complok and the Employer.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <span>6. Cyber security</span>
          </p>

          <p>
            <span>
              6.1. Complok never requests any information which is intrinsically secret (e.g., the password for a user
              account of the Platform) from a User.
            </span>
          </p>

          <p>
            <span>
              6.2. The User is prohibited from providing access to their user account on the Platform to any third
              persons.
            </span>
          </p>

          <p>
            <span>
              6.3. Should the User lose their password for the user account of the Platform, or suspect that it has
              become accessible to third persons, the User shall immediately inform Complok and change the password.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>7. Protection of the functioning and contents of the Platform </span>
            </b>
          </p>

          <p>
            <span>
              7.1. The User confirms that they shall avoid any activities, the purpose of which is or which bring about:
            </span>
          </p>

          <p>
            <span>7.1.1. Processing of the data of other Users, including recording in any part or form;</span>
          </p>

          <p>
            <span>
              7.1.2. Use of the Platform in a manner different from the purpose thereof, including for sending of spam
              mail or for other inadvisable communication;
            </span>
          </p>

          <p>
            <span>
              7.1.3. Interfering with the work of the Platform in any manner, including attacking the Platform or its
              server, or interfering with its normal use in any other manner;
            </span>
          </p>

          <p>
            <span>7.1.4. Hindering other Users from using the Platform;</span>
          </p>

          <p>
            <span>
              7.1.5. Copying, reproducing, modifying, duplicating, making available to third parties or otherwise
              exploiting any contents of the Platform, except the certificates issued to the User;
            </span>
          </p>

          <p>
            <span>7.1.6. Modifying or deleting any contents of the Platform, except the data of the User;</span>
          </p>

          <p>
            <span>
              7.1.7. Scanning the Platform or exploring it in any other manner with a device with the purpose of
              determining the technical structure and principles of functioning of the Platform, data recorded on the
              Platform and other files of the Platform.
            </span>
          </p>

          <p>
            <span>
              7.2. Complok grants the right to public search engines to use relevant tools and reproduce the contents of
              the Platform to the extent necessary for effectively finding the Platform and displaying introductory
              information to the public in search engines.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>8. Limiting access to the Platform</span>
            </b>
          </p>

          <p>
            <span>
              8.1. Should Complok know that the activities of a User are unlawful or infringe the Terms of Use in any
              other manner, Complok will be entitled to promptly limit the access of the User to the Platform and delete
              their user account.
            </span>
          </p>

          <p>
            <span>
              8.2. Should Complok suspect that the activities of a User are unlawful or infringe the Terms of Use in any
              other manner, Complok will be entitled to promptly limit the access of the User to their user account. The
              User will then have 14 days to prove that there has been no infringement of the Terms of Use. Should the
              User not convincingly refute the suspicion of unlawful behaviour or other infringement of the Terms of Use
              within the prescribed period of time, Complok will be entitled to limit the access of the User to the
              Platform and delete their user account.{' '}
            </span>
          </p>

          <p>
            <span>
              8.3. Complok will be entitled will be entitled to limit the access of the User to the Platform or user
              account and delete their user account without any advance notice if it derives from amendments to law and
              regulations or instructions provided by a state authority.
            </span>
          </p>

          <p>
            <span>
              8.4. Any delay in limiting the User’s access to the Platform or user account, providing the User with an
              additional opportunity to explain or taking other such User-friendly measures does not deprive Complok of
              the right to limit the access of the User to the Platform or user account and delete their user account
              without an advance notice in the cases prescribed in the Terms of Use.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>9. Limited liability of Complok</span>
            </b>
          </p>

          <p>
            <span>
              9.1. The liability of Complok with regard to the Platform is limited to the minimum permitted under
              applicable laws and regulations. For example, Complok will never be liable for:
            </span>
          </p>

          <p>
            <span>9.1.1. Any other than direct proprietary damage;</span>
          </p>

          <p>
            <span>9.1.2. Any violation of the Terms of User, except in case of intentional violation;</span>
          </p>

          <p>
            <span>9.1.3. Any damage caused by a third person;</span>
          </p>

          <p>
            <span>9.1.4. Any damage caused by a temporary technical failure of the Platform;</span>
          </p>

          <p>
            <span>
              9.1.5. The fact that a User feels discriminated or offended by any information on the Platform or contents
              of online training, unless the publication of such information or expressing such position is prohibited
              in Estonia.
            </span>
          </p>

          <p>
            <span>
              9.2. The User confirms that they shall never submit a claim regarding the Platform against the management
              board, employees, service providers or other related persons of Complok.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>10. Final provisions</span>
            </b>
          </p>

          <p>
            <span>
              10.1. Complok may amend the Terms of Use and the documents referred to herein at any time. The User shall
              become acquainted with the amendments.{' '}
            </span>
          </p>

          <p>
            <span>
              10.2. Complok will give 14 days’ advance notice of any amendments to the Terms of Use and the documents
              referred to herein via the Platform, unless the amendments have to take effect earlier due to regulations
              or instructions of state authorities.{' '}
            </span>
          </p>

          <p>
            <span>
              10.3. Should the User not agree with any amendments to the Terms of Use, the User will have to delete
              their user account, and refrain from further use of the Platform.
            </span>
          </p>

          <p>
            <span>
              10.4. Should the User continue to use the Platform, the User thereby confirms that the amendments are
              suitable, and they will be deemed to have agreed with the amendments.
            </span>
          </p>

          <p>
            <span>10.5. The Terms of Use do not provide any rights to third persons.</span>
          </p>

          <p>
            <span>
              10.6. The Terms of Use and all legal relationships created hereunder shall be governed by Estonian law.{' '}
            </span>
          </p>

          <p>
            <span>
              10.7. Issues not regulated under the Terms of Use shall be governed by relevant laws and regulations in
              good faith and considering the spirit of the Terms of Use.
            </span>
          </p>

          <p>
            <span>
              10.8. If any provision of the Terms of Use appears to be in conflict with applicable laws and regulations,
              it shall not affect the validity of the remaining Terms of Use. The conflicting provision shall then be
              replaced with such provision of the applicable regulations, the purpose of which is as close as possible
              to the replaced provision of the Terms of Use.{' '}
            </span>
          </p>

          <p>
            <span>
              10.9. Any disputes regarding the Terms of Use and the legal relationships created hereunder shall be
              settled by negotiations between Complok and the User. Should negotiations fail, the interested party shall
              have a recourse to Harju County Court.
            </span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>
        </div>
      ) : (
        <div className="WordSection1">
          <Title level={3}>
            <span>Kasutustingimused</span>
          </Title>

          <p></p>

          <p>
            <b>
              <span>1. Kohaldumisala</span>
            </b>
          </p>

          <p>
            <span>1.1. Need Kasutustingimused reguleerivad Platvormi kasutamist.</span>
          </p>

          <p>
            <span>
              1.2. Kasutustingimused ei reguleeri Platvormil osutatavat teenust ehk online-koolitusi. Online-koolituste
              pakkumine Platvormil on reguleeritud:{' '}
            </span>
          </p>

          <p>
            <span>
              1.2.1. Töötaja puhul Töötaja ning Tööandja vahel sõlmitud lepinguga ja Tööandja ning Comploki vahel
              sõlmitud lepinguga.
            </span>
          </p>

          <p>
            <span>
              1.3. Kasutustingimused moodustavad tervikliku kokkuleppe Comploki ning Kasutaja vahel. Need on ülimuslikud
              kõigi muude infoallikate (näiteks Platvormi turundusmaterjalid) ja tõlgenduste (näiteks Platvormi
              kasutajatoe selgitused) suhtes. Tööandja ja Comploki vahel sõlmitav leping on ülimuslik Kasutustingimuste
              suhtes.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>2. Mõisted</span>
            </b>
          </p>

          <p>
            <span>
              2.1. Complok on äriühing Complok Learning OÜ (registrikood 16171413), mis asub aadressil Angerja tn 9-31,
              Tallinn 10416, Eesti, ning on Platvormi omanik ja haldaja.
            </span>
          </p>

          <p>
            <span>2.2. Platvorm on https://www.elearning.complok.eu/</span>
          </p>

          <p>
            <span>
              veebileht, sh kõik selle alamdomeenid ja aliased, koos kõigi selle komponentide, sisu ja toimimiseks
              vajaliku tarkvaraga.
            </span>
          </p>

          <p>
            <span>2.3. Kasutaja on iga isik, kes Platvormi kasutab, sh ainult külastab.</span>
          </p>

          <p>
            <span>
              2.4. Töötaja on Kasutaja, kes on loonud Platvormil kasutajakonto Tööandja poolt talle määratud
              online-koolituste läbimiseks või Tööandja teiste Töötajate online-koolituste haldamiseks.
            </span>
          </p>

          <p>
            <span>
              2.5. Tööandja on isik, kes Complokiga sõlmitud lepingu alusel määrab endaga mingil moel seotud
              füüsilistele isikutele Platvormil läbitavaid online-koolitusi ning tasub nende eest.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>3. Üldised tingimused</span>
            </b>
          </p>

          <p>
            <span>
              3.1. Platvormi kasutamisega, sh ainult Platvormi külastamisega, Kasutaja kinnitab, et on tutvunud ja
              nõustunud Kasutustingimustega ning nendega seotud andmetöötluse põhimõtetega, sh küpsiste kasutamise
              tingimustega, mis on kättesaadavad: https://www.elearning.complok.eu/
            </span>
          </p>

          <p>
            <span>
              3.2. Platvormi ainus eesmärk on võimaldada Kasutajatel läbida online-koolitusi ning saada nende edukat
              läbimist tõendavaid tunnistusi. Platvormi kasutamine ükskõik millisel muul eesmärgil on keelatud.
            </span>
          </p>

          <p>
            <span>
              3.3. Iga isik peab ise veenduma, et tal on õigus Platvormi kasutada. Isik, kellel ei ole õigust Platvormi
              kasutada (näiteks isikule kohalduv õigus keelab isikul Platvormi kaudu online-koolitustel osaleda või
              Eesti õigus keelab Complokil isikule teenust osutada), peab Platvormi kasutamisest igakülgselt hoiduma, sh
              vältima Platvormi külastamist.
            </span>
          </p>

          <p>
            <span>
              3.4. Complokil on õigus koheselt piirata iga isiku ligipääsu Platvormile ja kustutada tema kasutajakonto,
              kui selgub, et isikul puudub mistahes põhjusel õigus Platvormi kasutamiseks.
            </span>
          </p>

          <p>
            <span>
              3.5. Complokil on õigus keelata igal isikul Platvormi kasutamine, kui selle lubamine läheks vastuollu
              Comploki ärieetika, moraalsete väärtuste või avaliku korraga. Complokil on õigus koheselt piirata sellise
              isiku ligipääsu Platvormile ja kustutada tema kasutajakonto.
            </span>
          </p>

          <p>
            <span>
              3.6. Kasutajal on keelatud Platvormi kasutada ükskõik millisel ebaseaduslikul viisil, sh Platvormilt
              autoriõigustega kaitstud teoseid kopeerida, või muul moel Kasutustingimusi rikkuval moel. Complokil on
              õigus koheselt piirata sellise isiku ligipääsu Platvormile ja kustutada tema kasutajakonto.
            </span>
          </p>

          <p>
            <span>
              3.7. Kasutaja kohustub Platvormile sisestama ning muul viisil Complokile edastama ainult õiget ja
              ajakohast infot ning tagama vajalikud uuendused, kui sisestatud informatsioon aegub. Kasutaja vastutab
              kahju eest, mis on põhjustatud Complokile või kolmandale isikule ebaõige info andmisest või info
              uuendamata jätmisest.
            </span>
          </p>

          <p>
            <span>
              3.8. Complokil on õigus igal ajal küsida Kasutajatelt lisainfot, mis on vajalik Comploki teenuste
              osutamiseks, Platvormi toimimiseks või selle eesmärgipärase kasutamise võimaldamiseks. Kui Kasutaja
              lisainfot mõistliku aja jooksul ei anna või keeldub selle andmisest, siis on Complokil õigus koheselt
              piirata Kasutaja ligipääsu Platvormile ja kustutada tema kasutajakonto.
            </span>
          </p>

          <p>
            <span>
              3.9. Tööandja ja Kasutaja peavad hoiduma Platvormiga seotud kohustuste võtmisest, kui nad teavad või pidid
              teadma, et ei suuda neid tõenäoliselt täita.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>4. Platvormi kasutamine</span>
            </b>
          </p>

          <p>
            <span>
              4.1. Platvorm on Kasutustingimustega tutvumise ja nendega nõustumise järel kättesaadav kõikidele isikutele
              Internetis.
            </span>
          </p>

          <p>
            <span>
              4.2. Kasutajakontota Kasutajad pääsevad ligi ainult Platvormi tasuta osale (näiteks saab tutvuda
              Platvormil pakutavate online-koolitustega, tellida uudiskirja ja võtta ühendust kasutajatoega).
            </span>
          </p>

          <p>
            <span>
              4.3. Kasutajakontoga Kasutajad saavad läbida online-koolitusi ning tutvuda neile väljastatud
              tunnistustega.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>5. Kasutajakontod</span>
            </b>
          </p>

          <p>
            <span>5.1. Online-koolituste läbimiseks peab Kasutaja looma Platvormil kasutajakonto.</span>
          </p>

          <p>
            <span>5.2. Complok kustutab kasutajakontod järgmistel põhimõtetel:</span>
          </p>

          <p>
            <span>5.2.1. Kasutaja kasutajakonto Kasutustingimustes ettenähtud juhtudel;</span>
          </p>

          <p>
            <span>
              5.2.2. Töötaja kasutajakonto Tööandjalt saadud korralduse alusel, kuid mitte hiljem kui kolm kuud peale
              Comploki ja Tööandja vahelise lepingu lõppemist.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>

          <p>
            <b>
              <span>6. Küberturvalisus</span>
            </b>
          </p>

          <p>
            <span>
              6.1. Complok ei küsi kunagi Kasutajalt informatsiooni, mis oma olemuselt on salajane (näiteks Platvormi
              kasutajakonto parool).
            </span>
          </p>

          <p>
            <span>6.2. Kasutajal on keelatud anda kolmandatele isikutele ligipääs oma kasutajakontole Platvormil.</span>
          </p>

          <p>
            <span>
              6.3. Kui Kasutaja kaotab Platvormi kasutajakonto parooli või ta kahtlustab, et see on muutunud
              kolmandatele isikutele kättesaadavaks, kohustub Kasutaja sellest Complokile viivitamatult teatama ning
              parooli muutma.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>7. Platvormi toimimise ja sisu kaitsmine</span>
            </b>
          </p>

          <p>
            <span>
              7.1. Kasutaja kinnitab, et ta hoidub igasugusest tegevusest, mille eesmärk on või millega kaasneb:
            </span>
          </p>

          <p>
            <span>7.1.1. Teiste Kasutajate andmete töötlemine, sh mistahes osas või vormis salvestamine;</span>
          </p>

          <p>
            <span>
              7.1.2. Platvormi kasutamine selle eesmärgist erineval viisil, seal hulgas rämpsposti saatmiseks või muuks
              ebasoovitavaks kommunikatsiooniks;
            </span>
          </p>

          <p>
            <span>
              7.1.3. Platvormi töö häirimine mistahes viisil, sealhulgas Platvormi või selle serveri ründamine või muul
              viisil tavapärase toimimise häirimine;
            </span>
          </p>

          <p>
            <span>7.1.4. Teiste Kasutajate takistamine Platvormi kasutamast;</span>
          </p>

          <p>
            <span>
              7.1.5. Platvormi mistahes sisu, välja arvatud Kasutajale väljastatud tunnistuste, kopeerime,
              taasesitamine, modifitseerimine, dubleerimine, kolmandatele isikutele kättesaadavaks tegemine või muul
              viisil ärakasutamine;
            </span>
          </p>

          <p>
            <span>7.1.6. Platvormi mistahes sisu, välja arvatud Kasutaja enda andmete, muutmine või kustutamine;</span>
          </p>

          <p>
            <span>
              7.1.7. Platvormi skaneerimine või muul viisil uurimine tööriistaga, mille eesmärk on välja selgitada
              Platvormi tehniline struktuur ja toimimise põhimõtted, Platvormile salvestatud andmed ja muud Platvormi
              filed.
            </span>
          </p>

          <p>
            <span>
              7.2. Complok annab õiguse avalikele otsingumootoritele kasutada asjakohaseid tööriistu ja taas esitada
              Platvormi sisu ulatuses, mis on vajalikud Platvormi efektiivseks leidmiseks ja avalikkusele tutvustava
              info kuvamiseks avalikes otsingumootorites.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>8. Ligipääsu piiramine Platvormile</span>
            </b>
          </p>

          <p>
            <span>
              8.1. Kui Complok teab, et Kasutaja tegevus on ebaseaduslik või rikub muul viisil Kasutustingimusi, on tal
              õigus koheselt piirata Kasutaja ligipääsu Platvormile ja kustutada tema kasutajakonto.
            </span>
          </p>

          <p>
            <span>
              8.2. Kui Complok kahtlustab, et Kasutaja tegevus on ebaseaduslik või rikub muul viisil Kasutustingimusi,
              on tal õigus koheselt piirata Kasutaja ligipääs tema kasutajakontole. Kasutajal on seejärel aega 14 päeva
              tõestamaks, et Kasutustingimuste rikkumist ei ole toimunud. Kui Kasutaja ettenähtud aja jooksul
              ebaseadusliku käitumise või muul viisil Kasutustingimuste rikkumise kahtlust veenvalt ümber ei lükka, on
              Complokil õigus piirata Kasutaja ligipääsu Platvormile ja kustutada tema kasutajakonto.{' '}
            </span>
          </p>

          <p>
            <span>
              8.3. Complokil on õigus etteteatamata piirata Kasutaja ligipääsu Platvormile või kasutajakontole ja
              kustutada tema kasutajakonto, kui selline vajadus tuleneb õigusaktide muudatusest või mõne riigiasutuse
              antud juhisest.
            </span>
          </p>

          <p>
            <span>
              8.4. Kasutaja Platvormile või kasutajakontole ligipääsu piiramise viivitamisega, Kasutajale selgitusteks
              täiendava võimaluse andmisega ja muude selliste Kasutajat soosivate meetmete rakendamisega ei kao Comploki
              õigus etteteatamata piirata Kasutaja ligipääsu Platvormile või kasutajakontole ja kustutada tema
              kasutajakonto Kasutustingimustes ettenähtud juhtudel.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>9. Comploki vastutuse piirangud</span>
            </b>
          </p>

          <p>
            <span>
              9.1. Comploki vastutus seoses Platvormiga on piiratud kohalduva regulatsiooniga lubatud miinimumini.
              Näiteks ei vastuta Complok kunagi:
            </span>
          </p>

          <p>
            <span>9.1.1. muu kui otsese varalise kahju eest;</span>
          </p>

          <p>
            <span>9.1.2. Kasutustingimuste rikkumise eest, välja arvatud nende tahtlikul rikkumisel;</span>
          </p>

          <p>
            <span>9.1.3. kahju eest, mille on põhjustanud kolmas isik;</span>
          </p>

          <p>
            <span>9.1.4. kahju eest, mille on põhjustanud Platvormi ajutine tehniline tõrge;</span>
          </p>

          <p>
            <span>
              9.1.5. selle eest, kui Kasutaja tunneb ennast diskrimineerituna või solvatuna Platvormil oleva info või
              online-koolituse sisu tõttu, kui sellise info avaldamine või seisukoha väljendamine ei ole Eestis
              keelatud.
            </span>
          </p>

          <p>
            <span>
              9.2. Kasutaja kinnitab, et ei esita kunagi nõuet Comploki juhatuse, töötajate, teenusepakkujate või muude
              seotud isikute vastu seoses Platvormiga.
            </span>
          </p>

          <p>
            <span> </span>
          </p>

          <p>
            <b>
              <span>10. Lõppsätted</span>
            </b>
          </p>

          <p>
            <span>
              10.1. Complok võib Kasutustingimusi ja nendes viidatud dokumente igal ajal muuta. Kasutaja on kohustatud
              muudatustega tutvuma.{' '}
            </span>
          </p>

          <p>
            <span>
              10.2. Complok teavitab Kasutustingimuste ja nendes viidatud dokumentide muutmisest Platvormi kaudu 14
              päeva ette, välja arvatud juhul, kui õigusaktidest või riigiasutuse juhistest tulenevalt peavad muudatused
              jõustuma varem.{' '}
            </span>
          </p>

          <p>
            <span>
              10.3. Kui Kasutaja ei nõustu Kasutustingimuste muudatustega, peab Kasutaja oma kasutajakonto kustutama
              ning hoiduma edaspidi Platvormi kasutamisest.
            </span>
          </p>

          <p>
            <span>
              10.4. Kui Kasutaja jätkab Platvormi kasutamist, kinnitab ta sellega muudatuste sobimist ja ta loetakse
              muudatustega nõustunuks.
            </span>
          </p>

          <p>
            <span>10.5. Kasutustingimused ei anna kolmandatele isikutele mitte mingeid õigusi.</span>
          </p>

          <p>
            <span>
              10.6. Kasutustingimustele ja nende alusel loodud kõigile õigussuhetele kohaldatakse Eesti õigust.{' '}
            </span>
          </p>

          <p>
            <span>
              10.7. Kasutustingimustes reguleerimata küsimustes kohaldatakse asjakohast regulatsiooni heas usus ning
              Kasutustingimuste vaimu silmas pidades.
            </span>
          </p>

          <p>
            <span>
              10.8. Kui Kasutustingimuste mõni säte osutub kohalduva regulatsiooniga vastuolus olevaks, siis ei mõjuta
              see ülejäänud osa Kasutustingimuste kehtivust. Vastuolus säte asendatakse siis kohalduva regulatsiooni
              sellise sättega, mis on oma eesmärgilt võimalikult sarnane asendatavale Kasutustingimuste sättele.{' '}
            </span>
          </p>

          <p>
            <span>
              10.9. Kasutustingimusi ja nende alusel loodud õigussuhteid puudutavad vaidlused lahendatakse Comploki ja
              Kasutaja vaheliste läbirääkimiste teel. Kui läbirääkimistel lahendust ei leita, pöördub huvitatud osapool
              Harju Maakohtusse
            </span>
          </p>
        </div>
      )}
    </Col>
  );
};

export default TermsAndConditions;

const LanguagePopoverContent: FC<{ updateLanguage: (newLang: string) => void }> = ({ updateLanguage }) => {
  const languages = [
    { key: 'en', title: 'English' },
    { key: 'et', title: 'Eesti' }
  ];

  return (
    <div className="complok-header-profile-popover">
      {languages.map((action, index) => (
        <div
          onClick={() => updateLanguage(action.key)}
          className={`complok-header-profile-popover-item ${
            index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
          }`}
          key={`complok-header-popover-action-${index}`}
        >
          {action.title}
        </div>
      ))}
    </div>
  );
};
