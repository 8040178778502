import { Form, Input, message, Modal } from 'antd';
import apiClient from 'api/apiClient';
import { isString } from 'lodash';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserGroup } from 'types/GroupTypes';
import { OrganizationManagementContext } from '../OrganizationManagementContext';

interface AddUsersToGroupModalProps {
  showModal?: unknown;
  closeModal?: () => void;

  onSuccess?: () => void;
}
const CreateGroupModal: FC<AddUsersToGroupModalProps> = ({ showModal, closeModal = () => {}, onSuccess }) => {
  const { organization } = useContext(OrganizationManagementContext);
  const [groupName, setGroupName] = useState<string>();
  const [previousGroupName, setPreviousGroupName] = useState<string>('nana');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const submitCreateGroup = async () => {
    if (!groupName || !organization?._id) return;
    setIsLoading(true);
    const groupCreation = await apiClient
      .post<UserGroup>('/groups', { title: groupName, organization: organization._id })
      .catch((reason) => null);
    if (!groupCreation || isString(groupCreation)) {
      message.error(t("Couldn't create group"));
      setPreviousGroupName(groupName);
    }
    if (groupCreation?.status === 201) {
      message.success(t('Group created'));
      if (onSuccess) onSuccess();
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={t('Create a new group')}
      visible={!!showModal}
      onOk={submitCreateGroup}
      confirmLoading={isLoading}
      okText={t('Create')}
      okButtonProps={{ disabled: !(groupName?.length ?? 0) || previousGroupName === groupName }}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form>
        <Form.Item label={t('Group name')}>
          <Input onChange={(e) => setGroupName(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateGroupModal;
