import axios, { AxiosInstance, AxiosResponse } from 'axios';

class ApiClient {
  contentType: string;

  baseURL: string;

  protected readonly instance: AxiosInstance;

  count: number;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.contentType = 'application/json';
    this.count = 0;
    this.instance = axios.create({
      baseURL,
      timeout: 15000
    });
  }

  setAccount = (accountId: string) => {
    this.instance.defaults.headers.account_id = accountId;
  };

  setAccessToken = (accessToken: string) => {
    this.instance.defaults.headers.common.Authorization = accessToken === null ? null : `Bearer ${accessToken}`;
  };

  request = async (config: any) => {
    const response = await this.instance.request(config);
    this.count += 1;
    return response;
  };

  get = async (url: string, config = {}) =>
    this.request({
      ...config,
      method: 'get',
      url
    });

  post: <T = any>(url: string, data?: any, config?: any) => Promise<AxiosResponse<T>> = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: 'post',
      url,
      data
    });

  patch = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: 'patch',
      url,
      data
    });

  put = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: 'put',
      url,
      data
    });

  delete = async (url: string, config = {}) =>
    this.request({
      ...config,
      method: 'delete',
      url
    });

  options = async (url: string, config = {}) =>
    this.request({
      ...config,
      method: 'options',
      url
    });
}

const apiClient = new ApiClient(process.env.REACT_APP_API ?? '/api/v1');

export default apiClient;
