/* global __DEV__ */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      debug: true,

      // have a common namespace used around the full app
      ns: ['translation'],
      defaultNS: 'translation',

      keySeparator: false, // we use content as keys
      nsSeparator: false,

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
      },

      allowedAddOrUpdateHosts: ['localhost:3000'],

      react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        useSuspense: true,
      },
      backend: {
        referenceLng: 'en',
        apiKey: '0d212264-9259-4a98-882e-3131f69a758c',
        projectId: 'cf87e30e-5d2d-4b61-84b5-9bc64feae4c8',
        crossDomain: true,
      },
      saveMissing: true,
      saveMissingTo: 'all',
    },
    (error, t) => {
      if (!error) {
        return t;
      }
      console.log('error while initializing i18n:', error);
    }
  );

export const languages = [
  { value: 'en', title: 'English', locale: 'en-gb' },
  { value: 'et', title: 'Eesti', locale: 'et' },
  { value: 'ru', title: 'Русский', locale: 'ru' },
];

export default i18n;
