import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICourseHeader } from 'types/Course/CourseTypes';
import { UserGroup } from 'types/GroupTypes';
import CourseDetailsDrawer from '../drawers/CourseDetailsDrawer';

interface IOrganizationCoursesTable {
  courses?: ICourseHeader[];
  groups?: UserGroup[];
}

const OrganizationCoursesTable: FC<IOrganizationCoursesTable> = ({ courses, groups }) => {
  const { t } = useTranslation();
  const [selectedCourse, setSelectedCourse] = useState<string>();

  const courseColumns: ColumnsType<ICourseHeader> = [
    {
      title: t('Title'),
      dataIndex: 'title'
    }
    // {
    //   title: t('Deadline'),
    //   dataIndex: ['configurations', 'deadline'],
    //   render: (deadline) => (deadline ? `${deadline} ${t('months')}` : t('No deadline')),
    // },
  ];

  return (
    <div>
      <Table
        onRow={(rowCourse) => ({ onClick: () => setSelectedCourse(rowCourse._id) })}
        columns={courseColumns}
        rowKey={'_id'}
        dataSource={courses}
      />
      {selectedCourse && (
        <CourseDetailsDrawer
          onClose={() => setSelectedCourse(undefined)}
          groups={groups}
          selectedCourse={selectedCourse}
        />
      )}
    </div>
  );
};

export default OrganizationCoursesTable;
