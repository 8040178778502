/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Pagination, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import ComplokBook from 'assets/illustrations/ComplokBook';
import { keys, sum } from 'lodash';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ICourseQuiz, ICourseQuizQuestion } from 'types/Course/CourseContent/course.content.quiz.types';
import { CourseContentCustomTextSection } from 'types/Course/CourseContent/course.content.types';
import CourseQuestionnaireBanner from './QuestionnaireComponents/CourseQuestionnaireBanner';
import QuestionnaireQuestion from './QuestionnaireComponents/CourseQuestionnaireQuestion';

enum QUIZ_STATE {
  INTRODUCTION,
  QUESTIONS,
  RESULTS
}

interface ICourseContentQuiz {
  content: ICourseQuiz;

  handleCompletion?: () => void;
  handleContinue?: () => void;

  style?: CSSProperties;
}

const CourseQuiz: FC<ICourseContentQuiz> = ({ content, style, handleCompletion, handleContinue }) => {
  const { t } = useTranslation();
  const { title, questions = [] } = content;

  const { pathname } = useLocation();

  const [quizState, setQuizState] = useState<QUIZ_STATE>(QUIZ_STATE.INTRODUCTION);
  const [answeredQuestions, setAnsweredQuestions] = useState<{ [key: string]: boolean | undefined }>({});

  const [questionIndex, setQuestionIndex] = useState<number>(0);

  const customText = content?.custom_text;

  // init quizstate on navigation
  useEffect(() => {
    setQuizState(QUIZ_STATE.INTRODUCTION);
  }, [pathname]);

  const startQuiz = () => {
    setQuestionIndex(0);
    setQuizState(QUIZ_STATE.QUESTIONS);
  };

  const retryQuiz = () => {
    setAnsweredQuestions({});
    setQuestionIndex(0);
    setQuizState(QUIZ_STATE.INTRODUCTION);
  };

  const handleQuestionAnswer = (answers: { [key: string]: boolean | undefined }) => {
    setAnsweredQuestions({ ...answeredQuestions, ...answers });
  };

  const handleNextQuestion = (targetQuestion: number) => {
    if (questions.length <= targetQuestion) {
      setQuizState(QUIZ_STATE.RESULTS);
    } else {
      setQuestionIndex(targetQuestion);
    }
  };

  const unansweredQuestions = questions.reduce((unansweredQuestionIds, question, index) => {
    const answerIds = question.answers.map((answer) => answer._id);
    const tickedAnswersIds = keys(answeredQuestions).filter((answerId) => answeredQuestions[answerId]);
    if (!tickedAnswersIds.find((selectedAnswerId) => answerIds.includes(selectedAnswerId))) {
      return [...unansweredQuestionIds, index + 1];
    }
    return unansweredQuestionIds;
  }, [] as number[]);

  return (
    <div id="complok-coursecontent-quiz" style={{ ...style }}>
      {quizState === QUIZ_STATE.INTRODUCTION && (
        <QuizIntroduction
          amountOfQuestions={questions.length}
          title={title}
          handleStart={startQuiz}
          customText={customText?.intro}
        />
      )}
      {quizState === QUIZ_STATE.QUESTIONS && (
        <QuestionnaireQuestion
          question={questions[questionIndex]}
          currentQuestionIndex={questionIndex}
          totalQuestionCount={questions.length}
          handleNext={handleNextQuestion}
          handleAnswer={handleQuestionAnswer}
          allowSubmit={unansweredQuestions.length === 0}
          submitDeniedMessage={`${t('Please answer question')} ${unansweredQuestions.join(', ')}`}
        />
      )}

      {quizState === QUIZ_STATE.RESULTS && (
        <QuizResults
          answers={answeredQuestions}
          content={content}
          handleRetry={retryQuiz}
          handleCompletion={handleCompletion}
          handleContinue={handleContinue}
          customText={customText?.ending}
        />
      )}
    </div>
  );
};

const QuizIntroduction: FC<{
  title?: string;
  customText?: CourseContentCustomTextSection;
  amountOfQuestions?: number;
  handleStart?: Function;
}> = ({ amountOfQuestions, handleStart, title, customText }) => {
  const { t } = useTranslation();

  const defaultTitle = t('It’s time to test what you’ve learned so far!');

  return (
    <div id="complok-coursecontent-quiz-introduction">
      <ComplokBook id="complok-coursecontent-quiz-introduction-image" />
      <Title level={3} id="complok-coursecontent-quiz-introduction-title">
        {customText?.title ?? title ?? defaultTitle}
      </Title>
      {customText?.text && <Text id="complok-coursecontent-quiz-introduction-text">{customText.text}</Text>}
      {!customText?.text && (
        <Text id="complok-coursecontent-quiz-introduction-text">
          {`${t('You’ll have to answer to')} ${amountOfQuestions ?? 'X'} ${t('questions')}. ${t(
            'You can do it anytime you want, retry the quiz as much as you want'
          )}.`}
          <br />
          <b>
            {t(
              'You’re allowed to use notes you’ve made earlier and allowed to make up to 4 mistakes. Multiple answers are allowed.'
            )}
          </b>
        </Text>
      )}
      <Button
        type={'primary'}
        onClick={() => handleStart && handleStart()}
        id="complok-coursecontent-quiz-introduction-start"
      >
        {t('Start Quiz')}
      </Button>
    </div>
  );
};
interface IQuizResults {
  content: ICourseQuiz;
  answers: { [key: string]: boolean | undefined };

  customText?: {
    success?: CourseContentCustomTextSection;
    failure?: CourseContentCustomTextSection;
  };

  handleRetry?: () => void;
  handleContinue?: () => void;
  handleCompletion?: () => void;
}
const QuizResults: FC<IQuizResults> = ({
  answers,
  content,
  handleRetry,
  handleContinue,
  handleCompletion,
  customText
}) => {
  const { questions } = content;
  const { t } = useTranslation();

  const totalAnswers = sum(questions.map((question) => question.answers.length)) ?? 1;
  const correctAnswers =
    sum(
      questions.map(
        (question) => question.answers.filter((answer) => answer.isCorrect === (answers[answer._id] ?? false)).length
      )
    ) ?? 0;
  const correctlyAnsweredQuestions = questions
    .map((question) =>
      question.answers.reduce((questionCorrectlyAnswered, answer) => {
        const isAnswerCorrect = answer.isCorrect === (answers[answer._id] ?? false);
        if (isAnswerCorrect) return questionCorrectlyAnswered;
        return false;
      }, true)
    )
    .filter((questionAnswers) => questionAnswers === true).length;

  const resultPercentage = (correctlyAnsweredQuestions / Math.max(questions?.length ?? 1, 1)) * 100;
  const quizPassed = resultPercentage >= (content?.pass_percentage ?? 0);

  useEffect(() => {
    if (quizPassed) {
      if (handleCompletion) handleCompletion();
    }
  }, []);

  return (
    <div id="complok-coursecontent-quiz-result">
      <CourseQuestionnaireBanner
        percentage={resultPercentage}
        handleContinue={handleContinue}
        handleRetry={handleRetry}
        customText={{
          title: {
            success: customText?.success?.title,
            neutral: customText?.success?.title,

            failure: customText?.failure?.title
          },
          description: {
            success: customText?.success?.text,
            neutral: customText?.success?.text,

            failure: customText?.failure?.text
          }
        }}
        thresholds={{
          success: 66,
          failure: 66,
          allowContinue: false
        }}
      />
      <div id="complok-coursecontent-quiz-result-questions">
        {questions.map((question) => (
          <div id="complok-coursecontent-quiz-result-questions-question" key={question._id}>
            <h2 id="complok-coursecontent-quiz-result-questions-question-title">{question.question}</h2>
            <div id="complok-coursecontent-quiz-result-questions-question-answers">
              {question.answers.map((answer) => (
                <div
                  key={answer._id}
                  id="complok-coursecontent-quiz-result-questions-question-answers-answer"
                  className={answers[answer._id] ? 'gray' : 'white'}
                >
                  <Checkbox
                    checked={answers[answer._id] ?? false}
                    className={`answer-checkbox 
                    ${
                      ''
                      //  ? 'wrong-answer-checkbox'
                      //  : 'correct-answer-checkbox'
                    }`}
                  >
                    <Row>
                      <span
                        id="complok-quiz-result-questions-question-answers-answer-text"
                        className={answer.isCorrect ? 'correct' : 'incorrect'}
                      >
                        {answer.answer}
                        {answer.isCorrect && <CheckOutlined style={{ marginLeft: 5 }} />}
                        {!answer.isCorrect && <CloseOutlined style={{ marginLeft: 5 }} />}
                      </span>
                    </Row>
                  </Checkbox>
                </div>
              ))}
              <h3>{question.explanation}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const isQuestionCorrectlyAnswered = (question: ICourseQuizQuestion, answers: { [key: string]: boolean | undefined }) =>
  !question.answers.find((answer) => answer.isCorrect !== (answers[answer._id] ?? false));

const isAnswerCorrect = (question: ICourseQuizQuestion, userHasCheckedAnswer: boolean) => question;

export default CourseQuiz;
