import apiClient from 'api/apiClient';
import { isArray } from 'lodash';
import { utils as csvUtils, writeFile } from 'xlsx';

const downloadOrganizationProgress = async({ group_id, file_name, organization }: { group_id?: string; file_name?: string; organization: string }) => {
  const orgProgressData = await apiClient
    .get('/courses/progress_stats/all', { params: { ...group_id && {group_id}, organization } })
    .then((res) => res.data)
    .catch((err) => {
      console.error('Error while fetching organization progress', err);
    });
  if (orgProgressData) {
    const filteredOrgProgress = orgProgressData.filter((userData: any) => userData?.name);
    const tableCompatibleOrgProgress = filteredOrgProgress.map((userData: any) => {
      delete userData._id;
      if (isArray(userData?.courses)) {
        const courseProgressesByTitle = userData.courses.reduce(
          (progresses: { [key: string]: string }, progress: any) => {
            if (progress.course_title) {
              progresses[progress.course_title] = `${
                progress?.course_completed === true ? '100%' : `${progress?.course_completion_percentage ?? 0}%`
              }`;
            }
            return progresses;
          },
          {}
        );
        userData = { ...userData, ...courseProgressesByTitle };
      }
      delete userData.courses;
      return userData;
    });
    const worksheet = csvUtils.json_to_sheet(tableCompatibleOrgProgress);
    const workbook = csvUtils.book_new();
    csvUtils.book_append_sheet(workbook, worksheet);
    writeFile(workbook, `${file_name ?? 'Users_Progress_Data'}.csv`);
  }
};

export default downloadOrganizationProgress;
