import { SVGProps } from 'react';

interface IChevron {
  thin?: boolean;
  direction: 'right' | 'left' | 'up' | 'down';
}
const Chevron = (props: SVGProps<SVGSVGElement> & IChevron) => {
  const strokeWidth = props.thin ? 1 : 2;
  const paths = {
    up: 'M17 14.5L11.5 9L6 14.5',
    down: 'M7 9L12.5 14.5L18 9',
    left: 'M15 7L9.5 12.5L15 18',
    right: 'M9 18L14.5 12.5L9 7',
  };
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d={paths[props.direction]}
        stroke="#171725"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chevron;
