import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#prefix__clip0_2156:35860)">
      <rect width={30} height={30} rx={15} fill="#fff" />
      <path d="M-7.143 0v30h44.681V0h-44.68z" fill="#4758A9" />
      <path d="M-7.143 0l44.681 30-44.68-30zm44.681 0l-44.68 30z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.637 15L-8.25 1.648l2.213-3.296 21.234 14.257L36.432-1.648l2.213 3.296L18.759 15l19.886 13.352-2.213 3.296-21.234-14.257-21.234 14.257-2.213-3.296L11.637 15z"
        fill="#fff"
      />
      <path d="M-7.143 0l44.681 30-44.68-30zm44.681 0l-44.68 30z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.824 15L-7.88 1.099-6.404-1.1l21.602 14.505L36.801-1.1 38.276 1.1 17.572 15l20.704 13.901-1.475 2.198-21.603-14.505L-6.404 31.1-7.88 28.9 12.824 15z"
        fill="#ED5565"
      />
      <path d="M15.198 0v30V0zm-22.34 15h44.68z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.506 18.309V30H11.89V18.309H-7.143V11.69H11.89V0h6.617v11.691h19.032v6.618H18.507z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.372 13.015V0h3.97v13.015h20.515v3.97H17.343V30h-3.97V16.985H-7.144v-3.97h20.515z"
        fill="#ED5565"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_2156:35860">
        <rect width={30} height={30} rx={15} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
