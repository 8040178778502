import AddUsersFromFileModal from "components/modals/InviteUsersModal/AddUsers/AddUsersFromFile";
import { UserContext } from "context/UserContext";
import { fetcher } from "index";
import {
  createContext,
  Dispatch,
  FC,
  useContext,
  useReducer,
  useState,
} from "react";
import { useParams } from "react-router";
import useSWR from "swr";
import { DetailedUserGroup, UserGroup } from "types/GroupTypes";
import { Organization } from "types/OrganizationTypes";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import AddAdministratorsModal from "./modals/AddAdministratorsModal";
import AddUsersToGroupModal from "./modals/AddUsersToGroupModal";
import AssignCourseToGroupModal from "./modals/AssignCourseToGroupModal";
import CreateGroupModal from "./modals/CreateGroupModal";
import CreateOrganizationModal from "./modals/CreateOrganizationModal";
import EditOrganizationModal from "./modals/EditOrganizationModal";
import {
  ManagementModal,
  managementModalReducer,
  MANAGEMENT_MODALS,
} from "./modals/management.modals";

export interface OrganizationManagementContextValues {
  modalAction: Dispatch<ManagementModal>;

  organization?: Organization;
  refreshOrganization: () => void;

  organizations?: Organization[];
  refreshOrganizations: () => void;

  updateOrganization: (newOrg: string) => void;
  groups: UserGroup[];
  groupsLoading?: boolean;
  refreshGroups: () => void;

  group?: DetailedUserGroup;
  isGroupLoading?: boolean;
  refreshGroup?: () => void;
}

export const OrganizationManagementContext =
  createContext<OrganizationManagementContextValues>({
    groups: [],
    refreshOrganizations: () => {},
    updateOrganization: () => {},
    modalAction: () => {},
    refreshOrganization: () => {},
    refreshGroups: () => {},
    refreshGroup: () => {},
  });

export const OrganizationManagementContextProvider: FC = ({ children }) => {
  const { groupIdentifier } = useParams();

  const { accountData } = useContext(UserContext);
  const [selectedOrganization, setSelectedOrganization] = useState<
    string | undefined
  >(accountData?.organization);

  const { data: organization, mutate: refreshOrganization } =
    useSWR<Organization>(
      selectedOrganization
        ? `/organizations/details/${selectedOrganization}`
        : null,
      fetcher,
      { refreshInterval: 20000 }
    );
  const { data: organizations, mutate: refreshOrganizations } = useSWR<
    Organization[]
  >(
    accountData?.access_level === USER_ACCESS_LEVEL.SUPER_ADMIN
      ? "organizations"
      : null,
    fetcher,
    { refreshInterval: 10000 }
  );

  const {
    data: groups = [],
    isValidating: groupsLoading,
    mutate: refreshGroups,
  } = useSWR<UserGroup[]>(
    organization?._id ? `/groups/?organization_id=${organization?._id}` : null,
    fetcher,
    { refreshInterval: 10000 }
  );
  const {
    data: group,
    isValidating: isGroupLoading,
    mutate: refreshGroup,
  } = useSWR<DetailedUserGroup>(
    groupIdentifier ? `/groups/${groupIdentifier}` : null,
    fetcher,
    { refreshInterval: 10000 }
  );

  const [modal, modalAction] = useReducer(
    managementModalReducer,
    undefined as ManagementModal
  );
  const closeModal = () => modalAction({});

  const closeModalAndRefreshGroups = () => {
    closeModal();
    refreshOrganization();
    refreshGroups();
  };

  const closeModalAndRefreshGroup = () => {
    refreshOrganization();
    closeModal();
    refreshGroup();
  };

  const contextValues = {
    organization,
    organizations,

    groups,
    groupsLoading,

    group,
    isGroupLoading,
  };
  const contextFunctions = {
    refreshOrganization,
    refreshOrganizations,

    refreshGroups,
    refreshGroup,
    updateOrganization: (newOrg: string) => setSelectedOrganization(newOrg),
    modalAction,
  };

  return (
    <OrganizationManagementContext.Provider
      value={{ ...contextValues, ...contextFunctions }}
    >
      {children}

      <CreateGroupModal
        showModal={modal?.type === MANAGEMENT_MODALS.CREATE_GROUP}
        closeModal={closeModal}
        onSuccess={closeModalAndRefreshGroups}
      />

      <AddUsersToGroupModal
        group={group}
        showModal={modal?.type === MANAGEMENT_MODALS.ADD_USERS}
        onSuccess={closeModalAndRefreshGroup}
        onCancel={closeModal}
      />

      <AddUsersFromFileModal
        group={group?._id}
        onSuccess={closeModalAndRefreshGroup}
        onCancel={closeModal}
        showModal={modal?.type === MANAGEMENT_MODALS.ADD_USERS_FROM_FILE}
      />
      <AssignCourseToGroupModal
        group={group}
        organization={organization}
        onSuccess={closeModalAndRefreshGroup}
        onCancel={closeModal}
        showModal={modal?.type === MANAGEMENT_MODALS.ASSIGN_COURSES}
      />

      <CreateOrganizationModal
        showModal={modal?.type === MANAGEMENT_MODALS.CREATE_ORGANIZATION}
        onSuccess={() => {
          closeModal();
          refreshOrganizations();
        }}
        onCancel={closeModal}
      />
      <AddAdministratorsModal
        organization={organization?._id}
        onSuccess={() => {
          closeModal();
        }}
        onCancel={closeModal}
        showModal={modal?.type === MANAGEMENT_MODALS.ADD_ADMINISTRATORS}
      />

      <EditOrganizationModal
        showModal={modal?.type === MANAGEMENT_MODALS.EDIT_ORGANIZATION}
        onSuccess={() => {
          closeModal();
        }}
        closeModal={closeModal}
        organization={organization?._id}
      />
    </OrganizationManagementContext.Provider>
  );
};

export default OrganizationManagementContextProvider;
