/* eslint-disable operator-linebreak */
import { Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { UserContext } from 'context/UserContext';
import { flatMap, isNumber } from 'lodash';
import { CSSProperties, FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ICourseExam } from 'types/Course/CourseContent/course.content.exam.types';
import { ICourseQuiz, isTypeofQuiz } from 'types/Course/CourseContent/course.content.quiz.types';
import { COURSE_CONTENT } from 'types/Course/CourseContent/course.content.types';
import { ICourseVideo, isTypeofVideo } from 'types/Course/CourseContent/course.content.video.types';
import { USER_ACCESS_LEVEL } from 'types/UserTypes';
import { LearnerContext } from '../LearnerContext';
import CourseCompletion from './CourseComponents/CourseCompletion';
import CourseDetails from './CourseComponents/CourseDetails';
import CourseExam from './CourseComponents/CourseExam';
import CourseNavigation from './CourseComponents/CourseNavigation';
import CourseQuiz from './CourseComponents/CourseQuiz';
import CourseVideo, { CourseVideoComponent } from './CourseComponents/CourseVideo';
import { CourseContext } from './CourseContext';

const CourseContent: FC = () => {
  const {
    course,
    courseProgress,
    videoStartTime,
    setVideoStartTime,
    advanceCourseProgress,
    completeCourse,
    unlockSubject
  } = useContext(CourseContext);

  const isSmallScreen = useBreakpoint().md === false;
  const { setHeaderNote } = useContext(LearnerContext);
  const { accountData } = useContext(UserContext);
  const { t } = useTranslation();

  const { moduleIdentifier, subjectIdentifier } = useParams();

  const navigate = useNavigate();
  const [showNavigation, setShowNavigation] = useState<boolean>(true);

  const allSubjects = flatMap(course?.ordered_modules ?? [], (mod) => mod?.ordered_subjects);
  const examUnlocked = allSubjects.length <= (courseProgress?.completed_subjects?.length ?? 0);

  const courseVideoRef = useRef<CourseVideoComponent>(null);

  const currentSubject =
    course?.ordered_modules
      ?.find((module) => module._id === moduleIdentifier)
      ?.ordered_subjects?.find((subject) => subject._id === subjectIdentifier) ?? undefined;

  const currentSubjectHasBeenCompleted =
    currentSubject && courseProgress?.completed_subjects?.includes(currentSubject?._id);

  const isQuiz = currentSubject?.content_type === COURSE_CONTENT.QUIZ && isTypeofQuiz(currentSubject?.content);
  const isVideo = currentSubject?.content_type === COURSE_CONTENT.VIDEO && isTypeofVideo(currentSubject?.content);
  const isExam = currentSubject?.content_type === COURSE_CONTENT.EXAM && currentSubject?.content;
  const isCertificate = subjectIdentifier === 'certificate' && courseProgress?.has_completed_course;

  const handleSubjectCompletion = async (exam: boolean = false, autonext: boolean = false) => {
    if (!currentSubject?._id) return;
    await advanceCourseProgress(currentSubject._id);
    const passedSubjectIndex = allSubjects.findIndex((sub) => sub._id === currentSubject._id) ?? undefined;

    if (!isNumber(passedSubjectIndex)) return;

    if (exam) {
      completeCourse();
      return;
    }

    if (passedSubjectIndex + 1 >= allSubjects.length) {
      return;
    }
    if (allSubjects[passedSubjectIndex + 1]?._id) {
      await unlockSubject(allSubjects[passedSubjectIndex + 1]?._id);
    }
    if (autonext) handleNextSubject();
  };

  const handleNextSubject = (courseCompleted: boolean = false) => {
    if (!course?._id || !currentSubject?.content) return;
    if (courseCompleted) {
      setShowNavigation(false);
      navigate(`/courses/${course._id}/${currentSubject.module}/certificate`);
      return;
    }
    const passedSubjectIndex = allSubjects.findIndex((sub) => sub._id === currentSubject._id) ?? undefined;
    if (!isNumber(passedSubjectIndex) || passedSubjectIndex + 1 >= allSubjects.length) {
      navigate(`/courses/${course._id}`);
    }
    const nextSubject = allSubjects[passedSubjectIndex + 1];
    if (nextSubject) navigate(`/courses/${nextSubject.course}/${nextSubject.module}/${nextSubject._id}`);
  };

  useEffect(() => {
    if (subjectIdentifier === 'certificate') setShowNavigation(false);
    if (currentSubject && setHeaderNote) {
      setHeaderNote(`${t('Currently subject')}: ${currentSubject.title}`);
    }
  }, [currentSubject, setHeaderNote, subjectIdentifier, t]);

  const showFullContent = isCertificate;

  const mainContentStyle: CSSProperties = {
    gridRow: '2/3',
    gridColumn: showFullContent ? '1/4' : '2/3',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  };
  mainContentStyle.padding = showFullContent ? 0 : mainContentStyle.padding;

  const renderCourseContent = () => {
    if (isCertificate) return <CourseCompletion />;
    if (!currentSubject?.content) return <Spin></Spin>;
    if (isExam) {
      return (
        <CourseExam
          content={currentSubject.content as ICourseExam}
          handleCompletion={handleSubjectCompletion}
          handleContinue={() => handleNextSubject(true)}
        />
      );
    }
    if (isQuiz) {
      return (
        <CourseQuiz
          content={currentSubject.content as ICourseQuiz}
          handleCompletion={handleSubjectCompletion}
          handleContinue={handleNextSubject}
        />
      );
    }
    if (isVideo) {
      return (
        <CourseVideo
          ref={courseVideoRef}
          subject={currentSubject}
          content={currentSubject.content as ICourseVideo}
          time={videoStartTime}
          handleVideoCompletion={() => handleSubjectCompletion(false, true)}
          handleTimeUpdate={() => setVideoStartTime(-1)}
          enableSkipping={(currentSubjectHasBeenCompleted || course?.configuration?.skipping) ?? false}
          initialFurthestPoint={
            courseProgress?.latest_subject_progress?._id === currentSubject?._id
              ? courseProgress?.latest_subject_progress?.progress ?? 0
              : 0
          }
          syncFurthestPoint
        />
      );
    }
    return <Spin></Spin>;
  };

  window.scrollTo(0, 0);

  return (
    <div id="complok-coursecontent" className={`${showNavigation && !isSmallScreen ? 'open-menu' : ''}`} style={{}}>
      <div id="complok-coursecontent-main" style={mainContentStyle}>
        {renderCourseContent()}
        {/* Details */}
        {currentSubject?.content && (
          <CourseDetails
            subject={currentSubject}
            style={{ gridArea: '2/2/3/3' }}
            setTime={courseVideoRef?.current?.setTime}
          />
        )}
      </div>

      {/* Navigation */}
      {!isSmallScreen && (
        <CourseNavigation
          adminAccess={accountData?.access_level === USER_ACCESS_LEVEL.ADMIN}
          course={course}
          courseProgress={courseProgress}
          showNavigation={showNavigation}
          handleToggleNavigation={setShowNavigation}
          stylesOpen={{ marginRight: 75 }}
          hasExamUnlocked={examUnlocked}
        />
      )}
    </div>
  );
};

export default CourseContent;
