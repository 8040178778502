/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import AdminTag from 'components/icons/AdminTag';
import ComplokIcon from 'components/icons/ComplokIcon';
import StudentTag from 'components/icons/StudentTag';
import SuperAdminTag from 'components/icons/SuperAdminTag';
import LanguagePicker from 'components/inputs/LanguagePicker';
import { SettingsContext } from 'context/SettingsContext';
import { UserContext } from 'context/UserContext';
import { getInitials } from 'helpers';
import i18n from 'i18n';
import { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { LearnerContext } from 'screens/Learner/LearnerContext';
import { USER_ACCESS_LEVEL } from 'types/UserTypes';
import './Header.scss';

interface IHeader {
  links: {
    name: string;
    path: string;
  }[];
}

const returnAccountOptionByAccessLevel = (access_level: USER_ACCESS_LEVEL | undefined) => {
  switch (access_level) {
    case USER_ACCESS_LEVEL.SUPER_ADMIN:
      return {
        text: i18n.t('Super admin'),
        icon: <SuperAdminTag />
      };
    case USER_ACCESS_LEVEL.ADMIN:
      return {
        text: i18n.t('Admin'),
        icon: <AdminTag />
      };
    case USER_ACCESS_LEVEL.LEARNER:
      return {
        text: i18n.t('Student'),
        icon: <StudentTag />
      };
    default:
      console.log('default');
      return { text: 'Error', icon: null };
  }
};

const Header: FC<IHeader> = ({ links = [] }) => {
  const { headerNote } = useContext(LearnerContext);
  const { userData, logout, accountData, accounts, refreshAccounts, accountsLoading, selectAccount } =
    useContext(UserContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const isSmallScreen = useBreakpoint().md === false;

  const pathNameIsCourseSubject = pathname && !!pathname.match(/(courses\/[\w\d]+\/[\w\d]+\/[\w\d]+)/);
  const showHeaderNote = pathNameIsCourseSubject;

  const popoverActions = [
    { title: userData?.name ?? (t('No user') as string) },
    { title: t('Log out') as string, action: logout }
  ];

  const mobilePopoverActions = [
    { title: userData?.name ?? (t('No user') as string) },
    { title: t('Settings') as string, action: () => navigate('/settings') },
    { title: t('Log out') as string, action: logout }
  ];

  // TODO: Move account and accounts fetching and switching functionality to UserContext
  const accountMobileUserActions =
    accounts?.map((account) => ({
      title: (
        <div style={{ borderBottom: '2px solid #f4a261', height: 30 }}>
          {t(returnAccountOptionByAccessLevel(account.access_level).text)}
        </div>
      ),
      action: () => selectAccount(account._id)
    })) ?? [];
  const mobilePopoverActionsWithAccounts = accounts
    ? [...mobilePopoverActions, ...accountMobileUserActions]
    : mobilePopoverActions;

  const userName = userData?.name ?? null;

  const accountSelectorDefaultVal = accountData?._id || '';

  return (
    <nav className="complok-header">
      <div className="complok-header-left">
        <Link to={'/'} className="complok-header-icon-link">
          <ComplokIcon />
        </Link>
        <div className="complok-header-divider"></div>
        <div className="complok-header-navigation">
          {(links.length &&
            links.map((link, locationIndex) => (
              <Link
                key={`complok-header-navigation-locartion-${locationIndex}`}
                className={`complok-header-navigation-location ${
                  pathname.startsWith(link.path) ? 'complok-header-navigation-location-active' : ''
                }`}
                to={link.path}
              >
                <h3>{t(link.name)}</h3>
              </Link>
            ))) ||
            null}
        </div>
      </div>
      <div className="complok-header-right">
        <HeaderNote note={showHeaderNote ? headerNote : undefined} />
        <span id="complok-header-role-selector">
          <Select
            defaultValue={accountSelectorDefaultVal}
            loading={accountsLoading}
            className="complok-header-selector"
            onDropdownVisibleChange={(open) => open && refreshAccounts()}
            onSelect={selectAccount}
          >
            {accounts
              ?.sort((accountA, accountB) => accountA.access_level - accountB.access_level)
              .map((userAccount) => {
                const { icon, text } = returnAccountOptionByAccessLevel(userAccount.access_level);

                return (
                  <Select.Option key={userAccount._id} value={userAccount._id}>
                    <Row align="middle" wrap={false} className="complok-header-selector-option">
                      <div className="complok-header-selector-option-icon">{icon}</div>
                      <div className="complok-header-selector-option-text">{text}</div>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        </span>
        <LanguagePicker />
        <div className="complok-header-divider"></div>
        <Popover
          className="complok-header-popover"
          trigger={'click'}
          placement={'bottomRight'}
          onVisibleChange={(visible) => visible && isSmallScreen && refreshAccounts()}
          content={() => (
            <ProfilePopoverContent actions={isSmallScreen ? mobilePopoverActionsWithAccounts : popoverActions} />
          )}
        >
          {userName ? (
            <div className="complok-header-user-name">{getInitials(userName)}</div>
          ) : (
            <div className="complok-header-profile">
              <Avatar size={30} icon={<UserOutlined />} />
            </div>
          )}
        </Popover>
      </div>
    </nav>
  );
};

const HeaderNote: FC<{ note?: string }> = ({ note }) => (
  <div
    id="complok-header-note"
    style={{
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
      padding: '5px 15px',
      marginRight: '11px',
      border: '1px solid #d9d9d9',
      borderRadius: 7,
      display: note ? 'block' : 'none'
    }}
  >
    {note}
  </div>
);

const ProfilePopoverContent: FC<{
  actions: { title: string | ReactNode; action?: Function }[];
}> = ({ actions = [] }) => (
  <div className="complok-header-profile-popover">
    {actions.map((action, index) => (
      <div
        className={`complok-header-profile-popover-item ${
          index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
        }`}
        onClick={() => action.action && action.action()}
        key={`complok-header-popover-action-${index}`}
      >
        {action.title}
      </div>
    ))}
  </div>
);

export default Header;
