import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Select, Switch } from "antd";
import Title from "antd/lib/typography/Title";
import Typhography from "antd/lib/typography/Typography";
import apiClient from "api/apiClient";
import GenericModal from "components/modals/GenericModal";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { CourseEditContext } from "./CourseEdit";

const { Option } = Select;

const CourseEditSettings: FC = () => {
  const { course, updateCourseConfiguration } = useContext(CourseEditContext);
  const navigate = useNavigate();

  const [deadline, setDeadline] = useState(
    // dayjs.duration({ milliseconds: course?.configuration?.deadline ?? 0 }).asMonths()
    course?.configuration?.deadline ?? 0
  );
  const [skipModules, setSkipModules] = useState(
    course?.configuration?.skipping ?? false
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const changeDeadline = async (numberOfTimeUnits: number) => {
    if (!course) return;
    const newDeadline = Math.max(numberOfTimeUnits, 0);
    setDeadline(newDeadline);
    // const deadlineAsMilliseconds = dayjs.duration({ months: deadline }).asMilliseconds();
    updateCourseConfiguration({ duedate: newDeadline });
  };

  const toggleDeadline = async (enable: boolean) => {
    if (!course) return;
    const newDeadline = enable ? 0 : -1;
    setDeadline(newDeadline);
    updateCourseConfiguration({ duedate: newDeadline });
  };

  const changeModuleSkipping = async (enableSkipping: boolean) => {
    if (!course) return;
    setSkipModules(enableSkipping);
    updateCourseConfiguration({ skipping: enableSkipping });
  };

  const changeCourseLocale = async (newLocale: string) => {
    if (!course || newLocale === course?.configuration?.locale) return;
    updateCourseConfiguration({ locale: newLocale });
  };

  const changeSuggestedDuration = async (suggestedDuration: number) => {
    if (
      !course ||
      suggestedDuration === course?.configuration?.suggested_duration
    )
      return;
    updateCourseConfiguration({ suggested_duration: suggestedDuration });
  };

  const deleteCourse = async () => {
    if (!course) return;
    await apiClient.delete(`/courses/${course._id}`).catch(() => null);
    setDeleteModalVisible(false);
    navigate("/courses");
  };

  const modalTitle = (
    <div className="super-admin-create-course-content-delete-course-header">
      <ExclamationCircleOutlined
        style={{
          marginRight: 20,
          color: "#F5222D",
          fontSize: 21,
          minHeight: 30,
          minWidth: 30,
          height: 30,
          width: 30,
        }}
      />
      <label className="super-admin-create-course-content-delete-course-header-title">
        Delete course
      </label>
    </div>
  );

  const modalContent = (
    <label>Are you sure that you want to delete this course?</label>
  );

  const deadlineEnabled = deadline >= 0;

  return (
    <div id="complok-course-edit-content-settings">
      <Title level={2} id="complok-course-edit-content-settings-title">
        Course Settings
      </Title>

      <div id="complok-course-edit-content-settings-options">
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Default Deadline
              </Title>
              <Switch checked={deadlineEnabled} onChange={toggleDeadline} />
            </div>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {deadlineEnabled && (
              <Typhography>
                Course must be completed in{" "}
                <InputNumber
                  value={deadline ?? 0}
                  onChange={(val) => val !== null && changeDeadline(val)}
                />{" "}
                months
              </Typhography>
            )}
            {!deadlineEnabled && (
              <Typhography>No default deadline set</Typhography>
            )}
          </div>
        </Card>
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Module skipping
              </Title>
              <Switch checked={!!skipModules} onChange={changeModuleSkipping} />
            </div>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typhography>
              Module skipping allows learners to skip/pass modules and complete
              them later
            </Typhography>
          </div>
        </Card>
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Course Language
              </Title>
              <Select
                defaultValue={
                  course?.configuration?.locale === "EN" ? "English" : "Eesti"
                }
                onChange={(selectedLocale) =>
                  changeCourseLocale(selectedLocale)
                }
              >
                <Option value="EE">Eesti</Option>
                <Option value="EN">English</Option>
              </Select>
            </div>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typhography>
              Main language that given course is taught in
            </Typhography>
          </div>
        </Card>

        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Suggested course duration
              </Title>
            </div>
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typhography>
              <Form
                onFinish={(data) => changeSuggestedDuration(data?.hours ?? 0)}
              >
                <Form.Item noStyle name="hours">
                  <div style={{ display: "flex" }}>
                    <Input
                      type={"number"}
                      suffix={"Hours"}
                      defaultValue={course?.configuration?.suggested_duration}
                    />

                    <Button type={"primary"} htmlType={"submit"}>
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Typhography>
          </div>
        </Card>

        <div
          className="super-admin-create-course-settings-container-item"
          style={{ background: "#f1f1f5" }}
        />
      </div>
      <Button
        onClick={() => setDeleteModalVisible(true)}
        danger
        style={{ marginTop: 160 }}
      >
        Delete course
      </Button>
      <GenericModal
        visible={deleteModalVisible}
        onOk={deleteCourse}
        title={modalTitle}
        okText={"Delete"}
        okType={"danger"}
        onCancel={() => setDeleteModalVisible(false)}
        closeIcon={<CloseOutlined />}
      >
        {modalContent}
      </GenericModal>
    </div>
  );
};

export default CourseEditSettings;
