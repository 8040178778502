import { CloudDownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ComplokHopping from "assets/illustrations/ComplokHopping";
import { UserContext } from "context/UserContext";
import { downloadCourseCertificate } from "features/certificate";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { CourseContext } from "../CourseContext";

const CourseCompletion: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { course, courseProgress } = useContext(CourseContext);
  const isSmallScreen = useBreakpoint().md === false;

  const userFullName = userData?.name ?? undefined;

  const downloadCertificate = () => {
    if (
      !courseProgress?.has_completed_course ||
      !courseProgress?._id ||
      !course
    )
      return;
    downloadCourseCertificate(
      {
        participant: userData?.name,
        titles: {
          course: course?.title,
          certificate: t("Certificate").toUpperCase(),
        },
        identifier: courseProgress?._id,
        hasDoneText: t("has completed"),
        durationText: course?.configuration?.suggested_duration
          ? `${t("Course duration:")} ${
              course.configuration.suggested_duration
            } ${t("hours")}`
          : undefined,
        date: courseProgress?.course_completed_at || courseProgress?.updated_at,
        additionalText: course?.additional_certificate_text
          ? t(course.additional_certificate_text)
          : "",
        locale: userData?.locale ?? "en",
        lecturer: `${t("Lecturer")} Mari-Liis Kurg`,
        registryText:
          t(`Complok Ltd (reg. number 16034780) is operating under the Estonian continuing
        education license number 229603`),
        signerAdditionalInformation: t("Founder & CEO of Complok"),
        signedBy: "Mari-Liis Kurg",
        createdBy: t("This course is created by Complok team of AML experts"),
      },
      course.title
    );
  };
  const downloadNotes = () => {};
  const rerouteToDashboard = () => navigate("/");

  if (isSmallScreen) {
    return (
      <Col span={24} style={{ padding: 20 }}>
        <Col onClick={() => navigate(`/courses/${course?._id}`)}>
          <LeftOutlined /> {t("Back to course")}
        </Col>
        <Col
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ComplokHopping
            style={{
              width: "174px",
              height: "242px",
              marginBottom: 20,
              marginTop: 40,
            }}
          />
          <h1 style={{ fontWeight: 600, width: "100%" }}>
            {t("Well done! You have earned your certificate!")}
          </h1>
          <p id="complok-coursecontent-completion-content-text">
            {t("Congratulations on finishing the course")}
            <br /> {course?.title}.
          </p>
        </Col>
        <Col id="complok-coursecontent-completion-content-actions">
          <Button
            size="large"
            title={
              userFullName ? "Download certificate" : "Users fullname not added"
            }
            disabled={!userFullName}
            type={"primary"}
            onClick={downloadCertificate}
          >
            {t("Download certificate")} <CloudDownloadOutlined />
          </Button>

          <Button
            size="large"
            id="complok-coursecontent-completion-content-actions-dashboard"
            onClick={rerouteToDashboard}
          >
            {t("Back to dashboard")}
          </Button>
        </Col>
      </Col>
    );
  }

  return (
    <div id="complok-coursecontent-completion">
      <div id="complok-coursecontent-completion-image">
        <ComplokHopping />
      </div>
      <div id="complok-coursecontent-completion-content">
        <h1 id="complok-coursecontent-completion-content-title">
          {t("Well done! You have earned your certificate!")}
        </h1>
        <p id="complok-coursecontent-completion-content-text">
          {t("Congratulations on finishing the course")} {course?.title}.
        </p>
        <div id="complok-coursecontent-completion-content-actions">
          <Button
            title={
              userFullName ? "Download certificate" : "Users fullname not added"
            }
            disabled={!userFullName}
            type={"primary"}
            id="complok-coursecontent-completion-content-actions-certificate"
            onClick={downloadCertificate}
          >
            {t("Download certificate")} <CloudDownloadOutlined />
          </Button>
          <Button
            disabled
            id="complok-coursecontent-completion-content-actions-notes"
            onClick={downloadNotes}
          >
            {t("Download Notes")} <CloudDownloadOutlined size={2} />
          </Button>
          <Button
            id="complok-coursecontent-completion-content-actions-dashboard"
            onClick={rerouteToDashboard}
          >
            {t("Back to dashboard")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CourseCompletion;
