import { SVGProps } from 'react';

const ComplokCertificateScroll = (props: SVGProps<SVGSVGElement>) => (
  <svg width="162" height="145" viewBox="0 0 162 145" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2243_25932)">
      <path
        d="M73.4207 143.321C40.4631 143.321 23.5441 129.673 13.6694 99.0422C3.79468 68.4118 20.8395 26.3343 54.6776 20.7365C88.5157 15.1388 89.7108 4.76089 107.573 9.98127C125.436 15.2016 141.789 34.2592 149.902 56.6502C158.016 79.0412 142.103 106.212 123.863 121.307C105.624 136.402 92.7298 143.321 73.4207 143.321Z"
        fill="#172845"
      />
      <path
        d="M81.5344 1.67893C86.4403 3.8174 76.1253 9.91832 66.4393 12.7486C56.7533 15.579 48.8913 10.8618 54.0488 6.33324C59.2063 1.80472 72.5403 -2.28353 81.5344 1.67893Z"
        fill="#172845"
      />
      <path
        d="M79.6472 144.956C118.587 144.956 150.154 113.389 150.154 74.4498C150.154 35.5101 118.587 3.94324 79.6472 3.94324C40.7075 3.94324 9.14062 35.5101 9.14062 74.4498C9.14062 113.389 40.7075 144.956 79.6472 144.956Z"
        fill="url(#paint0_radial_2243_25932)"
      />
      <path
        d="M12.1594 98.7277C10.9644 98.9792 7.3164 97.6584 5.93268 95.5828C4.54897 93.5073 19.7698 94.7652 19.7698 94.7652C19.7698 94.7652 17.1282 98.1616 12.1594 98.7277Z"
        fill="url(#paint1_linear_2243_25932)"
      />
      <path
        d="M9.70627 97.7213C14.7778 97.7213 18.8891 86.6828 18.8891 73.066C18.8891 59.4493 14.7778 48.4108 9.70627 48.4108C4.63473 48.4108 0.523438 59.4493 0.523438 73.066C0.523438 86.6828 4.63473 97.7213 9.70627 97.7213Z"
        fill="url(#paint2_linear_2243_25932)"
      />
      <path
        d="M9.89411 97.7842C4.92532 97.7842 1.27734 88.3498 1.27734 76.9027C1.27734 67.7199 4.48505 60.2352 8.5104 60.2352L27.8824 64.7638L29.1403 93.4444L9.89411 97.7842Z"
        fill="url(#paint3_linear_2243_25932)"
      />
      <path
        d="M9.64251 84.2616C7.62984 84.2616 5.93164 80.4249 5.93164 75.7077C5.93164 71.9339 7.25246 68.9149 8.88776 68.9149C8.95065 68.9149 9.07645 68.9149 9.13934 68.9778L25.0521 70.7389L25.3036 82.6892L9.70541 84.2616H9.64251Z"
        fill="url(#paint4_linear_2243_25932)"
      />
      <path
        d="M9.39037 78.7268C8.57272 78.7268 7.88086 77.1544 7.88086 75.2046L22.347 75.8336L21.5922 78.6639H9.70485H9.39037V78.7268Z"
        fill="url(#paint5_linear_2243_25932)"
      />
      <path
        d="M9.70434 78.7267H9.38986C10.4591 78.7267 11.2767 76.777 11.2767 74.3869C11.2767 71.6195 10.3962 69.2923 9.20117 68.9778L25.1139 70.7389V78.7267H9.70434Z"
        fill="url(#paint6_linear_2243_25932)"
      />
      <path
        d="M8.50977 60.2353C12.3464 61.1158 14.2962 66.2104 14.2962 73.5693C14.2962 79.4815 12.2206 84.2616 9.70479 84.2616H9.83058L26.2465 82.6263L27.0012 64.638L8.50977 60.2353Z"
        fill="url(#paint7_linear_2243_25932)"
      />
      <path
        d="M149.649 48.4108C149.46 48.4108 149.209 48.4108 149.02 48.4737C94.7406 64.3864 65.7455 64.0719 10.8372 48.5995C15.3657 50.1719 18.8879 60.4868 18.8879 73.066C18.8879 85.7082 14.6109 96.4005 10.0195 97.7842C67.255 84.7647 97.1307 84.3873 148.517 97.5326C148.894 97.6584 149.272 97.7213 149.649 97.7213C154.744 97.7213 158.832 86.6516 158.832 73.066C158.769 59.4805 154.681 48.4108 149.649 48.4108Z"
        fill="url(#paint8_linear_2243_25932)"
      />
      <path
        d="M158.519 54.952C103.422 69.7326 72.8549 70.5502 20.2109 57.971C21.4688 62.185 21.5946 67.4054 21.5317 73.8208C21.4059 86.1484 18.324 97.4068 12.1602 98.7277C69.144 85.7711 97.1957 84.4502 148.456 97.5326C148.833 97.6584 149.211 97.7213 149.588 97.7213C154.683 97.7213 161.476 86.6516 161.476 73.066C161.476 65.8959 160.343 59.4176 158.519 54.952Z"
        fill="url(#paint9_linear_2243_25932)"
      />
      <path
        d="M92.6669 75.5819C93.2959 94.891 89.5221 117.659 84.8049 128.603C79.8361 122.251 78.9556 117.534 78.9556 117.534C78.9556 117.534 72.7289 124.389 64.3008 126.779C76.5026 102.627 76.3768 84.01 75.9366 76.9027L92.6669 75.5819Z"
        fill="url(#paint10_linear_2243_25932)"
      />
      <path
        d="M96.4395 58.4113L97.2571 64.9525L103.358 67.2167L100.591 73.1919L104.427 78.4122L98.8924 81.8715L99.3327 88.3498L92.7915 88.2869L89.6467 93.9476L84.1747 90.3625L78.4512 93.4444L75.8095 87.4693L69.2683 86.9661L70.3376 80.4878L65.1172 76.5254L69.5199 71.6824L67.2557 65.5185L73.5453 63.8203L74.9919 57.4049L81.2186 59.4176L85.8729 54.7633L90.0869 59.8579L96.4395 58.4113Z"
        fill="url(#paint11_linear_2243_25932)"
      />
      <path
        d="M90.0875 59.8579L88.5151 57.971C86.754 59.7321 85.0558 61.4303 83.2947 63.1914C78.892 67.5941 74.4264 71.9968 70.0236 76.4625C69.2689 77.2172 68.577 77.9091 67.8223 78.6638L70.401 80.6136L69.3318 87.029L71.5331 87.2177C73.6087 85.1421 75.6214 83.1295 77.697 81.0539C82.0997 76.6512 86.5024 72.3113 90.8422 67.9086C92.8549 65.8959 94.9305 63.8832 96.9432 61.8706L96.5029 58.5371L90.0875 59.8579Z"
        fill="url(#paint12_linear_2243_25932)"
      />
      <path
        d="M100.592 73.1918L103.422 67.2796L101.661 66.6506C100.34 67.9715 99.0195 69.2923 97.6358 70.676C93.3588 74.9529 89.0819 79.2299 84.805 83.5068C82.2891 86.0226 79.8362 88.4756 77.3203 90.9914L78.3895 93.5073L84.176 90.4254L89.5851 94.0733L92.7299 88.3498L99.2711 88.4756L98.8308 81.9344L104.429 78.4751L100.592 73.1918Z"
        fill="url(#paint13_linear_2243_25932)"
      />
      <path
        d="M84.6771 84.9534C90.2696 84.9534 94.8033 80.4197 94.8033 74.8271C94.8033 69.2346 90.2696 64.7009 84.6771 64.7009C79.0845 64.7009 74.5508 69.2346 74.5508 74.8271C74.5508 80.4197 79.0845 84.9534 84.6771 84.9534Z"
        fill="url(#paint14_linear_2243_25932)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_2243_25932"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(79.6235 74.4287) scale(70.4832)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.1918" stopColor="#E7AD80" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_2243_25932"
        x1="9.23668"
        y1="99.0923"
        x2="22.4744"
        y2="83.8689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2243_25932"
        x1="12.0628"
        y1="42.0756"
        x2="7.42277"
        y2="102.888"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2243_25932"
        x1="14.1231"
        y1="51.9267"
        x2="16.0915"
        y2="113.372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2243_25932"
        x1="16.1318"
        y1="64.0856"
        x2="15.2178"
        y2="92.4881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2243_25932"
        x1="15.7563"
        y1="64.6163"
        x2="14.4023"
        y2="87.2357"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2243_25932"
        x1="15.9253"
        y1="88.0375"
        x2="18.5969"
        y2="56.6822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2243_25932"
        x1="13.7432"
        y1="101.864"
        x2="20.5365"
        y2="43.9207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2243_25932"
        x1="83.5296"
        y1="115.991"
        x2="84.9742"
        y2="54.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0590277" stopColor="#172845" />
        <stop offset="0.1477" stopColor="#363C4E" />
        <stop offset="0.3419" stopColor="#846E64" />
        <stop offset="0.5687" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2243_25932"
        x1="86.9812"
        y1="116.667"
        x2="86.6923"
        y2="56.4254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="0.9257" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2243_25932"
        x1="73.3319"
        y1="106.37"
        x2="-36.084"
        y2="162.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="0.9257" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2243_25932"
        x1="64.0868"
        y1="115.734"
        x2="94.4131"
        y2="54.7481"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#F0E6DA" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2243_25932"
        x1="67.6799"
        y1="92.15"
        x2="90.3413"
        y2="58.158"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.6135" stopColor="#E7AD80" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2243_25932"
        x1="80.8188"
        y1="100.909"
        x2="103.48"
        y2="66.9167"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.6135" stopColor="#E7AD80" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2243_25932"
        x1="87.16"
        y1="83.166"
        x2="73.8301"
        y2="38.4369"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#172845" />
        <stop offset="0.6072" stopColor="#E7AD80" />
        <stop offset="0.9257" stopColor="#F0E6DA" />
      </linearGradient>
      <clipPath id="clip0_2243_25932">
        <rect width="160.951" height="144.787" fill="white" transform="translate(0.523438 0.106567)" />
      </clipPath>
    </defs>
  </svg>
);

export default ComplokCertificateScroll;
