import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import apiClient from "api/apiClient";
import UiCard from "components/cards/UiCard";
import SuperAdminCourseContents from "components/data/CourseContent/SuperAdminCourseContents";
import EditIcon from "components/icons/EditIcon";
import SuccessCheck from "components/icons/SuccessCheck";
import GenericModal from "components/modals/GenericModal";
import { FC, useContext, useState } from "react";
import ReactMde, { SaveImageHandler, Suggestion } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useNavigate } from "react-router";
import * as Showdown from "showdown";
import { mutate } from "swr";
import { CourseEditContext } from "./CourseEdit";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

// TODO: Why timeout?
const loadSuggestions = async (text: string) =>
  setTimeout(() => {
    const suggestions: Suggestion[] = [
      {
        preview: "Andre",
        value: "@andre",
      },
      {
        preview: "Angela",
        value: "@angela",
      },
      {
        preview: "David",
        value: "@david",
      },
      {
        preview: "Louise",
        value: "@louise",
      },
    ].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
    return suggestions;
  }, 250);

const CourseEditDescription: FC = () => {
  const { course, refreshCourse } = useContext(CourseEditContext);

  const navigate = useNavigate();

  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [courseTitle, setCourseTitle] = useState<string>(course?.title ?? "");
  const [courseDescription, setCourseDescription] = useState<string>(
    course?.description ?? ""
  );
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const save: SaveImageHandler = async function* (data: ArrayBuffer) {
    // Promise that waits for "time" milliseconds
    const wait = function (time: number) {
      return new Promise((a, r) => {
        // setTimeout(() => a(), time);
      });
    };

    // Upload "data" to your server
    // Use XMLHttpRequest.send to send a FormData object containing
    // "data"
    // Check this question: https://stackoverflow.com/questions/18055422/how-to-receive-php-image-data-over-copy-n-paste-javascript-with-xmlhttprequest

    await wait(2000);
    // yields the URL that should be inserted in the markdown
    yield "https://picsum.photos/300";
    await wait(2000);

    // returns true meaning that the save was successful
    return true;
  };

  const updateCourseTitle = async () => {
    if (!course) return;
    if (courseTitle?.length) {
      const response = await apiClient
        .patch("/courses", { title: courseTitle, _id: course._id })
        .catch(() => null);
      if (response) {
        mutate(`/courses/content/${course._id}`);
      }
      setEditTitle(false);
    }
  };

  const updateCourseOutcome = async (outcome?: string[]) => {
    if (!course) return;
    const changeCourseOutcomeResponse = await apiClient
      .patch("/courses", {
        _id: course._id,
        outcome: outcome?.filter((oc) => !!oc) ?? [],
      })
      .catch(() => null);
    if (!changeCourseOutcomeResponse) refreshCourse();
  };

  const updateCourseCertificateAdditionalText = async (text?: string) => {
    if (!course) return;
    const changeCourseOutcomeResponse = await apiClient
      .patch("/courses", { _id: course._id, additional_certificate_text: text })
      .catch(() => null);
    if (!changeCourseOutcomeResponse) refreshCourse();
  };

  const deleteCourse = async () => {
    if (!course) return;
    await apiClient.delete(`/courses/${course._id}`).catch(() => null);
    setDeleteModalVisible(false);
    navigate("/courses", { replace: true });
  };

  const modalTitle = (
    <div className="super-admin-create-course-content-delete-course-header">
      <ExclamationCircleOutlined
        style={{
          marginRight: 20,
          color: "#F5222D",
          fontSize: 21,
          minHeight: 30,
          minWidth: 30,
          height: 30,
          width: 30,
        }}
      />
      <label className="super-admin-create-course-content-delete-course-header-title">
        Delete course
      </label>
    </div>
  );

  const modalContent = (
    <label>Are you sure that you want to delete this course?</label>
  );

  if (!course) {
    return null;
  }

  return (
    <div id="complok-course-edit-content-description">
      <div className="super-admin-create-course-content-description-header">
        {editTitle ? (
          <div className="super-admin-create-course-content-description-course-name">
            <Input
              style={{
                borderRadius: 10,
                fontFamily: "poppins",
                fontWeight: 400,
                fontSize: 16,
              }}
              defaultValue={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
              onPressEnter={updateCourseTitle}
            />
            <SuccessCheck
              onClick={() => updateCourseTitle()}
              style={{ marginLeft: 15, cursor: "pointer" }}
            />
          </div>
        ) : (
          <div
            id="complok-course-edit-content-description-title"
            onClick={() => setEditTitle(true)}
          >
            <label className="super-admin-create-course-content-description-course-name-text">
              {courseTitle}
            </label>
            <EditIcon
              style={{
                marginLeft: 15,
                cursor: "pointer",
                minHeight: 30,
                minWidth: 30,
              }}
            />
          </div>
        )}
        <div style={{ flex: 1 }} />
        <div style={{ zIndex: 0 }}>
          <Button
            style={{ zIndex: 0 }}
            danger
            type={"primary"}
            size="small"
            onClick={() => setDeleteModalVisible(true)}
          >
            Delete course
          </Button>
        </div>
      </div>
      <div className="super-admin-create-course-content-description-content">
        <div className="super-admin-create-course-content-description-content-edit-description">
          <UiCard header="Description">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ReactMde
                value={courseDescription}
                onChange={setCourseDescription}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                // loadSuggestions={loadSuggestions}
                childProps={{
                  writeButton: {
                    tabIndex: -1,
                  },
                }}
                paste={{
                  saveImage: save,
                }}
              />

              <div style={{ marginTop: 20, justifyContent: "flex-end" }}>
                <Button
                  type={"primary"}
                  onClick={async () => {
                    if (course && courseDescription?.length) {
                      const response = await apiClient
                        .patch("/courses", {
                          description: courseDescription,
                          _id: course._id,
                        })
                        .catch(() => null);
                      if (response) {
                        mutate(`/courses/content/${course._id}`);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </UiCard>
          <UiCard header="What will you learn" style={{ marginTop: 32 }}>
            <Form
              layout={"horizontal"}
              onFinish={(data) => updateCourseOutcome(data?.outcome ?? [])}
              initialValues={{ outcome: course.outcome ?? [] }}
            >
              <Form.List name="outcome" initialValue={course.outcome}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item {...field} key={index}>
                        <Input
                          defaultValue={
                            course?.outcome && course.outcome[index]
                          }
                          suffix={
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          }
                        />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type={"dashed"}
                        onClick={() => add("", fields.length)}
                      >
                        Add new knowledge to be aquired
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type={"primary"} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </UiCard>
          <UiCard
            header="Certificate additional text"
            style={{ marginTop: 32, marginBottom: 10 }}
          >
            <Form
              layout="horizontal"
              onFinish={(data) =>
                updateCourseCertificateAdditionalText(
                  data.certificate_additional_text
                )
              }
            >
              <Form.Item name="certificate_additional_text">
                <Input.TextArea
                  defaultValue={
                    course?.additional_certificate_text &&
                    course.additional_certificate_text
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button type={"primary"} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </UiCard>
        </div>
        <div className="super-admin-create-course-content-description-content-course-content">
          <SuperAdminCourseContents course={course} />
        </div>
      </div>
      <GenericModal
        visible={deleteModalVisible}
        onOk={deleteCourse}
        title={modalTitle}
        okText={"Delete"}
        okType={"danger"}
        onCancel={() => setDeleteModalVisible(false)}
      >
        {modalContent}
      </GenericModal>
    </div>
  );
};

export default CourseEditDescription;
