import { CSSProperties, FC } from 'react';

interface IComplokUiCard {
  children?: any;
  header: string;
  style?: CSSProperties;
}

const UiCard: FC<IComplokUiCard> = ({ children, header, style }) => (
  <div className="complok-ui-card" style={style}>
    <label className="complok-ui-card-header">{header}</label>
    <div className="complok-ui-card-children">{children}</div>
  </div>
);

export default UiCard;
