import { SVGProps } from 'react';

const ComplokLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="182" height="163" viewBox="0 0 182 163" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2166:37755)">
      <path
        d="M82.4298 161.23C45.1621 161.23 26.0304 145.865 14.8643 111.381C3.69825 76.8977 22.9722 29.5271 61.2356 23.2252C99.4989 16.9233 100.85 5.23997 121.049 11.117C141.247 16.9941 159.739 38.4489 168.914 63.6566C178.088 88.8642 160.094 119.453 139.469 136.447C118.844 153.441 104.264 161.23 82.4298 161.23Z"
        fill="#172845"
      />
      <path
        d="M91.6042 1.77035C97.1516 4.17782 85.4877 11.0462 74.535 14.2326C63.5823 17.4189 54.6921 12.1083 60.524 7.01014C66.356 1.91197 81.4338 -2.69055 91.6042 1.77035Z"
        fill="#172845"
      />
      <path
        d="M90.6979 74.7483C93.9052 74.7483 96.5053 72.1575 96.5053 68.9616C96.5053 65.7658 93.9052 63.175 90.6979 63.175C87.4906 63.175 84.8906 65.7658 84.8906 68.9616C84.8906 72.1575 87.4906 74.7483 90.6979 74.7483Z"
        fill="url(#paint0_radial_2166:37755)"
      />
      <path
        d="M90.9701 67.6056L91.3784 68.3289L92.1951 68.5097C92.4219 68.5549 92.5127 68.8714 92.3766 69.0522L91.8321 69.6399L91.9229 70.4989C91.9682 70.7249 91.696 70.9057 91.4692 70.8153L90.6979 70.4537L89.9266 70.8153C89.6998 70.9057 89.4275 70.7249 89.4729 70.4989L89.5637 69.6399L89.0192 69.0522C88.8377 68.8714 88.9285 68.6001 89.2007 68.5097L90.0173 68.3289L90.4257 67.6056C90.5618 67.3795 90.8794 67.3795 90.9701 67.6056Z"
        fill="#E7AD80"
      />
      <path
        d="M90.9701 67.6056L91.3784 68.3289L92.1951 68.5097C92.4219 68.5549 92.5127 68.8714 92.3766 69.0522L91.8321 69.6399L91.9229 70.4989C91.9682 70.7249 91.696 70.9057 91.4692 70.8153L90.6979 70.4537L89.9266 70.8153C89.6998 70.9057 89.4275 70.7249 89.4729 70.4989L89.5637 69.6399L89.0192 69.0522C88.8377 68.8714 88.9285 68.6001 89.2007 68.5097L90.0173 68.3289L90.4257 67.6056C90.5618 67.3795 90.8794 67.3795 90.9701 67.6056Z"
        fill="url(#paint1_radial_2166:37755)"
      />
      <path
        d="M119.129 106.086C122.336 106.086 124.936 103.495 124.936 100.299C124.936 97.1033 122.336 94.5126 119.129 94.5126C115.921 94.5126 113.321 97.1033 113.321 100.299C113.321 103.495 115.921 106.086 119.129 106.086Z"
        fill="url(#paint2_radial_2166:37755)"
      />
      <path
        d="M119.401 98.9432L119.809 99.6665L120.626 99.8473C120.853 99.8925 120.943 100.209 120.807 100.39L120.263 100.978L120.354 101.836C120.399 102.063 120.127 102.243 119.9 102.153L119.129 101.791L118.357 102.153C118.13 102.243 117.858 102.063 117.904 101.836L117.994 100.978L117.45 100.39C117.268 100.209 117.359 99.9377 117.631 99.8473L118.448 99.6665L118.856 98.9432C118.992 98.7171 119.31 98.7171 119.401 98.9432Z"
        fill="#E7AD80"
      />
      <path
        d="M119.401 98.9432L119.809 99.6665L120.626 99.8473C120.853 99.8925 120.943 100.209 120.807 100.39L120.263 100.978L120.354 101.836C120.399 102.063 120.127 102.243 119.9 102.153L119.129 101.791L118.357 102.153C118.13 102.243 117.858 102.063 117.904 101.836L117.994 100.978L117.45 100.39C117.268 100.209 117.359 99.9377 117.631 99.8473L118.448 99.6665L118.856 98.9432C118.992 98.7171 119.31 98.7171 119.401 98.9432Z"
        fill="url(#paint3_radial_2166:37755)"
      />
      <path
        d="M135.023 88.9888C137.353 88.9888 139.242 87.1065 139.242 84.7845C139.242 82.4625 137.353 80.5801 135.023 80.5801C132.693 80.5801 130.804 82.4625 130.804 84.7845C130.804 87.1065 132.693 88.9888 135.023 88.9888Z"
        fill="url(#paint4_radial_2166:37755)"
      />
      <path
        d="M135.204 83.7898L135.522 84.2871L136.112 84.4227C136.293 84.4679 136.339 84.694 136.248 84.7844L135.84 85.2365L135.885 85.8694C135.885 86.0502 135.703 86.1858 135.567 86.0954L135.023 85.8242L134.479 86.0954C134.297 86.1858 134.116 86.0502 134.161 85.8694L134.206 85.2365L133.798 84.7844C133.662 84.6488 133.753 84.4227 133.934 84.4227L134.524 84.2871L134.841 83.7898C134.887 83.6542 135.114 83.6542 135.204 83.7898Z"
        fill="#E7AD80"
      />
      <path
        d="M135.204 83.7898L135.522 84.2871L136.112 84.4227C136.293 84.4679 136.339 84.694 136.248 84.7844L135.84 85.2365L135.885 85.8694C135.885 86.0502 135.703 86.1858 135.567 86.0954L135.023 85.8242L134.479 86.0954C134.297 86.1858 134.116 86.0502 134.161 85.8694L134.206 85.2365L133.798 84.7844C133.662 84.6488 133.753 84.4227 133.934 84.4227L134.524 84.2871L134.841 83.7898C134.887 83.6542 135.114 83.6542 135.204 83.7898Z"
        fill="url(#paint5_radial_2166:37755)"
      />
      <path
        d="M108.392 55.5803C110.723 55.5803 112.612 53.6979 112.612 51.3759C112.612 49.0539 110.723 47.1716 108.392 47.1716C106.062 47.1716 104.173 49.0539 104.173 51.3759C104.173 53.6979 106.062 55.5803 108.392 55.5803Z"
        fill="url(#paint6_radial_2166:37755)"
      />
      <path
        d="M108.618 50.4264L108.936 50.9237L109.526 51.0593C109.707 51.1045 109.753 51.3305 109.662 51.4209L109.254 51.873L109.299 52.5059C109.299 52.6868 109.118 52.8224 108.981 52.732L108.437 52.4607L107.893 52.732C107.711 52.8224 107.53 52.6868 107.575 52.5059L107.62 51.873L107.212 51.4209C107.076 51.2853 107.167 51.0593 107.348 51.0593L107.938 50.9237L108.256 50.4264C108.301 50.2455 108.528 50.2455 108.618 50.4264Z"
        fill="#E7AD80"
      />
      <path
        d="M108.618 50.4264L108.936 50.9237L109.526 51.0593C109.707 51.1045 109.753 51.3305 109.662 51.4209L109.254 51.873L109.299 52.5059C109.299 52.6868 109.118 52.8224 108.981 52.732L108.437 52.4607L107.893 52.732C107.711 52.8224 107.53 52.6868 107.575 52.5059L107.62 51.873L107.212 51.4209C107.076 51.2853 107.167 51.0593 107.348 51.0593L107.938 50.9237L108.256 50.4264C108.301 50.2455 108.528 50.2455 108.618 50.4264Z"
        fill="url(#paint7_radial_2166:37755)"
      />
      <path
        d="M120.187 53.229C121.215 53.229 122.047 52.3991 122.047 51.3754C122.047 50.3518 121.215 49.5219 120.187 49.5219C119.16 49.5219 118.327 50.3518 118.327 51.3754C118.327 52.3991 119.16 53.229 120.187 53.229Z"
        fill="url(#paint8_radial_2166:37755)"
      />
      <path
        d="M120.188 51.9181C120.489 51.9181 120.732 51.6752 120.732 51.3756C120.732 51.076 120.489 50.8331 120.188 50.8331C119.887 50.8331 119.644 51.076 119.644 51.3756C119.644 51.6752 119.887 51.9181 120.188 51.9181Z"
        fill="#E7AD80"
      />
      <path
        d="M120.188 51.9181C120.489 51.9181 120.732 51.6752 120.732 51.3756C120.732 51.076 120.489 50.8331 120.188 50.8331C119.887 50.8331 119.644 51.076 119.644 51.3756C119.644 51.6752 119.887 51.9181 120.188 51.9181Z"
        fill="url(#paint9_radial_2166:37755)"
      />
      <path
        d="M122.736 31.7869C123.763 31.7869 124.596 30.957 124.596 29.9334C124.596 28.9097 123.763 28.0798 122.736 28.0798C121.709 28.0798 120.876 28.9097 120.876 29.9334C120.876 30.957 121.709 31.7869 122.736 31.7869Z"
        fill="url(#paint10_radial_2166:37755)"
      />
      <path
        d="M122.736 30.476C123.037 30.476 123.28 30.2331 123.28 29.9335C123.28 29.6339 123.037 29.391 122.736 29.391C122.435 29.391 122.191 29.6339 122.191 29.9335C122.191 30.2331 122.435 30.476 122.736 30.476Z"
        fill="#E7AD80"
      />
      <path
        d="M122.736 30.476C123.037 30.476 123.28 30.2331 123.28 29.9335C123.28 29.6339 123.037 29.391 122.736 29.391C122.435 29.391 122.191 29.6339 122.191 29.9335C122.191 30.2331 122.435 30.476 122.736 30.476Z"
        fill="url(#paint11_radial_2166:37755)"
      />
      <path
        d="M138.987 113.928C140.616 113.928 141.937 112.612 141.937 110.988C141.937 109.365 140.616 108.049 138.987 108.049C137.358 108.049 136.037 109.365 136.037 110.988C136.037 112.612 137.358 113.928 138.987 113.928Z"
        fill="url(#paint12_radial_2166:37755)"
      />
      <path
        d="M138.987 111.849C139.464 111.849 139.851 111.464 139.851 110.989C139.851 110.514 139.464 110.128 138.987 110.128C138.511 110.128 138.124 110.514 138.124 110.989C138.124 111.464 138.511 111.849 138.987 111.849Z"
        fill="#E7AD80"
      />
      <path
        d="M138.987 111.849C139.464 111.849 139.851 111.464 139.851 110.989C139.851 110.514 139.464 110.128 138.987 110.128C138.511 110.128 138.124 110.514 138.124 110.989C138.124 111.464 138.511 111.849 138.987 111.849Z"
        fill="url(#paint13_radial_2166:37755)"
      />
      <path
        d="M36.8865 75.6188C37.9139 75.6188 38.7467 74.789 38.7467 73.7653C38.7467 72.7416 37.9139 71.9117 36.8865 71.9117C35.8592 71.9117 35.0264 72.7416 35.0264 73.7653C35.0264 74.789 35.8592 75.6188 36.8865 75.6188Z"
        fill="url(#paint14_radial_2166:37755)"
      />
      <path
        d="M36.8862 74.308C37.1869 74.308 37.4307 74.0651 37.4307 73.7655C37.4307 73.4658 37.1869 73.223 36.8862 73.223C36.5855 73.223 36.3418 73.4658 36.3418 73.7655C36.3418 74.0651 36.5855 74.308 36.8862 74.308Z"
        fill="#E7AD80"
      />
      <path
        d="M36.8862 74.308C37.1869 74.308 37.4307 74.0651 37.4307 73.7655C37.4307 73.4658 37.1869 73.223 36.8862 73.223C36.5855 73.223 36.3418 73.4658 36.3418 73.7655C36.3418 74.0651 36.5855 74.308 36.8862 74.308Z"
        fill="url(#paint15_radial_2166:37755)"
      />
      <path
        d="M42.3816 115.342C43.409 115.342 44.2418 114.512 44.2418 113.488C44.2418 112.464 43.409 111.634 42.3816 111.634C41.3543 111.634 40.5215 112.464 40.5215 113.488C40.5215 114.512 41.3543 115.342 42.3816 115.342Z"
        fill="url(#paint16_radial_2166:37755)"
      />
      <path
        d="M42.3813 114.031C42.682 114.031 42.9258 113.788 42.9258 113.488C42.9258 113.189 42.682 112.946 42.3813 112.946C42.0807 112.946 41.8369 113.189 41.8369 113.488C41.8369 113.788 42.0807 114.031 42.3813 114.031Z"
        fill="#E7AD80"
      />
      <path
        d="M42.3813 114.031C42.682 114.031 42.9258 113.788 42.9258 113.488C42.9258 113.189 42.682 112.946 42.3813 112.946C42.0807 112.946 41.8369 113.189 41.8369 113.488C41.8369 113.788 42.0807 114.031 42.3813 114.031Z"
        fill="url(#paint17_radial_2166:37755)"
      />
      <path
        d="M61.6899 53.8567C63.5191 53.8567 65.0019 52.3791 65.0019 50.5565C65.0019 48.7338 63.5191 47.2563 61.6899 47.2563C59.8608 47.2563 58.3779 48.7338 58.3779 50.5565C58.3779 52.3791 59.8608 53.8567 61.6899 53.8567Z"
        fill="url(#paint18_radial_2166:37755)"
      />
      <path
        d="M61.6901 51.5056C62.2163 51.5056 62.6428 51.0806 62.6428 50.5562C62.6428 50.0319 62.2163 49.6069 61.6901 49.6069C61.1639 49.6069 60.7373 50.0319 60.7373 50.5562C60.7373 51.0806 61.1639 51.5056 61.6901 51.5056Z"
        fill="#E7AD80"
      />
      <path
        d="M61.6901 51.5056C62.2163 51.5056 62.6428 51.0806 62.6428 50.5562C62.6428 50.0319 62.2163 49.6069 61.6901 49.6069C61.1639 49.6069 60.7373 50.0319 60.7373 50.5562C60.7373 51.0806 61.1639 51.5056 61.6901 51.5056Z"
        fill="url(#paint19_radial_2166:37755)"
      />
      <path
        d="M68.5571 77.8273C70.3862 77.8273 71.8691 76.3497 71.8691 74.5271C71.8691 72.7044 70.3862 71.2269 68.5571 71.2269C66.7279 71.2269 65.2451 72.7044 65.2451 74.5271C65.2451 76.3497 66.7279 77.8273 68.5571 77.8273Z"
        fill="url(#paint20_radial_2166:37755)"
      />
      <path
        d="M68.5572 75.4762C69.0834 75.4762 69.51 75.0511 69.51 74.5268C69.51 74.0025 69.0834 73.5775 68.5572 73.5775C68.0311 73.5775 67.6045 74.0025 67.6045 74.5268C67.6045 75.0511 68.0311 75.4762 68.5572 75.4762Z"
        fill="#E7AD80"
      />
      <path
        d="M68.5572 75.4762C69.0834 75.4762 69.51 75.0511 69.51 74.5268C69.51 74.0025 69.0834 73.5775 68.5572 73.5775C68.0311 73.5775 67.6045 74.0025 67.6045 74.5268C67.6045 75.0511 68.0311 75.4762 68.5572 75.4762Z"
        fill="url(#paint21_radial_2166:37755)"
      />
      <path
        d="M68.5581 77.8272C70.3872 77.8272 71.87 76.3497 71.87 74.5271C71.87 72.7044 70.3872 71.2269 68.5581 71.2269C66.7289 71.2269 65.2461 72.7044 65.2461 74.5271C65.2461 76.3497 66.7289 77.8272 68.5581 77.8272Z"
        fill="url(#paint22_radial_2166:37755)"
      />
      <path
        d="M68.5582 75.4762C69.0844 75.4762 69.511 75.0511 69.511 74.5268C69.511 74.0025 69.0844 73.5775 68.5582 73.5775C68.032 73.5775 67.6055 74.0025 67.6055 74.5268C67.6055 75.0511 68.032 75.4762 68.5582 75.4762Z"
        fill="#E7AD80"
      />
      <path
        d="M64.1383 121.067C64.6645 121.067 65.0911 120.642 65.0911 120.117C65.0911 119.593 64.6645 119.168 64.1383 119.168C63.6121 119.168 63.1855 119.593 63.1855 120.117C63.1855 120.642 63.6121 121.067 64.1383 121.067Z"
        fill="url(#paint23_radial_2166:37755)"
      />
      <path
        d="M82.2934 104.537C84.1226 104.537 85.6054 103.06 85.6054 101.237C85.6054 99.4145 84.1226 97.937 82.2934 97.937C80.4643 97.937 78.9814 99.4145 78.9814 101.237C78.9814 103.06 80.4643 104.537 82.2934 104.537Z"
        fill="url(#paint24_radial_2166:37755)"
      />
      <path
        d="M82.2936 102.186C82.8198 102.186 83.2463 101.761 83.2463 101.237C83.2463 100.713 82.8198 100.288 82.2936 100.288C81.7674 100.288 81.3408 100.713 81.3408 101.237C81.3408 101.761 81.7674 102.186 82.2936 102.186Z"
        fill="#E7AD80"
      />
      <path
        d="M82.2936 102.186C82.8198 102.186 83.2463 101.761 83.2463 101.237C83.2463 100.713 82.8198 100.288 82.2936 100.288C81.7674 100.288 81.3408 100.713 81.3408 101.237C81.3408 101.761 81.7674 102.186 82.2936 102.186Z"
        fill="url(#paint25_radial_2166:37755)"
      />
      <path
        d="M92.5952 47.0079C94.4243 47.0079 95.9072 45.5304 95.9072 43.7078C95.9072 41.8851 94.4243 40.4076 92.5952 40.4076C90.766 40.4076 89.2832 41.8851 89.2832 43.7078C89.2832 45.5304 90.766 47.0079 92.5952 47.0079Z"
        fill="url(#paint26_radial_2166:37755)"
      />
      <path
        d="M92.5953 44.6569C93.1215 44.6569 93.5481 44.2318 93.5481 43.7075C93.5481 43.1832 93.1215 42.7581 92.5953 42.7581C92.0691 42.7581 91.6426 43.1832 91.6426 43.7075C91.6426 44.2318 92.0691 44.6569 92.5953 44.6569Z"
        fill="#E7AD80"
      />
      <path
        d="M92.5953 44.6569C93.1215 44.6569 93.5481 44.2318 93.5481 43.7075C93.5481 43.1832 93.1215 42.7581 92.5953 42.7581C92.0691 42.7581 91.6426 43.1832 91.6426 43.7075C91.6426 44.2318 92.0691 44.6569 92.5953 44.6569Z"
        fill="url(#paint27_radial_2166:37755)"
      />
      <path
        d="M114.573 76.4575C116.402 76.4575 117.885 74.98 117.885 73.1573C117.885 71.3347 116.402 69.8571 114.573 69.8571C112.744 69.8571 111.261 71.3347 111.261 73.1573C111.261 74.98 112.744 76.4575 114.573 76.4575Z"
        fill="url(#paint28_radial_2166:37755)"
      />
      <path
        d="M114.573 74.1064C115.099 74.1064 115.526 73.6814 115.526 73.1571C115.526 72.6327 115.099 72.2077 114.573 72.2077C114.047 72.2077 113.62 72.6327 113.62 73.1571C113.62 73.6814 114.047 74.1064 114.573 74.1064Z"
        fill="#E7AD80"
      />
      <path
        d="M114.573 74.1064C115.099 74.1064 115.526 73.6814 115.526 73.1571C115.526 72.6327 115.099 72.2077 114.573 72.2077C114.047 72.2077 113.62 72.6327 113.62 73.1571C113.62 73.6814 114.047 74.1064 114.573 74.1064Z"
        fill="url(#paint29_radial_2166:37755)"
      />
      <path
        d="M55.4002 74.5675C56.4275 74.5675 57.2603 73.7376 57.2603 72.7139C57.2603 71.6903 56.4275 70.8604 55.4002 70.8604C54.3729 70.8604 53.54 71.6903 53.54 72.7139C53.54 73.7376 54.3729 74.5675 55.4002 74.5675Z"
        fill="url(#paint30_radial_2166:37755)"
      />
      <path
        d="M55.3999 73.2564C55.7006 73.2564 55.9443 73.0135 55.9443 72.7139C55.9443 72.4143 55.7006 72.1714 55.3999 72.1714C55.0992 72.1714 54.8555 72.4143 54.8555 72.7139C54.8555 73.0135 55.0992 73.2564 55.3999 73.2564Z"
        fill="#E7AD80"
      />
      <path
        d="M55.3999 73.2564C55.7006 73.2564 55.9443 73.0135 55.9443 72.7139C55.9443 72.4143 55.7006 72.1714 55.3999 72.1714C55.0992 72.1714 54.8555 72.4143 54.8555 72.7139C54.8555 73.0135 55.0992 73.2564 55.3999 73.2564Z"
        fill="url(#paint31_radial_2166:37755)"
      />
      <path
        d="M55.4305 98.9045C56.4578 98.9045 57.2906 98.0747 57.2906 97.051C57.2906 96.0273 56.4578 95.1974 55.4305 95.1974C54.4031 95.1974 53.5703 96.0273 53.5703 97.051C53.5703 98.0747 54.4031 98.9045 55.4305 98.9045Z"
        fill="url(#paint32_radial_2166:37755)"
      />
      <path
        d="M55.4302 97.5934C55.7309 97.5934 55.9746 97.3505 55.9746 97.0509C55.9746 96.7513 55.7309 96.5084 55.4302 96.5084C55.1295 96.5084 54.8857 96.7513 54.8857 97.0509C54.8857 97.3505 55.1295 97.5934 55.4302 97.5934Z"
        fill="#E7AD80"
      />
      <path
        d="M55.4302 97.5934C55.7309 97.5934 55.9746 97.3505 55.9746 97.0509C55.9746 96.7513 55.7309 96.5084 55.4302 96.5084C55.1295 96.5084 54.8857 96.7513 54.8857 97.0509C54.8857 97.3505 55.1295 97.5934 55.4302 97.5934Z"
        fill="url(#paint33_radial_2166:37755)"
      />
      <path
        d="M80.1715 68.9617C82.5018 68.9617 84.3909 67.0794 84.3909 64.7574C84.3909 62.4354 82.5018 60.553 80.1715 60.553C77.8412 60.553 75.9521 62.4354 75.9521 64.7574C75.9521 67.0794 77.8412 68.9617 80.1715 68.9617Z"
        fill="url(#paint34_radial_2166:37755)"
      />
      <path
        d="M80.3529 63.808L80.6704 64.3053L81.2602 64.4409C81.4417 64.4861 81.4871 64.7122 81.3964 64.8026L80.988 65.2547L81.0334 65.8876C81.0334 66.0684 80.8519 66.204 80.7158 66.1136L80.1714 65.8424L79.6269 66.1136C79.4455 66.204 79.264 66.0684 79.3093 65.8876L79.3547 65.2547L78.9464 64.8026C78.8103 64.6669 78.901 64.4409 79.0825 64.4409L79.6723 64.3053L79.9899 63.808C80.0353 63.6272 80.2621 63.6272 80.3529 63.808Z"
        fill="#E7AD80"
      />
      <path
        d="M80.3529 63.808L80.6704 64.3053L81.2602 64.4409C81.4417 64.4861 81.4871 64.7122 81.3964 64.8026L80.988 65.2547L81.0334 65.8876C81.0334 66.0684 80.8519 66.204 80.7158 66.1136L80.1714 65.8424L79.6269 66.1136C79.4455 66.204 79.264 66.0684 79.3093 65.8876L79.3547 65.2547L78.9464 64.8026C78.8103 64.6669 78.901 64.4409 79.0825 64.4409L79.6723 64.3053L79.9899 63.808C80.0353 63.6272 80.2621 63.6272 80.3529 63.808Z"
        fill="url(#paint35_radial_2166:37755)"
      />
      <path
        d="M89.6394 143.078C120.56 143.078 145.625 118.101 145.625 87.291C145.625 56.4808 120.56 31.5042 89.6394 31.5042C58.7191 31.5042 33.6533 56.4808 33.6533 87.291C33.6533 118.101 58.7191 143.078 89.6394 143.078Z"
        fill="url(#paint36_radial_2166:37755)"
      />
      <path
        d="M67.2566 130.894C68.4543 130.894 69.4253 129.926 69.4253 128.733C69.4253 127.539 68.4543 126.572 67.2566 126.572C66.0589 126.572 65.0879 127.539 65.0879 128.733C65.0879 129.926 66.0589 130.894 67.2566 130.894Z"
        fill="url(#paint37_radial_2166:37755)"
      />
      <path
        d="M67.2568 129.366C67.6074 129.366 67.8915 129.082 67.8915 128.733C67.8915 128.384 67.6074 128.101 67.2568 128.101C66.9063 128.101 66.6221 128.384 66.6221 128.733C66.6221 129.082 66.9063 129.366 67.2568 129.366Z"
        fill="#E7AD80"
      />
      <path
        d="M67.2568 129.366C67.6074 129.366 67.8915 129.082 67.8915 128.733C67.8915 128.384 67.6074 128.101 67.2568 128.101C66.9063 128.101 66.6221 128.384 66.6221 128.733C66.6221 129.082 66.9063 129.366 67.2568 129.366Z"
        fill="url(#paint38_radial_2166:37755)"
      />
      <path
        d="M85.7562 137.686C86.6081 137.686 87.2986 136.856 87.2986 135.833C87.2986 134.81 86.6081 133.981 85.7562 133.981C84.9044 133.981 84.2139 134.81 84.2139 135.833C84.2139 136.856 84.9044 137.686 85.7562 137.686Z"
        fill="url(#paint39_radial_2166:37755)"
      />
      <path
        d="M85.7561 136.376C86.0054 136.376 86.2075 136.133 86.2075 135.834C86.2075 135.534 86.0054 135.291 85.7561 135.291C85.5068 135.291 85.3047 135.534 85.3047 135.834C85.3047 136.133 85.5068 136.376 85.7561 136.376Z"
        fill="#E7AD80"
      />
      <path
        d="M85.7561 136.376C86.0054 136.376 86.2075 136.133 86.2075 135.834C86.2075 135.534 86.0054 135.291 85.7561 135.291C85.5068 135.291 85.3047 135.534 85.3047 135.834C85.3047 136.133 85.5068 136.376 85.7561 136.376Z"
        fill="url(#paint40_radial_2166:37755)"
      />
      <path
        d="M98.0951 129.042C98.9469 129.042 99.6375 128.212 99.6375 127.189C99.6375 126.166 98.9469 125.337 98.0951 125.337C97.2433 125.337 96.5527 126.166 96.5527 127.189C96.5527 128.212 97.2433 129.042 98.0951 129.042Z"
        fill="url(#paint41_radial_2166:37755)"
      />
      <path
        d="M98.095 127.732C98.3443 127.732 98.5464 127.489 98.5464 127.189C98.5464 126.89 98.3443 126.647 98.095 126.647C97.8457 126.647 97.6436 126.89 97.6436 127.189C97.6436 127.489 97.8457 127.732 98.095 127.732Z"
        fill="#E7AD80"
      />
      <path
        d="M98.095 127.732C98.3443 127.732 98.5464 127.489 98.5464 127.189C98.5464 126.89 98.3443 126.647 98.095 126.647C97.8457 126.647 97.6436 126.89 97.6436 127.189C97.6436 127.489 97.8457 127.732 98.095 127.732Z"
        fill="url(#paint42_radial_2166:37755)"
      />
      <path
        d="M48.7387 84.5871C49.5905 84.5871 50.281 83.7578 50.281 82.7348C50.281 81.7119 49.5905 80.8826 48.7387 80.8826C47.8868 80.8826 47.1963 81.7119 47.1963 82.7348C47.1963 83.7578 47.8868 84.5871 48.7387 84.5871Z"
        fill="url(#paint43_radial_2166:37755)"
      />
      <path
        d="M48.7385 83.2771C48.9878 83.2771 49.19 83.0343 49.19 82.7349C49.19 82.4355 48.9878 82.1928 48.7385 82.1928C48.4892 82.1928 48.2871 82.4355 48.2871 82.7349C48.2871 83.0343 48.4892 83.2771 48.7385 83.2771Z"
        fill="#E7AD80"
      />
      <path
        d="M48.7385 83.2771C48.9878 83.2771 49.19 83.0343 49.19 82.7349C49.19 82.4355 48.9878 82.1928 48.7385 82.1928C48.4892 82.1928 48.2871 82.4355 48.2871 82.7349C48.2871 83.0343 48.4892 83.2771 48.7385 83.2771Z"
        fill="url(#paint44_radial_2166:37755)"
      />
      <g filter="url(#filter0_i_2166:37755)">
        <path
          d="M114.216 67.5827H109.258V57.8801C109.258 46.9315 100.592 37.8299 89.7233 37.664C87.1025 37.6362 84.5021 38.1313 82.0721 39.1205C79.642 40.1097 77.4304 41.5736 75.5645 43.4278C73.6987 45.282 72.2156 47.4898 71.2006 49.9241C70.1857 52.3583 69.659 54.9708 69.6509 57.611V67.5827H64.7033C62.5168 67.5895 60.4217 68.4675 58.8756 70.0249C57.3295 71.5824 56.4579 73.6929 56.4512 75.8955V115.789C56.4579 117.992 57.3295 120.103 58.8756 121.66C60.4217 123.217 62.5168 124.095 64.7033 124.102H114.216C116.402 124.095 118.497 123.217 120.043 121.66C121.588 120.102 122.459 117.992 122.465 115.789V75.8955C122.459 73.6932 121.588 71.5827 120.043 70.0252C118.497 68.4676 116.402 67.5895 114.216 67.5827ZM92.3508 93.5164V107.805C92.3556 108.561 92.0747 109.291 91.5651 109.847C91.0555 110.403 90.3552 110.743 89.6062 110.798C89.2151 110.817 88.8244 110.755 88.4577 110.617C88.0909 110.479 87.7558 110.267 87.4726 109.995C87.1893 109.723 86.9638 109.396 86.8097 109.033C86.6556 108.67 86.5762 108.28 86.5761 107.886V93.509C85.2373 92.8513 84.1594 91.756 83.5181 90.4018C82.8769 89.0475 82.7101 87.5144 83.0451 86.0524C83.3802 84.5904 84.1972 83.286 85.3628 82.352C86.5285 81.4179 87.974 80.9094 89.4634 80.9094C90.9529 80.9094 92.3984 81.4179 93.5641 82.352C94.7297 83.286 95.5467 84.5904 95.8817 86.0524C96.2168 87.5144 96.05 89.0475 95.4088 90.4018C94.7675 91.756 93.6895 92.8513 92.3508 93.509V93.5164ZM103.49 67.5827H75.4256V57.611C75.4256 53.8635 76.9035 50.2695 79.534 47.6196C82.1645 44.9698 85.7323 43.4811 89.4525 43.4811C93.1726 43.4811 96.7404 44.9698 99.3709 47.6196C102.001 50.2695 103.479 53.8635 103.479 57.611L103.49 67.5827Z"
          fill="#DDA77E"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.4095 108.104V93.6421V93.6347C93.7644 92.969 94.8554 91.8604 95.5045 90.4898C96.1535 89.1192 96.3223 87.5674 95.9832 86.0878C95.6441 84.6081 94.8172 83.2879 93.6374 82.3426C92.4577 81.3972 90.9947 80.8826 89.4872 80.8826C87.9797 80.8826 86.5167 81.3972 85.3369 82.3426C84.1571 83.2879 83.3302 84.6081 82.9912 86.0878C82.6521 87.5674 82.8208 89.1192 83.4699 90.4898C84.1189 91.8604 85.2099 92.969 86.5649 93.6347V108.186C86.5649 108.585 86.6454 108.98 86.8013 109.347C86.9573 109.714 87.1855 110.045 87.4722 110.32C87.7589 110.596 88.098 110.81 88.4692 110.95C88.8404 111.089 89.2358 111.152 89.6316 111.133C90.3898 111.077 91.0985 110.733 91.6143 110.171C92.13 109.608 92.4143 108.869 92.4095 108.104Z"
        fill="#172845"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_2166:37755"
        x="56.4512"
        y="37.6629"
        width="66.0137"
        height="90.4394"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.156863 0 0 0 0 0.270588 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2166:37755" />
      </filter>
      <radialGradient
        id="paint0_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90.8358 69.1095) scale(4.71985 4.70305)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90.6787 68.9349) scale(3.03049 3.01971)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(119.266 100.447) scale(4.71985 4.70305)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(119.109 100.272) scale(3.03049 3.01973)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(135.102 84.8987) scale(3.43543 3.4232)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(134.988 84.7713) scale(2.20584 2.19796)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(108.498 51.5017) scale(3.43543 3.4232)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(108.383 51.3743) scale(2.20583 2.19798)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(120.22 51.436) scale(1.51898 1.51356)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(120.181 51.3845) scale(0.531004 0.529116)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(122.769 29.9939) scale(1.51897 1.51357)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(122.729 29.9424) scale(0.531004 0.529118)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(139.038 111.085) scale(2.40872 2.40014)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(138.977 111.003) scale(0.842039 0.839055)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.9191 73.8259) scale(1.51897 1.51357)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.8795 73.7744) scale(0.531002 0.529118)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(42.4142 113.549) scale(1.51897 1.51357)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(42.3746 113.497) scale(0.531004 0.529122)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(61.7425 50.6403) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(61.6727 50.5481) scale(0.945316 0.941953)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(68.6097 74.6109) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(68.5399 74.5187) scale(0.945314 0.941953)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(68.6107 74.6109) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64.121 120.109) scale(0.945318 0.941957)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82.3461 101.321) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82.2762 101.229) scale(0.945318 0.941957)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(92.6478 43.7916) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(92.578 43.6994) scale(0.945326 0.941953)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(114.625 73.2412) scale(2.70403 2.6944)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(114.556 73.1489) scale(0.945314 0.94195)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(55.4576 72.779) scale(1.51898 1.51357)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(55.418 72.7273) scale(0.53101 0.529118)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(55.4879 97.1161) scale(1.51897 1.51357)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(55.4483 97.0643) scale(0.531012 0.529118)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.2446 64.8868) scale(3.43543 3.4232)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint35_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.1301 64.7596) scale(2.20583 2.19798)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint36_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(89.6549 87.2754) scale(56.0035 55.8042)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="0.1918" stopColor="#E7AD80" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint37_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(67.2945 128.804) scale(1.77093 1.76463)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(67.249 128.743) scale(0.619082 0.616883)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint39_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.7832 135.894) scale(1.25948 1.51254)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint40_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.7505 135.842) scale(0.440285 0.528757)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint41_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(98.1221 127.25) scale(1.25948 1.51254)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(98.0894 127.198) scale(0.440285 0.528757)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint43_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(48.7656 82.7954) scale(1.25948 1.51254)"
      >
        <stop offset="0.00317503" stopColor="#E7AD80" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint44_radial_2166:37755"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(48.733 82.7438) scale(0.440287 0.528757)"
      >
        <stop stopColor="#F0E6DA" stopOpacity="0.9647" />
        <stop offset="1" stopColor="#E7AD80" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_2166:37755">
        <rect width="182" height="163" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ComplokLock;
