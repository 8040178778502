import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  BackTop,
  Button,
  Checkbox,
  Col,
  message,
  Modal,
  Result,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
} from "antd";
import apiClient from "api/apiClient";
import Close from "components/icons/Close";
import { first, forEach, isEmpty, keys, set, slice, values } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { OrganizationManagementContext } from "screens/Admin/Management/OrganizationManagementContext";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import xlsx from "xlsx";

const SORTABLE_COLUMNS = new Set(["first_name", "last_name", "name"]);

const { Option } = Select;
const { Column } = Table;
const { Dragger } = Upload;

const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

interface IAddUsersFromFileModal {
  group?: string;
  showModal: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const AddUsersFromFileModal: FC<IAddUsersFromFileModal> = ({
  group,
  onCancel,
  onSuccess,
  showModal,
}) => {
  const { organization } = useContext(OrganizationManagementContext);
  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [step, setStep] = useState(0);

  const [data, setData] = useState<any[]>([]);
  const [skipFirstRow, setSkipFirstRow] = useState(false);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({
    access_level: "access_level",
  });
  const [reversedMapping, setReversedMapping] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (!group) onCancel();

    const newVal: { [key: string]: string } = {};

    keys(mapping).forEach((key: string) => {
      const val = mapping[key];
      newVal[val] = key;
    });

    setReversedMapping(newVal);
  }, [mapping]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const renderTableRow = (key: string, val: any) => <span>{val}</span>;

  const addUsers = async () => {
    if (!group) {
      onCancel();
      return;
    }
    setImporting(true);
    const newErrors: { [key: string]: string } = {};

    const mappingValuesAsString = values(mapping).toString();

    if (!mappingValuesAsString.includes("email")) {
      setImporting(false);
      return message.error("Missing field email");
    }

    if (!mappingValuesAsString.includes("name")) {
      setImporting(false);
      return message.error("Missing field name");
    }

    const importedRowData = skipFirstRow ? slice(data, 1) : data;
    const userData: any = [];

    forEach(importedRowData, (rowData, index) => {
      const user = {};

      forEach(mapping, (value, key) => {
        // handle email
        if (mapping[key] === "email") {
          const email = rowData[key];

          // validate email
          if (validateEmail(email)) {
            set(user, value, `${rowData[key]}`.trim());
          } else {
            newErrors[`${key},${index}`] = "Incorrect email";
          }
        } else if (mapping[key] === "access_level") {
          set(user, value, parseInt(rowData[key]));
        } else if (rowData[key]) {
          // set all other fields
          set(user, value, `${rowData[key]}`.trim());
        }
      });

      userData.push(user);
    });

    if (!isEmpty(newErrors)) {
      message.error("Please review the table, found incorrect inputs");
      setErrors(newErrors);
      setImporting(false);
    } else {
      apiClient
        .post("/users/create", {
          organization: organization?._id,
          from_file: true,
          group_id: group,
          users: userData.map((x: any) => ({
            ...x,
            access_level: USER_ACCESS_LEVEL.LEARNER,
          })),
        })
        .then((response) => {
          if (response?.data) {
            setStep(2);
            setImporting(false);
            onSuccess();
          }
        })
        .catch((err) => {
          console.error("Importing failed, please review input!");
          message.error("Failed to import users!");

          const failedFields: null | { [key: string]: string } =
            err?.response?.data?.failedFieldsWithMapping || null;
          const fieldKey: string | null =
            err?.response?.data?.column_name || null;

          if (failedFields && fieldKey && reversedMapping[fieldKey]) {
            const failedFieldsWithTableMapping: { [key: string]: string } = {};

            keys(failedFields).forEach((key) => {
              failedFieldsWithTableMapping[
                `${reversedMapping[fieldKey]},${key}`
              ] = failedFields[key];
            });

            setErrors(failedFieldsWithTableMapping);
          }
          setImporting(false);
        });
    }
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closable={false}
      destroyOnClose
      className="complok-modal-add-users"
      width={"100vw"}
      visible={showModal}
    >
      <Col span={24}>
        <div className="complok-modal-add-users-header">
          <Close
            className="complok-modal-add-users-header-close"
            onClick={() => {
              onCancel();
              setStep(0);
              setData([]);
              setErrors({});
              setMapping({ access_level: "access_level" });
            }}
          />
        </div>
        {step === 0 && (
          <Spin spinning={loading}>
            <Dragger
              name="file"
              multiple={false}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              showUploadList={false}
              beforeUpload={(file, fileList) => {
                setLoading(true);
                return true;
              }}
              onChange={(upload) => {
                const reader = new FileReader();
                const readAsBinaryString = !!reader.readAsBinaryString;
                const file: any = upload.file.originFileObj;

                reader.onload = (e) => {
                  const binaryString = e.target?.result;
                  const workBook = xlsx.read(binaryString, {
                    type: readAsBinaryString ? "binary" : "array",
                  });
                  const workSheetName = workBook.SheetNames[0];
                  const workSheet = workBook.Sheets[workSheetName];
                  const sheetData: any = xlsx.utils.sheet_to_json(workSheet, {
                    header: "A",
                  });

                  const dataWithExtraColumns = sheetData.map(
                    (colDataByRow: any) => ({
                      ...colDataByRow,
                    })
                  );

                  setData(dataWithExtraColumns);
                  setStep(1);
                  setLoading(false);
                };

                if (readAsBinaryString) {
                  reader.readAsBinaryString(file);
                } else {
                  reader.readAsArrayBuffer(file);
                }
              }}
              style={{ paddingBottom: 96, paddingTop: 96, marginTop: 24 }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag your contacts import file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Supports uploading Excel and CSV formatted files.
              </p>
            </Dragger>
          </Spin>
        )}
        {step === 1 && (
          <>
            <BackTop />
            <Alert
              message="Please check the import data and map columns by selecting the correct datatype for the column."
              type="info"
              showIcon={true}
              style={{ marginTop: 24 }}
            />
            <Button
              type="ghost"
              style={{ marginTop: 24, marginRight: 24 }}
              onClick={() => {
                setStep(0);
                setData([]);
              }}
            >
              Import another file
            </Button>
            <Button
              type="primary"
              style={{ marginTop: 24, marginRight: 24 }}
              onClick={() => {
                addUsers();
              }}
            >
              Import contacts
            </Button>
            <Checkbox onChange={(e) => setSkipFirstRow(e.target.checked)}>
              Skip first row when importing
            </Checkbox>
            <Table
              scroll={{ x: "100vh", y: "100vh" }}
              dataSource={skipFirstRow ? slice(data, 1) : data}
              pagination={false}
              style={{ marginTop: 24 }}
              bordered
              loading={importing}
            >
              {keys(first(data)).map((keyName, index) => (
                <Column
                  sorter={
                    SORTABLE_COLUMNS.has(mapping[keyName])
                      ? (a: any, b: any) => {
                          if (
                            mapping[keyName] &&
                            SORTABLE_COLUMNS.has(mapping[keyName]) &&
                            a[keyName] &&
                            b[keyName] &&
                            typeof a[keyName] === "string" &&
                            typeof b[keyName] === "string"
                          ) {
                            return `${a[keyName]}`.localeCompare(b[keyName]);
                          }
                          return 0;
                        }
                      : false
                  }
                  width={200}
                  key={keyName}
                  render={(val, record, colIndex) => {
                    const errorFieldKey = `${keyName},${colIndex}`;
                    const errorVal = errors[errorFieldKey];
                    if (errorVal) {
                      return {
                        children: (
                          <Space>
                            <span>{val}</span>

                            <Tooltip color={"red"} title={errorVal}>
                              <InfoCircleOutlined style={{ color: "red" }} />
                            </Tooltip>
                          </Space>
                        ),
                        props: {
                          style: {
                            backgroundColor: "rgba(255,0,0,0.1)",
                          },
                        },
                      };
                    }
                    return renderTableRow(keyName, val);
                  }}
                  title={() => (
                    <div>
                      <Select
                        allowClear={true}
                        onChange={(value) => {
                          setMapping({
                            ...mapping,
                            [keyName]: value?.toString() || "",
                          });
                        }}
                        style={{ width: "100%" }}
                      >
                        <Option value="email">Email</Option>
                        <Option value="name">Full name</Option>
                        <Option value="first_name">First name</Option>
                        <Option value="last_name">Last name</Option>
                      </Select>
                    </div>
                  )}
                  dataIndex={keyName}
                />
              ))}
            </Table>
          </>
        )}
        {step === 2 && (
          <Result
            status="success"
            title="Successfully imported contacts!"
            extra={[
              <Button
                onClick={() => {
                  onCancel();
                  setStep(0);
                  setData([]);
                  setErrors({});
                  setMapping({ access_level: "access_level" });
                }}
                type="primary"
                key="console"
              >
                Go back
              </Button>,
              <Button key="buy" onClick={() => setStep(0)}>
                Import Again
              </Button>,
            ]}
          />
        )}
      </Col>
    </Modal>
  );
};

export default AddUsersFromFileModal;
