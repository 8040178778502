import { IUser, USER_ACCESS_LEVEL } from 'types/UserTypes';

interface ICourseConfiguration {
  deadline: number;
  module_skipping: boolean;
  certificate?: string;
}

export enum COURSE_COMPLETION_STATUS {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed'
}

export enum COURSE_STATE {
  PUBLISHED = 'published',
  IN_DRAFT = 'in_draft'
}

export interface ICourseHeader {
  progress_id: string;
  _id: string;
  title: string;
  status: COURSE_COMPLETION_STATUS;
  completed_pct?: number;
  due_date?: string;
  duration?: number;
  additional_certificate_text?: string;
  course_completed_at?: Date;
}

export interface ILearnerCourseHeader extends Omit<ICourseHeader, 'status'> {
  status: COURSE_COMPLETION_STATUS;
  completed_pct?: number;
}

export interface IAdminCourseHeader extends ICourseHeader {
  // status: ADMIN_COURSE_STATUS;
}

export interface ISuperAdminCourseHeader extends Omit<ICourseHeader, 'status'> {
  status: COURSE_STATE;
}

export type ICourseHeaderType<T = USER_ACCESS_LEVEL> = T extends USER_ACCESS_LEVEL.LEARNER
  ? ILearnerCourseHeader[]
  : T extends USER_ACCESS_LEVEL.ADMIN
  ? IAdminCourseHeader[]
  : ISuperAdminCourseHeader[];

export interface ICourseCompletionProgress {
  _id: string;
  course: string;
  user: string;
  updated_at?: Date | string;
  completed_subjects?: string[];
  unlocked_subjects?: string[];
  organization?: string;
  has_completed_course?: boolean;
  latest_subject?: string;

  latest_subject_progress?: {
    _id: string;
    progress: number;
  };

  latest_unlocked_subject?: string;

  course_completed_at?: Date | string;
}

export interface ICourseUserStats {
  _id: string;
  access_level: USER_ACCESS_LEVEL;
  organization: string;
  status: COURSE_COMPLETION_STATUS;
  team: string;
  user: IUser;
  completed_pct: number;
}

export enum TEST_TYPE {
  exam = 0,
  quiz = 1,
  final_exam = 2
}

/*
  course: { type: Types.ObjectId, ref: 'course', required: true },
  user: { type: Types.ObjectId, ref: 'user', required: true },
  organization: { type: Types.ObjectId, ref: 'organization' },
  latest_subject: { type: Types.ObjectId, ref: 'subject' },
  completed_subjects: [{ type: Types.ObjectId, ref: 'subject', unique: true }],
  has_completed_course: { type: Boolean, default: false }
*/
