import { Input } from 'antd';
import apiClient from 'api/apiClient';
import Tag from 'components/data/Tag';
import Chevron from 'components/icons/Chevron';
import Close from 'components/icons/Close';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import SuccessCheck from 'components/icons/SuccessCheck';
import { isEqual } from 'lodash';
import { FC, useState } from 'react';
import { mutate } from 'swr';
import { ICourseModule } from 'types/Course/course.module.types';
import { ICourseSubject } from 'types/Course/course.subject.types';
import { Course } from 'types/Course/course.types';
import './courseContent.scss';

interface IEditableCourseContent {
  course: Course;
  selectedSubject: ICourseSubject | undefined;
  updateSelectedSubject: (subject: ICourseSubject) => void;
}

const EditableCourseContents: FC<IEditableCourseContent> = ({ course, selectedSubject, updateSelectedSubject }) => {
  const [subjectTitle, setSubjectTitle] = useState<string>('');
  const [moduleTitle, setModuleTitle] = useState<string>('');
  const [subjectToEdit, setSubjectToEdit] = useState<string | undefined>();
  const [moduleToEdit, setModuleToEdit] = useState<string | undefined>();
  const [showNavigation, setShowNavigation] = useState<boolean>(true);

  const addModule = async () => {
    const response = await apiClient
      .post('modules/', { course: course._id, title: 'Created module' })
      .catch(() => null);

    if (response) {
      mutate(`/courses/content/${course._id}`);

      // set edit mode to just created module
      setModuleToEdit(response.data._id);
    }
  };

  const addSubject = async (module: string) => {
    const response = await apiClient
      .post('subjects/', {
        course: course._id,
        title: 'Created subject',
        module
      })
      .catch(() => null);

    if (response) {
      setSubjectToEdit(response.data._id);
      updateSelectedSubject(response.data);
      mutate(`/courses/content/${course._id}`);
    }
  };

  const editSubjectTitle = async (
    subjectId: string,
    moduleId: string,
    courseId: string,
    moduleIndex: number,
    subjectIndex: number
  ) => {
    if (subjectToEdit && subjectTitle.length) {
      mutate(
        `/courses/content/${course._id}`,
        (data: Course) => {
          const subjectToUpdate = data.ordered_modules[moduleIndex].ordered_subjects[subjectIndex] as ICourseSubject;
          const newSubject = { ...subjectToUpdate, title: subjectTitle };
          const newData = { ...data };
          newData.ordered_modules[moduleIndex].ordered_subjects[subjectIndex] = newSubject;
          return newData;
        },
        false
      );

      const response = await apiClient
        .patch('/subjects', {
          _id: subjectId,
          module: moduleId,
          course: courseId,
          title: subjectTitle
        })
        .catch((err) => null);

      if (response) mutate(`/courses/content/${course._id}`);
    }
    setSubjectToEdit(undefined);
  };

  const editModuleTitle = async (moduleIndex: number) => {
    if (moduleToEdit && moduleTitle.length) {
      mutate(
        `/courses/content/${course._id}`,
        (data: Course) => {
          const moduleToUpdate = data.ordered_modules[moduleIndex] as ICourseModule;
          const newModule = { ...moduleToUpdate, title: moduleTitle };
          const newData = { ...data };
          newData.ordered_modules[moduleIndex] = newModule;
          return newData;
        },
        false
      );

      const response = await apiClient
        .patch('/modules', {
          _id: moduleToEdit,
          title: moduleTitle
        })
        .catch(() => null);

      if (response) mutate(`/courses/content/${course._id}`);
    }
    setModuleToEdit(undefined);
    setModuleTitle('');
  };

  const deleteModule = async (moduleId: string) => {
    const response = await apiClient.delete(`/modules/${moduleId}`).catch(() => null);
    if (response) {
      mutate(`/courses/content/${course._id}`);
    }
  };

  const deleteSubject = async (subjecId: string) => {
    console.log('deleteSubject:', deleteSubject);
    const response = await apiClient.delete(`/subjects/${subjecId}`).catch(() => null);
    if (response) {
      mutate(`/courses/content/${course._id}`);
    }
  };

  const startEditing = (id: string, isSubject = true) => (isSubject ? setSubjectToEdit(id) : setModuleToEdit(id));

  if (!showNavigation) {
    return (
      <div className="course-content-closed" onClick={() => setShowNavigation(true)}>
        <Chevron direction={'left'} />
      </div>
    );
  }

  return (
    <div className="course-content" id="complok-course-edit-content-components-navigation">
      <div className="course-content-header">
        <div className="course-content-title">Course Content</div>
        <div style={{ flex: 1 }} />
        <div className="course-content-close" onClick={() => setShowNavigation(false)}>
          <Close />
        </div>
      </div>
      {course.ordered_modules.map((module, moduleIndex: number) => (
        <div key={module._id} className="course-content-module">
          {isEqual(module._id, moduleToEdit) ? (
            <div className="course-content-module-container">
              <Input
                style={{
                  borderRadius: 10,
                  fontFamily: 'poppins',
                  fontWeight: 400,
                  fontSize: 16
                }}
                defaultValue={module.title}
                onChange={(e) => setModuleTitle(e.target.value)}
                onPressEnter={() => editModuleTitle(moduleIndex)}
              />
              <SuccessCheck
                onClick={() => editModuleTitle(moduleIndex)}
                style={{ marginLeft: 15, cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div className="course-content-module-container">
              <label className="course-content-module-title">{module.title}</label>
              <DeleteIcon
                onClick={() => deleteModule(module._id)}
                style={{
                  minHeight: 26,
                  minWidth: 26,
                  height: 26,
                  width: 26,
                  cursor: 'pointer'
                }}
              />
              <EditIcon
                onClick={() => {
                  setModuleTitle(module.title);
                  startEditing(module._id, false);
                }}
                style={{
                  minHeight: 26,
                  minWidth: 26,
                  width: 26,
                  height: 26,
                  marginLeft: 5,
                  cursor: 'pointer'
                }}
              />
            </div>
          )}
          {module.ordered_subjects.map((subject: ICourseSubject, subjectIndex: number) => (
            <div key={subject._id} className="course-content-subject-container">
              {isEqual(subject._id, subjectToEdit) ? (
                <div className="course-content-subject">
                  <Input
                    style={{
                      borderRadius: 10,
                      fontFamily: 'poppins',
                      fontWeight: 400,
                      fontSize: 14
                    }}
                    defaultValue={subject.title}
                    onChange={(e) => setSubjectTitle(e.target.value)}
                    onPressEnter={() =>
                      editSubjectTitle(subject._id, subject.module, subject.course, moduleIndex, subjectIndex)
                    }
                  />
                  <SuccessCheck
                    onClick={() =>
                      editSubjectTitle(subject._id, subject.module, subject.course, moduleIndex, subjectIndex)
                    }
                    style={{ marginLeft: 15, cursor: 'pointer' }}
                  />
                </div>
              ) : (
                <>
                  <div className="course-content-subject">
                    <div
                      onClick={() => updateSelectedSubject(subject)}
                      className={
                        isEqual(selectedSubject?._id, subject._id)
                          ? 'course-content-subject-title-selected'
                          : 'course-content-subject-title'
                      }
                    >
                      {subject.title}
                    </div>
                    <Tag style={{ borderRadius: 15 }} title={subject.content_type || 'empty'} type="warning" />
                    <div style={{ flex: 1 }} />
                    <DeleteIcon
                      onClick={() => deleteSubject(subject._id)}
                      style={{
                        height: 22,
                        width: 22,
                        minHeight: 22,
                        minWidth: 22,
                        cursor: 'pointer'
                      }}
                    />
                    <EditIcon
                      onClick={() => startEditing(subject._id)}
                      style={{
                        height: 22,
                        width: 22,
                        minHeight: 22,
                        minWidth: 22,
                        marginLeft: 10,
                        cursor: 'pointer'
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="course-content-add-subject" onClick={() => addSubject(module._id)}>
            +
          </div>
        </div>
      ))}
      {/* TODO: REFACTO */}
      {/*
      {course?.final_quiz && course?.final_quiz?._id && (
        <div
          style={{ cursor: 'pointer' }}
          className="course-content-module"
          onClick={() =>
            updateSelectedSubject({
              _id: course?.final_quiz._id,
              title: course?.final_quiz.title,
              module: course?.final_quiz.module || '',
              course: course?.final_quiz.course,
              content: course?.final_quiz,
              content_type: COURSE_CONTENT.EXAM,
            })
          }
        >
          <div className="course-content-module-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label className="course-content-module-title">{course.final_quiz.title}</label>
              <Tag style={{ borderRadius: 15, marginLeft: 10 }} title={'Final exam'} type="success" />
            </div>
            <div style={{ flex: 1 }} />
          </div>
        </div>
      )}
      */}

      <div className="course-content-add-module" onClick={addModule}>
        +
      </div>
    </div>
  );
};

export default EditableCourseContents;
