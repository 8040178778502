import { Button, Form, Input, message, Select, Table, Tabs } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { ColumnsType } from 'antd/lib/table';
import apiClient from 'api/apiClient';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Course } from 'types/Course/course.types';
import { DetailedUserGroup } from 'types/GroupTypes';
import CourseDetailsDrawer from './drawers/CourseDetailsDrawer';
import { MANAGEMENT_MODALS } from './modals/management.modals';
import { OrganizationManagementContext } from './OrganizationManagementContext';
import GroupUsersTable from './tables/GroupUsersTable';

const { TabPane: Tab } = Tabs;

const CourseDuedatePicker = generatePicker<Dayjs>(dayjsGenerateConfig) as any;

const GroupManagement: FC = () => {
  const { t } = useTranslation();
  const { group, modalAction, refreshGroup } = useContext(OrganizationManagementContext);
  const [selectedCourse, setSelectedCourse] = useState<string>();

  const removeCourseFromGroup = async (courseId: string) => {
    if (!courseId || !group?._id) return;
    await apiClient
      .delete('/groups/detach_course_from_group', { data: { group: group._id, course: courseId } })
      .catch((err) => console.error(err));
    if (refreshGroup) refreshGroup();
  };

  const updateGroupCourseDuedate = async (courseId: string, duedate: Dayjs | null) => {
    if (!courseId || !group?._id) return;
    const deadline = duedate ? duedate.toDate() : undefined;
    await apiClient
      .put(`/groups/${group._id}/update_course_configuration`, {
        course: courseId,
        duedate: deadline
      })
      .catch((err) => console.error(err));
    if (refreshGroup) refreshGroup();
  };

  const removeAccountFromGroup = async (account_id?: string) => {
    if (!group?._id || !account_id) return;
    await apiClient
      .put(`/groups/${group?._id}/remove_accounts`, { accounts: [account_id] })
      .catch((err) => console.error(err));
    message.success(t('Removed account from group'));
    if (refreshGroup) refreshGroup();
  };

  const courseColumns: ColumnsType<Course> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      onCell: (rowCourse) => ({ onClick: () => setSelectedCourse(rowCourse._id) })
    },
    {
      title: t('Deadline'),
      dataIndex: ['configuration', 'duedate'],
      // todo this is currently hard coded
      render: (duedate, course) => (
        <CourseDuedatePicker
          style={{ minWidth: 200 }}
          onChange={(date: any) => updateGroupCourseDuedate(course._id, date)}
          value={duedate ? dayjs(duedate) : undefined}
          placeholder={t('Select due date')}
        />
      )

      // t('No deadline')) // t('31. december'),
    },
    {
      title: (
        <Button onClick={() => modalAction({ type: MANAGEMENT_MODALS.ASSIGN_COURSES })}>
          {t('Assign new courses')}
        </Button>
      ),
      align: 'right',
      render: (course: Course) => (
        <Button onClick={() => removeCourseFromGroup(course?._id)} type={'text'} danger>
          {t('Remove course from group')}
        </Button>
      )
    }
  ];

  return (
    <div id="complok-management-group">
      <Tabs>
        <Tab tab={t('Users')} key={'users'}>
          <GroupUsersTable
            group={group}
            onAddUsers={() => modalAction({ type: MANAGEMENT_MODALS.ADD_USERS })}
            onAddUsersFromFile={() => modalAction({ type: MANAGEMENT_MODALS.ADD_USERS_FROM_FILE })}
            removeAccountFromGroup={removeAccountFromGroup}
          />
        </Tab>
        <Tab tab={t('Courses')} key={'courses'}>
          <Table
            rowKey={'_id'}
            columns={courseColumns}
            dataSource={group?.assigned_courses}
            id="complok-groupmanagement-details-group-courses"
          />
          <CourseDetailsDrawer
            onClose={() => setSelectedCourse(undefined)}
            defaultGroup={group?._id}
            selectedCourse={selectedCourse}
          />
        </Tab>
        <Tab tab={t('Group Settings')} key={'group'}>
          <UserGroupSettings group={group} />
        </Tab>
      </Tabs>
      {/* TODO: When need to add users to organization without group in org view, add these modals to org context */}
    </div>
  );
};

const UserGroupSettings: FC<{ group?: DetailedUserGroup; refreshGroup?: () => void }> = ({ group, refreshGroup }) => {
  const { t } = useTranslation();

  const saveSettings = async (settingValues: any) => {
    if (!group || !group?._id) {
      console.error('No Group ID when saving UserGroupSettings');
      return;
    }

    if (!settingValues?.title || !settingValues?.locale) return;

    await apiClient
      .patch(`/groups/${group._id}/details`, { title: settingValues.title, locale: settingValues.locale })
      .then((response) => {
        if (response?.data) {
          if (refreshGroup) refreshGroup();
        }
      })
      .catch(() => {
        message.error(t('Failed to update group name'));
      });
  };

  return (
    <div id="complok-groupmanagement-details-group-settings">
      <Form
        style={{ marginTop: 32 }}
        layout={'vertical'}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 6 }}
        initialValues={{ title: group?.title, locale: group?.locale ?? 'en' }}
        onFinish={saveSettings}
      >
        <Form.Item name="title" key="title" label={t('Group Title')} rules={[{ required: true }]}>
          <Input size={'small'} placeholder={group?.title ?? ''} />
        </Form.Item>
        <Form.Item name="locale" key="locale" label={t('Group language')}>
          <Select>
            <Select.Option value={'et'}>{t('Eesti')}</Select.Option>
            <Select.Option value={'en'}>{t('English')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'}>{t('Save changes')}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GroupManagement;
