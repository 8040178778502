import { Button } from 'antd';
import { CSSProperties, FC } from 'react';
import '../complokTheme.scss';

interface IComplokButton {
  onClick?: Function;
  title?: string;
  iconRight?: boolean;
  disabled?: boolean;
  icon?: any;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  style?: CSSProperties;
  link?: boolean;
  textOnly?: boolean;

  type?: 'secondary' | 'danger' | 'primary'; // Primary should be default
  size?: 'default' | 'small'; // |'large' Not implemented yet

  className?: string;
}

const ComplokButton: FC<IComplokButton> = ({
  title,
  children,
  iconRight,
  disabled,
  link,
  icon,
  textOnly,
  onClick,
  type = 'primary',
  size = 'default',
  loading,
  className,
  style
}) => {
  const buttonClass = `complok-button complok-button-${type} complok-button-${size} ${className || ''}`;
  return (
    <Button
      onClick={() => onClick && onClick()}
      className={buttonClass}
      title={title}
      size={size === 'small' ? 'small' : 'middle'}
      type={textOnly ? 'text' : (link && 'link') || 'primary'}
      disabled={disabled}
      style={style && style}
      loading={loading}
    >
      {!iconRight && icon}
      {children}
      {iconRight && icon}
    </Button>
  );
};

export default ComplokButton;
