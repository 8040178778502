import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={30} height={30} rx={5} fill="#2F54EB" />
    <path
      d="M20.75 11.766h-4V10a.5.5 0 00-.5-.5H9.875v-.625a.125.125 0 00-.125-.125h-.875a.125.125 0 00-.125.125v12.25c0 .069.056.125.125.125h.875a.125.125 0 00.125-.125V17h3.875v1.766a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5v-6.5a.5.5 0 00-.5-.5z"
      fill="#FAFAFB"
    />
  </svg>
);

export default SvgComponent;
