import { Button, Col, message, Row, Select, Tabs, Typography } from "antd";
import apiClient from "api/apiClient";
import EditIcon from "components/icons/EditIcon";
import { UserContext } from "context/UserContext";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { USER_ACCESS_LEVEL } from "types/UserTypes";
import { MANAGEMENT_MODALS } from "./modals/management.modals";
import { OrganizationManagementContext } from "./OrganizationManagementContext";
import downloadOrganizationProgress from "./statistics/downloadOrganizationProgress";
import GroupTable from "./tables/GroupTable";
import OrganizationCoursesTable from "./tables/OrganizationCoursesTable";
import OrganizationUsersTable from "./tables/OrganizationUsersTable";

const { TabPane: Tab } = Tabs;

const OrganizationManagement: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { accountData } = useContext(UserContext);
  const {
    organization,
    groups,
    refreshGroups,
    groupsLoading,
    modalAction,
    updateOrganization,
    organizations,
  } = useContext(OrganizationManagementContext);

  const selectUserGroup = (groupId: string, path?: "users" | "courses") =>
    navigate(
      `/management/g/${groupId}${
        path === "users" ? "/u" : (path === "courses" && "/c") || ""
      }`
    );

  const onAddUsers = (groupId?: string) => {
    // Missing functionality to add users without groups
    if (!groupId) return;
    selectUserGroup(groupId);
    modalAction({ type: MANAGEMENT_MODALS.ADD_USERS });
  };

  const onDeleteGroup = (id: string) => {
    const loading = message.loading(t("Deleting group!"));

    apiClient
      .delete(`/groups/${id}`)
      .then(() => {
        loading();
        refreshGroups();
      })
      .catch(() => {
        loading();
        message.error("Failed to delete group!");
      });
  };

  return (
    <div id="complok-management-organization">
      {accountData?.access_level === USER_ACCESS_LEVEL.SUPER_ADMIN &&
        organizations && (
          <Row align="middle">
            <Col>
              <Select
                style={{ width: 250 }}
                defaultValue={organization?._id ?? accountData?.organization}
                options={organizations.map((org) => ({
                  value: org._id,
                  label: org?.title,
                }))}
                onSelect={(val: string) => val && updateOrganization(val)}
              />
            </Col>
            <Col
              onClick={() =>
                modalAction({ type: MANAGEMENT_MODALS.EDIT_ORGANIZATION })
              }
              style={{
                marginLeft: 10,
                marginTop: 5,
                cursor: "pointer",
                fontSize: 14,
              }}
              flex={1}
            >
              <EditIcon />
            </Col>
            {/* Create another col */}
            <Col>
              {/* Button to create a new organization */}
              <Button
                type="primary"
                onClick={() =>
                  modalAction({ type: MANAGEMENT_MODALS.CREATE_ORGANIZATION })
                }
              >
                {t("Create new organization")}
              </Button>
            </Col>
          </Row>
        )}
      <Tabs>
        <Tab tab={t("Groups")} key="groups">
          <Col style={{ paddingBottom: 60 }}>
            <GroupTable
              loading={!!groupsLoading}
              groups={groups ?? []}
              selectGroup={(groupId) => navigate(`/management/g/${groupId}`)}
              onAddUsers={onAddUsers}
              onCreateGroup={() =>
                modalAction({ type: MANAGEMENT_MODALS.CREATE_GROUP })
              }
              onDeleteGroup={onDeleteGroup}
            />
          </Col>
        </Tab>
        <Tab tab={t("Users")} key="users">
          <Col style={{ paddingBottom: 60 }}>
            <OrganizationUsersTable organization={organization?._id} />
          </Col>
        </Tab>
        <Tab tab={t("Courses")} key="courses">
          <OrganizationCoursesTable
            groups={groups}
            courses={organization?.courses || []}
          />
          <Row
            justify={"space-between"}
            style={{ padding: 16, background: "#fafafa" }}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              {t("Download organization users course progress data as .CSV")}
            </Typography>
            <Button
              onClick={() =>
                organization &&
                downloadOrganizationProgress({
                  file_name: t("Organization_Learners_Course_Progress_Data"),
                  organization: organization?._id,
                })
              }
            >
              {t("Download")}
            </Button>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
};

export default OrganizationManagement;
