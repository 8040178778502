/* eslint-disable operator-linebreak */
import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  FastForwardOutlined,
  GlobalOutlined,
  LoadingOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import { Button, Col, Grid, Row, Skeleton, Tabs, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import Chevron from "components/icons/Chevron";
import dayjs from "dayjs";
import { chunk, flatMap } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import Showdown from "showdown";
import { Course } from "types/Course/course.types";
import {
  ICourseCompletionProgress,
  ICourseHeader,
} from "types/Course/CourseTypes";
import { LearnerContext } from "../LearnerContext";
import CourseNavigation from "./CourseComponents/CourseNavigation";
import { CourseContext } from "./CourseContext";

const { useBreakpoint } = Grid;
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const CourseInformation: FC = () => {
  const isSmallScreen = useBreakpoint().md === false;
  const { courseHeaders } = useContext(LearnerContext);
  const { course, courseProgress, startNewCourse } = useContext(CourseContext);
  const [startingCourse, setStartingCourse] = useState<boolean>(false);

  // TODO: Maybe add default tab possibility to course creator?

  const { t } = useTranslation();
  const allSubjects = flatMap(
    course?.ordered_modules ?? [],
    (mod) => mod?.ordered_subjects
  );
  const examUnlocked =
    allSubjects.length <= (courseProgress?.completed_subjects?.length ?? 0);

  const currentCourseHeader = courseHeaders
    ? courseHeaders.find((ch) => ch._id === course?._id)
    : undefined;

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartingCourse(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [startingCourse]);

  const handleStartCourse = () => {
    setStartingCourse(true);
    const courseFirstSubject =
      course?.ordered_modules?.[0]?.ordered_subjects?.[0]?._id;
    if (!courseFirstSubject) return;
    if (courseProgress && courseProgress.latest_unlocked_subject) return;
    // unlockSubject(course?.ordered_modules?.[0]?.ordered_subjects?.[0]?._id);
    startNewCourse(courseFirstSubject);
  };

  if (
    startingCourse &&
    course &&
    courseProgress?.latest_unlocked_subject &&
    findModuleBySubject(course, courseProgress.latest_unlocked_subject)
  ) {
    return (
      <Navigate
        to={`/courses/${course?._id}/${
          findModuleBySubject(course, courseProgress.latest_unlocked_subject)
            ?._id
        }/${courseProgress.latest_unlocked_subject}`}
      />
    );
  }

  const outcomeTabKey = t("What will you learn");
  const outcomeTab = course?.outcome?.length && (
    <Tabs.TabPane tab={outcomeTabKey} key="outcome">
      {course.outcome.map((x, index) => (
        <Col span={24} key={index}>
          <Row justify="start" wrap={false}>
            <CheckOutlined
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 6,
                maxHeight: 28,
              }}
            />
            <Typography>{x}</Typography>
          </Row>
        </Col>
      ))}
    </Tabs.TabPane>
  );

  const descriptionTabKey = t("Description");
  const descriptionTab = course?.description && (
    <Tabs.TabPane tab={descriptionTabKey} key="description">
      {ReactHtmlParser(converter.makeHtml(course.description))}
    </Tabs.TabPane>
  );

  const contentsTabKey = t("Course Content");
  const contentsTab = course && (
    <Tabs.TabPane tab={contentsTabKey} key="content">
      <CourseNavigation
        styles={{ padding: 0 }}
        showNavigation
        hideTitle
        hideClose
        course={course}
        courseProgress={courseProgress}
        hasExamUnlocked={examUnlocked}
      />
    </Tabs.TabPane>
  );

  const smallScreenCourseInformation = (
    <Col id="complok-courseinformation-content" className="complok-mobile">
      <Tabs
        tabBarStyle={{ marginTop: 16, marginBottom: 16 }}
        style={{ padding: "0 15px 32px 15px" }}
      >
        {contentsTab}
        {outcomeTab}
        {descriptionTab}
      </Tabs>
    </Col>
  );

  const courseInformation = (
    <>
      <Col
        style={{
          marginTop: 44,
          color: "#595959",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "28px",
          letterSpacing: "-1%",
        }}
        span={24}
      >
        {(course?.outcome?.length && (
          <div>
            <Title level={2}>{t("What will you learn")}</Title>

            {chunk(course.outcome, 2).map((column, rowIndex) => (
              <Row
                gutter={16}
                style={{ width: "100%", marginBottom: 10 }}
                key={rowIndex}
              >
                {column.map((x, colIndex) => (
                  <Col span={12} style={{ width: "50%" }} key={colIndex}>
                    <Row justify={"start"} wrap={false}>
                      <CheckOutlined
                        style={{ marginRight: 10, paddingTop: 6 }}
                      />
                      <Typography>{x}</Typography>
                    </Row>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        )) ||
          null}
      </Col>

      <Row gutter={26} style={{ marginTop: 44 }} justify="space-between">
        <Col
          style={{
            color: "#595959",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "28px",
            letterSpacing: "-1%",
          }}
          span={12}
        >
          {course?.description && (
            <div style={{ paddingRight: 38 }}>
              <Title level={2}>{t("Description")}</Title>
              {ReactHtmlParser(converter.makeHtml(course.description))}
            </div>
          )}
        </Col>
        <Col
          style={{ justifyContent: "flex-end", display: "flex" }}
          span={12}
          id="complok-courseinformation-navigation"
        >
          {course && (
            <CourseNavigation
              showNavigation
              hideClose
              course={course}
              courseProgress={courseProgress}
              hasExamUnlocked={examUnlocked}
            />
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <div
      id="complok-learner-courseinfo"
      className="enable-vertical-scroll h100p"
      style={{
        padding: "20px 10vh",
        paddingBottom: 100,
        margin: "60px 0 80px 0",
      }}
    >
      {(courseHeaders?.length ?? 0) > 1 && (
        <Link
          to={"/courses"}
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Chevron direction="left" /> <Text>{t("Go Back")}</Text>
        </Link>
      )}
      <CourseInformationBanner
        course={course}
        courseHeader={currentCourseHeader}
        courseProgress={courseProgress}
        startingCourse={startingCourse}
        handleStartCourse={handleStartCourse}
        completion_prc={currentCourseHeader?.completed_pct ?? 0}
      />
      {isSmallScreen && smallScreenCourseInformation}
      {!isSmallScreen && courseInformation}
    </div>
  );
};

const CourseInformationBanner: FC<{
  course?: Course;
  courseHeader?: ICourseHeader;
  courseProgress?: ICourseCompletionProgress;
  completion_prc: number;

  startingCourse?: boolean;

  handleStartCourse?: Function;
}> = ({
  course,
  courseHeader,
  courseProgress,
  startingCourse,
  handleStartCourse,
  completion_prc,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useBreakpoint().md === false;

  const duedate = course?.configuration?.deadline && (
    <Text>
      <CalendarOutlined />
      {`${t("Due date")}: ${dayjs(courseHeader?.due_date).format("DD MMM")}`}
    </Text>
  );
  const courseDuration = dayjs.duration({
    hours: course?.configuration?.suggested_duration ?? 0,
  });
  const duration = course?.configuration?.suggested_duration && (
    <Text>
      <ClockCircleOutlined />
      {/* {courseDuration.hours() > 0 && `${courseDuration.hours()} ${t('hours')}`} */}
      {courseDuration.hours() > 0 &&
        `${t("Duration")}: ${courseDuration.humanize()}`}
    </Text>
  );

  const locale = (
    <Text>
      <GlobalOutlined />
      {course?.configuration?.locale === "EN" ? "English" : "Eesti"}
    </Text>
  );

  // TODO: Not implemented
  const translations = false && (
    <Text>
      <TranslationOutlined />
      Estonian, Spanish, Russian
    </Text>
  );

  /** @description Removed due to lack of necessity */
  const skipCourseComponents = !!course?.configuration?.skipping && (
    <Text>
      <FastForwardOutlined />
      {t("Can skip modules")}
    </Text>
  );

  const courseLabels = (
    <div
      id="courseinfo-banner-labels"
      style={isSmallScreen ? { display: "flex", flexDirection: "column" } : {}}
    >
      {duedate}
      {duration}
      {locale}
      {translations}
      {/* {skipCourseComponents} */}
    </div>
  );

  return (
    <div
      className="courseinfo-banner main-bg"
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "space-between",
        overflowX: "hidden",
        overflowY: "hidden",
        color: "white",
        padding: "32px 20px",
        maxHeight: 400,
        borderRadius: 15,
        backgroundColor: "#F4A261", // '#F4A261', //'#E76F51', // '#264653',
      }}
    >
      <div
        className="courseinfo-banner-info"
        style={{
          paddingLeft: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
          minWidth: 300,
        }}
      >
        {completion_prc !== undefined && (
          <Typography
            style={{ color: "white" }}
            id={"complok-learner-courseinfo-banner-smallpercentage"}
          >
            {completion_prc}
            {t("% Completed")}
          </Typography>
        )}
        {course?.title ? (
          <Title
            level={2}
            style={{
              color: "white",
              marginBottom: 15,
              marginTop: 8,
              maxWidth: 768,
            }}
          >
            {course.title}
          </Title>
        ) : (
          <Skeleton paragraph={{ rows: 0 }} active />
        )}
        {courseLabels}
        <Button
          className="courseinfo-banner-action"
          style={{
            bottom: 0,
            marginTop: 30,
            alignSelf: "flex-start",
            width: 185,
            height: 44,
            color: "rgb(244, 162, 97)",
            borderColor: "rgb(244, 162, 97)",
            fontSize: 14,
          }}
          onClick={() =>
            handleStartCourse && !startingCourse && handleStartCourse()
          }
        >
          {courseProgress && !startingCourse
            ? t("Continue Learning")
            : t("Start Learning")}
          {startingCourse && <LoadingOutlined spin />}
        </Button>
      </div>
      <div className="courseinfo-banner-ghost-percentage">
        {completion_prc}%
      </div>
      {/*
      <div
        className="courseinfo-banner-percentage"
        style={{
          fontSize: '12em',
          fontWeight: 600,
          lineHeight: '100%',
          margin: '50px 0px'
        }}
      >
        {completion_prc}%
      </div>
      */}
    </div>
  );
};

const findModuleBySubject = (course: Course, subjectIdentifier: string) =>
  course?.ordered_modules?.find((mod) =>
    mod.ordered_subjects.find((s) => s._id === subjectIdentifier)
  ) ?? undefined;

export default CourseInformation;
