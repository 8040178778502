import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Spin } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import apiClient from "api/apiClient";
import SettingsContextProvider from "context/SettingsContext";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/et";

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import "react-toggle/style.css"; // for ES6 modules
import { SWRConfig } from "swr";
import "./components/complokTheme.scss";
import UserContextProvider from "./context/UserContext";
import i18n from "./i18n";
import MainRoutes from "./Router";
import appData from './app.json'
import "./Styling.less";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const fetcher = (url: string) =>
  apiClient.get(url).then((res) => res.data);

i18n.on("languageChanged", (changedLanguageLocale) => {
  dayjs.locale(changedLanguageLocale);
});

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://af6a4a0db79945809768d7561f79659c@o1054191.ingest.sentry.io/6041956",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

console.info('Welcome to Complok e-learning')
console.info([appData?.name, appData?.version, appData?.patch_name, process.env.NODE_ENV].join(' '))

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <I18nextProvider i18n={i18n}>
        <SWRConfig
          value={{
            fetcher,
            shouldRetryOnError: false,
            revalidateOnFocus: false,
          }}
        >
          <SettingsContextProvider>
            <UserContextProvider>
              <BrowserRouter>
                <MainRoutes />
              </BrowserRouter>
            </UserContextProvider>
          </SettingsContextProvider>
        </SWRConfig>
      </I18nextProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
