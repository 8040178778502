import { SVGProps } from 'react';

const AdminTag = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.507 6.103c-.467 0-.904.18-1.235.512a1.74 1.74 0 00-.513 1.236c0 .467.183.905.513 1.236.33.33.768.512 1.235.512a1.74 1.74 0 001.236-.512 1.74 1.74 0 00.513-1.236 1.74 1.74 0 00-.513-1.236 1.734 1.734 0 00-1.236-.512zm6.443 3.68l-1.022-.874a5.619 5.619 0 000-1.803l1.022-.874a.5.5 0 00.145-.55l-.014-.04a6.912 6.912 0 00-1.244-2.152l-.028-.033a.502.502 0 00-.548-.148l-1.27.451a5.456 5.456 0 00-1.555-.898l-.246-1.327a.5.5 0 00-.403-.401l-.042-.008a7.041 7.041 0 00-2.481 0l-.042.008a.5.5 0 00-.404.401l-.246 1.333a5.522 5.522 0 00-1.546.896l-1.278-.455a.5.5 0 00-.548.148l-.028.033a6.968 6.968 0 00-1.244 2.152l-.014.04a.502.502 0 00.145.55l1.034.883a5.492 5.492 0 000 1.781l-1.03.883a.5.5 0 00-.146.55l.014.04c.283.787.7 1.513 1.244 2.152l.028.033a.502.502 0 00.548.149l1.278-.455c.466.383.985.686 1.546.895l.247 1.333a.5.5 0 00.403.402l.042.007c.82.148 1.66.148 2.481 0l.042-.007a.5.5 0 00.403-.402l.246-1.327a5.494 5.494 0 001.556-.898l1.269.452a.5.5 0 00.548-.149l.028-.033a6.967 6.967 0 001.244-2.151l.014-.04a.504.504 0 00-.148-.548zm-6.443.815a2.747 2.747 0 110-5.494 2.747 2.747 0 010 5.494z"
      fill="#595959"
    />
  </svg>
);

export default AdminTag;
