import Button from 'components/interactables/Button';
import { FC, useContext, useState } from 'react';
import { CourseEditContext } from './CourseEdit';

interface ICourseUsersTeams {
  id: string;
}

const CourseEditAccess: FC = () => {
  const { course } = useContext(CourseEditContext);

  return (
    <div className="super-admin-create-course-teams-container">
      <label className="super-admin-create-course-teams-container-title">Sharing Course with User Groups</label>
      <div className="super-admin-create-course-teams-container-content">
        <div className="super-admin-create-course-teams-container-content-header">
          <label className="super-admin-create-course-teams-container-content-header-title">Team management</label>
          <Button size="small">Manage teams</Button>
        </div>
      </div>
    </div>
  );
};

export default CourseEditAccess;
