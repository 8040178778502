import { SVGProps } from 'react';

const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5zm3.377 10.662l-5.118 3.724a.188.188 0 01-.298-.152V8.29a.187.187 0 01.298-.152l5.118 3.721a.185.185 0 010 .303z"
      fill="#44444F"
    />
  </svg>
);

export default PlayIcon;
