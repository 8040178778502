/* eslint-disable no-confusing-arrow */
import { string } from 'joi';

export const getUserAdministratedTeams = (defaultTeam?: string) => (defaultTeam ? `/team/data/${defaultTeam}` : '/team/teams');
export const postInviteUsers = () => '/users/invite';

export const getVideoUrl = (videoId?: string) => (videoId ? `/fileuploads/video/${videoId}` : null);
export const getVideoChapters = (videoId?: string) => (videoId ? `/video/chapters/${videoId}` : null);

export const getCourseInformation = (courseIdentifier?: string) => (courseIdentifier ? `/courses/content/${courseIdentifier}` : null);

export const getCourseHeaders = (accountId: string) => `/courses/by_account/${accountId}`;

export const getCourseProgress = (courseIdentifier?: string, accountId: string | undefined = undefined) =>
  courseIdentifier && accountId ? `/progresses/${accountId}/${courseIdentifier}` : null;

export const updateCourseProgress = (
  courseIdentifier: string,
  moduleIdentifier: string,
  subjectIdentifier: string,
  accountId: string | undefined = undefined
) =>
  courseIdentifier && moduleIdentifier && subjectIdentifier
    ? `/progresses/${accountId}/${courseIdentifier}/${moduleIdentifier}/${subjectIdentifier}`
    : null;

export const unlockCourseProgress = (courseIdentifier: string, subjectIdentifier: string, accountId: string | undefined = undefined) =>
  `/progresses/unlock/${accountId}/${courseIdentifier}/${subjectIdentifier}`;

export const getCourseNotes = (courseIdentifier: string) => `/notes/${courseIdentifier}`;
export const postCreateCourseNote = (courseIdentifier: string, moduleIdentifier: string, subjectIdentifier: string) =>
  `/notes/${courseIdentifier}/${moduleIdentifier}/${subjectIdentifier}`;
export const deleteCourseNote = (noteIdentifier: string) => `/notes/${noteIdentifier}`;

/**    CERTIFICATES */
const getAllCertificates = () => '/certificates';
const postCreateCertificate = (courseIdentifier: string) => `/certificates/${courseIdentifier}`;
const patchActivateCertificate = (courseIdentifier: string) => `/certificates/state/${courseIdentifier}`;

export const certificateAPI = {
  getAllCertificates,
  postCreateCertificate,
  patchActivateCertificate
};
