import Header from 'components/header/Header';
import i18n from 'i18n';
import { Navigate, Outlet, Route } from 'react-router-dom';
import SettingsView from 'screens/SettingsScreen/SettingsView';
import OrganizationManagementRouter from './Management/OrganizationManagementRouter';

const AdminRoutes = [
  {
    name: i18n.t('Management'),
    path: '/management'
  },
  {
    name: i18n.t('Settings'),
    path: '/settings'
  }
];

const AdminRouter = (
  <Route
    element={
      <>
        <Header links={AdminRoutes} />
        <Outlet />
      </>
    }
  >
    {OrganizationManagementRouter}

    <Route path="/settings" element={<SettingsView />} />

    <Route path="*" element={<Navigate to="/management" />} />
  </Route>
);

export default AdminRouter;
