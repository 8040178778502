import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Col, InputNumber, message, Row, Slider } from 'antd';
import apiClient from 'api/apiClient';
import Button from 'components/interactables/Button';
import GenericModal from 'components/modals/GenericModal';
import { FC, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { ICourseExam } from 'types/Course/CourseContent/course.content.exam.types';

interface IExamSettingsModal {
  visible: boolean;
  onCancel?: any;
  exam: ICourseExam;
}

const ExamSettingsModal: FC<IExamSettingsModal> = ({ visible, onCancel, exam }) => {
  const { settings = {} } = exam ?? {};
  const [passPct, setPassPct] = useState((settings?.pass_percentage ?? 0) * 100);
  const [updatingSettings, setUpdatingSettings] = useState(false);

  useEffect(() => {
    setPassPct((settings?.pass_percentage || 0) * 100);
  }, [settings]);

  const updateExamSettings = async () => {
    setUpdatingSettings(true);
    apiClient
      .patch('/exams', {
        _id: exam._id,
        settings: { pass_percentage: passPct / 100 }
      })
      .then(() => {
        setUpdatingSettings(false);
        onCancel();
      })
      .catch(() => {
        setUpdatingSettings(false);
        message.error('Failed to update settings');
      });
  };

  const modalBody = (
    <div className="super-admin-create-course-content-content-quiz-modal">
      <Checkbox checked={true}>Final examination Quiz</Checkbox>
      <div className="super-admin-create-course-content-content-quiz-modal-completion">
        <Row>
          <Col flex="auto">
            <div className="super-admin-create-course-content-content-quiz-modal-completion-title">
              Quiz pass percentage
            </div>
          </Col>
          <Col>
            <Toggle
              checked={passPct !== 0}
              onChange={(e) => setPassPct(e.target.checked ? 75 : 0)}
              className="complok-toggle"
              icons={false}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto" style={{ marginRight: 20 }}>
            <Slider onChange={(val) => setPassPct(val)} value={passPct} tipFormatter={(val) => `${val} %`} />
          </Col>
          <Col flex="100px">
            <InputNumber value={`${passPct} %`} />
          </Col>
        </Row>
      </div>
    </div>
  );

  const modalFooter = (
    <Row style={{ justifyContent: 'flex-end' }}>
      <Col>
        <Button onClick={updateExamSettings}>Save</Button>
      </Col>
      <Col style={{ marginLeft: 10 }}>
        <Button
          loading={updatingSettings}
          onClick={() => {
            setPassPct((settings?.pass_percentage && settings.pass_percentage * 100) || 0);
          }}
          type="secondary"
        >
          Reset
        </Button>
      </Col>
    </Row>
  );

  return (
    <GenericModal
      closeIcon={<CloseOutlined />}
      visible={visible}
      title="Settings"
      onCancel={onCancel}
      okText={'Save'}
      footer={modalFooter}
      destroyOnClose={true}
    >
      {modalBody}
    </GenericModal>
  );
};

export default ExamSettingsModal;
