import { Checkbox, Button, Col, Form, Input, message, Popover, Row, Select, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ComplokIconFull from 'assets/illustrations/ComplokIconFull';
import ComplokWalkingMobile from 'assets/illustrations/ComplokWalkingMobile';
import ComplokIcon from 'components/icons/ComplokIcon';
import EnglishFlag from 'components/icons/EnglishFlag';
import EstonianFlag from 'components/icons/EstonianFlag';
import WalkingComplokIcon from 'components/icons/WalkingComplokIcon';
import { UserContext } from 'context/UserContext';
import i18n from 'i18n';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../api/apiClient';
import '../ForgotPassword/ForgotPassword.scss';

interface IValidateSetPasswordResponse {
  email: string;
  hash: string;
}

const LANGUAGE_LABELS: { [key: string]: string } = {
  et: 'Eesti',
  en: 'English'
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const { Title, Text } = Typography;

const SetPasswordView: FC = () => {
  const { clearUserCache } = useContext(UserContext);
  const [form] = useForm();
  const [translateLang, setTranslateLang] = useState<string | undefined>();

  const [settingNewPw, setSettingsNewPw] = useState<boolean>(false);

  const { uuid: setPasswordUuid } = useParams();
  const navigate = useNavigate();

  const isSmallScreen = useBreakpoint().md === false;

  const { t } = useTranslation();
  const { data, error } = useSWR<IValidateSetPasswordResponse>(
    setPasswordUuid ? `users/setpassword/${setPasswordUuid}` : null
  );

  const queryParams = useQuery();
  const language = queryParams.get('lang') || 'en';

  const userHasPassword = !!data?.hash;

  useEffect(() => {
    i18n.changeLanguage(language);
    form.setFieldsValue({ locale: language });
    setTranslateLang(language);
  }, [language]);

  useEffect(() => {
    clearUserCache();
  }, [clearUserCache]);

  const setPassword = (values: { password: string; locale: string }) => {
    const { password, locale } = values;
    if (password && data) {
      setSettingsNewPw(true);
      apiClient
        .patch(`users/setpassword/${setPasswordUuid}`, {
          password,
          email: data.email,
          locale,
          set_password_uuid: setPasswordUuid
        })
        .then((response) => {
          if (response?.data) {
            message.success(t('Password has been updated'));
            setSettingsNewPw(false);

            navigate('/login');
          }
        })
        .catch(() => {
          setSettingsNewPw(false);
          message.error(t('Failed to update password'));
        });
    }
  };

  if (error) {
    return (
      <div
        style={{
          height: '80%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>
          <Title style={{ textAlign: 'center', margin: '40px 0px' }}>{t('Link is invalid')}</Title>
          <Button size="large" type="primary" onClick={() => navigate('/')}>
            {t('Go back to login screen')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={isSmallScreen ? 'login-view-mobile' : 'login-view'}>
      <Row className="login-view-header">
        <Col flex={1}>
          <a href="https://elearning.complok.eu">{isSmallScreen ? <ComplokIcon /> : <ComplokIconFull />}</a>
        </Col>
        <Col>
          <Popover
            trigger={'hover'}
            placement={'bottomRight'}
            content={() => (
              <LanguagePopoverContent
                updateLanguage={(val) => {
                  setTranslateLang(val);
                  i18n.changeLanguage(val);
                }}
              />
            )}
          >
            <span className="login-view-header-langauge-container">
              <p className="login-view-header-langauge-prefix">{`${t('Language')}`}</p>
              <p className="login-view-header-langauge-value">{translateLang && LANGUAGE_LABELS[translateLang]}</p>
            </span>
          </Popover>
        </Col>
      </Row>
      <Row style={{ height: '100%', width: '100%' }}>
        <Col className={isSmallScreen ? 'login-view-mobile-icon-container' : 'login-view-icon-container'}>
          {isSmallScreen ? <ComplokWalkingMobile style={{ width: 205, height: 200 }} /> : <WalkingComplokIcon />}
        </Col>
        <Col className={isSmallScreen ? 'login-view-mobile-form' : 'login-view-form'}>
          <Form
            size="large"
            form={form}
            name="register"
            onFinish={setPassword}
            style={{ width: '100%' }}
            scrollToFirstError
            labelCol={{ span: 24 }}
          >
            <Title style={{ margin: '20px 0px', width: '100%' }}>
              {userHasPassword
                ? t('Create yourself a new password!')
                : t('Welcome to All-In-One Online AML Training Centre!')}
            </Title>
            {!userHasPassword && (
              <div style={{ paddingBottom: 26 }}>
                <Text>{t('Let’s get you started. First things first.')}</Text>{' '}
              </div>
            )}
            <Form.Item
              label={
                <div style={{ fontWeight: 550 }}>{userHasPassword ? t('New password') : t('Create Password')}</div>
              }
              requiredMark={'optional'}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Please input your password!')
                },
                {
                  min: 8,
                  message: t('Password is too short')
                },
                {
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                  message: t('Password should container at least 1 uppercase, 1 lowercase and 1 number')
                }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              hidden={userHasPassword}
              name="locale"
              label={<div style={{ fontWeight: 550 }}>{t('Preferred language')}</div>}
            >
              <Select onSelect={(val: string) => i18n.changeLanguage(val)}>
                <Select.Option key="en" value="en">
                  <Row align="middle">
                    <EnglishFlag style={{ marginRight: 10 }} />
                    <div className="complok-header-selector-option-text">English</div>
                  </Row>
                </Select.Option>
                <Select.Option key="et" value="et">
                  <Row align="middle">
                    <EstonianFlag style={{ marginRight: 10 }} />
                    <div className="complok-header-selector-option-text">Eesti</div>
                  </Row>
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              hidden={userHasPassword}
              valuePropName="checked"
              name="termsAndPolicy"
              rules={[
                {
                  required: !userHasPassword,
                  message: t('Please agree with Privacy Policy and Terms and Conditions!'),
                  validator: (rule, val, cb) => {
                    if (userHasPassword) {
                      return cb();
                    }
                    return val ? cb() : cb(t('Please agree with Privacy Policy and Terms and Conditions!'));
                  }
                }
              ]}
            >
              <Checkbox>
                <Text>
                  {t('By signing up you agree to our')}{' '}
                  <Link to={`/privacy-policy?lang=${translateLang}`}>{t('Privacy policy')}</Link> {t('and')}{' '}
                  <Link to={`/terms-and-conditions?lang=${translateLang}`}>{t('Terms of Service')}</Link>
                </Text>
              </Checkbox>
            </Form.Item>
            <Button
              size="large"
              loading={settingNewPw}
              onClick={() => form.submit()}
              type="primary"
              style={{ borderRadius: 15 }}
              className="login-form-button"
            >
              {userHasPassword ? t('Update password') : t('Create account')}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SetPasswordView;

const LanguagePopoverContent: FC<{ updateLanguage: (newLang: string) => void }> = ({ updateLanguage }) => {
  const languages = [
    { key: 'en', title: 'English' },
    { key: 'et', title: 'Eesti' }
  ];

  return (
    <div className="complok-header-profile-popover">
      {languages.map((action, index) => (
        <div
          onClick={() => updateLanguage(action.key)}
          className={`complok-header-profile-popover-item ${
            index === 0 ? 'complok-header-profile-popover-item-withspace' : ''
          }`}
          key={`complok-header-popover-action-${index}`}
        >
          {action.title}
        </div>
      ))}
    </div>
  );
};
