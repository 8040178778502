/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Dropdown, Menu, Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Close from "components/icons/Close";
import PlayIcon from "components/icons/PlayIcon";
import { formatSecondsToMinutesWithSeconds } from "helpers";
import { Dictionary, flatMap } from "lodash";
import { CSSProperties, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICourseSubject } from "types/Course/course.subject.types";
import { COURSE_CONTENT } from "types/Course/CourseContent/course.content.types";
import { isTypeofVideo } from "types/Course/CourseContent/course.content.video.types";
import { CourseContext } from "../CourseContext";
import CourseNavigation from "./CourseNavigation";

interface ICourseCompletionDetails {
  subject?: ICourseSubject;
  setTime?: (time: number) => void;

  tabs?: Array<"notes" | "resources" | "video_chapters" | "additional_text">;

  style?: CSSProperties;
}

const CourseDetails: FC<ICourseCompletionDetails> = ({
  subject,
  style,
  tabs = ["additional_text", "resources", "video_chapters"],
  setTime = () => console.info("Set Time function not initialized"),
}) => {
  const chapters = isTypeofVideo(subject?.content)
    ? subject?.content.chapters ?? []
    : [];

  const isSmallScreen = useBreakpoint().md === false;
  const [selectedTab, setSelectedTab] = useState<string>(
    subject?.content_type === "video" ? "video_chapters" : ""
  );
  const { course, courseProgress } = useContext(CourseContext);
  const allSubjects = flatMap(
    course?.ordered_modules ?? [],
    (mod) => mod?.ordered_subjects
  );
  const examUnlocked =
    allSubjects.length <= (courseProgress?.completed_subjects?.length ?? 0);

  const { t } = useTranslation();

  useEffect(() => {
    if (subject?.content_type === "video" && isTypeofVideo(subject?.content)) {
      setSelectedTab(
        (subject?.content?.chapters ?? []).length > 0 ? "video_chapters" : ""
      );
    } else if (isSmallScreen) {
      setSelectedTab("content");
    }
  }, [subject, isSmallScreen]);

  if (!tabs) return <></>;

  if (!isSmallScreen && subject?.content_type === "quiz") {
    return null;
  }

  return (
    <div id="complok-coursecontent-details" style={style ?? {}}>
      <Tabs
        activeKey={selectedTab}
        onTabClick={(val) => setSelectedTab(val)}
        defaultValue={"content"}
      >
        {isSmallScreen && (
          <Tabs.TabPane tab={t("Course Content")} key="content">
            <CourseNavigation
              styles={{ padding: 0 }}
              showNavigation
              hideTitle
              hideClose
              course={course}
              courseProgress={courseProgress}
              hasExamUnlocked={examUnlocked}
            />
          </Tabs.TabPane>
        )}
        {chapters?.length > 0 &&
          tabs.includes("video_chapters") &&
          subject?.content_type === COURSE_CONTENT.VIDEO && (
            <Tabs.TabPane tab={t("Video Chapters")} key={"video_chapters"}>
              <VideoChapters chapters={chapters} setTime={setTime} />
            </Tabs.TabPane>
          )}
        {isTypeofVideo(subject?.content) &&
          subject?.content?.additional_text &&
          tabs.includes("additional_text") && (
            <Tabs.TabPane tab={t("Additional Text")} key={"additiona_text"}>
              <AdditionalText text={subject?.content?.additional_text ?? ""} />
            </Tabs.TabPane>
          )}
        {/* {tabs.includes('notes') && (
          <Tabs.TabPane tab={t('Notes')} key={'notes'}>
            <CourseNotes />
          </Tabs.TabPane>
        )} */}
      </Tabs>
    </div>
  );
};

const CourseNotes: FC = () => {
  const { notes, createNote, deleteNote } = useContext(CourseContext);
  const [newNoteContent, setNewNoteContent] = useState<string>();

  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>("All Lectures");
  const [sort, setSort] = useState<string>();
  const filterOptions: Dictionary<{ title: string }> = {
    showAll: { title: "All Lectures" },
    showCurrent: { title: "Current Lecture" },
  };
  const sortOptions: Dictionary<{ title: string }> = {
    mostRecent: { title: "Most Recent" },
    creationDate: { title: "Creation Date" },
  };

  const createNewNote = () => {
    if (!newNoteContent || newNoteContent === "") return;
    createNote(newNoteContent);
    setNewNoteContent("");
  };

  return (
    <div id="complok-coursecontent-details-notes">
      <div id="complok-coursecontent-details-notes-addnote">
        <textarea
          id="complok-coursecontent-details-notes-addnote-field"
          draggable={false}
          placeholder={t("Write note here")}
          value={newNoteContent}
          onChange={(event) => setNewNoteContent(event.target.value)}
        />
        <Button
          type={"primary"}
          disabled={!newNoteContent}
          onClick={createNewNote}
          id="complok-coursecontent-details-notes-addnote-add"
        >
          {t("Add Note")}
        </Button>
        <Button type={"text"} onClick={() => setNewNoteContent(undefined)}>
          {t("Clear")}
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <input
            style={{
              padding: "8px 0px 8px 12px",
              border: "1px solid #D5D5DC",
              minWidth: 240,
              borderRadius: 10,
            }}
            type="text"
            className="complok-coursecompletion-main-details-notes-header-search"
            placeholder={t("Search")}
          />
        </div>
        {/* <div style={{ display: "flex" }}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="all">{t("Show All Notes")}</Menu.Item>
              </Menu>
            }
          >
            <Button style={{ marginRight: 10 }}>{t("Show all")}</Button>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="date">{t("Show All Notes")}</Menu.Item>
              </Menu>
            }
          >
            <Button>{t("Sort By")}</Button>
          </Dropdown>
      </div>
      </div>
      {/* <div id="complok-coursecontent-details-notes-list">
        {notes.map((note, noteIndex) => (
          <div
            id="complok-coursecontent-details-notes-list-note"
            key={`complok-coursecompletion-note-${noteIndex}`}
          >
            <h4 className="complok-coursecompletion-main-details-notes-list-item-title">
              {note.content}
            </h4>
            <Close
              id="complok-coursecontent-details-notes-list-note-delete"
              onClick={() => deleteNote(note._id)}
            />
          </div>
        ))}
      </div> */}
      </div>
    </div>
  );
};

const VideoChapters: FC<{
  chapters: { _id: string; timestamp: number; title: string }[];
  setTime: (time: number) => void;
}> = ({ chapters, setTime }) => {
  const { videoTime } = useContext(CourseContext);

  const videoChapters = chapters
    .sort(
      (chapterA, chapterB) =>
        (chapterA?.timestamp ?? 0) - (chapterB?.timestamp ?? 0)
    )
    .map((chapter) => (
      <div
        onClick={() => {
          document
            .getElementById("complok-coursecontent-main")
            ?.scrollTo({ top: 0, behavior: "smooth" });
          setTime(chapter?.timestamp ?? 0);
        }}
        key={chapter._id}
        style={{
          cursor: "pointer",
          padding: "16px 32px",
          marginBottom: 10,
          display: "flex",
          background: "#FFF",
          border: "1px solid #F1F1F5",
          borderColor:
            (chapter?.timestamp ?? 0) < videoTime ? "#F99820" : "#F1F1F5",
          borderRadius: 15,
        }}
        id="complok-coursecontent-details-videochapters-chapter"
      >
        <PlayIcon
          id="complok-coursecontent-details-videochapters-chapter-button"
          style={{ minWidth: 24, minHeight: 24, maxHeight: 24, maxWidth: 24 }}
        />
        <h3 style={{ marginLeft: 16, marginRight: 30 }}>
          {formatSecondsToMinutesWithSeconds(chapter.timestamp)}
        </h3>
        <h3 className="complok-coursecompletion-main-details-video-chapters-chapter-title">
          {chapter.title}
        </h3>
      </div>
    ));

  return (
    <div id="complok-coursecontent-details-videochapters">{videoChapters}</div>
  );
};

const AdditionalText: FC<{ text?: string }> = ({ text }) => <div>{text}</div>;

export default CourseDetails;
